import { createAction, createActions, handleActions, combineActions } from 'redux-actions';
import { fasterActions } from '../../utils';
import urls from '@/api/urls';


const moduleName = 'wechat';

/**
 * TODO: 微信管理
 */

export const authorizerConfigs = fasterActions(moduleName, 'authorizerConfigs');
export const cardInfoLoad = fasterActions(`${moduleName}/cardInfoLoad`);
// 查询工具，红包信息查询接口
export const loadRedpacketQueryInfo = fasterActions(`${moduleName}/loadRedpacketQueryInfo`);

// export const wechatAuthorize = fasterActions(moduleName, 'wechatAuthorize');

export const setAuthorizerConfigs = fasterActions(moduleName, 'setAuthorizerConfigs');

export const loadExperiencers = fasterActions(moduleName, 'loadExperiencers');
export const addExperiencers = fasterActions(moduleName, 'addExperiencers');
export const editExperiencers = fasterActions(moduleName, 'editExperiencers');
export const deleteExperiencers = fasterActions(moduleName, 'deleteExperiencers');
export const enableOrDisabled = fasterActions(moduleName, 'enableOrDisabled');
export const checkExperiencers = fasterActions(moduleName, 'checkExperiencers');


export const getSecretKey = fasterActions(moduleName, 'getSecretKey');
export const addSecretKey = fasterActions(moduleName, 'addSecretKey');


export const getMenu = fasterActions(moduleName, 'getMenu');
export const getMenuFrom = fasterActions(moduleName, 'getMenuFrom');
export const publishMenu = fasterActions(moduleName, 'publishMenu');


export const addMenu = fasterActions(moduleName, 'addMenu');

export const putMenu = fasterActions(moduleName, 'putMenu');
export const exchangeMenu = fasterActions(moduleName, 'exchangeMenu');
export const delMenu = fasterActions(moduleName, 'delMenu');


//手机号设置
export const editPhoneSetting = fasterActions(moduleName, 'editPhoneSetting');




const initialState = {
  wechatLoad: false,
  redpacketQueryInfo: {},

  experiencersInfoloading: false,
  experiencersInfo: {},

  menuList: [],
}

const reducer = handleActions(
  {
    // 下拉列表
    [authorizerConfigs.REQUEST]: (state, { payload }) => ({ ...state }),
    [authorizerConfigs.SUCCESS]: (state, { payload }) => {
      let authorizerConfigsSelector;
      let wechatScriptionSelector;
      if (payload.res.content) {
        if (payload.type === 'WECHAT_SCRIPTION') {
          wechatScriptionSelector = payload.res.content.map(val => {
            return {
              text: val.name, value: val.authorizerAppId, authType: val.wechatAccountType, headUrl: val.headUrl
            }
          })
        } else {
          // payload.res.content.forEach(val => {
          //   authorizerConfigsSelector.push({ text: val.name, value: val.authorizerAppId });
          // });
          authorizerConfigsSelector = payload.res.content.map(val => {
            // console.log(val)
            return {
              text: val.name + (val.wechatAccountType == 'MINI_PROGRAM' ? '(小程序)' : '(公众号)'), value: val.authorizerAppId, authType: val.wechatAccountType, headUrl: val.headUrl
            }
          })
        }
      }
      return ({ ...state, authorizerConfigs: payload.res, authorizerConfigsSelector: authorizerConfigsSelector || state.authorizerConfigsSelector || [], wechatScriptionSelector: wechatScriptionSelector || state.wechatScriptionSelector || [] })
    },
    [authorizerConfigs.FAIL]: (state, { payload }) => ({ ...state, error: payload }),
    // 卡券信息查询
    [cardInfoLoad.REQUEST]: (state, { payload }) => ({ ...state, }),
    [cardInfoLoad.SUCCESS]: (state, { payload }) => ({ ...state, cardInfoObj: payload.card.value }),
    [cardInfoLoad.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

    [setAuthorizerConfigs.REQUEST]: (state, { payload }) => ({ ...state, setAuthorizerConfigs: '' }),
    [setAuthorizerConfigs.SUCCESS]: (state, { payload }) => ({ ...state, setAuthorizerConfigs: payload }),
    [setAuthorizerConfigs.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

    [loadRedpacketQueryInfo.REQUEST]: (state, { payload }) => ({ ...state }),
    [loadRedpacketQueryInfo.SUCCESS]: (state, { payload }) => {
      return ({ ...state, redpacketQueryInfo: payload })
    },
    [loadRedpacketQueryInfo.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

    //微信体验者

    [loadExperiencers.REQUEST]: (state, { payload }) => ({ ...state, experiencersInfoloading: true }),
    [loadExperiencers.SUCCESS]: (state, { payload }) => {
      return ({ ...state, experiencersInfo: payload, experiencersInfoloading: false })
    },
    [loadExperiencers.FAIL]: (state, { payload }) => ({ ...state, error: payload, experiencersInfoloading: false }),

    [addExperiencers.REQUEST]: (state, { payload }) => ({ ...state }),
    [addExperiencers.SUCCESS]: (state, { payload }) => {
      return ({ ...state })
    },
    [addExperiencers.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

    [editExperiencers.REQUEST]: (state, { payload }) => ({ ...state }),
    [editExperiencers.SUCCESS]: (state, { payload }) => {
      return ({ ...state })
    },
    [editExperiencers.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

    [deleteExperiencers.REQUEST]: (state, { payload }) => ({ ...state }),
    [deleteExperiencers.SUCCESS]: (state, { payload }) => {
      return ({ ...state })
    },
    [deleteExperiencers.FAIL]: (state, { payload }) => ({ ...state, error: payload }),
    [enableOrDisabled.REQUEST]: (state, { payload }) => { return { ...state } },
    [enableOrDisabled.SUCCESS]: (state, { payload }) => ({ ...state, }),
    [enableOrDisabled.FAIL]: (state, { payload }) => ({ ...state, error: payload, }),
    [checkExperiencers.REQUEST]: (state, { payload }) => ({ ...state }),
    [checkExperiencers.SUCCESS]: (state, { payload }) => {
      return ({ ...state })
    },
    [checkExperiencers.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

    // [wechatAuthorize.REQUEST]: (state, { payload }) => ({ ...state }),
    // [wechatAuthorize.SUCCESS]: (state, { payload }) => ({ ...state, wechatAuthorize: payload }),
    // [wechatAuthorize.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

    [getSecretKey.REQUEST]: (state, { payload }) => ({ ...state }),
    [getSecretKey.SUCCESS]: (state, { payload }) => ({ ...state }),
    [getSecretKey.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

    [addSecretKey.REQUEST]: (state, { payload }) => ({ ...state }),
    [addSecretKey.SUCCESS]: (state, { payload }) => ({ ...state }),
    [addSecretKey.FAIL]: (state, { payload }) => ({ ...state, error: payload }),


    [getMenu.REQUEST]: (state, { payload }) => ({ ...state, menuloading: true }),
    [getMenu.SUCCESS]: (state, { payload }) => ({ ...state, menuList: payload, menuloading: false, }),
    [getMenu.FAIL]: (state, { payload }) => ({ ...state, error: payload, menuloading: false, }),
 
    [getMenuFrom.REQUEST]: (state, { payload }) => ({ ...state }),
    [getMenuFrom.SUCCESS]: (state, { payload }) => ({ ...state }),
    [getMenuFrom.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

    [publishMenu.REQUEST]: (state, { payload }) => ({ ...state }),
    [publishMenu.SUCCESS]: (state, { payload }) => ({ ...state }),
    [publishMenu.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

    
    
    [addMenu.REQUEST]: (state, { payload }) => ({ ...state }),
    [addMenu.SUCCESS]: (state, { payload }) => ({ ...state }),
    [addMenu.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

    [putMenu.REQUEST]: (state, { payload }) => ({ ...state }),
    [putMenu.SUCCESS]: (state, { payload }) => ({ ...state }),
    [putMenu.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

    [exchangeMenu.REQUEST]: (state, { payload }) => ({ ...state }),
    [exchangeMenu.SUCCESS]: (state, { payload }) => ({ ...state }),
    [exchangeMenu.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

    

    [delMenu.REQUEST]: (state, { payload }) => ({ ...state }),
    [delMenu.SUCCESS]: (state, { payload }) => ({ ...state }),
    [delMenu.FAIL]: (state, { payload }) => ({ ...state, error: payload }),



    [editPhoneSetting.REQUEST]: (state, { payload }) => ({ ...state }),
    [editPhoneSetting.SUCCESS]: (state, { payload }) => ({ ...state }),
    [editPhoneSetting.FAIL]: (state, { payload }) => ({ ...state, error: payload }),
  },

  initialState
);

export default reducer;
