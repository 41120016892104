import { createAction, createActions, handleActions, combineActions } from 'redux-actions';
import { fasterActions } from '../../utils';

const moduleName = 'targetAll';

// 指标说明新加
export const changeTargetPaging = createAction(`${moduleName}/changeTargetPaging`);
export const targetDetailSum = fasterActions(moduleName, 'targetDetailSum');

const initialPoolState = {
  targetData:{},
  targetParams:{
      childModuleEn:'',
      indicatorsEn:''
  },
  targetDataList:{}
}
var targetDataList = {}
// Reducer
const reducer = handleActions(
  {

      [changeTargetPaging]: (state, { payload }) => {
          return { ...state, targetParams: { ...payload} }
      },

      [targetDetailSum.REQUEST]: (state, { payload }) => ({ ...state, targetData: {},loading:false }),
      [targetDetailSum.SUCCESS]: (state,  { payload = {} }) => {
        const targetData = payload;
        // targetDataList.push(targetData)
        for(let k in payload){
          targetDataList[k] = payload[k]
        }
        return {
          ...state,
          targetData,
          targetDataList
        }
      },
      [targetDetailSum.FAIL]: (state, { payload }) => ({ ...state, loading: false }),
      
  },
  initialPoolState
)
export default reducer;