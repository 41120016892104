import { createAction, createActions, handleActions, combineActions } from 'redux-actions';
import { fasterActions } from '../../utils';

const moduleName = 'dataScreen';

export const getwarning_statistics = fasterActions(moduleName, 'getwarning_statistics');
export const getwarning_events = fasterActions(moduleName, 'getwarning_events');
export const getlink_data = fasterActions(moduleName, 'getlink_data');

export const getout_control_rates = fasterActions(moduleName, 'getout_control_rates');
export const getinsp_control_rates = fasterActions(moduleName, 'getinsp_control_rates');


export const getscan_num = fasterActions(moduleName, 'getscan_num');
export const getuser_count = fasterActions(moduleName, 'getuser_count');


export const getmarketing_report = fasterActions(moduleName, 'getmarketing_report');


export const getlink_tendency = fasterActions(moduleName, 'getlink_tendency');

export const getarea_heat_maps = fasterActions(moduleName, 'getarea_heat_maps');

const initialPoolState = {
    warning_statistics: [],
    warning_events:{},
    link_data:{},
    out_control_rates:[],
    insp_control_rates:{},
    scan_num:{},
    user_count:{},
    marketing_report:{},
    link_tendency:{},
    area_heat_maps:{},
}

// Reducer
const reducer = handleActions(
    {
        [getwarning_statistics.REQUEST]: (state, { payload }) => ({ ...state, loading: true }),
        [getwarning_statistics.SUCCESS]: (state, { payload }) => ({ ...state, loading: false, warning_statistics: payload }),
        [getwarning_statistics.FAIL]: (state, { payload }) => ({ ...state, loading: false, error: payload }),


        [getwarning_events.REQUEST]: (state, { payload }) => ({ ...state, loading: true }),
        [getwarning_events.SUCCESS]: (state, { payload }) => ({ ...state, loading: false, warning_events: payload }),
        [getwarning_events.FAIL]: (state, { payload }) => ({ ...state, loading: false, error: payload }),


        [getlink_data.REQUEST]: (state, { payload }) => ({ ...state, loading: true }),
        [getlink_data.SUCCESS]: (state, { payload }) => ({ ...state, loading: false, link_data: payload }),
        [getlink_data.FAIL]: (state, { payload }) => ({ ...state, loading: false, error: payload }),


        [getout_control_rates.REQUEST]: (state, { payload }) => ({ ...state, loading: true }),
        [getout_control_rates.SUCCESS]: (state, { payload }) => ({ ...state, loading: false, out_control_rates: payload }),
        [getout_control_rates.FAIL]: (state, { payload }) => ({ ...state, loading: false, error: payload }),
        
        [getinsp_control_rates.REQUEST]: (state, { payload }) => ({ ...state, loading: true }),
        [getinsp_control_rates.SUCCESS]: (state, { payload }) => ({ ...state, loading: false, insp_control_rates: payload }),
        [getinsp_control_rates.FAIL]: (state, { payload }) => ({ ...state, loading: false, error: payload }),
  

        [getscan_num.REQUEST]: (state, { payload }) => ({ ...state, loading: true }),
        [getscan_num.SUCCESS]: (state, { payload }) => ({ ...state, loading: false, scan_num: payload }),
        [getscan_num.FAIL]: (state, { payload }) => ({ ...state, loading: false, error: payload }),
        
        [getuser_count.REQUEST]: (state, { payload }) => ({ ...state, loading: true }),
        [getuser_count.SUCCESS]: (state, { payload }) => ({ ...state, loading: false, user_count: payload }),
        [getuser_count.FAIL]: (state, { payload }) => ({ ...state, loading: false, error: payload }),
  
        [getmarketing_report.REQUEST]: (state, { payload }) => ({ ...state, loading: true }),
        [getmarketing_report.SUCCESS]: (state, { payload }) => ({ ...state, loading: false, marketing_report: payload }),
        [getmarketing_report.FAIL]: (state, { payload }) => ({ ...state, loading: false, error: payload }),

        [getlink_tendency.REQUEST]: (state, { payload }) => ({ ...state, loading: true }),
        [getlink_tendency.SUCCESS]: (state, { payload }) => ({ ...state, loading: false, link_tendency: payload }),
        [getlink_tendency.FAIL]: (state, { payload }) => ({ ...state, loading: false, error: payload }),
    
        [getarea_heat_maps.REQUEST]: (state, { payload }) => ({ ...state, loading: true }),
        [getarea_heat_maps.SUCCESS]: (state, { payload }) => ({ ...state, loading: false, area_heat_maps: payload }),
        [getarea_heat_maps.FAIL]: (state, { payload }) => ({ ...state, loading: false, error: payload }),
    

        
    },
    initialPoolState
)
export default reducer;