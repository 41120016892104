import { handleActions } from 'redux-actions';
import { fasterActions } from '../../utils';

const moduleName = 'amountBill';

export const loadList = fasterActions(moduleName, 'loadList');
export const loadListSjf = fasterActions(moduleName, 'loadListSjf');
export const loadListZl = fasterActions(moduleName, 'loadListZl');
export const loadOverview = fasterActions(moduleName, 'loadOverview');
// export const loadListSjf = fasterActions(moduleName, 'loadListSjf');
export const downLoadAsyn = fasterActions(moduleName, 'downLoadAsyn');
export const downLoadAsynSjf = fasterActions(moduleName, 'downLoadAsynSjf');
export const downLoadAsynZl = fasterActions(moduleName, 'downLoadAsynZl');

const initialState = {
    loading: false,
    loaded: false,

    oLoading: false, //红包概览

    list: {},
    listSjf:{},
    listZl:{},
};

const reducer = handleActions({
    [loadList.REQUEST]: (state, { payload }) => ({ ...state,  loading: true }),
    [loadList.SUCCESS]: (state, { payload }) => ({ ...state, loading: false, listLoaded: true, list: payload }),
    [loadList.FAIL]: (state, { payload }) => ({ ...state, loading: false, listLoaded: false, list: {}, error: payload }),

    [loadListSjf.REQUEST]: (state, { payload }) => ({ ...state,  listSjf: true }),
    [loadListSjf.SUCCESS]: (state, { payload }) => ({ ...state, loading: false, listLoaded: true, listSjf: payload }),
    [loadListSjf.FAIL]: (state, { payload }) => ({ ...state, loading: false, listLoaded: false, listSjf: {}, error: payload }),

    [loadListZl.REQUEST]: (state, { payload }) => ({ ...state,  listZl: true }),
    [loadListZl.SUCCESS]: (state, { payload }) => ({ ...state, loading: false, listLoaded: true, listZl: payload }),
    [loadListZl.FAIL]: (state, { payload }) => ({ ...state, loading: false, listLoaded: false, listZl: {}, error: payload }),

    [loadOverview.REQUEST]: (state, { payload }) => ({ ...state,  Loading: true }),
    [loadOverview.SUCCESS]: (state, { payload }) => ({ ...state, loLoading: false, list: payload }),
    [loadOverview.FAIL]: (state, { payload }) => ({ ...state, oLoading: false, error: payload }),

    [downLoadAsyn.REQUEST]: (state, { payload }) => ({ ...state,  Loading: true }),
    [downLoadAsyn.SUCCESS]: (state, { payload }) => ({ ...state, }),
    [downLoadAsyn.FAIL]: (state, { payload }) => ({ ...state, oLoading: false, error: payload }),

    [downLoadAsynSjf.REQUEST]: (state, { payload }) => ({ ...state,  Loading: true }),
    [downLoadAsynSjf.SUCCESS]: (state, { payload }) => ({ ...state, }),
    [downLoadAsynSjf.FAIL]: (state, { payload }) => ({ ...state, oLoading: false, error: payload }),

    [downLoadAsynZl.REQUEST]: (state, { payload }) => ({ ...state,  Loading: true }),
    [downLoadAsynZl.SUCCESS]: (state, { payload }) => ({ ...state, }),
    [downLoadAsynZl.FAIL]: (state, { payload }) => ({ ...state, oLoading: false, error: payload }),
    
}, initialState);

export default reducer;


