import { createAction, createActions, handleActions, combineActions } from 'redux-actions';
import { fasterActions } from '../../utils';
import urls from '@/api/urls';

const moduleName = 'whPurchaseApproval';

/**
 * TODO: 企业管理  批货审批
 */
export const loadWhPurchaseApprovalList = fasterActions(moduleName, 'loadWhPurchaseApprovalList');
export const loadWhPurchaseApprovalId = fasterActions(moduleName, 'loadWhPurchaseApprovalId');
export const createWhPurchaseApproval = fasterActions(moduleName, 'createWhPurchaseApproval');
export const editWhPurchaseApproval = fasterActions(moduleName, 'editWhPurchaseApproval');
export const ableWhPurchaseApproval = fasterActions(moduleName, 'ableWhPurchaseApproval');
export const deleteWhPurchaseApproval = fasterActions(moduleName, 'deleteWhPurchaseApproval');



const initialState = {

  whPurchaseApprovalList: [],
  whPurchaseApprovalListLoading: false,
  whPurchaseApprovalListLoaded: false,

}

const reducer = handleActions(
  {

    [loadWhPurchaseApprovalList.REQUEST]: (state, { payload }) => ({ ...state, whPurchaseApprovalListLoading: true }),
    [loadWhPurchaseApprovalList.SUCCESS]: (state, { payload }) => ({ ...state, whPurchaseApprovalListLoading: false, loadWhPurchaseApprovalList: true, whPurchaseApprovalList: payload }),
    [loadWhPurchaseApprovalList.FAIL]: (state, { payload }) => ({ ...state, whPurchaseApprovalListLoading: false, loadWhPurchaseApprovalList: false, error: payload }),

    [loadWhPurchaseApprovalId.REQUEST]: (state, { payload }) => ({ ...state }),
    [loadWhPurchaseApprovalId.SUCCESS]: (state, { payload }) => ({ ...state }),
    [loadWhPurchaseApprovalId.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

    [createWhPurchaseApproval.REQUEST]: (state, { payload }) => ({ ...state }),
    [createWhPurchaseApproval.SUCCESS]: (state, { payload }) => ({ ...state }),
    [createWhPurchaseApproval.FAIL]: (state, { payload }) => ({ ...state }),
    [editWhPurchaseApproval.REQUEST]: (state, { payload }) => ({ ...state ,editWhPurchaseApproval:payload}),
    [editWhPurchaseApproval.SUCCESS]: (state, { payload }) => ({ ...state ,editWhPurchaseApproval:payload}),
    [editWhPurchaseApproval.FAIL]: (state, { payload }) => ({ ...state,editWhPurchaseApproval:payload }),
    [ableWhPurchaseApproval.REQUEST]: (state, { payload }) => ({ ...state ,ableWhPurchaseApproval:payload}),
    [ableWhPurchaseApproval.SUCCESS]: (state, { payload }) => ({ ...state ,ableWhPurchaseApproval:payload}),
    [ableWhPurchaseApproval.FAIL]: (state, { payload }) => ({ ...state ,ableWhPurchaseApproval:payload}),

    [deleteWhPurchaseApproval.REQUEST]: (state, { payload }) => ({ ...state }),
    [deleteWhPurchaseApproval.SUCCESS]: (state, { payload }) => ({ ...state }),
    [deleteWhPurchaseApproval.FAIL]: (state, { payload }) => ({ ...state }),
    
    
  },
  initialState
);

export default reducer;
