import { createAction, handleActions } from 'redux-actions';
import { fasterActions } from '../../utils';

const moduleName = 'sendWay';


export const loadList = fasterActions(moduleName, 'loadList');
export const updateSendWay = fasterActions(moduleName, 'updateSendWay');
export const addSendWay = fasterActions(moduleName, 'addSendWay');
export const delSendWay = fasterActions(moduleName, 'delSendWay');
export const updateSelected = createAction(`${moduleName}/updateSelected`);

const initialState = {
  listLoaded: false,
  selected: {},
}

const reducer = handleActions(
  {
    [loadList.SUCCESS]: (state, { payload }) => {
      let selector = [{ value: '-1', text: '常规发放' }];
      for (let elem of payload.content.values()) {
        selector.push({ value: elem.id, text: elem.name })
      }
      return {
        ...state,
        selector,
        list: payload.content,
        listLoaded: true,
        // selected: payload.content && payload.content[0]
      }
    },
    [updateSelected]: (state, { payload }) => {
      if (payload.new) {
        return { ...state, selected: { ...payload, incentiveType: 'QR_CODE', purpose: "FORMAL_USE" } }
      }
      return { ...state, selected: payload }
    },
    // [addSelectedDetailsItem]: (state, { payload }) => {
      
    //   let details = [];
    //   if (payload && payload.details) {
    //     details = [...payload.details]
    //   }
    //   details.push({});
    //   return { ...state, selected: { ...payload, details } };
    // },
    // [delSelectedDetailsItem]: (state, { payload }) => {
    //   let details = [...state.selected.details];
    //   details.splice(payload, 1);
    //   return { ...state, selected: { ...state.selected, details } }
    // },
    // [updateConsumptionCheckedItems]: (state, { payload }) => {
    //   const { checked, value } = payload;
    //   let checkedItems = [...state.checkedItems];
    //   if (checked) {
    //     checkedItems.push(value);
    //   } else {
    //     const index = checkedItems.indexOf(value);
    //     checkedItems.splice(index, 1);
    //   }
    //   return { ...state, checkedItems }
    // },
    [updateSendWay.REQUEST]: (state, { payload }) => {
      return { ...state, updateSendWayLoading: true }
    },
    [updateSendWay.SUCCESS]: (state, { payload }) => {
      return { ...state, updateSendWayLoading: false }
    },
    [updateSendWay.FAIL]: (state, { payload }) => {
      return { ...state, updateSendWayLoading: false }
    },
    [addSendWay.REQUEST]: (state, { payload }) => {
      return { ...state, addSendWayLoading: true }
    },
    [addSendWay.SUCCESS]: (state, { payload }) => {
      return { ...state, addSendWayLoading: false, selected: {} }
    },
    [addSendWay.FAIL]: (state, { payload }) => {
      return { ...state, addSendWayLoading: false }
    },
  },
  initialState
);

export default reducer;