// import '@babel/polyfill';
import ReactDOM from 'react-dom';
import React from 'react';
// import * as stores from './stores';
import App from './pages/App';
import './styles/index.scss';
import 'moment/locale/zh-cn';
import configureStore from './redux/store/configureStore';

import { store as layoutStore } from '@ice/stark-data'; // 应用之前通信的数据存储

const initialState = window.__INITIAL_STATE__;
export const store = configureStore(initialState);

layoutStore.set('commonStore', store); // store通信给子应用

// configure({ enforceActions: 'always' });
// Date = function (Date) {
//   MyDate.prototype = Date.prototype;
//   return MyDate;

//   function MyDate() {
//     // 当只有一个参数并且参数类型是字符串时，把字符串中的-替换为/
//     if (arguments.length === 1) {
//       let arg = arguments[0];
//       if (Object.prototype.toString.call(arg) === '[object String]' && arg.indexOf('T') === -1) {
//         arguments[0] = arg.replace(/-/g, "/");
//         // console.log(arguments[0]);
//       }
//     }
//     let bind = Function.bind;
//     let unbind = bind.bind(bind);
//     return new (unbind(Date, null).apply(null, arguments));
//   }
// }(Date);

ReactDOM.render((
  <App store={store} />
), document.getElementById('root'));



