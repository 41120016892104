import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap, map, catchError, concatMap } from 'rxjs/operators';
import { ajax } from '../../ajax';
import urls from '../../../api/urls';
import * as uiActions from '../ui';
import queryString from 'query-string';
import * as actions from './mobileManagement';
import { ajaxBaseConfig, actionApi } from '../../utils';

export const getAcctPermissionPageEpic = actions$ => actions$.pipe(
  ofType(actions.getAcctPermissionPage.REQUEST.toString()),
  mergeMap(action => { actionApi()
    const { page = 0, size = 20, word = '', sort = 'id,desc', } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.mobile.role.roleList}?page=${page}&size=${size}&word=${word}` 
    }).pipe(
      map(res => {
        return actions.getAcctPermissionPage.SUCCESS(res.response)
      }),
      catchError(error => of(actions.getAcctPermissionPage.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);

// 禁用、启用角色
export const enableOrDisabledRoleEpic = (actions$, state$) => actions$.pipe(
  ofType(actions.enableOrDisabledRole.REQUEST.toString()),
  mergeMap(action => {
      actionApi()
      const { id = '', status = '' } = action.payload;
      return (
          ajax({
              ...ajaxBaseConfig,
              url: `${urls.mobile.role.status}?id=${id}&status=${status}`,
              method: 'PUT',
          }).pipe(
              concatMap(res => [actions.enableOrDisabledRole.SUCCESS(Object.assign(res.response)), actions.getAcctPermissionPage.REQUEST(state$.value.role.roleSearch)]),
              catchError(error => of(actions.enableOrDisabledRole.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
          )
      )
  })
);

// 创建角色
export const addAcctPermissionEpic = (actions$, state$) => actions$.pipe(
  ofType(actions.addAcctPermission.REQUEST.toString()),
  mergeMap(action => {
      actionApi()
      const { data, history } = action.payload
      return (
          ajax({
              ...ajaxBaseConfig,
              url: `${urls.mobile.role.addRole}`,
              method: 'POST',
              body: data,
          }).pipe(
              map(res => {
                  history.goBack();
                  return actions.addAcctPermission.SUCCESS(Object.assign(res.response))
              }),
              catchError(error => of(actions.addAcctPermission.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
          )
      )
  })
);

// 编辑角色
export const editAcctPermissionEpic = (actions$, state$) => actions$.pipe(
  ofType(actions.editAcctPermission.REQUEST.toString()),
  mergeMap(action => {
      actionApi()
      const { data, history } = action.payload;
      return (
          ajax({
              ...ajaxBaseConfig,
              url: `${urls.mobile.role.editRole}`,
              method: 'POST',
              body: data,
          }).pipe(
              map(res => {
                  history.goBack();
                  return actions.editAcctPermission.SUCCESS(Object.assign(res.response))
              }),
              catchError(error => of(actions.editAcctPermission.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
          )
      )
  })
);

// 根据id查角色详情
export const getAcctPermissionInfoEpic = (actions$, state$) => actions$.pipe(
  ofType(actions.getAcctPermissionInfo.REQUEST.toString()),
  mergeMap(action => {
      actionApi()
      const { id, callback } = action.payload;
      return (
          ajax({
              ...ajaxBaseConfig,
              url: `${urls.mobile.role.roleInfo}?roleId=${id}`,
          }).pipe(
              map(res => {
                  callback && callback(res.response);
                  return actions.getAcctPermissionInfo.SUCCESS(Object.assign(res.response))
              }),
              catchError(error => of(actions.getAcctPermissionInfo.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
          )
      )
  })
);


// 角色选框树
export const getAcctPermissionTreeEpic = (actions$, state$) => actions$.pipe(
  ofType(actions.getAcctPermissionTree.REQUEST.toString()),
  mergeMap(action => {
      actionApi()
      return (
          ajax({
              ...ajaxBaseConfig,
              url: `${urls.mobile.role.roleTree}`,
          }).pipe(
              map(res => {
                  return actions.getAcctPermissionTree.SUCCESS(Object.assign(res.response))
              }),
              catchError(error => of(actions.getAcctPermissionTree.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
          )
      )
  })
);