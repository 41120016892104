import { createAction, handleActions } from 'redux-actions';
import { fasterActions } from '../../utils';

const moduleName = 'mobile';

// 账号权限
export const changeRoleSearch = createAction(`${moduleName}/changeRoleSearch`);
export const getAcctPermissionPage = fasterActions(moduleName, 'getAcctPermissionPage');
export const getAcctPermissionTree = fasterActions(moduleName, 'getAcctPermissionTree');
export const getAcctPermissionInfo = fasterActions(moduleName, 'getAcctPermissionInfo');
export const addAcctPermission = fasterActions(moduleName, 'addAcctPermission');
export const editAcctPermission = fasterActions(moduleName, 'editAcctPermission');
export const enableOrDisabledRole = fasterActions(moduleName, 'enableOrDisabledRole');




const initialState = {
  roleSearch: {
    page: 0,
    size: 20
  },
  roleTableData: [],
  acctPermissionTree: [],
  totalElements: 0, /* 总数据条数 */
  perPageCountList: [20, 50, 100], // 每页大小候选
}

const reducer = handleActions(
  {
    [changeRoleSearch]: (state, { payload }) => {
      return { ...state, roleSearch: { ...state.roleSearch, ...payload } }
    },

    [getAcctPermissionPage.REQUEST]: (state, { payload }) => ({ ...state, roleLoading: true, roleSearch: payload }),
    [getAcctPermissionPage.SUCCESS]: (state, { payload }) => {
      const { content = [], totalElements = 0 } = payload;
      return { ...state, roleLoading: false, roleTableData: content, totalElements }
    },
    [getAcctPermissionPage.FAIL]: (state, { payload }) => ({ ...state, roleLoading: false, error: payload }),

    // 禁用、启用角色
    [enableOrDisabledRole.REQUEST]: (state, { payload }) => {
      return { ...state }
    },
    [enableOrDisabledRole.SUCCESS]: (state, { payload }) => ({ ...state, }),
    [enableOrDisabledRole.FAIL]: (state, { payload }) => ({ ...state, error: payload, }),

    [getAcctPermissionTree.REQUEST]: (state, { payload }) => ({ ...state, loading: true }),
    [getAcctPermissionTree.SUCCESS]: (state, { payload }) => ({ ...state, loading: false, acctPermissionTree: payload }),
    [getAcctPermissionTree.FAIL]: (state, { payload }) => ({ ...state, loading: false, error: payload }),

    [getAcctPermissionInfo.REQUEST]: (state, { payload }) => ({ ...state, loading: true }),
    [getAcctPermissionInfo.SUCCESS]: (state, { payload }) => ({ ...state, loading: false }),
    [getAcctPermissionInfo.FAIL]: (state, { payload }) => ({ ...state, loading: false, error: payload }),

    [addAcctPermission.REQUEST]: (state, { payload }) => ({ ...state, disabled: true }),
    [addAcctPermission.SUCCESS]: (state, { payload }) => ({ ...state, disabled: false }),
    [addAcctPermission.FAIL]: (state, { payload }) => ({ ...state, disabled: false, error: payload }),

    [editAcctPermission.REQUEST]: (state, { payload }) => ({ ...state, disabled: true }),
    [editAcctPermission.SUCCESS]: (state, { payload }) => ({ ...state, disabled: false }),
    [editAcctPermission.FAIL]: (state, { payload }) => ({ ...state, disabled: false, error: payload }),
  },
  initialState
)

export default reducer;