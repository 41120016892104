import { of } from "rxjs"
import { ofType } from "redux-observable"
import { mergeMap, map, catchError,concatMap } from "rxjs/operators"
import { ajax } from "rxjs/ajax"
import urls from "../../../api/urls"
import * as uiActions from '../ui';
import queryString from 'query-string';
import { trims } from 'ucode-utils'
import * as actions from "./subscriber"
import { ajaxBaseConfig, apiBaseConfig, actionApi } from "../../utils";

// 以下这个作为模板进行参考
// export const searchEpic = actions$ => actions$.pipe(
//   ofType(actions.search.REQUEST.toString()),
//   mergeMap(action => {
//     actionApi();
//     return ajax({
//       ...apiBaseConfig,
//       url: `${urls.pointMall.order.order}/status-count`,
//     }).pipe(
//       map(res => actions.search.SUCCESS(res.response)),
//       catchError(error => of(actions.search.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
//     )
//   }
//   )
// );

// 获取列表
export const subscriberEpic = actions$ => actions$.pipe(
  ofType(actions.getSubscriberList.REQUEST.toString()),
  mergeMap(action => {
    actionApi();
    const { page = 0, size = 20} = action.payload;
    return ajax({
      ...apiBaseConfig,
      method: 'GET',
      url: `${urls.subscribeMsg.config}?page=${page}&size=${size}`,
    }).pipe(
      map(res => actions.getSubscriberList.SUCCESS(res.response)),
      catchError(error => of(actions.getSubscriberList.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);

// 提交表单
export const subscriberSubmitEpic = actions$ => actions$.pipe(
  ofType(actions.subscriberSubmit.REQUEST.toString()),
  mergeMap(action => {
    actionApi();
    const { form } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      method: 'POST',
      url: `${urls.subscribeMsg.config}`,
      body: trims(form)
    }).pipe(
      mergeMap(res =>{
        return [actions.subscriberSubmit.SUCCESS(res.response),actions.getSubscriberList.REQUEST({page:0,size:20})]
      }),
      catchError(error => of(actions.subscriberSubmit.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);

// 删除当前项列表
export const subscriberDeleteEpic = actions$ => actions$.pipe(
  ofType(actions.subscriberDelete.REQUEST.toString()),
  mergeMap(action => {
    actionApi();
    const { id,page } = action.payload;
    console.log('id',action.payload)
    return ajax({
      ...ajaxBaseConfig,
      method: 'DELETE',
      url: `${urls.subscribeMsg.config}/${id}`,
    }).pipe(
      mergeMap(res => {
        return [actions.subscriberDelete.SUCCESS(res.response),actions.getSubscriberList.REQUEST({page,size:20})]
      }),
      catchError(error => of(actions.subscriberDelete.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);

// 获取订阅配置列表
export const subscriberConfigureEpic = actions$ => actions$.pipe(
  ofType(actions.subscriberConfigureList.REQUEST.toString()),
  mergeMap(action => {
    actionApi();
    const { id ,callback } = action.payload;
    return ajax({
      ...apiBaseConfig,
      method: 'GET',
      url: `${urls.subscribeMsg.findByMiniAppSubscribeId}?miniAppSubscribeId=${id}`,
    }).pipe(
      concatMap(res => {
        callback && callback(res.response);
        return [actions.subscriberConfigureList.SUCCESS(res.response)];
      }),
      catchError(error => of(actions.subscriberConfigureList.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);
// 修改订阅表单
export const editSubscriberEpic = actions$ => actions$.pipe(
  ofType(actions.editSubscriber.REQUEST.toString()),
  mergeMap(action => {
    actionApi();
    const { form } = action.payload;
    console.log('form',form)
    return ajax({
      ...ajaxBaseConfig,
      method: 'PUT',
      url: `${urls.subscribeMsg.config}`,
      body: trims(form)
    }).pipe(
      mergeMap(res =>{
        return [actions.editSubscriber.SUCCESS(res.response),actions.getSubscriberList.REQUEST({page:0,size:20})]
      }),
      catchError(error => of(actions.editSubscriber.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);

// 表单回显
export const echoEpic = actions$ => actions$.pipe(
  ofType(actions.echo.REQUEST.toString()),
  mergeMap(action => {
    actionApi();
    const { id,callback } = action.payload;
    return ajax({
      ...apiBaseConfig,
      method: 'GET',
      url: `${urls.subscribeMsg.detail}/${id}`,
    }).pipe(
      concatMap(res => {
        callback && callback(res.response);
        return [actions.echo.SUCCESS(res.response)];
      }),
      // map(res => actions.echo.SUCCESS(res.response)),
      catchError(error => of(actions.echo.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);

