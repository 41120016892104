import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap, map, catchError, concatMap, debounce } from 'rxjs/operators';
import { ajax } from '../../ajax';
import urls from '../../../api/urls';
import * as uiActions from '../ui';
import queryString from 'query-string';
import * as actions from './h5CodePackage';
import { ajaxBaseConfig, actionApi } from '../../utils';

/**
 * TODO: H5代码包管理
 */

// Epics
// H5代码包table
export const h5CodePackageEpic = actions$ => actions$.pipe(
  ofType(actions.h5CodePackage.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { keyWords = '', page = 0, size = 20, sort = '' } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.code.h5CodePackage.h5CodePackageTable}?keyWords=${keyWords}&page=${page}&size=${size}&sort=${sort}`,
      method: 'GET',
    }).pipe(
      map(res => actions.h5CodePackage.SUCCESS(res.response)),
      catchError(error => of(actions.h5CodePackage.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);

// add H5代码包
export const h5CodePackageaddEpic = actions$ => actions$.pipe(
  ofType(actions.h5CodePackageadd.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const data = { ...action.payload.data, associated: false, }
    const { callback } = action.payload
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.code.h5CodePackage.h5CodePackageaddPackage}`,
      method: action.payload.data.id ? 'PUT' : 'POST',
      body: data
    }).pipe(
      concatMap(res => {
        callback && callback(res.response)
        return [actions.h5CodePackageadd.SUCCESS(res.response)]
      }),
      catchError(error => of(actions.h5CodePackageadd.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);
//删除
export const h5CodePackageDelEpic = actions$ => actions$.pipe(
  ofType(actions.h5CodePackageDel.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { callback } = action.payload
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.code.h5CodePackage.h5CodePackageDel}/${action.payload.data.id}`,
      method: 'DELETE',
      body: { id: action.payload.data.id }
    }).pipe(
      concatMap(res => {
        callback && callback(res.response)
        return [actions.h5CodePackageDel.SUCCESS(res.response)]
      }),
      // concatMap(res => [actions.h5CodePackageDel.SUCCESS(res.response)]),
      catchError(error => of(actions.h5CodePackageDel.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);

// H5link table
export const h5LinkTableEpic = actions$ => actions$.pipe(
  ofType(actions.h5LinkTable.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { page = 0, size = 20, id = "" } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.code.h5CodePackage.h5linkTable}?page=${page}&size=${size}&h5PackId=${id}`,
      method: 'GET',
    }).pipe(
      map(res => actions.h5LinkTable.SUCCESS(res.response)),
      catchError(error => of(actions.h5LinkTable.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);
// add H5link
export const h5LinkaddEpic = (actions$, state$) => actions$.pipe(
  ofType(actions.h5Linkadd.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { callback } = action.payload
    const data = { ...action.payload }
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.code.h5CodePackage.h5Linkadd}`,
      method: 'POST',
      body: { ...data }
    }).pipe(
      concatMap(res => {
        callback && callback(res.response)
        return [actions.h5Linkadd.SUCCESS(res.response), actions.h5LinkTable.REQUEST({ page: state$.value.h5CodePackage.page, size: state$.value.h5CodePackage.size, id: state$.value.h5CodePackage.payload.h5PackId, })]
      }),
      catchError(error => {
        return of(actions.h5Linkadd.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
      })
    )
  }
  )
);

// list 规则
export const h5ruleTableEpic = actions$ => actions$.pipe(
  ofType(actions.h5ruleTable.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    let data = {}; let urlSearch = `${urls.code.h5CodePackage.h5ruleTable}`
    urlSearch = `${urlSearch}?page=${action.payload.page}&size=${action.payload.size}`
    return ajax({
      ...ajaxBaseConfig,
      url: `${urlSearch}`,
      method: 'GET',
      body: data
    }).pipe(
      map(res => actions.h5ruleTable.SUCCESS(res.response)),
      catchError(error => of(actions.h5ruleTable.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);

// add edit 规则
export const h5ruleAddEpic = actions$ => actions$.pipe(
  ofType(actions.h5ruleAdd.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { callback, data } = action.payload
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.code.h5CodePackage.h5ruleAdd}`,
      method: data.method,
      body: data,
    }).pipe(
      concatMap(res => {
        callback && callback(res.response)
        return [actions.h5ruleAdd.SUCCESS(res.response), actions.h5ruleTable.REQUEST({ page: 0, size: 20, method: 'GET' })]
      }),
      catchError(error => of(actions.h5ruleAdd.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);

//删除
export const h5ruleDelEpic = actions$ => actions$.pipe(
  ofType(actions.h5ruleDel.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { callback } = action.payload
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.code.h5CodePackage.h5ruleDel}/${action.payload.data.id}`,
      method: 'DELETE',
      body: { id: action.payload.data.id }
    }).pipe(
      concatMap(res => {
        callback && callback(res.response)
        return [actions.h5ruleDel.SUCCESS(res.response)]
      }),
      // concatMap(res => [actions.h5CodePackageDel.SUCCESS(res.response)]),
      catchError(error => of(actions.h5ruleDel.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);
//add  h5PathAdd
export const h5PathAddEpic = (actions$, state$) => actions$.pipe(
  ofType(actions.h5PathAdd.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { callback } = action.payload
    const data = { ...action.payload }
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.code.h5CodePackage.h5PathAdd}`,
      method: 'POST',
      body: { ...data }
    }).pipe(
      concatMap(res => {
        callback && callback(res.response)
        return [actions.h5PathAdd.SUCCESS(res.response), actions.h5ruleTable.REQUEST({ page: 0, size: 20, method: 'GET' })]
      }),
      catchError(error => {
        return of(actions.h5PathAdd.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
      })
    )
  }
  )
);
// edit
export const h5PathEditEpic = (actions$, state$) => actions$.pipe(
  ofType(actions.h5PathEdit.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { callback } = action.payload
    const data = { ...action.payload }
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.code.h5CodePackage.h5PathEdit}`,
      method: 'PUT',
      body: { ...data }
    }).pipe(
      concatMap(res => {
        callback && callback(res.response)
        return [actions.h5PathEdit.SUCCESS(res.response), actions.h5ruleTable.REQUEST({ page: 0, size: 20, method: 'GET' })]
      }),
      catchError(error => {
        return of(actions.h5PathEdit.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
      })
    )
  }
  )
);
//删除
export const h5PathDelEpic = actions$ => actions$.pipe(
  ofType(actions.h5PathDel.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { callback } = action.payload
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.code.h5CodePackage.h5PathDel}/${action.payload.data.id}`,
      method: 'DELETE',
      body: { id: action.payload.data.id }
    }).pipe(
      concatMap(res => {
        callback && callback(res.response)
        return [actions.h5PathDel.SUCCESS(res.response)]
      }),
      // concatMap(res => [actions.h5CodePackageDel.SUCCESS(res.response)]),
      catchError(error => of(actions.h5PathDel.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);

// 查询所有代码包 不带分页
export const h5PackageAllEpic = actions$ => actions$.pipe(
  ofType(actions.h5PackageAll.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.code.h5CodePackage.h5PackageAll}`,
      method: 'GET',
    }).pipe(
      map(res => actions.h5PackageAll.SUCCESS(res.response)),
      catchError(error => of(actions.h5PackageAll.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);

// 查询当前帐号域名 不带分页
export const domainAllEpic = actions$ => actions$.pipe(
  ofType(actions.domainAll.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.code.h5CodePackage.domainAll}?page=&size=&sort=id,desc&deptId=${action.payload.deptId}&isPage=false`,
      method: 'GET',
    }).pipe(
      map(res => actions.domainAll.SUCCESS(res.response)),
      catchError(error => of(actions.domainAll.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);