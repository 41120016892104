import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap, map, catchError, concatMap } from 'rxjs/operators';
import { ajax } from '../../ajax';
import urls from '../../../api/urls';
import * as uiActions from '../ui';
import queryString from 'query-string';
import * as actions from './fieldassign';
import { ajaxBaseConfig, apiBaseConfig, actionApi } from '../../utils';

/**
 * TODO: 字段分配
 */

// 列表
export const fieldAssignListEpic = actions$ => actions$.pipe(
    ofType(actions.fieldAssignList.REQUEST.toString()),
    mergeMap(action => {
        actionApi();
        const { page = 0, size = 20, dictDataType = '', code = '', dictTypeList = [] } = action.payload;
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.data.fieldAssign.dict}?page=${page}&size=${size}&dictDataType=${dictDataType}&code=${code}&dictTypeList=${dictTypeList}`
        }).pipe(
            map(res => actions.fieldAssignList.SUCCESS(res.response)),
            catchError(error => of(actions.fieldAssignList.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    }
    )
);

// 新增
export const fieldAssignAddEpic = actions$ => actions$.pipe(
    ofType(actions.fieldAssignAdd.REQUEST.toString()),
    mergeMap(action => {
        actionApi();
        const { dictDataType = '', dictIdList = [], callback } = action.payload;
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.data.fieldAssign.dict}`,
            method: 'post',
            body: {
                dictDataType: dictDataType,
                dictIdList: dictIdList
            }
        }).pipe(

            concatMap((res) =>{ 
                callback && callback();
                return [actions.fieldAssignList.REQUEST({ page: 0, size: 20, dictDataType: dictDataType })]
            }),
            catchError(error => of(actions.fieldAssignAdd.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    }
    )
);
// 删除
export const fieldAssignDeleteEpic = actions$ => actions$.pipe(
    ofType(actions.fieldAssignDelete.REQUEST.toString()),
    mergeMap(action => {
        actionApi();
        const { dictId = '', dictDataType = '',callback,dictTypeList,page=0,size=20} = action.payload;
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.data.fieldAssign.dict}/${dictId}?dictDataType=${dictDataType}`,
            method: 'delete'
        }).pipe(
            concatMap((res) =>{ 
                callback && callback();
                return [actions.fieldAssignList.REQUEST({ page: 0, size:size, dictDataType: dictDataType,dictTypeList:dictTypeList })]}),
            catchError(error => of(actions.fieldAssignDelete.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    }
    )
);
// 字段
export const typeListEpic = actions$ => actions$.pipe(
    ofType(actions.typeList.REQUEST.toString()),
    mergeMap(action => {
        actionApi();
        const { dictDataType = "" } = action.payload;
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.data.fieldAssign.typelist}?dictDataType=${dictDataType}`
        }).pipe(
            map(res => actions.typeList.SUCCESS(res.response)),
            catchError(error => of(actions.typeList.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    }
    )
);

// 下拉
export const fieldAssignSelectionEpic = actions$ => actions$.pipe(
    ofType(actions.fieldAssignSelection.REQUEST.toString()),

    mergeMap(action => {
        actionApi();
        const { data, callback } = action.payload;
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.data.fieldAssign.eseTypeList}?dictDataType=${data.dictDataType}`
            // url: `${urls.data.fieldAssign.typelist}?dictDataType=${data.dictDataType}`
        }).pipe(
            map(res => {
                callback && callback(res.response)
                return actions.fieldAssignSelection.SUCCESS(res.response)
            }),
            catchError(error => {
                console.log(error)
                return of(actions.fieldAssignSelection.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
            })
        )
    }
    )
);


// 字典企业关系查询(code name)
export const fieldAssignDictMapEpic = actions$ => actions$.pipe(
    ofType(actions.fieldAssignDictMap.REQUEST.toString()),

    mergeMap(action => {
        actionApi();
        const { data, callback } = action.payload;
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.data.fieldAssign.dictMap}?dictDataType=${data.dictDataType}`
        }).pipe(
            map(res => {
                callback && callback(res.response)
                return actions.fieldAssignDictMap.SUCCESS(res.response)
            }),
            catchError(error => of(actions.fieldAssignDictMap.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    }
    )
);

// 下拉
export const fieldAssignEseQueryEpic = actions$ => actions$.pipe(
    ofType(actions.fieldAssignEseQuery.REQUEST.toString()),

    mergeMap(action => {
        actionApi();
        const { dictDataType } = action.payload;
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.data.fieldAssign.eseQuery}?dictDataType=${dictDataType}`
        }).pipe(
            map(res => actions.fieldAssignEseQuery.SUCCESS(res.response)),
            catchError(error => of(actions.fieldAssignEseQuery.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    }
    )
);

// 增加或减少表头字段
export const configTableTitleEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.configTableTitle.REQUEST.toString()),
    mergeMap(action => {
        actionApi();

        const { dictDataType = '', removeDictId = [], addDictId = [] } = action.payload;
        
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.data.fieldAssign.configTableTitle}`,
            method: 'POST',
            body: {
                dictDataType: dictDataType,
                removeDictId: removeDictId,
                addDictId: addDictId
            },
        }).pipe(
            concatMap(res => {
                action.payload.callback && action.payload.callback(res);
                return []
            }),
            catchError(error => of(uiActions.notification(error.xhr.response))
            )
        )
    }
    )
);