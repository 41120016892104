import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap, map, catchError, concatMap } from 'rxjs/operators';
import { ajax } from '../../ajax';
import urls from '@/api/urls';
import * as uiActions from '../ui';
import qs from 'query-string';
import * as actions from './characteristic';
import { ajaxBaseConfig, actionApi } from '../../utils';

/**
 * 宴会类型
 */
// 获取宴会类型列表
export const banquetTypeTableListEpic = actions$ => actions$.pipe(
  ofType(actions.banquetTypeTableList.REQUEST.toString()),

  mergeMap(action => {
    actionApi()
    const { keywords = '', page = 0, size = 20, sort = '' } = action.payload;
    return (

      ajax({
        ...ajaxBaseConfig,
        url: `${urls.characteristic.banquetTypeList}?keywords=${keywords}&page=${page}&size=${size}&sort=${sort}`,
      }).pipe(
        map(res => actions.banquetTypeTableList.SUCCESS(res.response || [])),
        catchError(error => of(actions.banquetTypeTableList.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);

// 宴会类型创建
export const banquetTypeCreateEpic = actions$ => actions$.pipe(
  ofType(actions.banquetTypeCreate.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { callback } = action.payload;
    return (
      ajax({
        ...ajaxBaseConfig,
        method: 'POST',
        url: `${urls.characteristic.createBanquetType}`,
        body: action.payload.data,
      }).pipe(
        map(res => {
          callback && callback()
          return actions.banquetTypeCreate.SUCCESS(res.response)
        }),
        catchError(error => of(actions.banquetTypeCreate.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);

// 宴会类型编辑
export const banquetTypeEditEpic = actions$ => actions$.pipe(
  ofType(actions.banquetTypeEdit.REQUEST.toString()),

  mergeMap(action => {
    actionApi()
    const { data, callback } = action.payload;
    return (

      ajax({
        ...ajaxBaseConfig,
        method: 'PATCH',
        url: `${urls.characteristic.editBanquetType}`,
        body: action.payload.data,
      }).pipe(
        map(res => {
          callback && callback()
          return actions.banquetTypeEdit.SUCCESS(res)
        }),
        catchError(error => of(actions.banquetTypeEdit.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);

// 宴会类型删除
export const banquetTypeDeleteEpic = actions$ => actions$.pipe(
  ofType(actions.banquetTypeDelete.REQUEST.toString()),

  mergeMap(action => {
    actionApi()
    const { id, callback } = action.payload;
    return (

      ajax({
        ...ajaxBaseConfig,
        method: 'DELETE',
        url: `${urls.characteristic.editBanquetType}/${id}`,
      }).pipe(
        map(res => {
          callback && callback()
          return actions.banquetTypeDelete.SUCCESS(res)
        }),
        catchError(error => of(actions.banquetTypeDelete.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);

// 宴会类型改变状态
export const banquetTypeStatusEpic = actions$ => actions$.pipe(
  ofType(actions.banquetTypeStatus.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { data,callback } = action.payload;
    const { id,status } = data
    return (
      ajax({
        ...ajaxBaseConfig,
        method: 'PATCH',
        url: `${urls.characteristic.disableBanquetType}/${id}?status=${status}`,
        // body: action.payload.data,
      }).pipe(
        map(res => {
          callback && callback()
          return actions.banquetTypeStatus.SUCCESS(res.response)
        }),
        catchError(error => of(actions.banquetTypeStatus.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);

/***
 * 宴会政策
 */
// 获取宴会政策正式列表
export const banquetPolicyOnlineTableListEpic = actions$ => actions$.pipe(
  ofType(actions.banquetPolicyOnlineTableList.REQUEST.toString()),

  mergeMap(action => {
    actionApi()
    const { keywords = '', page = 0, size = 20, sort = '',callback = null } = action.payload;
    // let url =  `${urls.characteristic.banquetPolicyOnlineList}?keywords=${keywords}&page=${page}&size=${size}`
    // if(isAll) {
    //   url = `${urls.characteristic.banquetPolicyOnlineList}`
    // }
    return (
      ajax({
        ...ajaxBaseConfig,
        url: `${urls.characteristic.banquetPolicyOnlineList}?keywords=${keywords}&page=${page}&size=${size}&sort=${sort}`,
      }).pipe(
        map(res => {
          callback && callback(res.response)
          return actions.banquetPolicyOnlineTableList.SUCCESS(res.response || [])
        }),
        catchError(error => of(actions.banquetPolicyOnlineTableList.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);

// 获取宴会政策草稿列表
export const banquetPolicyTestTableListEpic = actions$ => actions$.pipe(
  ofType(actions.banquetPolicyTestTableList.REQUEST.toString()),

  mergeMap(action => {
    actionApi()
    const { keywords = '', page = 0, size = 20, sort = '' } = action.payload;
    return (

      ajax({
        ...ajaxBaseConfig,
        url: `${urls.characteristic.banquetPolicyTestList}?keywords=${keywords}&page=${page}&size=${size}&sort=${sort}`,
      }).pipe(
        map(res => actions.banquetPolicyTestTableList.SUCCESS(res.response || [])),
        catchError(error => of(actions.banquetPolicyTestTableList.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);

// 宴会政策创建
export const banquetPolicyTestCreateEpic = actions$ => actions$.pipe(
  ofType(actions.banquetPolicyTestCreate.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { callback } = action.payload;
    return (
      ajax({
        ...ajaxBaseConfig,
        method: 'POST',
        url: `${urls.characteristic.createBanquetPolicyTest}`,
        body: action.payload.data,
      }).pipe(
        map(res => {
          callback && callback(res.response)
          return actions.banquetPolicyTestCreate.SUCCESS(res.response)
        }),
        catchError(error => of(actions.banquetPolicyTestCreate.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);

// 宴会政策编辑
export const banquetPolicyTestEditEpic = actions$ => actions$.pipe(
  ofType(actions.banquetPolicyTestEdit.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { callback,data } = action.payload;
    const { step } = data
    return (
      ajax({
        ...ajaxBaseConfig,
        method: 'PATCH',
        url: `${urls.characteristic.editBanquetPolicyTest}?step=${step}`,
        body: action.payload.data,
      }).pipe(
        map(res => {
          callback && callback(res.response)
          return actions.banquetPolicyTestEdit.SUCCESS(res.response)
        }),
        catchError(error => of(actions.banquetPolicyTestEdit.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);

// 宴会政策查看详情
export const banquetPolicyTestViewEpic = actions$ => actions$.pipe(
  ofType(actions.banquetPolicyTestView.REQUEST.toString()),

  mergeMap(action => {
    actionApi()
    const { id,callback } = action.payload;
    return (

      ajax({
        ...ajaxBaseConfig,
        url: `${urls.characteristic.editViewBanquetPolicyTest}/${id}`,
      }).pipe(
        map(res => {
          callback && callback(res.response)
          return actions.banquetPolicyTestView.SUCCESS(res.response || [])
        }),
        catchError(error => of(actions.banquetPolicyTestView.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);

// 宴会政策历史详情
export const banquetPolicyHistoryViewEpic = actions$ => actions$.pipe(
  ofType(actions.banquetPolicyHistoryView.REQUEST.toString()),

  mergeMap(action => {
    actionApi()
    const { id,callback } = action.payload;
    return (

      ajax({
        ...ajaxBaseConfig,
        url: `${urls.characteristic.editViewBanquetPolicyHistory}/${id}`,
      }).pipe(
        map(res => {
          callback && callback(res.response)
          return actions.banquetPolicyHistoryView.SUCCESS(res.response || [])
        }),
        catchError(error => of(actions.banquetPolicyHistoryView.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);

// 宴会政策发布
export const banquetPolicyTestReleaseEpic = actions$ => actions$.pipe(
  ofType(actions.banquetPolicyTestRelease.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { callback,id,remark } = action.payload;
    return (
      ajax({
        ...ajaxBaseConfig,
        method: 'POST',
        url: `${urls.characteristic.releaseBanquetPolicyTest}/${id}?remark=${remark}`,
        body: {id,remark}
      }).pipe(
        map(res => {
          callback && callback(res.response)
          return actions.banquetPolicyTestRelease.SUCCESS(res.response)
        }),
        catchError(error => of(actions.banquetPolicyTestRelease.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);

// 宴会政策删除
export const banquetPolicyTestDeleteEpic = actions$ => actions$.pipe(
  ofType(actions.banquetPolicyTestDelete.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { callback,id } = action.payload;
    return (
      ajax({
        ...ajaxBaseConfig,
        method: 'DELETE',
        url: `${urls.characteristic.deleteBanquetPolicyTest}/${id}`,
        body: {id}
      }).pipe(
        map(res => {
          callback && callback(res.response)
          return actions.banquetPolicyTestDelete.SUCCESS(res.response)
        }),
        catchError(error => of(actions.banquetPolicyTestDelete.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);

// 宴会政策状态更改
export const banquetPolicyTestStatusEpic = actions$ => actions$.pipe(
  ofType(actions.banquetPolicyTestStatus.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { callback,status,id } = action.payload;
    return (
      ajax({
        ...ajaxBaseConfig,
        method: 'PATCH',
        url: `${urls.characteristic.disableBanquetPolicyOnline}/${id}?status=${status}`,
        body: status,
      }).pipe(
        map(res => {
          callback && callback(res.response)
          return actions.banquetPolicyTestStatus.SUCCESS(res.response)
        }),
        catchError(error => of(actions.banquetPolicyTestStatus.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);

// 政策历史
export const banquetPolicyHistoryEpic = actions$ => actions$.pipe(
  ofType(actions.banquetPolicyHistory.REQUEST.toString()),

  mergeMap(action => {
    actionApi()
    const {  page = 0, size = 20,mallId,sort  } = action.payload;
    console.log('action.payload', action.payload)
    return (

      ajax({
        ...ajaxBaseConfig,
        url: `${urls.characteristic.banquetPolicyHistory}/${mallId}?page=${page}&size=${size}&sort=${sort}`,
      }).pipe(
        map(res => actions.banquetPolicyHistory.SUCCESS(res.response || [])),
        catchError(error => of(actions.banquetPolicyHistory.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);

/**
 * 执行奖励
 */

// 历史
export const banquetRewardHistoryEpic = actions$ => actions$.pipe(
  ofType(actions.banquetRewardHistory.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const {  page = 0, size = 20,mallId,sort,operatorName = ''  } = action.payload;
    return (
      ajax({
        ...ajaxBaseConfig,
        url: `${urls.characteristic.banquetRewardHistory}?activityId=${mallId}&page=${page}&size=${size}&operatorName=${operatorName}&sort=${sort}`,
        body: {activityId: mallId}
      }).pipe(
        map(res => actions.banquetRewardHistory.SUCCESS(res.response || [])),
        catchError(error => of(actions.banquetRewardHistory.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);

// 历史详情
export const banquetRewardHistoryViewEpic = actions$ => actions$.pipe(
  ofType(actions.banquetRewardHistoryView.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const {  mallId = '',id = ''  } = action.payload;
    return (
      ajax({
        ...ajaxBaseConfig,
        url: `${urls.characteristic.banquetRewardHistoryView}/${mallId?mallId: id}`,
        body: {id: mallId?mallId: id}
      }).pipe(
        map(res => {
          action.payload.callback && action.payload.callback(res.response)
          return actions.banquetRewardHistoryView.SUCCESS(res.response || [])
        }),
        catchError(error => of(actions.banquetRewardHistoryView.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);

// 状态更改
export const banquetRewardTestStatusEpic = actions$ => actions$.pipe(
  ofType(actions.banquetRewardTestStatus.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { callback,status,id } = action.payload;
    return (
      ajax({
        ...ajaxBaseConfig,
        url: `${urls.characteristic.disableBanquetRewardOnline}`,
        body: {
          id,
          optionStatus:status
        },
      }).pipe(
        map(res => {
          callback && callback(res.response)
          return actions.banquetRewardTestStatus.SUCCESS(res.response)
        }),
        catchError(error => of(actions.banquetRewardTestStatus.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);

// 正式列表
export const banquetRewardOnlineTableListEpic = actions$ => actions$.pipe(
  ofType(actions.banquetRewardOnlineTableList.REQUEST.toString()),

  mergeMap(action => {
    actionApi()
    const { keywords = '', page = 0, size = 20, sort = 'createdTime,desc',activityType = 'BANQUET' } = action.payload;
    return (

      ajax({
        ...ajaxBaseConfig,
        url: `${urls.characteristic.banquetRewardOnlineList}?name=${keywords}&page=${page}&size=${size}&activityType=${activityType}&sort=${sort}`,
      }).pipe(
        map(res => actions.banquetRewardOnlineTableList.SUCCESS(res.response || [])),
        catchError(error => of(actions.banquetRewardOnlineTableList.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);
// 草稿列表
export const banquetRewardTestTableListEpic = actions$ => actions$.pipe(
  ofType(actions.banquetRewardTestTableList.REQUEST.toString()),

  mergeMap(action => {
    actionApi()
    const { keywords = '', page = 0, size = 20, sort = 'updatedTime,desc',activityType = 'BANQUET' } = action.payload;
    return (

      ajax({
        ...ajaxBaseConfig,
        url: `${urls.characteristic.banquetRewardTestList}?name=${keywords}&page=${page}&size=${size}&activityType=${activityType}&sort=${sort}`,
      }).pipe(
        map(res => actions.banquetRewardTestTableList.SUCCESS(res.response || [])),
        catchError(error => of(actions.banquetRewardTestTableList.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);

// 待发布列表
export const banquetRewardWaitReleaseListEpic = actions$ => actions$.pipe(
  ofType(actions.banquetRewardWaitReleaseList.REQUEST.toString()),

  mergeMap(action => {
    actionApi()
    const { keywords = '', page = 0, size = 20, sort = 'scheduledPublish,asc',activityType = 'BANQUET' } = action.payload;
    return (

      ajax({
        ...ajaxBaseConfig,
        url: `${urls.characteristic.banquetRewardWaitReleaseList}?word=${keywords}&page=${page}&size=${size}&activityType=${activityType}&sort=${sort}`,
      }).pipe(
        map(res => actions.banquetRewardWaitReleaseList.SUCCESS(res.response || [])),
        catchError(error => of(actions.banquetRewardWaitReleaseList.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);

// 创建
export const banquetRewardCreateEpic = actions$ => actions$.pipe(
  ofType(actions.banquetRewardCreate.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { callback } = action.payload;
    return (
      ajax({
        ...ajaxBaseConfig,
        method: 'POST',
        url: `${urls.characteristic.banquetRewardCreate}`,
        body: action.payload.data,
      }).pipe(
        map(res => {
          callback && callback(res.response)
          return actions.banquetRewardCreate.SUCCESS(res.response)
        }),
        catchError(error => of(actions.banquetRewardCreate.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);

// 编辑
export const banquetRewardEditEpic = actions$ => actions$.pipe(
  ofType(actions.banquetRewardEdit.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { callback } = action.payload;
    return (
      ajax({
        ...ajaxBaseConfig,
        method: 'PUT',
        url: `${urls.characteristic.banquetRewardEdit}`,
        body: action.payload.data,
      }).pipe(
        map(res => {
          callback && callback(res.response)
          return actions.banquetRewardEdit.SUCCESS(res.response)
        }),
        catchError(error => of(actions.banquetRewardEdit.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);

// 编辑
export const banquetRewardOnlineEditEpic = actions$ => actions$.pipe(
  ofType(actions.banquetRewardOnlineEdit.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { callback,id } = action.payload;
    return (
      ajax({
        ...ajaxBaseConfig,
        method: 'PUT',
        url: `${urls.characteristic.banquetRewardOnlineEdit}/${id}`,
        body: action.payload.data,
      }).pipe(
        map(res => {
          callback && callback(res.response)
          return actions.banquetRewardOnlineEdit.SUCCESS(res.response)
        }),
        catchError(error => of(actions.banquetRewardOnlineEdit.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);

// 获取正式活动详情
export const banquetRewardOnlineViewEpic = actions$ => actions$.pipe(
  ofType(actions.banquetRewardOnlineView.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { callback,id } = action.payload;
    return (
      ajax({
        ...ajaxBaseConfig,
        method: 'PUT',
        url: `${urls.characteristic.banquetRewardOnlineView}/${id}`,
      }).pipe(
        map(res => {
          callback && callback(res.response)
          return actions.banquetRewardOnlineView.SUCCESS(res.response)
        }),
        catchError(error => of(actions.banquetRewardOnlineView.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);

// 方案添加
export const banquetRewardAddProgrammeEpic = actions$ => actions$.pipe(
  ofType(actions.banquetRewardAddProgramme.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { callback,data } = action.payload;
    return (
      ajax({
        ...ajaxBaseConfig,
        method: 'POST',
        url: `${urls.characteristic.banquetRewardAddProgramme}`,
        body: data,
      }).pipe(
        map(res => {
          callback && callback(res.response)
          return actions.banquetRewardAddProgramme.SUCCESS(res.response)
        }),
        catchError(error => of(actions.banquetRewardAddProgramme.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);

// 修改方案
export const banquetRewardEditProgrammeEpic = actions$ => actions$.pipe(
  ofType(actions.banquetRewardEditProgramme.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { callback } = action.payload;
    return (
      ajax({
        ...ajaxBaseConfig,
        method: 'PUT',
        url: `${urls.characteristic.banquetRewardEditProgramme}`,
        body: action.payload.data,
      }).pipe(
        map(res => {
          callback && callback(res.response)
          return actions.banquetRewardEditProgramme.SUCCESS(res.response)
        }),
        catchError(error => of(actions.banquetRewardEditProgramme.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);

// 方案列表
export const banquetRewardListProgrammeEpic = actions$ => actions$.pipe(
  ofType(actions.banquetRewardListProgramme.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { callback,activityId,entryType } = action.payload;
    return (
      ajax({
        ...ajaxBaseConfig,
        method: 'GET',
        url: `${urls.characteristic.banquetRewardListProgramme}?activityId=${activityId}&entryType=${entryType}`,
      }).pipe(
        map(res => {
          console.log('res', res)
          callback && callback(res.response)
          return actions.banquetRewardListProgramme.SUCCESS(res.response)
        }),
        catchError(error => of(actions.banquetRewardListProgramme.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);

// 方案准则列表
export const banquetRewardListRuleEpic = actions$ => actions$.pipe(
  ofType(actions.banquetRewardListRule.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { callback,planIds } = action.payload;
    return (
      ajax({
        ...ajaxBaseConfig,
        method: 'GET',
        url: `${urls.characteristic.banquetRewardListRule}?planIds=${planIds}`,
        body: {planIds}
      }).pipe(
        map(res => {
          callback && callback(res.response)
          return actions.banquetRewardListRule.SUCCESS(res.response)
        }),
        catchError(error => of(actions.banquetRewardListRule.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);

// 方案准则列表
export const banquetRewardListOnlineRuleEpic = actions$ => actions$.pipe(
  ofType(actions.banquetRewardListOnlineRule.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { callback,planIds,activityId } = action.payload;
    return (
      ajax({
        ...ajaxBaseConfig,
        method: 'GET',
        url: `${urls.characteristic.banquetRewardListOnlineRule}?planIds=${planIds}&activityId=${activityId}`,
        body: {planIds,activityId}
      }).pipe(
        map(res => {
          callback && callback(res.response)
          return actions.banquetRewardListOnlineRule.SUCCESS(res.response)
        }),
        catchError(error => of(actions.banquetRewardListOnlineRule.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);

// 方案顺序调整
export const banquetRewardSortRuleEpic = actions$ => actions$.pipe(
  ofType(actions.banquetRewardSortRule.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { callback,data } = action.payload;
    return (
      ajax({
        ...ajaxBaseConfig,
        method: 'POST',
        url: `${urls.characteristic.banquetRewardSortRule}`,
        body: data
      }).pipe(
        map(res => {
          callback && callback(res.response)
          return actions.banquetRewardSortRule.SUCCESS(res.response)
        }),
        catchError(error => of(actions.banquetRewardSortRule.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);

// 获取活动详情
export const banquetRewardActivityHistoryEpic = actions$ => actions$.pipe(
  ofType(actions.banquetRewardActivityHistory.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { callback,id } = action.payload;
    return (
      ajax({
        ...ajaxBaseConfig,
        method: 'GET',
        url: `${urls.characteristic.banquetRewardActivityHistory}/${id}`,
      }).pipe(
        map(res => {
          callback && callback(res.response)
          return actions.banquetRewardActivityHistory.SUCCESS(res.response)
        }),
        catchError(error => of(actions.banquetRewardActivityHistory.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);

// 核销政策
export const rewardPolicyListEpic = actions$ => actions$.pipe(
  ofType(actions.rewardPolicyList.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    return (
      ajax({
        ...ajaxBaseConfig,
        method: 'GET',
        url: `${urls.characteristic.writeOffPolicyFind}`,
      }).pipe(
        map(res => {
          action.payload?.callback && action.payload.callback(res.response)
          return actions.rewardPolicyList.SUCCESS(res.response)
        }),
        catchError(error => of(actions.rewardPolicyList.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);

// 投放奖品
export const banquetRewardThrowEpic = actions$ => actions$.pipe(
  ofType(actions.banquetRewardThrow.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { callback,data } = action.payload;
    return (
      ajax({
        ...ajaxBaseConfig,
        method: 'POST',
        url: `${urls.characteristic.banquetRewardThrow}`,
        body: data
      }).pipe(
        map(res => {
          callback && callback(res.response)
          return actions.banquetRewardThrow.SUCCESS(res.response)
        }),
        catchError(error => of(actions.banquetRewardThrow.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);



// 正式版优先级
export const banquetLevelEpic = actions$ => actions$.pipe(
  ofType(actions.banquetLevel.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { callback,data } = action.payload;
    return (
      ajax({
        ...ajaxBaseConfig,
        method: 'POST',
        url: `${urls.characteristic.banquetLevel}`,
        body: data
      }).pipe(
        map(res => {
          callback && callback(res.response)
          return actions.banquetLevel.SUCCESS(res.response)
        }),
        catchError(error => of(actions.banquetLevel.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);

// 启用 暂停
export const banquetRewardOnlineStatusEpic = actions$ => actions$.pipe(
  ofType(actions.banquetRewardOnlineStatus.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { callback,id,optionStatus } = action.payload;
    return (
      ajax({
        ...ajaxBaseConfig,
        method: 'GET',
        url: `${urls.characteristic.banquetRewardStatus}?optionStatus=${optionStatus}&id=${id}`,
      }).pipe(
        map(res => {
          callback && callback(res.response)
          return actions.banquetRewardOnlineStatus.SUCCESS(res.response)
        }),
        catchError(error => of(actions.banquetRewardOnlineStatus.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);

export const releaseBanquetRewardEpic = actions$ => actions$.pipe(
  ofType(actions.releaseBanquetReward.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { callback,id,remark } = action.payload;
    return (
      ajax({
        ...ajaxBaseConfig,
        method: 'POST',
        url: `${urls.characteristic.releaseBanquetReward}`,
        body: {id,remark}
      }).pipe(
        map(res => {
          callback && callback(res.response)
          return actions.releaseBanquetReward.SUCCESS(res.response)
        }),
        catchError(error => of(actions.releaseBanquetReward.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);

// 定时发布
export const timeReleaseBanquetRewardEpic = actions$ => actions$.pipe(
  ofType(actions.timeReleaseBanquetReward.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { callback,id,remark,scheduledPublish } = action.payload;
    return (
      ajax({
        ...ajaxBaseConfig,
        method: 'POST',
        url: `${urls.characteristic.banquetRewardWaitReleaseList}/publish`,
        body: {id,remark,scheduledPublish}
      }).pipe(
        map(res => {
          callback && callback(res.response)
          return actions.timeReleaseBanquetReward.SUCCESS(res.response)
        }),
        catchError(error => of(actions.timeReleaseBanquetReward.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);

// 取消发布
export const cancelReleaseBanquetRewardEpic = actions$ => actions$.pipe(
  ofType(actions.cancelReleaseBanquetReward.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { callback,id } = action.payload;
    return (
      ajax({
        ...ajaxBaseConfig,
        url: `${urls.characteristic.banquetRewardWaitReleaseList}/cancel/${id}`,
      }).pipe(
        map(res => {
          callback && callback(res.response)
          return actions.cancelReleaseBanquetReward.SUCCESS(res.response)
        }),
        catchError(error => of(actions.cancelReleaseBanquetReward.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);

// 待发布详情
export const banquetRewardWaitReleaseViewEpic = actions$ => actions$.pipe(
  ofType(actions.banquetRewardWaitReleaseView.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { callback,mallId = '',id = '' } = action.payload;
    return (
      ajax({
        ...ajaxBaseConfig,
        url: `${urls.characteristic.banquetRewardWaitReleaseList}/${mallId?mallId: id}`,
      }).pipe(
        map(res => {
          callback && callback(res.response)
          return actions.banquetRewardWaitReleaseView.SUCCESS(res.response)
        }),
        catchError(error => of(actions.banquetRewardWaitReleaseView.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);

// 删除草稿
export const banquetRewardDeleteEpic = actions$ => actions$.pipe(
  ofType(actions.banquetRewardDelete.REQUEST.toString()),

  mergeMap(action => {
    actionApi()
    const { id, callback } = action.payload;
    return (

      ajax({
        ...ajaxBaseConfig,
        method: 'DELETE',
        url: `${urls.characteristic.banquetRewardDelete}/${id}`,
      }).pipe(
        map(res => {
          callback && callback()
          return actions.banquetRewardDelete.SUCCESS(res)
        }),
        catchError(error => of(actions.banquetRewardDelete.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);

// 获取奖品列表
export const banquetRewardPrizeListEpic = actions$ => actions$.pipe(
  ofType(actions.banquetRewardPrizeList.REQUEST.toString()),

  mergeMap(action => {
    actionApi()
    const { fieldName = '', page = 0, size = 20, activityId,fieldValue } = action.payload;
    return (
      ajax({
        ...ajaxBaseConfig,
        url: `${urls.characteristic.banquetRewardPrizeList}${activityId}/prize?fieldName=${fieldName}&page=${Number(page)+1}&size=${size}&fieldValue=${fieldValue}`,
      }).pipe(
        map(res => {
          action.payload.callback && action.payload.callback(res.response)
          return actions.banquetRewardPrizeList.SUCCESS(res.response || [])
        }),
        catchError(error => of(actions.banquetRewardPrizeList.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);

/**
 * 终端门店
 */
 export const writeOffStoreAllEpic = actions$ => actions$.pipe(
  ofType(actions.writeOffStoreAll.REQUEST.toString()),

  mergeMap(action => {
    actionApi()
    return (
      ajax({
        ...ajaxBaseConfig,
        url: `${urls.characteristic.writeOffStoreAll}`,
      }).pipe(
        map(res => {
          action.payload.callback && action.payload.callback(res.response)
          return actions.writeOffStoreAll.SUCCESS(res.response || [])
        }),
        catchError(error => of(actions.writeOffStoreAll.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);

// 选择终端 -- 终端编辑确认
export const writeOffStoreEpic = actions$ => actions$.pipe(
  ofType(actions.writeOffStore.REQUEST.toString()),

  mergeMap(action => {
    actionApi()
    const { writeOffPolicy = [],terminalGroup = [],terminalLevel = [],brand = [], work = '',page=0,size=20,sort = 'id,desc' } = action.payload
    const params = new URLSearchParams()
    params.append('page', page)
    params.append('size', size)
    return (
      ajax({
        ...ajaxBaseConfig,
        method: 'POST',
        url: `${urls.characteristic.writeOffStore}?${params.toString()}`,
        body: {
          writeOffPolicy: writeOffPolicy.join(','),
          terminalGroup: terminalGroup.join(','),
          terminalLevel: terminalLevel.join(','),
          brand: brand.join(','),
          work,
          sort
        }
      }).pipe(
        map(res => {
          action.payload.callback && action.payload.callback(res.response)
          return actions.writeOffStore.SUCCESS(res.response || [])
        }),
        catchError(error => of(actions.writeOffStore.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);


// 清空终端
export const writeOffEmptyAllEpic = actions$ => actions$.pipe(
  ofType(actions.writeOffEmptyAll.REQUEST.toString()),

  mergeMap(action => {
    actionApi()
    return (
      ajax({
        ...ajaxBaseConfig,
        url: `${urls.characteristic.writeOffEmptyAll}`,
      }).pipe(
        map(res => {
          action.payload.callback && action.payload.callback(res.response)
          return actions.writeOffEmptyAll.SUCCESS(res.response || [])
        }),
        catchError(error => of(actions.writeOffEmptyAll.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);

// 工厂
export const banquetRewardFactoryEpic = actions$ => actions$.pipe(
  ofType(actions.banquetRewardFactory.REQUEST.toString()),

  mergeMap(action => {
    actionApi()
    const { status='ENABLE' } = action.payload
    return (
      ajax({
        ...ajaxBaseConfig,
        url: `${urls.characteristic.banquetRewardFactory}?status=${status}`,
      }).pipe(
        map(res => {
          action.payload.callback && action.payload.callback(res.response)
          return actions.banquetRewardFactory.SUCCESS(res.response || [])
        }),
        catchError(error => of(actions.banquetRewardFactory.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);

// 选择终端 -- 终端编辑确认
export const writeOffEditEpic = actions$ => actions$.pipe(
  ofType(actions.writeOffEdit.REQUEST.toString()),

  mergeMap(action => {
    actionApi()
    return (
      ajax({
        ...ajaxBaseConfig,
        method: 'PUT',
        url: `${urls.characteristic.writeOffEdit}`,
        body: action.payload.data
      }).pipe(
        map(res => {
          action.payload.callback && action.payload.callback(res.response)
          return actions.writeOffEdit.SUCCESS(res.response || [])
        }),
        catchError(error => of(actions.writeOffEdit.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);

// 删除终端
export const writeOffDeleteEpic = actions$ => actions$.pipe(
  ofType(actions.writeOffDelete.REQUEST.toString()),

  mergeMap(action => {
    actionApi()
    const { id } = action.payload;
    return (
      ajax({
        ...ajaxBaseConfig,
        method: 'DELETE',
        url: `${urls.characteristic.writeOffDelete}/${id}`,
      }).pipe(
        map(res => {
          action.payload.callback && action.payload.callback(res.response)
          return actions.writeOffDelete.SUCCESS(res.response || [])
        }),
        catchError(error => of(actions.writeOffDelete.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);

// 政策/批量政策
export const writeOffPolicyEpic = actions$ => actions$.pipe(
  ofType(actions.writeOffPolicy.REQUEST.toString()),

  mergeMap(action => {
    actionApi()
    return (
      ajax({
        ...ajaxBaseConfig,
        method: 'POST',
        url: `${urls.characteristic.writeOffPolicy}`,
        body: action.payload.data
      }).pipe(
        map(res => {
          action.payload.callback && action.payload.callback(res.response)
          return actions.writeOffPolicy.SUCCESS(res.response || [])
        }),
        catchError(error => of(actions.writeOffPolicy.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);

/***
 * 终端核销记录
 */
// 核销明细列表
 export const writeOffDetailEpic = actions$ => actions$.pipe(
  ofType(actions.writeOffDetail.REQUEST.toString()),

  mergeMap(action => {
    actionApi()
    const { keywords = '',writeOffStartTime = '',writeOffEndTime = '',policyCodes = [],policyIds = [],writeOffModels = [],page = 0,size = 20,sort = 'writeOffTime,desc' } = action.payload
    let str = `?page=${page}&size=${size}&sort=${sort}&keyword=${keywords}&writeOffStartTime=${writeOffStartTime}&writeOffEndTime=${writeOffEndTime}&policyIds=${policyIds}&writeOffModels=${writeOffModels}`
    return (
      ajax({
        ...ajaxBaseConfig,
        url: `${urls.characteristic.writeOffDetail}${str}`,
      }).pipe(
        map(res => {
          action.payload.callback && action.payload.callback(res.response)
          return actions.writeOffDetail.SUCCESS(res.response || [])
        }),
        catchError(error => of(actions.writeOffDetail.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);
// 核销明细列表导出
export const writeOffDetailExportEpic = actions$ => actions$.pipe(
  ofType(actions.writeOffDetailExport.REQUEST.toString()),

  mergeMap(action => {
    actionApi()
    const { keywords = '',writeOffStartTime ='',writeOffEndTime ='',policyCodes = [],policyIds = [],writeOffModels = [] } = action.payload
    return (
      ajax({
        ...ajaxBaseConfig,
        method: 'POST',
        url: `${urls.characteristic.writeOffDetailExport}`,
        body: {
          keyword: keywords,
          writeOffStartTime,
          writeOffEndTime,
          // policyCodes,
          policyIds,
          writeOffModels
        }
      }).pipe(
        map(res => {
          action.payload.callback && action.payload.callback(res.response)
          return actions.writeOffDetailExport.SUCCESS(res.response || [])
        }),
        catchError(error => of(actions.writeOffDetailExport.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);

export const writeOffLogEpic = actions$ => actions$.pipe(
  ofType(actions.writeOffLog.REQUEST.toString()),

  mergeMap(action => {
    actionApi()
    const { 
      keyword = '',
      writeOffStartTime = '',
      writeOffEndTime = '',
      policyIds = [],
      writeOffNo= '',
      actName= '',
      actIds = [],
      rewardName = '',
      prizeIds = [],
      rewardStartTime = '',
      rewardEndTime = '',
      rewardTypes = [],
      page = 0,
      size = 20,
      sort = 'writeOffTime,desc'
     } = action.payload
     let str = `?page=${page}&size=${size}&sort=${sort}&keyword=${keyword}&writeOffStartTime=${writeOffStartTime}&writeOffEndTime=${writeOffEndTime}&policyIds=${policyIds}&writeOffNo=${writeOffNo}&actIds=${actIds}&rewardName=${rewardName}&poolPrizeIds=${prizeIds}&rewardStartTime=${rewardStartTime}&rewardEndTime=${rewardEndTime}&rewardTypes=${rewardTypes}`
    return (
      ajax({
        ...ajaxBaseConfig,
        url: `${urls.characteristic.writeOffLog}${str}`,
      }).pipe(
        map(res => {
          action.payload.callback && action.payload.callback(res.response)
          return actions.writeOffLog.SUCCESS(res.response || [])
        }),
        catchError(error => of(actions.writeOffLog.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);
// 核销明细列表导出
export const writeOffLogExportEpic = actions$ => actions$.pipe(
  ofType(actions.writeOffLogExport.REQUEST.toString()),

  mergeMap(action => {
    actionApi()
    const { 
      keyword = '',
      writeOffStartTime = '',
      writeOffEndTime = '',
      policyIds = [],
      writeOffNo= '',
      actName= '',
      actIds = [],
      rewardName = '',
      prizeIds = [],
      rewardStartTime = '',
      rewardEndTime = '',
      rewardTypes = [],
      page = 0,
      size = 20
     } = action.payload
    return (
      ajax({
        ...ajaxBaseConfig,
        method: 'POST',
        url: `${urls.characteristic.writeOffLogExport}`,
        body: {
          keyword,
          writeOffStartTime,
          writeOffEndTime,
          policyIds,
          writeOffNo,
          actIds,
          rewardName,
          poolPrizeIds:prizeIds,
          rewardStartTime,
          rewardEndTime,
          rewardTypes
        }
      }).pipe(
        map(res => {
          action.payload.callback && action.payload.callback(res.response)
          return actions.writeOffLogExport.SUCCESS(res.response || [])
        }),
        catchError(error => of(actions.writeOffLogExport.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);

// 订单活动记录
export const orderActivityRecordListEpic = actions$ => actions$.pipe(
  ofType(actions.orderActivityRecordList.REQUEST.toString()),

  mergeMap(action => {
    actionApi()
    const { 
      activityType = '',
      warehouseNo= '',
      eseTagId = '',
      activityNames = '',
      prizeNames = '',
      rewardType = '',
      rewardName = '',
      warehouseStartTime = '',
      warehouseEndTime = '',
      businessStartTime = '',
      businessEndTime = '',
      sort = '',
      page = 0,
      size = 20
     } = action.payload
    const params = `?activityType=${activityType}&rewardName=${rewardName}&warehouseNo=${warehouseNo}&eseTagId=${eseTagId}&activityNames=${activityNames}&prizeNames=${prizeNames}&rewardTypes=${rewardType}&warehouseStartTime=${warehouseStartTime}&warehouseEndTime=${warehouseEndTime}&businessStartTime=${businessStartTime}&businessEndTime=${businessEndTime}&page=${page}&size=${size}&sort=${sort}`
    return (
      ajax({
        ...ajaxBaseConfig,
        url: `${urls.characteristic.orderActivityRecordList}${params}`,
      }).pipe(
        map(res => {
          action.payload.callback && action.payload.callback(res.response)
          return actions.orderActivityRecordList.SUCCESS(res.response || [])
        }),
        catchError(error => of(actions.orderActivityRecordList.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);

// 订单活动记录导出
export const orderActivityRecordExportEpic = actions$ => actions$.pipe(
  ofType(actions.orderActivityRecordExport.REQUEST.toString()),

  mergeMap(action => {
    actionApi()
    const { 
      activityType = '',
      warehouseNo= '',
      seqNum = '',
      activityNames = '',
      prizeNames = '',
      rewardType = '',
      rewardName = '',
      warehouseStartTime = '',
      warehouseEndTime = '',
      businessStartTime = '',
      businessEndTime = '',
      onError
     } = action.payload

    return (
      ajax({
        ...ajaxBaseConfig,
        method: 'POST',
        url: `${urls.characteristic.orderActivityRecordExport}`,
        body: {
          activityType,
          warehouseNo,
          seqNum,
          activityNames: activityNames ? activityNames.split(',') : [],
          prizeNames: prizeNames ? prizeNames.split(',') : [],
          rewardTypes: rewardType,
          rewardName,
          warehouseStartTime,
          warehouseEndTime,
          businessStartTime,
          businessEndTime
        }
      }).pipe(
        map(res => {
          action.payload.callback && action.payload.callback(res.response)
          return actions.orderActivityRecordExport.SUCCESS(res.response || [])
        }),
        catchError(error => {
          onError?.()
          return of(actions.orderActivityRecordExport.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
        })
      )
    )
  })
);

export const banquetOrderTypeListEpic = actions$ =>
  actions$.pipe(
    ofType(actions.banquetOrderTypeList.REQUEST.toString()),
    mergeMap(action => {
      actionApi()

      const { callback, onError, ...others } = action.payload
      const queryStr = qs.stringify(others, { encode: false })

      return ajax({
        ...ajaxBaseConfig,
        url: `${urls.characteristic.banquetOrderTypeList}?${queryStr}`
      }).pipe(
        map(res => {
          callback?.(res.response)
          return actions.banquetOrderTypeList.SUCCESS(res.response)
        }),
        catchError(error => {
          onError?.()
          return of(
            actions.banquetOrderTypeList.FAIL(error.xhr.response),
            uiActions.notification(error.xhr.response)
          )
        })
      )
    })
  )

export const banquetOrderTypeInfoEpic = actions$ =>
  actions$.pipe(
    ofType(actions.banquetOrderTypeInfo.REQUEST.toString()),
    mergeMap(action => {
      actionApi()
      const { callback, onError, id } = action.payload
      return ajax({
        ...ajaxBaseConfig,
        url: `${urls.characteristic.banquetOrderTypeList}/${id}`
      }).pipe(
        map(res => {
          callback?.(res.response)
          return actions.banquetOrderTypeInfo.SUCCESS(res.response)
        }),
        catchError(error => {
          onError?.()
          return of(
            actions.banquetOrderTypeInfo.FAIL(error.xhr.response),
            uiActions.notification(error.xhr.response)
          )
        })
      )
    })
  )

export const banquetOrderDataListEpic = actions$ =>
  actions$.pipe(
    ofType(actions.banquetOrderDataList.REQUEST.toString()),
    mergeMap(action => {
      actionApi()

      const { callback, onError, ...others } = action.payload
      const queryStr = qs.stringify(others, { encode: false })

      return ajax({
        ...ajaxBaseConfig,
        url: `${urls.characteristic.banquetOrderDataList}?${queryStr}`
      }).pipe(
        map(res => {
          callback?.(res.response)
          return actions.banquetOrderDataList.SUCCESS(res.response)
        }),
        catchError(error => {
          onError?.()
          return of(
            actions.banquetOrderDataList.FAIL(error.xhr.response),
            uiActions.notification(error.xhr.response)
          )
        })
      )
    })
  )

export const banquetOrderDataInfoEpic = actions$ =>
  actions$.pipe(
    ofType(actions.banquetOrderDataInfo.REQUEST.toString()),
    mergeMap(action => {
      actionApi()

      const { callback, onError, id } = action.payload

      return ajax({
        ...ajaxBaseConfig,
        url: `${urls.characteristic.banquetOrderDataList}/${id}`
      }).pipe(
        map(res => {
          callback?.(res.response)
          return actions.banquetOrderDataInfo.SUCCESS(res.response)
        }),
        catchError(error => {
          onError?.()
          return of(
            actions.banquetOrderDataInfo.FAIL(error.xhr.response),
            uiActions.notification(error.xhr.response)
          )
        })
      )
    })
  )

export const banquetOrderSelectionEpic = actions$ =>
  actions$.pipe(
    ofType(actions.banquetOrderSelection.REQUEST.toString()),
    mergeMap(action => {
      actionApi()

      const { callback, onError, ...others } = action.payload
      const queryStr = qs.stringify(others, { encode: false })

      return ajax({
        ...ajaxBaseConfig,
        url: `${urls.characteristic.banquetOrderDataList}/selections`
      }).pipe(
        map(res => {
          callback?.(res.response)
          return actions.banquetOrderSelection.SUCCESS(res.response)
        }),
        catchError(error => {
          onError?.()
          return of(
            actions.banquetOrderSelection.FAIL(error.xhr.response),
            uiActions.notification(error.xhr.response)
          )
        })
      )
    })
  )

export const banquetTypeSelectionEpic = actions$ =>
  actions$.pipe(
    ofType(actions.banquetTypeSelection.REQUEST.toString()),
    mergeMap(action => {
      actionApi()

      const { callback, onError, ...others } = action.payload
      const queryStr = qs.stringify(others, { encode: false })

      return ajax({
        ...ajaxBaseConfig,
        url: `${urls.characteristic.banquetTypeSelection}?${queryStr}`
      }).pipe(
        map(res => {
          callback?.(res.response)
          return actions.banquetTypeSelection.SUCCESS(res.response)
        }),
        catchError(error => {
          onError?.()
          return of(
            actions.banquetTypeSelection.FAIL(error.xhr.response),
            uiActions.notification(error.xhr.response)
          )
        })
      )
    })
  )

export const banquetPolicySelectionEpic = actions$ =>
  actions$.pipe(
    ofType(actions.banquetPolicySelection.REQUEST.toString()),
    mergeMap(action => {
      actionApi()

      const { callback, onError, ...others } = action.payload
      const queryStr = qs.stringify(others, { encode: false })

      return ajax({
        ...ajaxBaseConfig,
        url: `${urls.characteristic.banquetPolicySelection}?${queryStr}`
      }).pipe(
        map(res => {
          callback?.(res.response)
          return actions.banquetPolicySelection.SUCCESS(res.response)
        }),
        catchError(error => {
          onError?.()
          return of(
            actions.banquetPolicySelection.FAIL(error.xhr.response),
            uiActions.notification(error.xhr.response)
          )
        })
      )
    })
  )

export const banquetOrderTypeCreateEpic = actions$ =>
  actions$.pipe(
    ofType(actions.banquetOrderTypeCreate.REQUEST.toString()),
    mergeMap(action => {
      actionApi()

      const { callback, onError, ...body } = action.payload

      return ajax({
        ...ajaxBaseConfig,
        url: `${urls.characteristic.banquetOrderTypeList}`,
        method: 'POST',
        body
      }).pipe(
        map(res => {
          callback?.(res.response)
          return actions.banquetOrderTypeCreate.SUCCESS(res.response)
        }),
        catchError(error => {
          onError?.()
          return of(
            actions.banquetOrderTypeCreate.FAIL(error.xhr.response),
            uiActions.notification(error.xhr.response)
          )
        })
      )
    })
  )

export const banquetOrderTypeEditEpic = actions$ =>
  actions$.pipe(
    ofType(actions.banquetOrderTypeEdit.REQUEST.toString()),
    mergeMap(action => {
      actionApi()

      const { callback, onError, ...body } = action.payload

      return ajax({
        ...ajaxBaseConfig,
        url: `${urls.characteristic.banquetOrderTypeList}`,
        method: 'PATCH',
        body
      }).pipe(
        map(res => {
          callback?.(res.response)
          return actions.banquetOrderTypeEdit.SUCCESS(res.response)
        }),
        catchError(error => {
          onError?.()
          return of(
            actions.banquetOrderTypeEdit.FAIL(error.xhr.response),
            uiActions.notification(error.xhr.response)
          )
        })
      )
    })
  )

export const banquetOrderTypeStatusEpic = actions$ =>
  actions$.pipe(
    ofType(actions.banquetOrderTypeStatus.REQUEST.toString()),
    mergeMap(action => {
      actionApi()

      const { callback, onError, id, status } = action.payload

      return ajax({
        ...ajaxBaseConfig,
        url: `${urls.characteristic.banquetOrderTypeStatus}/${id}?status=${status}`,
        method: 'PATCH'
      }).pipe(
        map(res => {
          callback?.(res.response)
          return actions.banquetOrderTypeStatus.SUCCESS(res.response)
        }),
        catchError(error => {
          onError?.()
          return of(
            actions.banquetOrderTypeStatus.FAIL(error.xhr.response),
            uiActions.notification(error.xhr.response)
          )
        })
      )
    })
  )

export const levelRulesSelectionsEpic = actions$ =>
  actions$.pipe(
    ofType(actions.levelRulesSelections.REQUEST.toString()),
    mergeMap(action => {
      actionApi()

      const { callback, onError } = action.payload || {}

      return ajax({
        ...ajaxBaseConfig,
        url: `${urls.baseUrl}/tm/portal/business-rule-formal/v1/selections`,
        method: 'POST',
        body: {
          typeList: []
        }
      }).pipe(
        map(res => {
          callback?.(res.response)
          return actions.levelRulesSelections.SUCCESS(res.response)
        }),
        catchError(error => {
          onError?.()
          return of(
            actions.levelRulesSelections.FAIL(error.xhr.response),
            uiActions.notification(error.xhr.response)
          )
        })
      )
    })
  )