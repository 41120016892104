import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { ajax } from '../../ajax';
import urls from '@/api/urls';
import * as uiActions from '../ui';
import * as actions from './codeTempConfig';
import { ajaxBaseConfig, actionApi } from '../../utils';
import { trims } from 'ucode-utils';


// 
export const loadTemplateConfigsEpic = actions$ => actions$.pipe(
  ofType(actions.loadTemplateConfigs.REQUEST.toString()),
  mergeMap(action => { actionApi()
    const { name = '', page = 0, size = 20, sort = 'id,desc' } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.code.codeTempConfig.templateConfigs}?name=${name}&page=${page}&size=${size}&sort=${sort}`
    }).pipe(
      map(res => actions.loadTemplateConfigs.SUCCESS(res.response)),
      catchError(error => of(actions.loadTemplateConfigs.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
      )
    )}
  )
);

export const addTemplateConfigEpic = actions$ => actions$.pipe(
  ofType(actions.addTemplateConfig.REQUEST.toString()),
  mergeMap(action => { actionApi()
    return ajax({
      ...ajaxBaseConfig,
      url: urls.code.codeTempConfig.templateConfigs,
      method: 'POST',
      body: trims(action.payload.data)

    }).pipe(
      map(res => {
        action.payload.history.goBack();
        return actions.addTemplateConfig.SUCCESS(res.response);
      }),
      catchError(error => of(actions.addTemplateConfig.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
      )
    )}
  )
);

export const editTemplateConfigEpic = actions$ => actions$.pipe(
  ofType(actions.editTemplateConfig.REQUEST.toString()),
  mergeMap(action => { actionApi()
    return ajax({
      ...ajaxBaseConfig,
      url: urls.code.codeTempConfig.templateConfigs,
      method: 'PUT',
      body: trims(action.payload.data)

    }).pipe(
      map(res => {
        action.payload.history.goBack();
        return actions.editTemplateConfig.SUCCESS(res.response);
      }),
      catchError(error => of(actions.editTemplateConfig.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
      )
    )}
  )
);

export const loadFieldsEpic = actions$ => actions$.pipe(
  ofType(actions.loadFields.REQUEST.toString()),
  mergeMap(action => { actionApi();
    const { thirdFile } = action.payload || {};
    return ajax({
      ...ajaxBaseConfig,
      url: thirdFile ? `${urls.code.codeTempConfig.fields}?thirdFile=true` : urls.code.codeTempConfig.fields,
    }).pipe(
      map(res => actions.loadFields.SUCCESS(res.response)),
      catchError(error => of(actions.loadFields.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
      )
    )}
  )
);

export const loadTagMaterialsEpic = actions$ => actions$.pipe(
  ofType(actions.loadTagMaterials.REQUEST.toString()),
  mergeMap(action => { actionApi()
    return ajax({
      ...ajaxBaseConfig,
      url: urls.code.tagMaterials.tagMaterials,
    }).pipe(
      map(res => actions.loadTagMaterials.SUCCESS(res.response)),
      catchError(error => of(actions.loadTagMaterials.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
      )
    )}
  )
);

export const addTagMaterialEpic = actions$ => actions$.pipe(
  ofType(actions.addTagMaterial.REQUEST.toString()),
  mergeMap(action => { actionApi()
    return ajax({
      ...ajaxBaseConfig,
      method: 'POST',
      body: trims(action.payload),
      url: urls.code.tagMaterials.tagMaterials,
    }).pipe(
      mergeMap(res => [actions.addTagMaterial.SUCCESS(res.response), actions.loadTagMaterials.REQUEST(), uiActions.updateAddOrEditTagMaterialDialogStatus(false), uiActions.notification('新增成功', 'success')]),
      catchError(error => of(actions.addTagMaterial.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
      )
    )}
  )
);

export const editTagMaterialEpic = actions$ => actions$.pipe(
  ofType(actions.editTagMaterial.REQUEST.toString()),
  mergeMap(action => { actionApi()
    return ajax({
      ...ajaxBaseConfig,
      method: 'PUT',
      body: trims(action.payload),
      url: urls.code.tagMaterials.tagMaterials,
    }).pipe(
      mergeMap(res => [actions.editTagMaterial.SUCCESS(res.response), actions.loadTagMaterials.REQUEST(), uiActions.updateAddOrEditTagMaterialDialogStatus(false), uiActions.notification('编辑成功', 'success')]),
      catchError(error => of(actions.editTagMaterial.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
      )
    )}
  )
);


export const loadTemplateConfigByIdEpic = actions$ => actions$.pipe(
  ofType(actions.loadTemplateConfigById.REQUEST.toString()),
  mergeMap(action => { actionApi()
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.code.codeTempConfig.templateConfigs}/${action.payload}`,

    }).pipe(
      map(res => {
        // action.payload.history.goBack();
        return actions.loadTemplateConfigById.SUCCESS(res.response);
      }),
      catchError(error => of(actions.loadTemplateConfigById.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
      )
    )}
  )
);

export const loadDistributionListEpic = actions$ => actions$.pipe(
  ofType(actions.loadDistributionList.REQUEST.toString()),
  mergeMap(action => { actionApi();
    const { data, callback } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.code.codeTempConfig.templateConfigs}/${data.id}/distribution-list`,

    }).pipe(
      map(res => {
        callback && callback(res.response);
        // action.payload.history.goBack();
        return actions.loadDistributionList.SUCCESS(res.response);
      }),
      catchError(error => of(actions.loadDistributionList.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
      )
    )}
  )
);

export const templateDistributionEpic = actions$ => actions$.pipe(
  ofType(actions.templateDistribution.REQUEST.toString()),
  mergeMap(action => { actionApi();
    const { data, callback } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      method: 'POST',
      url: `${urls.code.codeTempConfig.templateConfigs}/distribution`,
      body: data,

    }).pipe(
      map(res => {
        // action.payload.history.goBack();
        callback && callback()
        return actions.templateDistribution.SUCCESS(res.response);
      }),
      catchError(error => of(actions.templateDistribution.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
      )
    )}
  )
);


