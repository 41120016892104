import { createAction, createActions, handleActions, combineActions } from 'redux-actions';
import { fasterActions } from '../../utils';
import urls from '@/api/urls';

const moduleName = 'codeCodeverify';

/**
 * TODO: 企业管理
 */
export const loadCodeCodeverifyList = fasterActions(moduleName, 'loadCodeCodeverifyList');
export const loadCodeCodeverifyId = fasterActions(moduleName, 'loadCodeCodeverifyId');
export const createCodeCodeverify = fasterActions(moduleName, 'createCodeCodeverify');
export const editCodeCodeverify = fasterActions(moduleName, 'editCodeCodeverify');
export const ableCodeCodeverify = fasterActions(moduleName, 'ableCodeCodeverify');
export const deleteCodeCodeverify = fasterActions(moduleName, 'deleteCodeCodeverify');

export const getCodeCodeverifySelection = fasterActions(moduleName, 'getCodeCodeverifySelection');

const initialState = {

  codeCodeverifyList: [],
  codeCodeverifySelection: [],
  loadCodeCodeverifyListLoading: false,
  loadCodeCodeverifyListLoaded: false,

}

const reducer = handleActions(
  {

    [loadCodeCodeverifyList.REQUEST]: (state, { payload }) => ({ ...state, loadCodeCodeverifyListLoading: true }),
    [loadCodeCodeverifyList.SUCCESS]: (state, { payload }) => ({ ...state, loadCodeCodeverifyListLoading: false, loadCodeCodeverifyListLoaded: true, codeCodeverifyList: payload }),
    [loadCodeCodeverifyList.FAIL]: (state, { payload }) => ({ ...state, loadCodeCodeverifyListLoading: false, loadCodeCodeverifyListLoaded: false, error: payload }),

    [loadCodeCodeverifyId.REQUEST]: (state, { payload }) => ({ ...state }),
    [loadCodeCodeverifyId.SUCCESS]: (state, { payload }) => ({ ...state }),
    [loadCodeCodeverifyId.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

    [createCodeCodeverify.REQUEST]: (state, { payload }) => ({ ...state }),
    [createCodeCodeverify.SUCCESS]: (state, { payload }) => ({ ...state }),
    [createCodeCodeverify.FAIL]: (state, { payload }) => ({ ...state }),
    [editCodeCodeverify.REQUEST]: (state, { payload }) => ({ ...state }),
    [editCodeCodeverify.SUCCESS]: (state, { payload }) => ({ ...state }),
    [editCodeCodeverify.FAIL]: (state, { payload }) => ({ ...state }),
    [ableCodeCodeverify.REQUEST]: (state, { payload }) => ({ ...state }),
    [ableCodeCodeverify.SUCCESS]: (state, { payload }) => ({ ...state }),
    [ableCodeCodeverify.FAIL]: (state, { payload }) => ({ ...state }),

    [deleteCodeCodeverify.REQUEST]: (state, { payload }) => ({ ...state }),
    [deleteCodeCodeverify.SUCCESS]: (state, { payload }) => ({ ...state }),
    [deleteCodeCodeverify.FAIL]: (state, { payload }) => ({ ...state }),

    [getCodeCodeverifySelection.REQUEST]: (state, { payload }) => ({ ...state }),
    [getCodeCodeverifySelection.SUCCESS]: (state, { payload }) => {
      let selector = []
      if (payload) {
        for (let elem of payload.values()) {
          selector.push({ ...elem, id: elem.id, value: elem.id, text: elem.name })
        }
      }
      return ({ ...state, codeCodeverifySelection: selector, })
    },
    [getCodeCodeverifySelection.FAIL]: (state, { payload }) => ({ ...state }),



  },
  initialState
);

export default reducer;
