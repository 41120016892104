import * as React from "react";

function SvgBackArrow2(props) {
  return (
    <svg width={12} height={14} {...props}>
      <path
        d="M15,8.5 C15,5.462 12.538,3 9.5,3 L4,3 L4,0 L0,4 L4,8 L4,5 L9.5,5 C11.43,5 13,6.57 13,8.5 C13,10.43 11.43,12 9.5,12 L9,12 L2,12 L2,14 L10,14 L10,13.975 C12.803,13.722 15,11.369 15,8.5 Z"
        fill="#222429"
        fillRule="nonzero"
      />
    </svg>
  );
}

export default SvgBackArrow2;