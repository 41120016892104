import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap, map, catchError, concatMap } from 'rxjs/operators';
import { ajax } from '../../ajax';
import urls from '../../../api/urls';
import * as uiActions from '../ui';
import queryString from 'query-string';
import * as actions from './factory';
import { ajaxBaseConfig, actionApi } from '../../utils';

export const loadSelectionsEpic = actions$ => actions$.pipe(
  ofType(actions.loadSelections.REQUEST.toString()),
  mergeMap(action => {
    actionApi();
    let url = `${urls.data.factory.factories}/allots?isPage=false&factoryType=${action.payload.factoryType}`;
    if(action.payload.status){
      url+=`&status=${action.payload.status}`;
    }

    return ajax({
      ...ajaxBaseConfig,
      url,
      // url: action.payload && action.payload.status ? `${urls.data.factory.selections}?factoryType=${action.payload.factoryType}&status=${action.payload.status}` : `${urls.data.factory.selections}?factoryType=${action.payload.factoryType}`,
      // url: urls.data.factory.selections(action.payload || ''),
    }).pipe(
      map(res => actions.loadSelections.SUCCESS(Object.assign(res.response, { factoryType: action.payload }))),
      catchError(error => of(actions.loadSelections.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
      )
    )}
  )
);
export const loadFactoryPageListEpic = (actions$, state$) => actions$.pipe(
  ofType(actions.loadFactoryPageList.REQUEST.toString()),
  mergeMap(action => { actionApi()
    const { factoryPageListParam = {}, factorySearchKey= '' } = state$.value.factory;
    return (
      ajax({
        ...ajaxBaseConfig,
        url: `${urls.data.factory.factories}?page=${factoryPageListParam.page}&size=${factoryPageListParam.size}&sort=${factoryPageListParam.sort}&factoryTypes=${factoryPageListParam.factoryType}&word=${factorySearchKey}`,
      }).pipe(
        map(res => actions.loadFactoryPageList.SUCCESS(Object.assign(res.response))),
        catchError(error => of(actions.loadFactoryPageList.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
        )
      )
    )
   }
  )
);
export const loadFactoryByIdEpic = (actions$, state$) => actions$.pipe(
  ofType(actions.loadFactoryById.REQUEST.toString()),
  mergeMap(action => { actionApi()
    const { id = '', callback } = state$.value.factory;
    return (
      ajax({
        ...ajaxBaseConfig,
        url: `${urls.data.factory.factories}/${id}`,
      }).pipe(
        map(res => { 
          callback && callback(res.response);
          return actions.loadFactoryById.SUCCESS(Object.assign(res.response));
        }),
        catchError(error => of(actions.loadFactoryById.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
        )
      )
    )
   }
  )
);
export const enableOrDisabledFactoryEpic = (actions$, state$) => actions$.pipe(
  ofType(actions.enableOrDisabledFactory.REQUEST.toString()),
  mergeMap(action => { actionApi()
    const { id = '', status = '' } = action.payload;
    return (
      ajax({
        ...ajaxBaseConfig,
        url: `${ urls.data.factory.factories }/${ id }/status?status=${status}`,
        method: 'PATCH',
      }).pipe(
        concatMap(res => [actions.enableOrDisabledFactory.SUCCESS(Object.assign(res.response)), actions.loadFactoryPageList.REQUEST()]),
        catchError(error => of(actions.enableOrDisabledFactory.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
        )
      )
    )
   }
  )
);

export const createFactoryEpic = (actions$, state$) => actions$.pipe(
  ofType(actions.createFactory.REQUEST.toString()),
  mergeMap(action => { actionApi()
    return (
      ajax({
        ...ajaxBaseConfig,
        url: `${ urls.data.factory.factories }`,
        method: 'POST',
        body: action.payload.data,
      }).pipe(
        concatMap(res => {
          action.payload.callback && action.payload.callback(res);
          return [actions.createFactory.SUCCESS(Object.assign(res.response)), actions.loadFactoryPageList.REQUEST({ factoryPageListParam: { ...state$.value.factory.factoryPageListParam, page: 0 } })]
        }),
        catchError(error => of(actions.createFactory.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
        )
      )
    )
   }
  )
);
export const editFactoryEpic = (actions$, state$) => actions$.pipe(
  ofType(actions.editFactory.REQUEST.toString()),
  mergeMap(action => { actionApi()
    return (
      ajax({
        ...ajaxBaseConfig,
        url: `${ urls.data.factory.factories }`,
        method: 'PUT',
        body: action.payload.data,
      }).pipe(
        concatMap(res => {
          action.payload.callback && action.payload.callback(res);
          return [actions.editFactory.SUCCESS(Object.assign(res.response)), actions.loadFactoryPageList.REQUEST()]
        }),
        catchError(error => of(actions.editFactory.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
        )
      )
    )
   }
  )
);

export const factoriesEpic = actions$ => actions$.pipe(
  ofType(actions.factories.REQUEST.toString()),
  mergeMap(action => {
    actionApi();
    return ajax({
      ...ajaxBaseConfig,
      url: urls.data.factory.factories,
    }).pipe(
      map(res => actions.factories.SUCCESS(res.response)),
      catchError(error => of(actions.factories.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
      )
    )
  }
  )
);