import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap, map, catchError, concatMap } from 'rxjs/operators';
import { ajax } from '../../ajax';
import urls from '@/api/urls';
import * as uiActions from '../ui';
import queryString from 'query-string';
import * as actions from './cityRank';
import { ajaxBaseConfig, actionApi } from '../../utils';

/**
 * TODO: 企业管理
 */


//list
export const loadCityRankListEpic = actions$ => actions$.pipe(
  ofType(actions.loadCityRankList.REQUEST.toString()),

  mergeMap(action => {
    actionApi()
    const { keywords = '', page = 0, size = 20, sort = 'id,desc' } = action.payload;
    return (

      ajax({
        ...ajaxBaseConfig,
        url: `${urls.code.cityRank.cityRank}?keyWords=${keywords}&page=${page}&size=${size}&sort=${sort}`,
      }).pipe(
        map(res => actions.loadCityRankList.SUCCESS(res.response || [])),
        catchError(error => of(actions.loadCityRankList.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);
//create
export const createCityRankEpic = actions$ => actions$.pipe(
  ofType(actions.createCityRank.REQUEST.toString()),

  mergeMap(action => {
    actionApi()
    const { data, callback } = action.payload;
    return (

      ajax({
        ...ajaxBaseConfig,
        method: 'POST',
        url: `${urls.code.cityRank.cityRank}`,
        body: action.payload.data,
      }).pipe(
        map(res => {
          callback && callback()
          return actions.createCityRank.SUCCESS(res)
        }),
        catchError(error => of(actions.createCityRank.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);
//edit
export const editCityRankEpic = actions$ => actions$.pipe(
  ofType(actions.editCityRank.REQUEST.toString()),

  mergeMap(action => {
    actionApi()
    const { data, callback } = action.payload;
    return (

      ajax({
        ...ajaxBaseConfig,
        method: 'PUT',
        url: `${urls.code.cityRank.cityRank}`,
        body: action.payload.data,
      }).pipe(
        map(res => {
          callback && callback()
          return actions.editCityRank.SUCCESS(res)
        }),
        catchError(error => of(actions.editCityRank.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);
//able

export const ableCityRankEpic = actions$ => actions$.pipe(
  ofType(actions.ableCityRank.REQUEST.toString()),

  mergeMap(action => {
    actionApi()
    const { data, callback } = action.payload;
    return (

      ajax({
        ...ajaxBaseConfig,
        method: 'PATCH',
        url: `${urls.code.cityRank.cityRank}/${data.id}/status?status=${data.status}`,
        //  body: action.payload.data,
      }).pipe(
        map(res => {
          callback && callback()
          return actions.ableCityRank.SUCCESS(res)
        }),
        catchError(error => of(actions.ableCityRank.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);

//通信录查询by id
export const loadCityRankIdEpic = actions$ => actions$.pipe(
  ofType(actions.loadCityRankId.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { data, callback } = action.payload;
    return (
      ajax({
        ...ajaxBaseConfig,
        url: `${urls.code.cityRank.cityRank}/${data.id}`,
      }).pipe(
        map(res => {
          callback && callback(res.response)
          return actions.loadCityRankId.SUCCESS(res.response)

        }),
        catchError(error => of(actions.loadCityRankId.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);


export const deleteCityRankEpic = actions$ => actions$.pipe(
  ofType(actions.deleteCityRank.REQUEST.toString()),

  mergeMap(action => {
    actionApi()
    const { id, callback } = action.payload;
    return (

      ajax({
        ...ajaxBaseConfig,
        method: 'DELETE',
        url: `${urls.code.cityRank.cityRank}/${id}`,
      }).pipe(
        map(res => {
          callback && callback()
          return actions.deleteCityRank.SUCCESS(res)
        }),
        catchError(error => of(actions.deleteCityRank.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);
