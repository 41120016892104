// 子应用列表
export const apps = [
  {
    path: '/code/',
    activePath: '/code',
    title: '码管理',
    umd: true,
    sandbox: true,
    hashType: true,
    entry: '//ucode-test-cdn.yjxxk.cn/front-end/ucode_v2/ucode-child-code/index.html',
  },
  {
    path: '/data/',
    activePath: '/data',
    title: '基础信息',
    umd: true,
    sandbox: true,
    hashType: true,
    entry: '//ucode-test-cdn.yjxxk.cn/front-end/ucode_v2/ucode-child-database/index.html',
  },
  {
    path: '/promotion/',
    activePath: '/promotion',
    title: '营销活动',
    umd: true,
    sandbox: true,
    hashType: true,
    entry: '//ucode-test-cdn.yjxxk.cn/front-end/ucode_v2/ucode-child-promotion/index.html',
  },
  {
    path: '/query-tools/',
    activePath: '/query-tools',
    title: '工具箱',
    umd: true,
    sandbox: true,
    hashType: true,
    entry: '//ucode-test-cdn.yjxxk.cn/front-end/ucode_v2/ucode-child-querytools/index.html',
  }
];


// 菜单共享同一个子应用
export const appNameMap = {
  'promotion,verify,logistics': 'promotion', // 核销管理、实物发货管理-》划分到活动模块
  'code,ws': 'code', // 码管理、资源分配-》码管理
  // 'data,dm,enterprise': 'data', // 》数据字典、基础数据-》基础数据
  'data,dm,enterprise,jncBillRecord,jPointsRecovery,special-activity,jShopMiniLoginLog': 'data', // 》数据字典、基础数据、实物发货管理、异常记录、回收单、特色活动、小程序登录日志-》基础数据
}