import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap, map, catchError, concatMap, concatMapTo } from 'rxjs/operators';
//不要encode
import { ajax as noecAjax } from 'rxjs/ajax';
import { ajax } from '../../ajax';
import urls from '@/api/urls';
import * as uiActions from '../ui';
import queryString from 'query-string';
import * as actions from './membershipSystem';
import { ajaxBaseConfig, actionApi } from '../../utils';

// Epics
// 会员体系
export const getMemberSystemTableEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.getMemberSystemTable.REQUEST),
    mergeMap(action => {
        actionApi()
        const { isVaild = true, page = 0, size = 20, name = '', id = '', sort = '', systemStatus = '', membershipSystemType = '' } = action.payload || {};
        const url = isVaild ? urls.memberShip.memberSystem.systemHistory + '/current' : urls.memberShip.memberSystem.memberSystem

        return ajax({
            ...ajaxBaseConfig,
            url: `${url}?systemStatusList=${isVaild ? systemStatus : 'DRAFT,TO_BE_PUBLISHED'}&membershipSystemType=${membershipSystemType}&name=${name}&page=${page}&size=${size}&id=${id}&sort=${sort}`,
        }).pipe(
            map(res => {
                return actions.getMemberSystemTable.SUCCESS(res.response)
            }),
            catchError(error => of(actions.getMemberSystemTable.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)

// 会员体系byid
export const getMemberSystemByIdEpic = actions$ => actions$.pipe(
    ofType(actions.getMemberSystemById.REQUEST.toString()),

    mergeMap(action => {
        actionApi()
        const { id, callback } = action.payload;

        return (

            ajax({
                ...ajaxBaseConfig,
                url: `${urls.memberShip.memberSystem.memberSystem}/${id}`,
            }).pipe(
                map(res => {
                    callback && callback(res.response)
                    return actions.getMemberSystemById.SUCCESS(res.response)
                }),
                catchError(error => of(actions.getMemberSystemById.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
            )
        )
    })
);
// 非草稿的会员体系 selection
export const getMemberSystemSelectionEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.getMemberSystemSelection.REQUEST),
    mergeMap(action => {
        actionApi()
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.memberShip.memberSystem.memberSystem}/all`,
        }).pipe(
            map(res => {
                return actions.getMemberSystemSelection.SUCCESS(res.response?.content)
            }),
            catchError(error => of(actions.getMemberSystemSelection.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
);

// 创建体系
export const createMemberSystemEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.createMemberSystem.REQUEST),
    mergeMap(action => {
        actionApi()
        const { data, callback } = action.payload || {};
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.memberShip.memberSystem.memberSystem}`,
            method: 'POST',
            body: data
        }).pipe(
            concatMap(res => {
                callback && callback(res);
                return [actions.createMemberSystem.SUCCESS(res.response)]
            }),
            catchError(error => of(actions.createMemberSystem.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)

// 修改会员体系
export const putMemberSystemEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.putMemberSystem.REQUEST),
    mergeMap(action => {
        actionApi()
        const { data, url, callback } = action.payload || {};
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.memberShip.memberSystem.memberSystem}/${url}`,
            method: 'PUT',
            body: data
        }).pipe(
            concatMap(res => {
                callback && callback(res.response);
                return [actions.putMemberSystem.SUCCESS(res.response)]
            }),
            catchError(error => of(actions.putMemberSystem.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)

// 删除会员体系
export const deleteMemberSystemEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.deleteMemberSystem.REQUEST),
    mergeMap(action => {
        actionApi()
        const { id, callback } = action.payload || {};
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.memberShip.memberSystem.memberSystem}/${id}`,
            method: 'DELETE'
        }).pipe(
            concatMap(res => {
                callback && callback();
                return [actions.deleteMemberSystem.SUCCESS(res.response)]
            }),
            catchError(error => of(actions.deleteMemberSystem.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)

// 发布体系
export const publishMemberSystemEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.publishMemberSystem.REQUEST),
    mergeMap(action => {
        actionApi()
        const { data, callback } = action.payload || {};
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.memberShip.memberSystem.memberSystem}/${data.id}/publish`,
            method: 'PATCH',
            body: action.payload.data,
        }).pipe(
            concatMap(res => {
                callback && callback();
                return [actions.publishMemberSystem.SUCCESS(res.response)]
            }),
            catchError(error => of(actions.publishMemberSystem.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)

// 禁用、启用体系
export const disabledOrEnableMemberSystemEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.disabledOrEnableMemberSystem.REQUEST),
    mergeMap(action => {
        actionApi()
        const { data, callback } = action.payload || {};
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.memberShip.memberSystem.systemHistory}/enbaleOrDisable?id=${data.id}&systemStatus=${data.systemStatus}`,
            method: 'PATCH',
            data: data,
        }).pipe(
            concatMap(res => {
                callback && callback();
                return [actions.disabledOrEnableMemberSystem.SUCCESS(res.response)]
            }),
            catchError(error => of(actions.disabledOrEnableMemberSystem.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)

// 会员体系历史
export const getSystemHistoryEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.getSystemHistory.REQUEST),
    mergeMap(action => {
        actionApi()
        const { page = 0, size = 20, systemId = '', name = '', id = '', sort = '' } = action.payload || {};
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.memberShip.memberSystem.systemHistory}?page=${page}&size=${size}&systemId=${systemId}&name=${name}&id=${id}&sort=${sort}`,
        }).pipe(
            map(res => actions.getSystemHistory.SUCCESS(res.response)),
            catchError(error => of(actions.getSystemHistory.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)
export const getSystemHistoryByIdEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.getSystemHistoryById.REQUEST),
    mergeMap(action => {
        actionApi()
        const { historyId = '' } = action.payload || {};
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.memberShip.memberSystem.systemHistory}/content/${historyId}`,
        }).pipe(
            map(res => actions.getSystemHistoryById.SUCCESS(res.response)),
            catchError(error => of(actions.getSystemHistoryById.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)


// 修改体系历史
export const putSystemHistoryEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.putSystemHistory.REQUEST),
    mergeMap(action => {
        actionApi()
        const { data, callback } = action.payload || {};
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.memberShip.memberSystem.systemHistory}`,
            method: 'PUT',
            body: data
        }).pipe(
            concatMap(res => {
                callback && callback();
                return [actions.putSystemHistory.SUCCESS(res.response), actions.getSystemHistory.REQUEST(state$.value.membershipSystem.systemHistorySearch)]
            }),
            catchError(error => of(actions.putSystemHistory.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)


/* 会员等级 */
export const getMemberLevelListEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.getMemberLevelList.REQUEST),
    mergeMap(action => {
        actionApi()
        const { systemId = '', callback } = action.payload || {};
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.memberShip.memberSystem.memberLevel}?systemId=${systemId}`,
        }).pipe(
            map(res => {
                callback && callback(res.response);
                return actions.getMemberLevelList.SUCCESS(res.response)
            }),
            catchError(error => of(actions.getMemberLevelList.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)

// 创建会员等级
export const createMemberLevelEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.createMemberLevel.REQUEST),
    mergeMap(action => {
        actionApi()
        const { data, callback } = action.payload || {};
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.memberShip.memberSystem.memberLevel}`,
            method: 'POST',
            body: data
        }).pipe(
            map(res => {
                callback && callback(res.response);
                return actions.createMemberLevel.SUCCESS(res.response)
            }),
            catchError(error => of(actions.createMemberLevel.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)

// 修改会员等级
export const putMemberLevelEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.putMemberLevel.REQUEST),
    mergeMap(action => {
        actionApi()
        const { data, callback } = action.payload || {};
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.memberShip.memberSystem.memberLevel}`,
            method: 'PUT',
            body: data
        }).pipe(
            map(res => {
                callback && callback(res.response);
                return actions.putMemberLevel.SUCCESS(res.response)
            }),
            catchError(error => of(actions.putMemberLevel.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)


// 删除会员等级
export const deleteMemberLevelEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.deleteMemberLevel.REQUEST),
    mergeMap(action => {
        actionApi()
        const { callback, id } = action.payload || {};
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.memberShip.memberSystem.memberLevel}/${id}`,
            method: 'DELETE'
        }).pipe(
            map(res => {
                callback && callback(res.response);
                return actions.deleteMemberLevel.SUCCESS(res.response)
            }),
            catchError(error => of(actions.deleteMemberLevel.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)





/* 会员权益 */
export const getMemberRightsTableEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.getMemberRightsTable.REQUEST),
    mergeMap(action => {
        actionApi()
        const { page = 0, size = 20, systemId = '', rightsType = '', name = '', sort = 'createdTime,desc', level = false } = action.payload || {};
        return noecAjax({
            ...ajaxBaseConfig,
            url: `${urls.memberShip.memberSystem.memberRights}?page=${page}&size=${size}&systemId=${systemId}&rightsType=${rightsType}&name=${name ? encodeURIComponent(name) : ''}&sort=${sort}`,
        }).pipe(
            concatMap(res => {
                if (level) {
                    return [actions.getMemberRightsTable.SUCCESS(res.response), actions.getMemberLevelList.REQUEST({ systemId })]
                } else {
                    return [actions.getMemberRightsTable.SUCCESS(res.response)]
                }
            }),
            catchError(error => of(actions.getMemberRightsTable.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)

// 删除会员权益
export const deleteMemberRightsEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.deleteMemberRights.REQUEST),
    mergeMap(action => {
        actionApi()
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.memberShip.memberSystem.memberRights}/${action.payload}`,
            method: 'DELETE'
        }).pipe(
            concatMap(res => [actions.deleteMemberRights.SUCCESS(res.response), actions.getMemberRightsTable.REQUEST(state$.value.membershipSystem.memberRightsSearch)]),
            catchError(error => of(actions.deleteMemberRights.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)

// 创建会员权益
export const createMemberRightsEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.createMemberRights.REQUEST),
    mergeMap(action => {
        actionApi()
        const { data, callback } = action.payload || {};
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.memberShip.memberSystem.memberRights}`,
            method: 'POST',
            body: data
        }).pipe(
            concatMap(res => {
                callback && callback();
                return [actions.createMemberRights.SUCCESS(res.response), actions.getMemberRightsTable.REQUEST(state$.value.membershipSystem.memberRightsSearch)]
            }),
            catchError(error => of(actions.createMemberRights.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)

// 修改会员权益
export const putMemebrRightsEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.putMemberRights.REQUEST),
    mergeMap(action => {
        actionApi()
        const { data, callback } = action.payload || {};
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.memberShip.memberSystem.memberRights}`,
            method: 'PUT',
            body: data
        }).pipe(
            concatMap(res => {
                callback && callback();
                return [actions.putMemberRights.SUCCESS(res.response), actions.getMemberRightsTable.REQUEST(state$.value.membershipSystem.memberRightsSearch)]
            }),
            catchError(error => of(actions.putMemberRights.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)
// 会员权益下拉
export const getMemberRightsSelectEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.getMemberRightsSelect.REQUEST),
    mergeMap(action => {
        actionApi()
        const { systemId = '', callback } = action.payload || {};
        return noecAjax({
            ...ajaxBaseConfig,
            url: `${urls.memberShip.memberSystem.memberRights}/${systemId}`,
        }).pipe(
            concatMap(res => {
                callback && callback();
                return [actions.getMemberRightsSelect.SUCCESS(res.response)]
            }),
            catchError(error => of(actions.getMemberRightsSelect.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)



// 修改会员权益
export const putMemberTagsEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.putMemberTags.REQUEST),
    mergeMap(action => {
        actionApi()
        const { data, callback } = action.payload || {};
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.memberShip.memberSystem.memberRights}/${data.id}/bind?limit=${data.bindTag}`,
            method: 'PUT',
            body: data.memberStrategyList
        }).pipe(
            concatMap(res => {
                callback && callback();
                return [actions.putMemberTags.SUCCESS(res.response), actions.getMemberRightsTable.REQUEST(state$.value.membershipSystem.memberRightsSearch)]
            }),
            catchError(error => of(actions.putMemberTags.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)



/* 会员字段 */
export const getMemberFieldsTableEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.getMemberFieldsTable.REQUEST),
    mergeMap(action => {
        actionApi()
        const { page = 0, size = 20, name = '', status = [] } = action.payload || {};
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.memberShip.memberSystem.memberFields}?page=${page}&size=${size}&name=${name}&statusList=${status}&sort=createdTime,asc`,
        }).pipe(
            map(res => actions.getMemberFieldsTable.SUCCESS(res.response)),
            catchError(error => of(actions.getMemberFieldsTable.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)

// 删除会员字段
export const deleteMemberFieldsEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.deleteMemberFields.REQUEST),
    mergeMap(action => {
        actionApi()
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.memberShip.memberSystem.memberFields}/${action.payload}`,
            method: 'DELETE'
        }).pipe(
            concatMap(res => [actions.deleteMemberFields.SUCCESS(res.response), actions.getMemberFieldsTable.REQUEST(state$.value.membershipSystem.memberFieldsSearch)]),
            catchError(error => of(actions.deleteMemberFields.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)

// 禁用、启用体系
export const disabledOrEnableMemberFieldsEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.disabledOrEnableMemberFields.REQUEST),
    mergeMap(action => {
        actionApi()
        const { id = '', status = '' } = action.payload || {};
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.memberShip.memberSystem.memberFields}/${id}/${status}`,
            method: 'PATCH',
        }).pipe(
            concatMap(res => [actions.disabledOrEnableMemberFields.SUCCESS(res.response), actions.getMemberFieldsTable.REQUEST(state$.value.membershipSystem.memberFieldsSearch)]),
            catchError(error => of(actions.disabledOrEnableMemberFields.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)

// 创建会员字段
export const createMemberFieldsEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.createMemberFields.REQUEST),
    mergeMap(action => {
        actionApi()
        const { data, callback } = action.payload || {};
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.memberShip.memberSystem.memberFields}`,
            method: 'POST',
            body: data
        }).pipe(
            concatMap(res => {
                callback && callback();
                return [actions.createMemberFields.SUCCESS(res.response), actions.getMemberFieldsTable.REQUEST(state$.value.membershipSystem.memberFieldsSearch)]
            }),
            catchError(error => of(actions.createMemberFields.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)

// 修改会员字段
export const putMemberFieldsEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.putMemberFields.REQUEST),
    mergeMap(action => {
        actionApi()
        const { data, callback } = action.payload || {};
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.memberShip.memberSystem.memberFields}`,
            method: 'PUT',
            body: data
        }).pipe(
            concatMap(res => {
                callback && callback();
                return [actions.putMemberFields.SUCCESS(res.response), actions.getMemberFieldsTable.REQUEST(state$.value.membershipSystem.memberFieldsSearch)]
            }),
            catchError(error => of(actions.putMemberFields.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)

// 修改会员字段
export const getMemberFieldsSelectEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.getMemberFieldsSelect.REQUEST),
    mergeMap(action => {
        actionApi()
        const { data = {}, callback } = action.payload || {};
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.memberShip.memberSystem.memberFields}/usedListBySize`,
        }).pipe(
            concatMap(res => {
                callback && callback();
                return [actions.getMemberFieldsSelect.SUCCESS(res.response)]
            }),
            catchError(error => of(actions.getMemberFieldsSelect.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)




//导入会员体系。产品内容 
export const importMemberSystemProductEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.importMemberSystemProduct.REQUEST),
    mergeMap(action => {
        actionApi()
        const { data, callback } = action.payload || {};
        return noecAjax({
            ...ajaxBaseConfig,
            url: `${urls.memberShip.memberSystem.memberLevel}/import?fileUrl=${data.fileUrl}`,
            // body: data
        }).pipe(
            concatMap(res => {
                callback && callback(res.response);
                return [actions.importMemberSystemProduct.SUCCESS(res.response)]
            }),
            catchError(error => of(actions.importMemberSystemProduct.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)





export const getOtherSystenPoolPrizesEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.getOtherSystenPoolPrizes.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.memberShip.memberSystem.memberLevel}/prizes?systemId=${action.payload.systemId}`,
        }).pipe(
            map(res => actions.getOtherSystenPoolPrizes.SUCCESS(res.response)),
            catchError(error => of(actions.getOtherSystenPoolPrizes.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)


// 绑定活动code
export const bindCodeEpic = actions$ => actions$.pipe(
    ofType(actions.bindCode.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        //   const { systemId, code,version } = action.payload;
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.memberShip.memberSystem.memberSystem}/code`,
            body: action.payload,
            method: 'PUT',
        }).pipe(
            map(res => actions.bindCode.SUCCESS(res.status)),
            catchError(error => of(actions.bindCode.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
);
