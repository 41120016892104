import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap, map, catchError, concatMap } from 'rxjs/operators';
import { ajax } from '../../ajax';
import urls from '../../../api/urls';
import * as uiActions from '../ui';
import queryString from 'query-string';
import * as actions from './warnRecords';
import { ajaxBaseConfig, apiBaseConfig, actionApi } from '../../utils';

/**
 * TODO: 预警事件
 */

// Epics
// 分页查询预警事件记录
export const warnRecordsEpic = actions$ => actions$.pipe(
  ofType(actions.warnRecords.REQUEST.toString()),
  mergeMap(action => {
    actionApi();
    const { eventName = '', startTime = '', endTime = '', page, size ,eventClass, module2, name} = action.payload;
    let url = `${urls.warn.records.records}?page=${page}&size=${size}&startTime=${startTime}&endTime=${endTime}&sort=id,desc`;
    // if (eventName) {
    //   url += `&eventName=${eventName}`;
    // }
    if (eventClass) {
         url += `&eventClass=${eventClass}`;
       }
       if (module2) {
        url += `&module=${module2}`;
      }
      if (name) {
        url += `&eventName=${name}`;
      }
    return ajax({
      ...apiBaseConfig,
      url,
    }).pipe(
      map(res => actions.warnRecords.SUCCESS(res.response)),
      catchError(error => of(actions.warnRecords.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);

export const warnRecordsSelectEpic = actions$ => actions$.pipe(
  ofType(actions.warnRecordsSelect.REQUEST.toString()),
  mergeMap(action => {
    actionApi();
    // const {  } = action.payload;
    let url = `${urls.warn.records.records}/select`;
    // if (eventName) {
    //   url += `&eventName=${eventName}`;
    // }
    return ajax({
      ...apiBaseConfig,
      url,
    }).pipe(
      map(res => actions.warnRecordsSelect.SUCCESS(res.response)),
      catchError(error => of(actions.warnRecordsSelect.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);


// 统计预警事件记录
export const warnRecordsStatisticsEpic = actions$ => actions$.pipe(
  ofType(actions.warnRecordsStatistics.REQUEST.toString()),
  mergeMap(action => {
    actionApi();
    const { eventName = '', startTime = '', endTime = '', type ,eventClass, module2, name} = action.payload;
    let url = `${urls.warn.records.statistics}?startTime=${startTime}&endTime=${endTime}&type=${type}&sort=id,desc`;
    // if (eventName) {
    //   url += `&eventName=${eventName}`;
    // }
    if (eventClass) {
      url += `&eventClass=${eventClass}`;
    }
    if (module2) {
     url += `&module=${module2}`;
   }
   if (name) {
     url += `&query=${name}`;
   }
    return ajax({
      ...apiBaseConfig,
      url,
    }).pipe(
      map(res => actions.warnRecordsStatistics.SUCCESS(res.response)),
      catchError(error => of(actions.warnRecordsStatistics.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);


// 预警事件记录详情
export const warnRecordsInfoEpic = actions$ => actions$.pipe(
  ofType(actions.warnRecordsInfo.REQUEST.toString()),
  mergeMap(action => {
    actionApi();
    const { recordId, page, size } = action.payload;
    let url = `${urls.warn.records.info}?page=${page}&size=${size}&sort=id,desc&recordId=${recordId}`;
    return ajax({
      ...apiBaseConfig,
      url,
    }).pipe(
      map(res => actions.warnRecordsInfo.SUCCESS(res.response)),
      catchError(error => of(actions.warnRecordsInfo.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);