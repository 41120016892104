import { createAction, createActions, handleActions, combineActions } from 'redux-actions';
import { fasterActions } from '../../utils';
import urls from '@/api/urls';

const moduleName = 'channel';

//字段分配
export const channelList = fasterActions(moduleName, 'channelList');
export const changeDictPaging = createAction(`${moduleName}/changeDictPaging`);
export const channelSelection = fasterActions(moduleName,'channelSelection');
export const channelUpdate = fasterActions(moduleName,'channelUpdate');
export const channelStatus = fasterActions(moduleName,'channelStatus');

const initialState = {
    dictPaging: {
        page: 0,
        size: 20
    },
    channelData:[]
}

const reducer = handleActions(
    {
        [changeDictPaging]: (state, { payload }) => {
            return { ...state, dictPaging: { ...payload} }
        },

        [channelList.REQUEST]: (state, { payload }) => ({ ...state, payload, loading: true }),
        [channelList.SUCCESS]: (state, { payload }) => ({ ...state, channelData: payload, loading: false }),
        [channelList.FAIL]: (state, { payload }) => ({ ...state, loading: false }),


        [channelSelection.REQUEST]: (state, { payload }) => ({ ...state, payload, loading: true }),
        [channelSelection.SUCCESS]: (state, { payload }) => {
            return ({ ...state, channelSelection: payload.content||[], loading: false })
        },
        [channelSelection.FAIL]: (state, { payload }) => ({ ...state, loading: false }),

        [channelUpdate.REQUEST]: (state, { payload }) => ({ ...state, payload, loading: true }),
        [channelUpdate.SUCCESS]: (state, { payload }) => ({ ...state, channelData: payload, loading: false }),
        [channelUpdate.FAIL]: (state, { payload }) => ({ ...state, loading: false }),

        [channelStatus.REQUEST]: (state, { payload }) => ({ ...state, payload, loading: true }),
        [channelStatus.SUCCESS]: (state, { payload }) => ({ ...state, channelData: payload, loading: false }),
        [channelStatus.FAIL]: (state, { payload }) => ({ ...state, loading: false }),
    },
    initialState
);

export default reducer;
