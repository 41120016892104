import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap, map, catchError, concatMap } from 'rxjs/operators';
import { ajax } from '../../ajax';
import urls from '@/api/urls';
import * as uiActions from '../ui';
import queryString from 'query-string';
import * as actions from './codeCodeverify';
import { ajaxBaseConfig, actionApi } from '../../utils';
import { trims } from 'ucode-utils';
/**
 * TODO: 企业管理
 */


//list
export const loadCodeCodeverifyListEpic = actions$ => actions$.pipe(
  ofType(actions.loadCodeCodeverifyList.REQUEST.toString()),

  mergeMap(action => {
    actionApi()
    const { keywords = '', page = 0, size = 20, sort = 'id,desc' } = action.payload;
    return (

      ajax({
        ...ajaxBaseConfig,
        url: `${urls.code.codeCodeverify.codeCodeverify}?word=${keywords}&page=${page}&size=${size}&sort=${sort}`,
      }).pipe(
        map(res => actions.loadCodeCodeverifyList.SUCCESS(res.response || [])),
        catchError(error => of(actions.loadCodeCodeverifyList.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);
//create
export const createCodeCodeverifyEpic = actions$ => actions$.pipe(
  ofType(actions.createCodeCodeverify.REQUEST.toString()),

  mergeMap(action => {
    actionApi()
    const { data, callback } = action.payload;
    return (

      ajax({
        ...ajaxBaseConfig,
        method: 'POST',
        url: `${urls.code.codeCodeverify.codeCodeverify}`,
        body: trims(action.payload.data),
      }).pipe(
        map(res => {
          callback && callback()
          return actions.createCodeCodeverify.SUCCESS(res)
        }),
        catchError(error => of(actions.createCodeCodeverify.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);
//edit
export const editCodeCodeverifyEpic = actions$ => actions$.pipe(
  ofType(actions.editCodeCodeverify.REQUEST.toString()),

  mergeMap(action => {
    actionApi()
    const { data, callback } = action.payload;
    return (

      ajax({
        ...ajaxBaseConfig,
        method: 'PUT',
        url: `${urls.code.codeCodeverify.codeCodeverify}`,
        body: trims(action.payload.data),
      }).pipe(
        map(res => {
          callback && callback()
          return actions.editCodeCodeverify.SUCCESS(res)
        }),
        catchError(error => of(actions.editCodeCodeverify.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);
//able

export const ableCodeCodeverifyEpic = actions$ => actions$.pipe(
  ofType(actions.ableCodeCodeverify.REQUEST.toString()),

  mergeMap(action => {
    actionApi()
    const { data, callback } = action.payload;
    return (

      ajax({
        ...ajaxBaseConfig,
        method: 'PATCH',
        url: `${urls.code.codeCodeverify.codeCodeverify}/${data.id}/status?status=${data.status}`,
        //
        // body: action.payload.data,
      }).pipe(
        map(res => {
          callback && callback()
          return actions.ableCodeCodeverify.SUCCESS(res)
        }),
        catchError(error => of(actions.ableCodeCodeverify.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);

//通信录查询by id
export const loadCodeCodeverifyIdEpic = actions$ => actions$.pipe(
  ofType(actions.loadCodeCodeverifyId.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { data, callback } = action.payload;
    return (
      ajax({
        ...ajaxBaseConfig,
        url: `${urls.code.codeCodeverify.codeCodeverify}/${data.id}`,
      }).pipe(
        map(res => {
          callback && callback(res.response)
          return actions.loadCodeCodeverifyId.SUCCESS(res.response)

        }),
        catchError(error => of(actions.loadCodeCodeverifyId.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);


export const deleteCodeCodeverifyEpic = actions$ => actions$.pipe(
  ofType(actions.deleteCodeCodeverify.REQUEST.toString()),

  mergeMap(action => {
    actionApi()
    const { id, callback } = action.payload;
    return (

      ajax({
        ...ajaxBaseConfig,
        method: 'DELETE',
        url: `${urls.code.codeCodeverify.codeCodeverify}/${id}`,
      }).pipe(
        map(res => {
          callback && callback()
          return actions.deleteCodeCodeverify.SUCCESS(res)
        }),
        catchError(error => of(actions.deleteCodeCodeverify.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);


export const getCodeCodeverifySelectionEpic = actions$ => actions$.pipe(
  ofType(actions.getCodeCodeverifySelection.REQUEST.toString()),

  mergeMap(action => {
    actionApi()
    const { status = '' } = action.payload;
    return (

      ajax({
        ...ajaxBaseConfig,
        url: `${urls.code.codeCodeverify.codeCodeverify}/selections?status=${status}`,
      }).pipe(
        map(res => actions.getCodeCodeverifySelection.SUCCESS(res.response || [])),
        catchError(error => of(actions.getCodeCodeverifySelection.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);
