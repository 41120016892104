import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap, map, catchError, concatMap } from 'rxjs/operators';
import { ajax } from '../../ajax';
import urls from '@/api/urls';
import * as uiActions from '../ui';
import queryString from 'query-string';
import * as actions from './channelBill';
import { ajaxBaseConfig, apiBaseConfig, actionApi } from '../../utils';

export const getChannelBillPageEpic = actions$ => actions$.pipe(
    ofType(actions.getChannelBillPage.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { page = 0, size = 20, word = '', storageTypeDetail = 'OUT_SELE', startTime = '', endTime = '', sort = 'id,desc', status = [], productId = [], sendId = [], sendType, payId = [], payType, receiveId = [], receiveType } = action.payload;
        let url = `${urls.mobile.bill.billList}?page=${page}&size=${size}&word=${word}&storageTypeDetail=${storageTypeDetail}&status=${status}&startTime=${startTime}&endTime=${endTime}&productId=${productId}&receiveType=${receiveType}`;
        if (sendId.length) {
            url = `${url}&sendId=${sendId}&sendType=${sendType}`;
        }
        if (payId.length) {
            url = `${url}&payId=${payId}&payType=${payType}`;
        }
        if (receiveId.length) {
            url = `${url}&receiveId=${receiveId}`;
        }
        return ajax({
            ...ajaxBaseConfig,
            url
        }).pipe(
            map(res => {
                return actions.getChannelBillPage.SUCCESS(res.response)
            }),
            catchError(error => of(actions.getChannelBillPage.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    }
    )
);

// 创建渠道单据
export const addChannelBillEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.addChannelBill.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { data, history } = action.payload
        return (
            ajax({
                ...ajaxBaseConfig,
                url: `${urls.mobile.bill.addBill}`,
                method: 'POST',
                body: data,
            }).pipe(
                map(res => {
                    history.goBack();
                    return actions.addChannelBill.SUCCESS(Object.assign(res.response))
                }),
                catchError(error => of(actions.addChannelBill.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
            )
        )
    })
);

// 编辑渠道单据
export const editChannelBillEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.editChannelBill.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { data, history } = action.payload;
        return (
            ajax({
                ...ajaxBaseConfig,
                url: `${urls.mobile.bill.editList}`,
                method: 'POST',
                body: data,
            }).pipe(
                map(res => {
                    history.goBack();
                    return actions.editChannelBill.SUCCESS(Object.assign(res.response))
                }),
                catchError(error => of(actions.editChannelBill.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
            )
        )
    })
);

// 根据id查渠道单据详情
export const getChannelBillDetailEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.getChannelBillDetail.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { id, callback } = action.payload;
        return (
            ajax({
                ...ajaxBaseConfig,
                url: `${urls.mobile.bill.detail}?storageId=${id}`,
            }).pipe(
                map(res => {
                    console.log(res, 'res')
                    callback && callback(res.response);
                    return actions.getChannelBillDetail.SUCCESS(Object.assign(res.response))
                }),
                catchError(error => of(actions.getChannelBillDetail.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
            )
        )
    })
);

// 单据详情
export const getBillInfoEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.getBillInfo.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { id, callback } = action.payload;
        return (
            ajax({
                ...ajaxBaseConfig,
                url: `${urls.mobile.bill.billInfo}?storageId=${id}`,
            }).pipe(
                map(res => {
                    console.log(res, 'res')
                    callback && callback(res.response);
                    return actions.getBillInfo.SUCCESS(Object.assign(res.response))
                }),
                catchError(error => of(actions.getBillInfo.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
            )
        )
    })
);

export const getCodesDetailEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.getCodesDetail.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { page = 0, size = 5, word='', storageId = '', productId = '', level = 1 } = action.payload;
        return (
            ajax({
                ...ajaxBaseConfig,
                url: `${urls.mobile.bill.codes}?page=${page}&size=${size}&word=${word}&storageId=${storageId}&productId=${productId}&level=${level}`,
            }).pipe(
                map(res => {
                    return actions.getCodesDetail.SUCCESS(Object.assign(res.response))
                }),
                catchError(error => of(actions.getCodesDetail.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
            )
        )
    })
);

export const getProductLevelEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.getProductLevel.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { id = '', status = '' } = action.payload;
        return (
            ajax({
                ...ajaxBaseConfig,
                url: `${urls.mobile.bill.productlevel}/${id}/status?status=${status}`,
                method: 'PATCH',
            }).pipe(
                concatMap(res => [actions.getProductLevel.SUCCESS(Object.assign(res.response))]),
                catchError(error => of(actions.getProductLevel.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
            )
        )
    })
);


// 关闭单据
export const closeChannelBillEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.closeChannelBill.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { id = '', callback } = action.payload;
        return (
            ajax({
                ...ajaxBaseConfig,
                url: `${urls.mobile.bill.close}?storageId=${id}`,
            }).pipe(
                concatMap(res => {
                    callback && callback(res.response);
                    return [actions.closeChannelBill.SUCCESS(Object.assign(res.response))]
                }),
                catchError(error => of(actions.closeChannelBill.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
            )
        )
    })
);

// 导出单据
export const exportChannelBillEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.exportChannelBill.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { params = {}, callback } = action.payload;
        const { word = '', storageTypeDetail = 'OUT_SELE', startTime = '', endTime = '', status = [], productId = [], sendId = [], sendType, payId = [], payType, receiveId = [], receiveType } = params;
        let url = `${urls.mobile.bill.export}?word=${word}&status=${status}&productId=${productId}&storageTypeDetail=${storageTypeDetail}&receiveType=${receiveType}`;
        if (startTime && endTime) {
            url = `${url}&startTime=${startTime}&endTime=${endTime}`
        }
        if (sendId.length) {
            url = `${url}&sendId=${sendId}&sendType=${sendType}`;
        }
        if (payId.length) {
            url = `${url}&payId=${payId}&payType=${payType}`;
        }
        if (receiveId.length) {
            url = `${url}&receiveId=${receiveId}`;
        }
        return (
            ajax({
                ...ajaxBaseConfig,
                url
            }).pipe(
                concatMap(res => {
                    callback && callback();
                    return [actions.exportChannelBill.SUCCESS(res.response), uiActions.notification('文件导出成功', 'success')]
                }),
                catchError(error => of(actions.exportChannelBill.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
            )
        )
    })
);

// 导入单据
export const importChannelBillEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.importChannelBill.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        return (
            ajax({
                ...apiBaseConfig,
                url: `${urls.mobile.bill.import}`,
                method: 'POST',
                body: action.payload,
            }).pipe(
                concatMap(res => {
                    return [actions.importChannelBill.SUCCESS(res.response), uiActions.notification('文件导入成功', 'success')]
                }),
                catchError(error => of(actions.importChannelBill.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
            )
        )
    })
);

// 导入单据
export const importChannelBilltransferEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.importChannelBilltransfer.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        return (
            ajax({
                ...apiBaseConfig,
                url: `${urls.mobile.bill.importtransfer}`,
                method: 'POST',
                body: action.payload,
            }).pipe(
                concatMap(res => {
                    return [actions.importChannelBilltransfer.SUCCESS(res.response), uiActions.notification('文件导入成功', 'success')]
                }),
                catchError(error => of(actions.importChannelBilltransfer.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
            )
        )
    })
);



// 基础数据管理---下拉框
// 仓库
export const getWarehouseSelectEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.getWarehouseSelect.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        return (
            ajax({
                ...ajaxBaseConfig,
                url: `${urls.data.tmfeign.tmfeign}`,
            }).pipe(
                concatMap(res => [actions.getWarehouseSelect.SUCCESS(Object.assign(res.response))]),
                catchError(error => of(actions.getWarehouseSelect.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
            )
        )
    })
);

// 经销商
export const getDealerSelectEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.getDealerSelect.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        return (
            ajax({
                ...ajaxBaseConfig,
                url: `${urls.data.tmfeign.dealerlist}`,
            }).pipe(
                concatMap(res => [actions.getDealerSelect.SUCCESS(Object.assign(res.response))]),
                catchError(error => of(actions.getDealerSelect.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
            )
        )
    })
);
// 门店
export const getShopSelectEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.getShopSelect.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        return (
            ajax({
                ...ajaxBaseConfig,
                url: `${urls.data.tmfeign.shoplist}`,
            }).pipe(
                concatMap(res => [actions.getShopSelect.SUCCESS(Object.assign(res.response))]),
                catchError(error => of(actions.getShopSelect.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
            )
        )
    })
);

// 产品
export const getProductSelectEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.getProductSelect.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        return (
            ajax({
                ...ajaxBaseConfig,
                url: `${urls.data.tmfeign.product}`,
            }).pipe(
                concatMap(res => [actions.getProductSelect.SUCCESS(Object.assign(res.response))]),
                catchError(error => of(actions.getProductSelect.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
            )
        )
    })
);

// 产品详情
export const getProductInfoEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.getProductInfo.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { productId, callback } = action.payload;
        return (
            ajax({
                ...ajaxBaseConfig,
                url: `${urls.data.tmfeign.productInfo}?productId=${productId}`,
            }).pipe(
                concatMap(res => {
                    callback && callback(res.response);
                    return [actions.getProductInfo.SUCCESS(Object.assign(res.response))]
                }),
                catchError(error => of(actions.getProductInfo.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
            )
        )
    })
);
