import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap, map, catchError, concatMap } from 'rxjs/operators';
import { ajax } from '../../ajax';
import urls from '../../../api/urls';
import * as uiActions from '../ui';

import * as actions from './codeDetail';
import { ajaxBaseConfig, apiBaseConfig, actionApi } from '../../utils';

// 码明细列表
export const codeDetailSumEpic = actions$ => actions$.pipe(
    ofType(actions.codeDetailSum.REQUEST.toString()),
    mergeMap(action => {
        actionApi();
        const { page = 0, size = 20,startTime='',endTime='',type='',orderCode = '',eseSeqNum='',mark=''} = action.payload;
        let typeVal = type.value || '';
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.codeDetail .codeDetailList}?page=${page}&size=${size}&type=${type}&orderCode=${orderCode}&eseSeqNum=${eseSeqNum}&mark=${mark}`
        }).pipe(
            map(res => actions.codeDetailSum.SUCCESS(res.response)),
            catchError(error => of(actions.codeDetailSum.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    } 
    )
);

// 订单类型
export const orderTypeEpic = actions$ => actions$.pipe(
    ofType(actions.orderType.REQUEST.toString()),
    mergeMap(action => {
        actionApi();
        // const  {type = 'scan-report'} = action.payload;
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.codeDetail.orderType}?type=1&childType=1,2,4,5,6`
        }).pipe(
            map(res => actions.orderType.SUCCESS(res.response)),
            catchError(error => of(actions.orderType.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    }
    )
);

//订单号list
export const codeOrderNoEpic = actions$ => actions$.pipe(
    ofType(actions.codeOrderNo.REQUEST.toString()),
    mergeMap(action => {
        actionApi();
        const { startTime='',endTime=''} = action.payload;
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.codeDetail.cordeOrder}?startTime=${startTime}&endTime=${endTime}`
        }).pipe(
            map(res => actions.codeOrderNo.SUCCESS(res.response)),
            catchError(error => of(actions.codeOrderNo.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    } 
    )
);

// // 筛选选下载
// export const selectLoadEpic = actions$ => actions$.pipe(
//     ofType(actions.selectLoad.REQUEST.toString()),
//     mergeMap(action => {
//         actionApi();

//         const {type=1,json}=action.payload;
//         let url = '';
//         if(type == 1){
//             url = `${urls.scan.downloadStatistics}`;
//         }else if(type==2){
//             url = `${urls.scan.downloadBanquet}`;
//         }else if(type==3){
//             url = `${urls.scan.downloadConsumer}`;
//         }
//         return ajax({
//             ...ajaxBaseConfig,
//             url: url,
//             method:'POST',
//             body:JSON.stringify(json)
//         }).pipe(
//             map(res => actions.selectLoad.SUCCESS(res.response)),
//             catchError(error => of(actions.selectLoad.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
//         )
//     }
//     )
// );




