

import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap, map, catchError, concatMap } from 'rxjs/operators';
//不要encode
import { ajax as noecAjax } from 'rxjs/ajax';
import { ajax } from '../../ajax';
import urls from '@/api/urls';
import * as uiActions from '../ui';
import queryString from 'query-string';
import * as actions from './prizeManager';
import { ajaxBaseConfig, actionApi } from '../../utils';

// Epics
export const loadListEpic = actions$ => actions$.pipe(
  ofType(actions.loadList.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.promotion.prizes.prizes}?${queryString.stringify(action.payload)}`,
    }).pipe(
      map(res => actions.loadList.SUCCESS(res.response)),
      catchError(error => of(actions.loadList.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
      )
    )
  }
  )
);

// 奖品列表
export const prizePagingEpic = (actions$) => actions$.pipe(

  ofType(actions.prizePaging.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { page = 0, size = 20, type = '', keyWords = '', sort = '' } = action.payload.paging;
    return (
      noecAjax({
        ...ajaxBaseConfig,
        url: `${urls.promotion.prizes.prizes}?page=${page}&size=${size}&type=${type}&keyWords=${keyWords ? encodeURIComponent(keyWords) : ''}&sort=${sort}`
      }).pipe(
        map(res => actions.prizePaging.SUCCESS(res.response)),
        catchError(error => of(actions.prizePaging.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);


// 奖品复制
export const prizeCopyEpic = (actions$, state$) => actions$.pipe(
  ofType(actions.prizeCopy.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { id, remark, nextParam = {}, callback } = action.payload;
    return (
      ajax({
        url: `${urls.promotion.prizes.prizes}`,
        method: 'POST',
        ...ajaxBaseConfig,
        body: JSON.stringify({ id, remark })
      }).pipe(
        concatMap(res => {
          callback && callback();
          return [actions.prizeCopy.SUCCESS(res.response), actions.prizePaging.REQUEST(nextParam)];
        }),
        catchError(error => of(actions.prizeCopy.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);

// 奖品删除
export const prizeDeleteEpic = (actions$, state$) => actions$.pipe(
  ofType(actions.prizeDelete.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { id, nextParam = {}, callback } = action.payload;
    return (
      ajax({
        ...ajaxBaseConfig,
        method: 'delete',
        url: `${urls.promotion.prizes.prizes}/${id}`
      }).pipe(
        concatMap(res => {
          callback && callback();
          return [actions.prizeDelete.SUCCESS(res.response), actions.prizePaging.REQUEST(nextParam)];
        }),
        catchError(error => of(actions.prizeDelete.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);


// 奖品详情
export const prizeDetailByIdEpic = actions$ => actions$.pipe(
  ofType(actions.prizeDetail.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { id, callback } = action.payload;
    return (
      ajax({
        ...ajaxBaseConfig,
        url: `${urls.promotion.prizes.prizes}/${id}`
      }).pipe(
        map(res => {
          callback && callback(res.response);
          return actions.prizeDetail.SUCCESS(res.response);
        }),
        // catchError(error => of (actions.prizeDetail.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);

// 奖品创建
export const prizeCreateEpic = (actions$, state$) => actions$.pipe(
  ofType(actions.prizeCreate.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { data, callback } = action.payload;
    const UrlConstants = {
      RED_PACKET: 'redPacket', PHYSICAL: 'physical', POINT: 'pointPrizeType',
      COUPON: 'coupon', CARD: 'cardPrizeType', CHANGE_OF_MONEY: 'changeOfMoney', WRITE_OFF: 'writeOff'
    };
    return (
      ajax({
        ...ajaxBaseConfig,
        url: `${urls.promotion.prizes[UrlConstants[data.prizeType]]}`,
        method: 'POST',
        body: JSON.stringify(data)
      }).pipe(
        concatMap(res => {
          callback && callback(res.response);
          return [actions.prizeCreate.SUCCESS(res.response), actions.prizePaging.REQUEST({ paging: { ...state$.value.prizeManager.paging, page: 0 } })];
        }),
        catchError(error => of(actions.prizeCreate.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);

// 奖品修改
export const prizeEditEpic = actions$ => actions$.pipe(
  ofType(actions.prizeEdit.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { data, callback } = action.payload;
    const UrlConstants = {
      RED_PACKET: 'redPacket', PHYSICAL: 'physical', POINT: 'pointPrizeType',
      COUPON: 'coupon', CARD: 'cardPrizeType', CHANGE_OF_MONEY: 'changeOfMoney', WRITE_OFF: 'writeOff'
    };
    return (
      ajax({
        ...ajaxBaseConfig,
        url: `${urls.promotion.prizes[UrlConstants[data.prizeType]]}`,
        method: 'PUT',
        body: JSON.stringify(data)
      }).pipe(
        map(res => {
          callback && callback(res.response);
          return actions.prizeEdit.SUCCESS(res.response);
        }),
        catchError(error => of(actions.prizeEdit.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);

export const prizeSelectEpic = actions$ => actions$.pipe(
  ofType(actions.prizeSelect.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.promotion.prizes.prizes}/selections`,
    }).pipe(
      map(res => actions.prizeSelect.SUCCESS(res.response)),
      catchError(error => of(actions.prizeSelect.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
      )
    )
  }
  )
);

// 校验免密金额总和
export const checkoverLimitEpic = (actions$) => actions$.pipe(
  ofType(actions.checkoverLimit.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { amount, callback } = action.payload;
    // debugger

    return (
      noecAjax({
        ...ajaxBaseConfig,
        url: `${urls.wechat.merchant.merchant}/check-over-limit?amount=${amount}`,
        method: 'GET',
      }).pipe(
        // map(res => actions.checkoverLimit.SUCCESS(res.response)),
        map(res => {
          callback && callback(res.response);
          return actions.checkoverLimit.SUCCESS(res.response)
        }),
        catchError(error => of(actions.checkoverLimit.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);

// 创建核销码
export const submitVoucherCodeTemplateListEpic = actions$ => actions$.pipe(
  ofType(actions.submitVoucherCodeTemplateList.REQUEST.toString()),
  mergeMap(action => {
    actionApi();
    const { data, listPharams } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.code.batchApplys.submitVoucherCode}`,
      method: 'POST',
      body: data
    }).pipe(
      // map(res => actions.submitVoucherCodeTemplateList.SUCCESS(res.response)),
      concatMap(res=>[actions.submitVoucherCodeTemplateList.SUCCESS(res.response), actions.voucherCodeList.REQUEST(listPharams)]),
      catchError(error => of(actions.submitVoucherCodeTemplateList.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);

// 核销码规格
export const loadChoiceListEpic = actions$ => actions$.pipe(
  ofType(actions.loadChoiceList.REQUEST.toString()),
  mergeMap(action => {
    actionApi();
    const { all } = action.payload || {};
    let url = `${urls.code.tagbatchConfigs.tagbatchConfigsVerifyCodeChoice}`;
    if (all) {
      url = `${urls.code.tagbatchConfigs.tagbatchConfigs}/allots?isPage=false`
      // url += `?all=${all}`
    }

    return ajax({
      ...ajaxBaseConfig,
      url,
    }).pipe(
      map(res => actions.loadChoiceList.SUCCESS(res.response)),
      catchError(error => of(actions.loadChoiceList.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
      )
    )
  }
  )
);

// 券码列表
export const voucherCodeListEpic = (actions$) => actions$.pipe(
  ofType(actions.voucherCodeList.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { page = 0, size = 20, sort = '' } = action.payload.pagingVoucher;
    const { prizeWriteOffId } = action.payload;
    return (
      noecAjax({
        ...ajaxBaseConfig,
        // 测试 - 没传分页
        // url: `${urls.code.voucherCode.voucherCode}/${typeId}`,
        url: `${urls.code.voucherCode.voucherCode}/${prizeWriteOffId}?page=${page}&size=${size}&sort=${sort}`,
      }).pipe(
        map(res => actions.voucherCodeList.SUCCESS(res.response)),
        catchError(error => of(actions.voucherCodeList.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);

// 券码数量
export const voucherCodeNumEpic = (actions$) => actions$.pipe(
  ofType(actions.voucherCodeNum.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { prizeWriteOffId } = action.payload;
    return (
      noecAjax({
        ...ajaxBaseConfig,
        url: `${urls.code.voucherCode.voucherCode}/${prizeWriteOffId}/num`,
      }).pipe(
        map(res => actions.voucherCodeNum.SUCCESS(res.response)),
        catchError(error => of(actions.voucherCodeNum.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);

// 券码备注
export const voucherCodeRemarkEpic = (actions$) => actions$.pipe(
  ofType(actions.voucherCodeRemark.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { id, remark, listPharams } = action.payload;
    return (
      noecAjax({
        ...ajaxBaseConfig,
        url: `${urls.code.voucherCode.voucherCode}/${id}/remark`,
        method: 'PUT',
        body: {remark}
      }).pipe(
        concatMap(res => [actions.voucherCodeRemark.SUCCESS(res.response), actions.voucherCodeList.REQUEST(listPharams)]),
        catchError(error => of(actions.voucherCodeRemark.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);
