import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap, map, catchError, concatMap } from 'rxjs/operators';
import { ajax } from '../../ajax';
import urls from '../../../api/urls';
import * as uiActions from '../ui';
import queryString from 'query-string';
import * as actions from './codingRegister';
import { ajaxBaseConfig, actionApi } from '../../utils';

/**
 * TODO: 微信管理
 */

// Epics
// 获取小程序素材列表
export const loadCodingRegisterListEpic = actions$ => actions$.pipe(
  ofType(actions.loadCodingRegisterList.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { page, size, keyWords, pageTemplateType = '' } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.code.codingTems.codingTemUrl}?page=${page}&size=${size}&keyWords=${keyWords}&pageTemplateTypes=${pageTemplateType ?  pageTemplateType || "" : ""}`
    }).pipe(
      map(res => actions.loadCodingRegisterList.SUCCESS(res.response)),
      catchError(error => of(actions.loadCodingRegisterList.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);
// 模板列表
export const loadCodingTemListEpic = actions$ => actions$.pipe(
  ofType(actions.loadCodingTemList.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { page, size, keyWords, pageTemplateId } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.code.codingTems.elementAll}?page=${page}&size=${size}&keyWords=${keyWords}&pageTemplateId=${pageTemplateId}`
    }).pipe(
      map(res => actions.loadCodingTemList.SUCCESS(res.response)),
      catchError(error => of(actions.loadCodingTemList.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);

// 根据id获取小程序素材列表
export const loadCodingRegisterIdEpic = actions$ => actions$.pipe(
  ofType(actions.loadCodingRegisterId.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    let url = `${urls.code.codingTems.codingTemsId}/`;

    return ajax({
      ...ajaxBaseConfig,
      url
    }).pipe(
      map(res => actions.loadCodingRegisterId.SUCCESS(res.response)),
      catchError(error => of(actions.loadCodingRegisterId.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);
export const loadTemIdEpic = actions$ => actions$.pipe(
  ofType(actions.loadTemId.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    let url = `${urls.code.codingTems.codingTemsId}/`;

    return ajax({
      ...ajaxBaseConfig,
      url
    }).pipe(
      map(res => actions.loadTemId.SUCCESS(res.response)),
      catchError(error => of(actions.loadTemId.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);
// 创建/修改 /上架下架 /删除小程序 获取模板

export const loadtemplateEpic = (actions$, state$) => actions$.pipe(
  ofType(actions.loadtemplate.REQUEST.toString()),
  mergeMap(action => {

    actionApi()
    let url = "";
    const { method, id } = action.payload;
    if (method === 'DELETE') {
      url = `${urls.code.codingTems.codingTemsdelete}/`
      url += id;
    } else if (method == "PATCH") {
      url = `${urls.code.codingTems.codingTemsLoad}`;
    } else if (method === 'POST' || method === 'PUT') {
      url = `${urls.code.codingTems.codingTemsdelete}`
    }
    return ajax({
      ...ajaxBaseConfig,
      url,
      method,
      body: action.payload,
    }).pipe(
      concatMap(res => {
        action.payload.callback && action.payload.callback(res);
        return [actions.loadtemplate.SUCCESS({ status: res.status, method })];
      }),
      catchError(error => of(actions.loadtemplate.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);
// 创建/修改 /上架下架 /删除元素 获取元素

export const loadTemAddEpic = (actions$, state$) => actions$.pipe(
  ofType(actions.loadTemAdd.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    let url = "";
    const { method, id } = action.payload;
    if (method === 'DELETE') {
      url = `${urls.code.codingTems.elements}/`
      url += id;
    } else if (method == "PATCH") {
      url = `${urls.code.codingTems.elementStatus}`;
    } else if (method === 'POST') {
      url = `${urls.code.codingTems.elementAdd}`
    } else if (method === 'PUT') {
      url = `${urls.code.codingTems.elementEdit}`
    }
    return ajax({
      ...ajaxBaseConfig,
      url,
      method,
      body: action.payload,
    }).pipe(
      concatMap(res => {
        action.payload.callback && action.payload.callback(res);
        return [actions.loadTemAdd.SUCCESS({ status: res.status, method })];
      }),
      catchError(error => of(actions.loadtemplate.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);