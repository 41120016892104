import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap, map, catchError, concatMap } from 'rxjs/operators';
import { ajax } from '../../ajax';
import urls from '@/api/urls';
import * as uiActions from '../ui';
import queryString from 'query-string';
import * as actions from './zone';
import { ajaxBaseConfig, actionApi } from '../../utils';

// Epics
export const getZoneEpic = actions$ => actions$.pipe(
    ofType(actions.getZone.REQUEST),
    mergeMap(action => {
        actionApi()
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.data.zones.selections}`
        }).pipe(
            concatMap(res => {
                let newarray = res.response.content.map((item) => { return { ...item, value: item.code, text: item.name, id: item.code } })

                let newarray2 = [];
                let newarray3 = [];
                let old = -1;
                let old2 = -1;
                for (let i = 0; i < newarray.length; i++) {
                    if (newarray[i].level == 1) {
                        newarray2.push({ ...newarray[i], children: [] });
                        newarray3.push({ ...newarray[i], children: [] });
                        old++
                        old2=-1;
                    }
                    else if (newarray[i].level == 2) {
                        newarray2[old].children.push(newarray[i])
                        newarray3[old].children.push({ ...newarray[i], children: [] })
                        old2++
                    }
                    else if (newarray[i].level == 3) {
                        if(newarray3[old].children[old2]){
                          if(newarray3[old].children[old2].children){
                               newarray3[old].children[old2].children.push(newarray[i])
                            }
                          }
                       else if(old2==-1){
                        // newarray3[old].children.push();
                        let a = JSON.parse(JSON.stringify(newarray3[old]))
                        a.value = a.value + '_0';
                        a.code =a.code + '_0';
                        a.id = a.id  + '_0';
                        a.level = 2;
                        a.parentCode = a.code;
                         a.children=[]
                        newarray3[old].children.push(a)
                        newarray3[old].children[0].children.push(newarray[i])
                        old2++
                     }
                }
                }

                let newarray2_2 = [];       
                for (let i = 0; i < newarray2.length; i++) {
                    if (newarray2[i].children.length != 0) {
                        newarray2_2.push(newarray2[i])          
                        // let a = JSON.parse(JSON.stringify(newarray2[i]))
                        // newarray2[i].value = newarray2[i].value + '_0';
                        // newarray2[i].code = newarray2[i].code + '_0';
                        // newarray2[i].id = newarray2[i].id + '_0';
                        // a.level = 2;
                        // a.parentCode = a.code;
                        // delete a.children
                        // newarray2[i].children.push(a)
                    }
                }
                let newarray3_2 = [];
                for (let i = 0; i < newarray3.length; i++) {
                    // if (newarray3[i].children.length != 0) {
                    //     newarray3_2.push(newarray3[i])          
                    // }
                    // else{

                    // }

                    if (newarray3[i].children.length != 0) {
                    let newdata=[];
                    for(let j=0;j<newarray3[i].children.length;j++){             
                        if (newarray3[i].children[j].children.length != 0) {
                           newdata.push(newarray3[i].children[j])
                        }
                    }
                    newarray3_2.push({...newarray3[i],children:newdata})
                }
                }


                action.payload && action.payload.callback && action.payload.callback(newarray);
                typeof action.payload === 'function' && action.payload()
                
                return [actions.getZone.SUCCESS({ list: newarray, list2: newarray2_2, list3: newarray3_2 })]
            }),
            // catchError(error => of(actions.getZone.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)