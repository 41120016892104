import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { ajax } from '../../ajax';
import urls from '../../../api/urls';
import * as uiActions from '../ui';
import { fasterActions } from '../../utils';
import { createAction, createActions, handleActions, combineActions } from 'redux-actions';
import { formatDate } from 'ucode-utils';

const moduleName = 'tagbatchConfigs';

const initialState = {
  listLoaded: false,
  promotionUploadList:{},
  promotionCodeList:{}
}

// Actions
export const downLoadCode = fasterActions(moduleName, 'downLoadCode');
export const loadList = fasterActions(moduleName, 'loadList');
export const loadAllList = fasterActions(moduleName, 'loadAllList');
export const loadChoiceList = fasterActions(moduleName, 'loadChoiceList');
export const tagbatchConfigsDetail = fasterActions(moduleName, 'tagbatchConfigsDetail');
export const extractionCode = fasterActions(moduleName, 'extractionCode');
export const downloadExtractionCode = fasterActions(moduleName, 'downloadExtractionCode');
export const updateWfTemplateIdSelector = createAction(`${moduleName}/wfTemplateIdSelector`);
export const updateFactorySelector = createAction(`${moduleName}/updateFactorySelector`);

export const promotionUpload = fasterActions(moduleName, 'promotionUpload');
export const getpromotionUploadList = fasterActions(moduleName, 'getpromotionUploadList');
export const getpromotionCodeList = fasterActions(moduleName, 'getpromotionCodeList'); 


const reducer = handleActions(
  {
    [loadList.REQUEST]: (state, { payload }) => ({ ...state, ...payload }),
    [loadList.SUCCESS]: (state, { payload = {} }) => {
      const selector = [];
      const codeTypeSelector = [];
      const { codeSourceType } = state;
      for (let elem of payload.content.values()) {
        selector.push({ value: elem.id, text: elem.name });
        if (codeSourceType && elem.enterpriseDomain && elem.enterpriseDomain.codeSourceType == codeSourceType) {
          codeTypeSelector.push({ value: elem.id, text: elem.name });
        }
      }
      return {
        ...state,
        listLoaded: true,
        selector,
        codeTypeSelector,
        list: payload.content
      }
    },
    [loadList.FAIL]: (state, { payload }) => {
      return {
        ...state,
        listLoaded: false,
      }
    },
    [loadAllList.REQUEST]: (state, { payload }) => ({ ...state, ...payload }),
    [loadAllList.SUCCESS]: (state, { payload = {} }) => {
      const codeTypeSelector = [];
      const { codeSourceType } = state;
      for (let elem of payload.content.values()) {
        if (codeSourceType && elem.enterpriseDomain && elem.enterpriseDomain.codeSourceType == codeSourceType) {
          codeTypeSelector.push({ value: elem.id, text: elem.name });
        }
      }
      return {
        ...state,
        codeTypeSelector,
      }
    },
    [loadAllList.FAIL]: (state, { payload }) => {
      return {
        ...state,
      }
    },

    [loadChoiceList.SUCCESS]: (state, { payload }) => {
      const choiceSelector = [];
      for (let elem of payload.content.values()) {
        choiceSelector.push({ value: elem.id, text: elem.name, combinationTagType: elem.combinationTagType, combinationConfigDtos: elem.combinationConfigDtos || [], historyTagTemplateConfig: elem.historyTagTemplateConfig || {} })
      }
      return { ...state, choiceSelector, listLoaded: true, }
    },
    [loadChoiceList.FAIL]: (state, { payload }) => ({ ...state, listLoaded: false, }),



    [downLoadCode.REQUEST]: (state, { payload }) => ({ ...state, downLoadCodeLoading: true, downLoadCodeLoaded: false }),
    [downLoadCode.SUCCESS]: (state, { payload }) => ({ ...state, downLoadCodeLoading: false, downLoadCodeLoaded: true }),
    [downLoadCode.FAIL]: (state, { payload }) => ({ ...state, downLoadCodeLoading: false, downLoadCodeLoaded: false, error: payload }),

    [tagbatchConfigsDetail.REQUEST]: (state, { payload }) => ({ ...state, payload }),
    [tagbatchConfigsDetail.SUCCESS]: (state, { payload }) => {
  
      const detailSelector = [], cADetailSelector = [];
      if (payload.wfTemplateList.length) {
        payload.wfTemplateList.forEach(val => {
          let signer = {};
          val.workflowNodes.forEach(v => {
            // nodeType : PROCESS_START=发码申请人, PROCESS_AUDIT=发码审核人,PACKET_RECEIVER=码包接收人,PASSWORD_RECEIVER=密钥接收人
            signer[v.nodeType] = v.signerName;
          });
         
          if (val.status == 'ENABLE') {
            cADetailSelector.push({ value: val.id, text: val.name, title: `审核人：${signer.PROCESS_AUDIT || '无(免审)'}，码包接收人：${signer.PACKET_RECEIVER || ''}，密码接收人：${signer.PASSWORD_RECEIVER || ''}`, factoryId: val.factoryId });
          }
          detailSelector.push({ value: val.id, text: val.name, factoryId: val.factoryId });
        });
      }

      return {
        ...state,
        tagbatchDetail: payload,
        detailSelector,
        cADetailSelector,
        wfTemplateIdSelector: cADetailSelector
      }
    },
    [tagbatchConfigsDetail.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

    [extractionCode.REQUEST]: (state, { payload }) => ({ ...state, }),
    [extractionCode.SUCCESS]: (state, { payload }) => {
      // const extractionList = [];
      // if (payload.content) {
      //   payload.content.forEach(val => {
      //     let { id, fileName = '', name, tagCount, updatedTime, downloadLinks, extractedCode } = val;
      //     // const temp = fileName.replace(name, '').split('/');
      //     // extractionList.push({ id, fileName: `${temp[temp.length - 2]}_${name}_${temp[temp.length - 1]}`, name, tagCount, updatedTime: formatDate(updatedTime), downloadLinks, extractedCode });
      //   });
      // }
      return ({ ...state, extractionList: payload.content ? payload.content : payload })

    },
    [extractionCode.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

    [downloadExtractionCode.REQUEST]: (state, { payload }) => ({ ...state, payload }),
    [downloadExtractionCode.SUCCESS]: (state, { payload }) => ({ ...state, downLoadFile: payload }),
    [downloadExtractionCode.FAIL]: (state, { payload }) => ({ ...state }),



    [promotionUpload.REQUEST]: (state, { payload }) => ({ ...state, payload }),
    [promotionUpload.SUCCESS]: (state, { payload }) => { return ({ ...state, promotionUploadContent: payload.content ? payload.content : payload }) },
    [promotionUpload.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

    [getpromotionUploadList.REQUEST]: (state, { payload }) => ({ ...state, payload }),
    [getpromotionUploadList.SUCCESS]: (state, { payload }) => { return ({ ...state, promotionUploadList: payload || {} }) },
    [getpromotionUploadList.FAIL]: (state, { payload }) => ({ ...state, error: payload }),


    
    [getpromotionCodeList.REQUEST]: (state, { payload }) => ({ ...state, payload }),
    [getpromotionCodeList.SUCCESS]: (state, { payload }) => { return ({ ...state, promotionCodeList: payload || {} }) },
    [getpromotionCodeList.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

    [updateWfTemplateIdSelector]: (state, { payload }) => {
      return { ...state, wfTemplateIdSelector: payload }
    },
    [updateFactorySelector]: (state, { payload }) => {
      return { ...state, factorySelector: payload }
    },

  },


  initialState
);

export default reducer;
