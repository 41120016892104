import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap, map, catchError, concatMap } from 'rxjs/operators';
import { createHashHistory } from 'history';
import { ajax } from '../../ajax';
import urls from '../../../api/urls';
import * as uiActions from '../ui';
// import queryString from 'query-string';
import * as actions from './users';
import { ajaxBaseConfig, actionApi } from '../../utils';


// Epics
const goPromotion = () => {
  const history = createHashHistory();
  // history.push('/promotionpromotionmanageproduct');
}
// openId绑定用户查询
export const checkOpenId = actions$ => actions$.pipe(
  ofType(actions.checkOpenId.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    return ajax({
      ...ajaxBaseConfig,
      method: 'POST',
      url: urls.data.users.openIds,
      body: {
        openId: action.payload.openId,
        enterpriseId: action.payload.enterpriseId,
      }
    }).pipe(
      mergeMap(res => {
        // goPromotion(); 
        
        return [actions.checkOpenId.SUCCESS(res.response), actions.selfInfo.REQUEST()]
      }),
      catchError(error => {
        // const { ecode = '' } = error.response || {};
        return of(actions.checkOpenId.FAIL(error.response))
      }
      )
    )
  }
  )
);

// 系统登录
export const systemLoginEpic = actions$ => actions$.pipe(
  ofType(actions.systemLogin.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    return ajax({
      ...ajaxBaseConfig,
      method: 'POST',
      url: urls.data.users.systemLogin,
      body: action.payload.body,
    }).pipe(
      mergeMap(res => {
        action.payload.callback && action.payload.callback();
        const history = createHashHistory();
        history.replace('/');
        // action.payload.history.replace('/');
        return [actions.systemLogin.SUCCESS(res.response),  actions.selfInfo.REQUEST()]
      }),
      catchError(error => of(actions.systemLogin.FAIL(error), uiActions.notification(error.xhr.response))
      )
    )
  }
  )
);
// 修改密码
export const changePasswordEpic = actions$ => actions$.pipe(
  ofType(actions.changePassword.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    return ajax({
      ...ajaxBaseConfig,
      method: 'PUT',
      url: urls.data.users.changePassword,
      body: action.payload,
    }).pipe(
      mergeMap(res => {
        return [actions.changePassword.SUCCESS(res.response), actions.toggleChangePWDDialog(false)]
      }),
      catchError(error => of(actions.changePassword.FAIL(error), uiActions.notification(error.xhr.response))
      )
    )
    }
  )
);
// 重置密码
export const resetPasswordEpic = actions$ => actions$.pipe(
  ofType(actions.resetPassword.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    return ajax({
      ...ajaxBaseConfig,
      method: 'PUT',
      url: urls.data.users.resetPassword,
      body: action.payload,
    }).pipe(
      mergeMap(res => {
        return [actions.resetPassword.SUCCESS(res.response), actions.toggleChangePWDDialog(false)]
      }),
      catchError(error => of(actions.resetPassword.FAIL(error), uiActions.notification(error.xhr.response))
      )
    )
    }
  )
);

// 系统登出
export const systemSignOutEpic = actions$ => actions$.pipe(
  ofType(actions.systemSignOut.REQUEST.toString()),
  mergeMap(action => {
    actionApi()

    return ajax({
      ...ajaxBaseConfig,
      method: 'DELETE',
      url: urls.data.users.systemSignOut,
      body: action.payload,
    }).pipe(
      mergeMap(res => {
        return [actions.systemSignOut.SUCCESS(res.response)]
      }),
      catchError(error => of(actions.systemSignOut.FAIL(error), uiActions.notification(error.xhr.response))
      )
    )
  }
  )
);


// 用户邀请码查询
export const invitationCodes = actions$ => actions$.pipe(
  ofType(actions.invitationCodes.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    return ajax({
      ...ajaxBaseConfig,
      url: urls.data.users.invitationCodes(action.payload.invitationCode)
    }).pipe(
      map(res => actions.invitationCodes.SUCCESS(res.response)),
      catchError(error => of(actions.invitationCodes.FAIL(error), uiActions.notification(error.xhr.response))
      )
    )
  }
  )
);
// 邀请码绑定腾讯账号
export const registration = actions$ => actions$.pipe(
  ofType(actions.registration.REQUEST.toString()),
  mergeMap(action => {
    return ajax({
      ...ajaxBaseConfig,
      body: action.payload,
      method: 'PATCH',
      url: urls.data.users.registration
    }).pipe(
      mergeMap(res => {
        return [actions.registration.SUCCESS(res.status), actions.checkOpenId.REQUEST(res.response.openId)]
      }),
      catchError(error => of(actions.registration.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
      )
    )
  }
  )
);

export const loadUsersSelectionsEpic = actions$ => actions$.pipe(
  ofType(actions.loadUsersSelections.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { status = '' } = action.payload || {};
    const newUrl = urls.data.users.selections;
    return (
      ajax({
        ...ajaxBaseConfig,
        url: !status ? newUrl : `${newUrl}?status=${action.payload.status}`
      }).pipe(
        map(res => actions.loadUsersSelections.SUCCESS(res.response)),
        catchError(error => of(actions.loadUsersSelections.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
        )
      )
    )
  })
);

/* 子账号管理 */
// 用户列表
export const getUsersListEpic = (actions$, state$) => actions$.pipe(
  ofType(actions.getUsersList.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { page = 0, size = 0, sort = 'id,desc', word = '', deptIds = [] } = action.payload;
    return (
      ajax({
        ...ajaxBaseConfig,
        url: `${urls.data.users.users}?page=${page}&size=${size}&sort=${sort}&word=${word}&deptIds=${deptIds}`,
      }).pipe(
        map(res => {
          return actions.getUsersList.SUCCESS(Object.assign(res.response))
        }),
        catchError(error => of(actions.getUsersList.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);

// 禁用、启用用户
export const enableOrDisabledUserEpic = (actions$, state$) => actions$.pipe(
  ofType(actions.enableOrDisabledUser.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { id = '', status = '' } = action.payload;
    return (
      ajax({
        ...ajaxBaseConfig,
        url: `${urls.data.users.users}/${id}/status?status=${status}`,
        method: 'PATCH',
      }).pipe(
        concatMap(res => [actions.enableOrDisabledUser.SUCCESS(Object.assign(res.response)), actions.getUsersList.REQUEST(state$.value.users.userSearch)]),
        catchError(error => of(actions.enableOrDisabledUser.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);

// 根据id查用户详情
export const getUserDataEpic = (actions$, state$) => actions$.pipe(
  ofType(actions.getUserData.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { id, callback } = action.payload;
    return (
      ajax({
        ...ajaxBaseConfig,
        url: `${urls.data.users.users}/${id}`,
      }).pipe(
        map(res => {
          callback && callback(res.response);
          return actions.getUserData.SUCCESS(Object.assign(res.response))
        }),
        catchError(error => of(actions.getUserData.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
        )
      )
    )
  })
);

// 创建用户
export const createUserEpic = (actions$, state$) => actions$.pipe(
  ofType(actions.createUser.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { data, history } = action.payload
    return (
      ajax({
        ...ajaxBaseConfig,
        url: `${urls.data.users.users}`,
        method: 'POST',
        body: data,
      }).pipe(
        map(res => {
          history.goBack();
          return actions.createUser.SUCCESS(Object.assign(res.response))
        }),
        catchError(error => of(actions.createUser.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);

// 编辑用户
export const editUserEpic = (actions$, state$) => actions$.pipe(
  ofType(actions.editUser.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { data, history } = action.payload
    return (
      ajax({
        ...ajaxBaseConfig,
        url: `${urls.data.users.users}`,
        method: 'PUT',
        body: data,
      }).pipe(
        map(res => {
          history.goBack();
          return actions.editUser.SUCCESS(Object.assign(res.response))
        }),
        catchError(error => of(actions.editUser.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);

// 生成邀请码
export const userInvitationCodesEpic = (actions$, state$) => actions$.pipe(
  ofType(actions.userInvitationCodes.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { callback } = action.payload;
    return (
      ajax({
        ...ajaxBaseConfig,
        url: `${urls.data.users.buildCode}`,
        method: 'POST',
      }).pipe(
        map(res => {
          callback && callback(res.response);
          return actions.userInvitationCodes.SUCCESS(Object.assign(res.response))
        }),
        catchError(error => of(actions.userInvitationCodes.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);

// 发送邀请码
export const sendOutInviteCodeEpic = (actions$, state$) => actions$.pipe(
  ofType(actions.sendOutInviteCode.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { ids, callback } = action.payload;
    return (
      ajax({
        ...ajaxBaseConfig,
        url: `${urls.data.users.sendOutCode}`,
        method: 'POST',
        body: ids
      }).pipe(
        concatMap(res => {
          callback && callback(res.response);
          return [actions.sendOutInviteCode.SUCCESS(Object.assign(res.response)), actions.getUsersList.REQUEST(state$.value.users.userSearch)]
        }),
        catchError(error => of(actions.sendOutInviteCode.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);

// 查询当前已发送邀请码次数
export const inviteCodeChanceEpic = (actions$, state$) => actions$.pipe(
  ofType(actions.inviteCodeChance.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { id, callback } = action.payload;
    return (
      ajax({
        ...ajaxBaseConfig,
        url: urls.data.users.getChance(id),
      }).pipe(
        map(res => {
          callback && callback(res.response);
          return actions.inviteCodeChance.SUCCESS(res.response)
        }),
        catchError(error => of(actions.inviteCodeChance.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);

// 当前用户信息查询
export const selfInfoEpic = (actions$, state$) => actions$.pipe(
  ofType(actions.selfInfo.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    return (
      ajax({
        ...ajaxBaseConfig,
        url: urls.data.users.self,
      }).pipe(
        mergeMap(res => {
          // action.payload && action.payload.callback && action.payload.callback();
          const nowRouter = window.location.hash.split('/')[2];
          return [actions.selfInfo.SUCCESS(res.response),uiActions.loadPermissions(nowRouter)]
        }),
        catchError(error => of(actions.selfInfo.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);


//用户授权


export const authorizationEpic = (actions$, state$) => actions$.pipe(
  ofType(actions.authorization.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { data, callback } = action.payload;
    return (
      ajax({
        ...ajaxBaseConfig,
        url: urls.data.users.authorization(data.id),
        method: 'PATCH',
        body: data.roleIds,
      }).pipe(
        map(res => {
          callback && callback(res.response);
          return actions.authorization.SUCCESS(res.response)
        }),
        catchError(error => of(actions.authorization.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);



//用户邀请码刷新


export const refreshEpic = (actions$, state$) => actions$.pipe(
  ofType(actions.refresh.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { data, callback } = action.payload;
    return (
      ajax({
        ...ajaxBaseConfig,
        url: urls.data.users.refresh(data.id),
        method: 'PATCH',
      }).pipe(
        map(res => {
          callback && callback(res.response);
          return actions.refresh.SUCCESS(res.response)
        }),
        catchError(error => of(actions.refresh.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);


//用户邀请码刷新


export const sendInvitationCodeEpic = (actions$, state$) => actions$.pipe(
  ofType(actions.sendInvitationCode.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { data, callback } = action.payload;
    return (
      ajax({
        ...ajaxBaseConfig,
        url: urls.data.users.sendOutCode,
        method: 'POST',
        body: data.ids,
      }).pipe(
        map(res => {
          callback && callback(res.response);
          return actions.sendInvitationCode.SUCCESS(res.response)
        }),
        catchError(error => of(actions.sendInvitationCode.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);
export const bindingEpic = (actions$, state$) => actions$.pipe(
  ofType(actions.binding.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { data, callback } = action.payload;
    return (
      ajax({
        ...ajaxBaseConfig,
        url: urls.data.users.users + '/binding/' + data.openId,
        method: 'POST',
        body: data,
      }).pipe(
        map(res => {
          callback && callback(res.response);
          return actions.binding.SUCCESS(res.response)
        }),
        catchError(error => of(actions.binding.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);
export const unlockEpic = (actions$, state$) => actions$.pipe(
  ofType(actions.unlock.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { data, callback } = action.payload;
    return (
      ajax({
        ...ajaxBaseConfig,
        url: `${urls.data.users.users}/unlock?account=${data}`,
        method: 'PATCH',
      }).pipe(
        map(res => {
          callback && callback(res.response);
          return actions.unlock.SUCCESS(res.response)
        }),
        catchError(error => of(actions.unlock.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);




export const layoutEpic = (actions$, state$) => actions$.pipe(
  ofType(actions.layout.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { data, callback } = action.payload;
    return (
      ajax({
        // ...ajaxBaseConfig,
        crossDomain: true, //设置跨域为true

        withCredentials: true, //默认情况下，标准的跨域请求是不会发送cookie的

        url: urls.baseUrl == 'https://cust.uma.plsbd.com/api-gateway' ? 'https://uma.plsbd.com/api/umaSso/v1/logout' : 'https://sso-qa.uma.plsbd.com/api/umaSso/v1/logout',
        method: 'POST',
        // body:data,
      }).pipe(
        map(res => {
          callback && callback(res.response);
          return actions.layout.SUCCESS(res.response)
        }),
        catchError(error => of(actions.layout.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);
//文件中心
export const getFileListEpic = (actions$, state$) => actions$.pipe(
  ofType(actions.getFileList.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { data, callback } = action.payload;
    return (
      ajax({
        ...ajaxBaseConfig,
        url: `${urls.data.users.files}?taskType=${data.taskType}&size=${data.size}&page=${data.page}&taskEvents=${data.taskEvents}&taskStatus=${data.taskStatus}&sort=${data.sort2}&sort=${data.sort}`,
        method: 'GET',
      }).pipe(
        map(res => {
          callback && callback(res.response);
          return actions.getFileList.SUCCESS(res.response)
        }),
        catchError(error => of(actions.getFileList.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);

export const delFileEpic = (actions$, state$) => actions$.pipe(
  ofType(actions.delFile.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { data, callback } = action.payload;
    return (
      ajax({
        ...ajaxBaseConfig,
        url: `${urls.data.users.files}/${data.id}`,
        method: 'DELETE',
      }).pipe(
        map(res => {
          callback && callback(res.response);
          return actions.delFile.SUCCESS(res.response)
        }),
        catchError(error => of(actions.delFile.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);

export const downFileEpic = (actions$, state$) => actions$.pipe(
  ofType(actions.downFile.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { data, callback } = action.payload;
    return (
      ajax({
        ...ajaxBaseConfig,
        url: `${urls.data.users.files}/${data.id}/file`,
        method: 'GET',
      }).pipe(
        map(res => {
          callback && callback(res.response);
          return actions.downFile.SUCCESS(res.response)
        }),
        catchError(error => of(actions.downFile.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);



