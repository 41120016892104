import { createAction, createActions, handleActions, combineActions } from 'redux-actions';
import { fasterActions } from '../../utils';

const moduleName = 'award';

export const getPool = fasterActions(moduleName, 'getPool');
export const getAwardAll = fasterActions(moduleName, 'getAwardAll');
export const getAward = fasterActions(moduleName, 'getAward');
// export const typeFilter = fasterActions(moduleName, 'typeFilter');
// export const typeValue = fasterActions(moduleName, 'typeValue');
export const createAward = fasterActions(moduleName, 'createAward');
export const createZHAward = fasterActions(moduleName, 'createZHAward');
export const createZNYXAward = fasterActions(moduleName, 'createZNYXAward');
export const editAward = fasterActions(moduleName, 'editAward');
export const getZHAward = fasterActions(moduleName, 'getZHAward');
export const getZNYXAward = fasterActions(moduleName, 'getZNYXAward');
export const editZHAward = fasterActions(moduleName, 'editZHAward');
export const editZNYXAward = fasterActions(moduleName, 'editZNYXAward');
export const deleteAward = fasterActions(moduleName, 'deleteAward');
export const ableAward = fasterActions(moduleName, 'ableAward');
export const changeAward = fasterActions(moduleName, 'changeAward');
export const getAwardHistory = fasterActions(moduleName, 'getAwardHistory');
export const getZHAwardHistory = fasterActions(moduleName, 'getZHAwardHistory');
export const getZNYXAwardHistory = createAction(`${moduleName}/getZNYXAwardHistory`);
export const changeZNYXAward = fasterActions(moduleName, 'changeZNYXAward');


export const changeAwardList = fasterActions(moduleName, 'changeAwardList');

export const unmount = fasterActions(moduleName, 'unmount');



const initialState = {
  loading: false,
  listLoaded: false,

  awardListloading: false,
  awardList: [],
  awardListAll: [],
  awardListArray: {},
  poolList: [],
  // filterType: [],
  filterValue: '',
  zhAward: [],

}

// Reducer

const reducer = handleActions(
  {
    [getPool.REQUEST]: (state, { payload }) => ({ ...state, awardListloading: true }),
    [getPool.SUCCESS]: (state, { payload }) => ({ ...state, awardListloading: payload.awardListloading ? false : true, poolList: payload.content }),
    [getPool.FAIL]: (state, { payload }) => ({ ...state, awardListloading: false, listLoaded: false, error: payload }),

    [getAwardAll.REQUEST]: (state, { payload }) => ({ ...state, }),
    [getAwardAll.SUCCESS]: (state, { payload }) => {
      // let Awardlist = [];
      // if (state.poolList.length > 0) {
      //   for (let i = 0; i < payload.records.length; i++) {
      //     let item = payload.records[i];
      //     let normalName = (item.awardType == 'COMBINATION' || item.awardType == 'INTELLIGENT') ? item.combinationAwardName : state.poolList.filter(key => key.id == item.poolId)[0].name + '-' + (state.poolList.filter(key => key.id == item.poolId)[0].poolPrizes).filter(key => key.id == item.poolPrizeId)[0].name
      //     let normalRemark = item.awardType == 'GENERAL' ? (state.poolList.filter(key => key.id == item.poolId)[0].poolPrizes).filter(key => key.id == item.poolPrizeId)[0].remark : ''
      //     let prizeType = item.awardType == 'GENERAL' ? (state.poolList.filter(key => key.id == item.poolId)[0].poolPrizes).filter(key => key.id == item.poolPrizeId)[0].prizeType : item.awardType
      //     let array = {
      //       ...item,
      //       normalName: normalName,
      //       normalRemark: normalRemark,
      //       prizeType: prizeType,
      //       value: item.id,
      //       text: normalName
      //     }
      //     Awardlist.push(array)
      //   }
      // }
      return { ...state, awardListAll: payload }
    },
    [getAwardAll.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

    [getAward.REQUEST]: (state, { payload }) => ({ ...state, awardListloading: true }),
    [getAward.SUCCESS]: (state, { payload }) => {
      // let Awardlist = [];
      // if (state.poolList.length > 0) {
      //   for (let i = 0; i < payload.records.length; i++) {
      //     let item = payload.records[i];
      //     let normalName = (item.awardType == 'COMBINATION' || item.awardType == 'INTELLIGENT') ? item.combinationAwardName : state.poolList.filter(key => key.id == item.poolId)[0].name + '-' + (state.poolList.filter(key => key.id == item.poolId)[0].poolPrizes).filter(key => key.id == item.poolPrizeId)[0].name
      //     let normalRemark = item.awardType == 'GENERAL' ? (state.poolList.filter(key => key.id == item.poolId)[0].poolPrizes).filter(key => key.id == item.poolPrizeId)[0].remark : ''
      //     let prizeType = item.awardType == 'GENERAL' ? (state.poolList.filter(key => key.id == item.poolId)[0].poolPrizes).filter(key => key.id == item.poolPrizeId)[0].prizeType : item.awardType
      //     let array = {
      //       ...item,
      //       normalName: normalName,
      //       normalRemark: normalRemark,
      //       prizeType: prizeType,
      //       value: item.id,
      //       text: normalName
      //     }
      //     Awardlist.push(array)
      //   }
      // }
      return { ...state, awardListloading: false, awardList: payload.Awardlist, awardListArray: payload }
    },
    [getAward.FAIL]: (state, { payload }) => ({ ...state, awardListloading: false, error: payload }),



    // [typeFilter.REQUEST]: (state, { payload }) => {
    //   let typeFilterList = [];
    //   if (payload.length == 0) {
    //     typeFilterList = state.awardListAll.filter(key => (key.normalName).indexOf(state.filterValue) >= 0);
    //   } else {
    //     typeFilterList = (state.awardListAll.filter(key => payload.indexOf(key.prizeType) >= 0)).filter(key => (key.normalName).indexOf(state.filterValue) >= 0);
    //   }
    //   return { ...state, filterType: payload, awardList: typeFilterList }
    // },
    // [typeValue.REQUEST]: (state, { payload }) => {
    //   return { ...state, filterValue: payload }
    // },

    [createAward.REQUEST]: (state, { payload }) => ({ ...state, createAwardloading: true }),
    [createAward.SUCCESS]: (state, { payload }) => ({ ...state, createAwardloading: false, listLoaded: true }),
    [createAward.FAIL]: (state, { payload }) => ({ ...state, createAwardloading: false, listLoaded: false, error: payload }),
    [createZHAward.REQUEST]: (state, { payload }) => ({ ...state, loading: true }),
    [createZHAward.SUCCESS]: (state, { payload }) => ({ ...state, loading: false, listLoaded: true }),
    [createZHAward.FAIL]: (state, { payload }) => ({ ...state, loading: false, listLoaded: false, error: payload }),
    [createZNYXAward.REQUEST]: (state, { payload }) => ({ ...state, loading: true }),
    [createZNYXAward.SUCCESS]: (state, { payload }) => ({ ...state, loading: false, listLoaded: true }),
    [createZNYXAward.FAIL]: (state, { payload }) => ({ ...state, loading: false, listLoaded: false, error: payload }),
    [editAward.REQUEST]: (state, { payload }) => ({ ...state, loading: true }),
    [editAward.SUCCESS]: (state, { payload }) => ({ ...state, loading: false, listLoaded: true }),
    [editAward.FAIL]: (state, { payload }) => ({ ...state, loading: false, listLoaded: false, error: payload }),
    [getZHAward.REQUEST]: (state, { payload }) => ({ ...state, loading: true }),
    [getZHAward.SUCCESS]: (state, { payload }) => ({ ...state, loading: false, listLoaded: true, zhAward: payload }),
    [getZHAward.FAIL]: (state, { payload }) => ({ ...state, loading: false, listLoaded: false, error: payload }),
    [getZNYXAward.REQUEST]: (state, { payload }) => ({ ...state, loading: true }),
    [getZNYXAward.SUCCESS]: (state, { payload }) => ({ ...state, loading: false, listLoaded: true, znyxAward: payload }),
    [getZNYXAward.FAIL]: (state, { payload }) => ({ ...state, loading: false, listLoaded: false, error: payload }),
    [editZHAward.REQUEST]: (state, { payload }) => ({ ...state, loading: true }),
    [editZHAward.SUCCESS]: (state, { payload }) => ({ ...state, loading: false, listLoaded: true }),
    [editZHAward.FAIL]: (state, { payload }) => ({ ...state, loading: false, listLoaded: false, error: payload }),
    [editZNYXAward.REQUEST]: (state, { payload }) => ({ ...state, loading: true }),
    [editZNYXAward.SUCCESS]: (state, { payload }) => ({ ...state, loading: false, listLoaded: true }),
    [editZNYXAward.FAIL]: (state, { payload }) => ({ ...state, loading: false, listLoaded: false, error: payload }),
    [deleteAward.REQUEST]: (state, { payload }) => ({ ...state, loading: true }),
    [deleteAward.SUCCESS]: (state, { payload }) => ({ ...state, loading: false, listLoaded: true }),
    [deleteAward.FAIL]: (state, { payload }) => ({ ...state, loading: false, listLoaded: false, error: payload }),
    [ableAward.REQUEST]: (state, { payload }) => ({ ...state }),
    [ableAward.SUCCESS]: (state, { payload }) => ({ ...state }),
    [ableAward.FAIL]: (state, { payload }) => ({ ...state }),

    [getAwardHistory.REQUEST]: (state, { payload }) => ({ ...state, awardListloading: true }),
    [getAwardHistory.SUCCESS]: (state, { payload }) => {
      return { ...state, awardListloading: false, awardList: payload.Awardlist, awardListArray: payload, awardListAll: payload.Awardlist }
    },

    [changeAward.REQUEST]: (state, { payload }) => ({ ...state, loading: true }),
    [changeAward.SUCCESS]: (state, { payload }) => ({ ...state, loading: false, listLoaded: true }),
    [changeAward.FAIL]: (state, { payload }) => ({ ...state, loading: false, listLoaded: false, error: payload }),

    [getZHAwardHistory.REQUEST]: (state, { payload }) => ({ ...state, loading: true }),
    [getZHAwardHistory.SUCCESS]: (state, { payload }) => ({ ...state, loading: false, listLoaded: true, zhAward: payload }),
    [getZHAwardHistory.FAIL]: (state, { payload }) => ({ ...state, loading: false, listLoaded: false, error: payload }),

    [getZNYXAwardHistory]: (state, { payload }) => {
      let probabilityPrizes = JSON.parse(JSON.stringify(state.awardListAll.filter(key => key.id == payload.awardId)[0].intelligentPrizes));
      probabilityPrizes.map(v => {
        state.poolList.some(val => {
          if (val.id == v.poolId) {
            val.poolPrizes.some(value => {
              if (value.id == v.poolPrizeId) {
                v.cost = (value.amount || 0) / 100;
                return true;
              }
            });
            return true;
          }
        });
      });
      return { ...state, znyxAward: [{ probabilityPrizes }] }
    },


    [changeZNYXAward.REQUEST]: (state, { payload }) => {
      return { ...state, znyxAward: [{ ...payload }] }
    },

    [unmount.REQUEST]: (state, { payload }) => ({
      awardListloading: false,
      loading: false,
      listLoaded: false,
      awardList: [],
      awardListAll: [],
      awardListArray: {},
      poolList: [],
      // filterType: [],
      filterValue: '',
      zhAward: [],
    }),

    [changeAwardList.REQUEST]: (state, { payload }) => {
      console.log(payload)
      return { ...state, awardList: payload.awardList, awardListArray: payload.awardListArray ? payload.awardListArray : state.awardListArray }
    },

  },
  initialState
)
export default reducer;

