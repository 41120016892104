import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap, map, catchError, concatMap } from 'rxjs/operators';
import { ajax } from '../../ajax';
import urls from '../../../api/urls';
import * as uiActions from '../ui';
import queryString from 'query-string';
import * as actions from './linkCirculate';
import { ajaxBaseConfig, apiBaseConfig, actionApi } from '../../utils';

// 产品列表
export const productionListEpic = actions$ => actions$.pipe(
    ofType(actions.productionList.REQUEST.toString()),
    mergeMap(action => {
        actionApi();
        const { page = 0, size = 20, startTime = '', endTime = '', sort = 'tagdate,desc', factoryIds = {}, deptId = {}, productIds = [], type = 0 } = action.payload;
        let factoryIdsValue = [factoryIds.value] || [];
        let deptIdsValue = [deptId.value] || [];
        let productIdsValue = [productIds.value] || [];
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.linkCirculate.production}?page=${page}&size=${size}&sort=${sort}&startTime=${startTime}&endTime=${endTime}&factoryIds=${factoryIdsValue}&deptIds=${deptIdsValue}&productIds=${productIdsValue}&type=${type}`
        }).pipe(
            map(res => actions.productionList.SUCCESS(res.response)),
            catchError(error => of(actions.productionList.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    }
    )
);
//产品 -基础统计
export const productionStaticEpic = actions$ => actions$.pipe(
    ofType(actions.productionStatic.REQUEST.toString()),
    mergeMap(action => {
        actionApi();
        const { startTime = '', endTime = '', factoryIds = {}, deptId = {}, productIds = [] } = action.payload;
        let factoryIdsValue = [factoryIds.value] || [];
        let deptIdsValue = [deptId.value] || [];
        let productIdsValue = [productIds.value] || [];
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.linkCirculate.productionStatistics}?startTime=${startTime}&endTime=${endTime}&factoryIds=${factoryIdsValue}&deptIds=${deptIdsValue}&productIds=${productIdsValue}`
        }).pipe(
            map(res => actions.productionStatic.SUCCESS(res.response)),
            catchError(error => of(actions.productionStatic.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    }
    )
);

//经销商列表
export const distributorListEpic = actions$ => actions$.pipe(
    ofType(actions.distributorList.REQUEST.toString()),
    mergeMap(action => {
        actionApi();
        const { page = 0, size = 20, sort = 'tagdate,desc', startTime = '', endTime = '', franchiserLevels = '', franchiserIds = {}, productIds = {}, deptId = {}, type = 0 } = action.payload;

        let franchiserIdsValue = [franchiserIds.value] || [];
        let deptIdsValue = [deptId.value] || [];
        let productIdsValue = [productIds.value] || [];

        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.linkCirculate.franchiser}?page=${page}&size=${size}&sort=${sort}&startTime=${startTime}&endTime=${endTime}&franchiserIds=${franchiserIdsValue}&productIds=${productIdsValue}&deptIds=${deptIdsValue}&type=${type}`
        }).pipe(
            map(res => actions.distributorList.SUCCESS(res.response)),
            catchError(error => of(actions.distributorList.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    }
    )
);

//经销商 -基础统计
export const distributorStaticEpic = actions$ => actions$.pipe(
    ofType(actions.distributorStatic.REQUEST.toString()),
    mergeMap(action => {
        actionApi();
        const { startTime = '', endTime = '', franchiserLevels = '', franchiserIds = {}, productIds = {}, deptId = {} } = action.payload;

        let franchiserIdsValue = [franchiserIds.value] || [];
        let deptIdsValue = [deptId.value] || [];
        let productIdsValue = [productIds.value] || [];

        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.linkCirculate.franchiserStatistics}?startTime=${startTime}&endTime=${endTime}&franchiserLevels=${franchiserLevels}&franchiserIds=${franchiserIdsValue}&productIds=${productIdsValue}&deptIds=${deptIdsValue}`
        }).pipe(
            map(res => actions.distributorStatic.SUCCESS(res.response)),
            catchError(error => of(actions.distributorStatic.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    }
    )
);

//终端列表
export const terminalListEpic = actions$ => actions$.pipe(
    ofType(actions.terminalList.REQUEST.toString()),
    mergeMap(action => {
        actionApi();
        const { page = 0, size = 20, sort = 'tagdate,desc', startTime = '', endTime = '', customerTypes = '', customerIds = {}, productIds = {}, deptId = {}, type = 0 } = action.payload;

        let customerIdsValue = [customerIds.value] || [];
        let deptIdsValue = [deptId.value] || [];
        let productIdsValue = [productIds.value] || [];

        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.linkCirculate.store}?page=${page}&size=${size}&sort=${sort}&startTime=${startTime}&endTime=${endTime}&customerTypes=${customerTypes}&customerIds=${customerIdsValue}&productIds=${productIdsValue}&deptIds=${deptIdsValue}&type=${type}`
        }).pipe(
            map(res => actions.terminalList.SUCCESS(res.response)),
            catchError(error => of(actions.terminalList.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    }
    )
);

//终端 -基础统计
export const terminalStaticEpic = actions$ => actions$.pipe(
    ofType(actions.terminalStatic.REQUEST.toString()),
    mergeMap(action => {
        actionApi();
        const { startTime = '', endTime = '', customerTypes = '', customerIds = {}, productIds = {}, deptId = {} } = action.payload;

        let customerIdsValue = [customerIds.value] || [];
        let deptIdsValue = [deptId.value] || [];
        let productIdsValue = [productIds.value] || [];

        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.linkCirculate.storeStatistics}?startTime=${startTime}&endTime=${endTime}&customerTypes=${customerTypes}&customerIds=${customerIdsValue}&productIds=${productIdsValue}&deptIds=${deptIdsValue}`
        }).pipe(
            map(res => actions.terminalStatic.SUCCESS(res.response)),
            catchError(error => of(actions.terminalStatic.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    }
    )
);

//tendency
export const tendencyEpic = actions$ => actions$.pipe(
    ofType(actions.tendency.REQUEST.toString()),
    mergeMap(action => {
        actionApi();

        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.linkCirculate.tendency}`
        }).pipe(
            map(res => {console.log(res); return actions.tendency.SUCCESS(res.response || {})}),
            catchError(error => of(actions.tendency.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    }
    )
);

//echarts tendency
export const echartsTendencyEpic = actions$ => actions$.pipe(
    ofType(actions.echartsTendency.REQUEST.toString()),
    mergeMap(action => {
        actionApi();

        let { startTime = '', endTime = '', factoryIds = [], productIds = {}, customerIds = {}, franchiserIds = {}, deptId = {}, menu = 'PRODUCT', type = 0, indexName = '', prizeId = '', prizeType = {}, promotionId = '', promotionType = {}, provinceCode = '', cityCode = '', dataVersion = { value: 'NORMAL' },areaType = '' } = action.payload;

        let params = '', search = '';
        let productIdsValue = [productIds.value] || [];
        if (endTime - startTime > 1000 * 24 * 3600 * 30) {
            startTime = endTime - 1000 * 24 * 3600 * 30;
        } else {
            startTime = startTime;
        }

        if (menu === 'PRODUCT') {
            let factoryIdsValue = [factoryIds.value] || [];
            let deptIdsValue = [deptId.value] || [];
            let indexName1 = indexName || 'usedNum';
            if (indexName1 === 'usedNum') {
                indexName1 = 'producedNum';
            } else if (indexName1 === 'totalUsedNum') {
                indexName1 = 'totalProducedNum';
            }
            params = `startTime=${startTime}&endTime=${endTime}&productIds=${productIdsValue}&deptIds=${deptIdsValue}&factoryIds=${factoryIdsValue}&type=${type}&indexName=${indexName1}`;
            search = 'production-tendency';
        } else if (menu === 'DISTRIBUTOR') {
            let franchiserIdsValue = [franchiserIds.value] || [];
            let deptIdsValue = [deptId.value] || [];
            let indexName1 = indexName || 'sendingInNum';
            params = `startTime=${startTime}&endTime=${endTime}&productIds=${productIdsValue}&deptIds=${deptIdsValue}&franchiserIds=${franchiserIdsValue}&type=${type}&indexName=${indexName1}`;
            search = 'franchiser-tendency';
        } else if (menu === 'TERMINAL') {
            let customerIdsValue = [customerIds.value] || [];
            let deptIdsValue = [deptId.value] || [];
            let indexName1 = indexName || 'sendingInSpNum';
            params = `startTime=${startTime}&endTime=${endTime}&productIds=${productIdsValue}&deptIds=${deptIdsValue}&customerIds=${customerIdsValue}&type=${type}&indexName=${indexName1}`;
            search = 'store-tendency';
        } else if (menu === 'SCANCODE') {
            let franchiserIdsValue = [franchiserIds.value] || [];
            let promotionIdValue = (promotionId == 'all') ? '' : promotionId;
            let promotionTypeValue = [promotionType.value] || [];
            let productIdsValue = [productIds.value] || [];
            let dataVersionValue = [dataVersion.value] || ['NORMAL'];
            let indexName1 = indexName || 'scanSum';
            const { officePost = {}, cityManagerPost = {}, planId = '' } = action.payload;
            let deptIdValue = [deptId.value] || [];
            let officePostValue = [officePost.value] || [];
            let cityManagerPostValue = [cityManagerPost.value] || [];
            let planIdValue = (planId == 'all') ? '' : planId;

            search = 'scan-tendency';
            params = `selectDeptId=${deptIdValue}&officePost=${officePostValue}&cityManagerPost=${cityManagerPostValue}&planId=${planIdValue}&startTime=${startTime}&endTime=${endTime}&franchiserId=${franchiserIdsValue}&promotionId=${promotionIdValue}&promotionType=${promotionTypeValue}&productIds=${productIdsValue}&type=${type}&indexName=${indexName1}&provinceCode=${provinceCode}&cityCode=${cityCode}&areaType=${areaType}&dataVersion=${dataVersionValue}`;
        } else if (menu === 'WINPRIZE') {
            let franchiserIdsValue = [franchiserIds.value] || [];
            let promotionIdValue = (promotionId == 'all') ? '' : promotionId;
            let promotionTypeValue = [promotionType.value] || [];
            let productIdsValue = [productIds.value] || [];
            let dataVersionValue = [dataVersion.value] || ['NORMAL'];
            let indexName1 = indexName || 'drawSum';
            const {officePost = {}, cityManagerPost = {}, planId = '',planRange='' } = action.payload;
            let deptIdValue = [deptId.value] || [];
            let officePostValue = [officePost.value] || [];
            let cityManagerPostValue = [cityManagerPost.value] || [];
            let planIdValue = (planId == 'all') ? '' : planId;
            search = 'lottery-tendency';
            params = `planRange=${planRange}&selectDeptId=${deptIdValue}&officePost=${officePostValue}&cityManagerPost=${cityManagerPostValue}&planId=${planIdValue}&startTime=${startTime}&endTime=${endTime}&franchiserId=${franchiserIdsValue}&promotionId=${promotionIdValue}&promotionType=${promotionTypeValue}&productIds=${productIdsValue}&type=${type}&indexName=${indexName1}&provinceCode=${provinceCode}&cityCode=${cityCode}&areaType=${areaType}&dataVersion=${dataVersionValue}`;

        } else if (menu === 'CASEPRIZES') {
            let franchiserIdsValue = [franchiserIds.value] || [];
            let promotionIdValue = (promotionId == 'all') ? '' : promotionId;
            let promotionTypeValue = [promotionType.value] || [];
            let productIdsValue = [productIds.value] || [];
            let dataVersionValue = [dataVersion.value] || ['NORMAL'];
            let indexName1 = indexName || 'cashSum';
            const { officePost = {}, cityManagerPost = {}, planId = '',planRange='' } = action.payload;
            let deptIdValue = [deptId.value] || [];
            let officePostValue = [officePost.value] || [];
            let cityManagerPostValue = [cityManagerPost.value] || [];
            let planIdValue = (planId == 'all') ? '' : planId;
            search = 'cash-tendency';
            params = `planRange=${planRange}&selectDeptId=${deptIdValue}&officePost=${officePostValue}&cityManagerPost=${cityManagerPostValue}&planId=${planIdValue}&startTime=${startTime}&endTime=${endTime}&franchiserId=${franchiserIdsValue}&promotionId=${promotionIdValue}&promotionType=${promotionTypeValue}&productIds=${productIdsValue}&type=${type}&indexName=${indexName1}&provinceCode=${provinceCode}&cityCode=${cityCode}&areaType=${areaType}&dataVersion=${dataVersionValue}`;

        } else if (menu === 'PRIZE') {
            let franchiserIdsValue = [franchiserIds.value] || [];
            let promotionIdValue = (promotionId == 'all') ? '' : promotionId;
            let promotionTypeValue = [promotionType.value] || [];
            let productIdsValue = [productIds.value] || [];
            let prizeIdValue = (prizeId == 'all') ? '' : prizeId;
            let prizeTypeValue = [prizeType.value] || [];
            let dataVersionValue = [dataVersion.value] || ['NORMAL'];
            let indexName1 = indexName || "lotterySum";
            const { officePost = {}, cityManagerPost = {}, planId = '',poolId='',planRange='' } = action.payload;
            let deptIdValue = [deptId.value] || [];
            let officePostValue = [officePost.value] || [];
            let cityManagerPostValue = [cityManagerPost.value] || [];
            let planIdValue = (planId == 'all') ? '' : planId;
            let poolIdValue = (poolId == 'all') ? '' : poolId;
            search = 'prize-tendency';
            params = `planRange=${planRange}&poolId=${poolIdValue}&selectDeptId=${deptIdValue}&officePost=${officePostValue}&cityManagerPost=${cityManagerPostValue}&planId=${planIdValue}&startTime=${startTime}&endTime=${endTime}&prizeId=${prizeIdValue}&prizeType=${prizeTypeValue}&franchiserId=${franchiserIdsValue}&promotionId=${promotionIdValue}&promotionType=${promotionTypeValue}&productIds=${productIdsValue}&type=${type}&indexName=${indexName1}&provinceCode=${provinceCode}&cityCode=${cityCode}&areaType=${areaType}&dataVersion=${dataVersionValue}`;
        }

        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.linkCirculate.echartsUrl}/${search}?${params}`
        }).pipe(
            map(res => actions.echartsTendency.SUCCESS(res.response || {})),
            catchError(error => of(actions.echartsTendency.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    }
    )
);

//扫码统计列表
export const scanListEpic = actions$ => actions$.pipe(
    ofType(actions.scanList.REQUEST.toString()),
    mergeMap(action => {
        actionApi();
        const { page = 0, size = 20, sort = 'tagdate,desc', startTime = '', endTime = '', franchiserIds = {}, promotionId = '', productIds = {}, promotionType = {}, type = 0, provinceCode = '', cityCode = '',areaType='', dataVersion = { value: 'NORMAL' } } = action.payload;
        let franchiserIdsValue = [franchiserIds.value] || [];
        let promotionIdValue = (promotionId == 'all') ? '' : promotionId;
        let promotionTypeValue = [promotionType.value] || [];
        let productIdsValue = [productIds.value] || [];
        let dataVersionValue = [dataVersion.value] || ['NORMAL'];

        const { deptId = {}, officePost = {}, cityManagerPost = {}, planId = '' } = action.payload;
        let deptIdValue = [deptId.value] || [];
        let officePostValue = [officePost.value] || [];
        let cityManagerPostValue = [cityManagerPost.value] || [];
        let planIdValue = (planId == 'all') ? '' : planId;


        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.linkCirculate.consumer}/scan?selectDeptId=${deptIdValue}&officePost=${officePostValue}&cityManagerPost=${cityManagerPostValue}&planId=${planIdValue}&page=${page}&size=${size}&sort=${sort}&startTime=${startTime}&endTime=${endTime}&franchiserId=${franchiserIdsValue}&promotionId=${promotionIdValue}&productIds=${productIdsValue}&promotionType=${promotionTypeValue}&type=${type}&provinceCode=${provinceCode}&cityCode=${cityCode}&areaType=${areaType}&dataVersion=${dataVersionValue}`
        }).pipe(
            map(res => actions.scanList.SUCCESS(res.response)),
            catchError(error => of(actions.scanList.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    }
    )
);

//扫码统计 -基础统计
export const scanStaticEpic = actions$ => actions$.pipe(
    ofType(actions.scanStatic.REQUEST.toString()),
    mergeMap(action => {
        actionApi();
        const { startTime = '', endTime = '', franchiserIds = {}, productIds = {}, promotionId = '', promotionType = {}, provinceCode = '', cityCode = '',areaType='', dataVersion = { value: 'NORMAL' } } = action.payload;

        let franchiserIdsValue = [franchiserIds.value] || [];
        let promotionIdValue = (promotionId == 'all') ? '' : promotionId;
        let promotionTypeValue = [promotionType.value] || [];
        let productIdsValue = [productIds.value] || [];
        let dataVersionValue = [dataVersion.value] || ['NORMAL'];

        const { deptId = {}, officePost = {}, cityManagerPost = {}, planId = '' } = action.payload;
        let deptIdValue = [deptId.value] || [];
        let officePostValue = [officePost.value] || [];
        let cityManagerPostValue = [cityManagerPost.value] || [];
        let planIdValue = (planId == 'all') ? '' : planId;

        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.linkCirculate.scanStatistics}?selectDeptId=${deptIdValue}&officePost=${officePostValue}&cityManagerPost=${cityManagerPostValue}&planId=${planIdValue}&startTime=${startTime}&endTime=${endTime}&franchiserId=${franchiserIdsValue}&promotionId=${promotionIdValue}&productIds=${productIdsValue}&promotionType=${promotionTypeValue}&provinceCode=${provinceCode}&cityCode=${cityCode}&areaType=${areaType}&dataVersion=${dataVersionValue}`
        }).pipe(
            map(res => actions.scanStatic.SUCCESS(res.response)),
            catchError(error => of(actions.scanStatic.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    }
    )
);

//中奖统计列表
export const lotteryListEpic = actions$ => actions$.pipe(
    ofType(actions.lotteryList.REQUEST.toString()),
    mergeMap(action => {
        actionApi();
        const { page = 0, size = 20, sort = 'tagdate,desc', startTime = '', endTime = '', franchiserIds = {}, promotionId = '', productIds = {}, promotionType = {}, type = 0, provinceCode = '', cityCode = '',areaType='', dataVersion = { value: 'NORMAL' },planRange='' } = action.payload;

        let franchiserIdsValue = [franchiserIds.value] || [];
        let promotionIdValue = (promotionId == 'all') ? '' : promotionId;
        let promotionTypeValue = [promotionType.value] || [];
        let productIdsValue = [productIds.value] || [];
        let dataVersionValue = [dataVersion.value] || ['NORMAL'];

        const { deptId = {}, officePost = {}, cityManagerPost = {}, planId = '' } = action.payload;
        let deptIdValue = [deptId.value] || [];
        let officePostValue = [officePost.value] || [];
        let cityManagerPostValue = [cityManagerPost.value] || [];
        let planIdValue = (planId == 'all') ? '' : planId;

        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.linkCirculate.consumer}/lottery?planRange=${planRange}&selectDeptId=${deptIdValue}&officePost=${officePostValue}&cityManagerPost=${cityManagerPostValue}&planId=${planIdValue}&page=${page}&size=${size}&sort=${sort}&startTime=${startTime}&endTime=${endTime}&franchiserId=${franchiserIdsValue}&promotionId=${promotionIdValue}&productIds=${productIdsValue}&promotionType=${promotionTypeValue}&type=${type}&provinceCode=${provinceCode}&cityCode=${cityCode}&areaType=${areaType}&dataVersion=${dataVersionValue}`
        }).pipe(
            map(res => actions.lotteryList.SUCCESS(res.response)),
            catchError(error => of(actions.lotteryList.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    }
    )
);

//中奖统计 -基础统计
export const lotteryStaticEpic = actions$ => actions$.pipe(
    ofType(actions.lotteryStatic.REQUEST.toString()),
    mergeMap(action => {
        actionApi();
        const { startTime = '', endTime = '', franchiserIds = {}, productIds = {}, promotionId = '', promotionType = {}, provinceCode = '', cityCode = '',areaType='', dataVersion = { value: 'NORMAL' },planRange } = action.payload;

        let franchiserIdsValue = [franchiserIds.value] || [];
        let promotionIdValue = (promotionId == 'all') ? '' : promotionId;
        let promotionTypeValue = [promotionType.value] || [];
        let productIdsValue = [productIds.value] || [];
        let dataVersionValue = [dataVersion.value] || ['NORMAL'];

        const { deptId = {}, officePost = {}, cityManagerPost = {}, planId = '' } = action.payload;
        let deptIdValue = [deptId.value] || [];
        let officePostValue = [officePost.value] || [];
        let cityManagerPostValue = [cityManagerPost.value] || [];
        let planIdValue = (planId == 'all') ? '' : planId;

        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.linkCirculate.lotteryStatistics}?planRange=${planRange}&selectDeptId=${deptIdValue}&officePost=${officePostValue}&cityManagerPost=${cityManagerPostValue}&planId=${planIdValue}&startTime=${startTime}&endTime=${endTime}&franchiserId=${franchiserIdsValue}&promotionId=${promotionIdValue}&productIds=${productIdsValue}&promotionType=${promotionTypeValue}&provinceCode=${provinceCode}&cityCode=${cityCode}&areaType=${areaType}&dataVersion=${dataVersionValue}`
        }).pipe(
            map(res => actions.lotteryStatic.SUCCESS(res.response)),
            catchError(error => of(actions.lotteryStatic.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    }
    )
);

//兑奖统计列表
export const cashListEpic = actions$ => actions$.pipe(
    ofType(actions.cashList.REQUEST.toString()),
    mergeMap(action => {
        actionApi();
        const { page = 0, size = 20, sort = 'tagdate,desc', startTime = '', endTime = '', franchiserIds = {}, promotionId = '', productIds = {}, promotionType = {}, type = 0, provinceCode = '', cityCode = '',areaType='', dataVersion = { value: 'NORMAL' },planRange='' } = action.payload;

        let franchiserIdsValue = [franchiserIds.value] || [];
        let promotionIdValue = (promotionId == 'all') ? '' : promotionId;
        let promotionTypeValue = [promotionType.value] || [];
        let productIdsValue = [productIds.value] || [];
        let dataVersionValue = [dataVersion.value] || ['NORMAL'];

        const { deptId = {}, officePost = {}, cityManagerPost = {}, planId = '' } = action.payload;
        let deptIdValue = [deptId.value] || [];
        let officePostValue = [officePost.value] || [];
        let cityManagerPostValue = [cityManagerPost.value] || [];
        let planIdValue = (planId == 'all') ? '' : planId;

        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.linkCirculate.consumer}/cash?planRange=${planRange}&selectDeptId=${deptIdValue}&officePost=${officePostValue}&cityManagerPost=${cityManagerPostValue}&planId=${planIdValue}&page=${page}&size=${size}&sort=${sort}&startTime=${startTime}&endTime=${endTime}&franchiserId=${franchiserIdsValue}&promotionId=${promotionIdValue}&productIds=${productIdsValue}&promotionType=${promotionTypeValue}&type=${type}&provinceCode=${provinceCode}&cityCode=${cityCode}&areaType=${areaType}&dataVersion=${dataVersionValue}`
        }).pipe(
            map(res => actions.cashList.SUCCESS(res.response)),
            catchError(error => of(actions.cashList.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    }
    )
);

//兑奖统计 -基础统计
export const cashStaticEpic = actions$ => actions$.pipe(
    ofType(actions.cashStatic.REQUEST.toString()),
    mergeMap(action => {
        actionApi();
        const { startTime = '', endTime = '', franchiserIds = {}, productIds = {}, promotionId = '', promotionType = {}, provinceCode = '', cityCode = '', areaType='',dataVersion = { value: 'NORMAL' },planRange='' } = action.payload;

        let franchiserIdsValue = [franchiserIds.value] || [];
        let promotionIdValue = (promotionId == 'all') ? '' : promotionId;
        let promotionTypeValue = [promotionType.value] || [];
        let productIdsValue = [productIds.value] || [];
        let dataVersionValue = [dataVersion.value] || ['NORMAL'];

        const { deptId = {}, officePost = {}, cityManagerPost = {}, planId = '' } = action.payload;
        let deptIdValue = [deptId.value] || [];
        let officePostValue = [officePost.value] || [];
        let cityManagerPostValue = [cityManagerPost.value] || [];
        let planIdValue = (planId == 'all') ? '' : planId;

        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.linkCirculate.cashStatistics}?planRange=${planRange}&selectDeptId=${deptIdValue}&officePost=${officePostValue}&cityManagerPost=${cityManagerPostValue}&planId=${planIdValue}&startTime=${startTime}&endTime=${endTime}&franchiserId=${franchiserIdsValue}&promotionId=${promotionIdValue}&productIds=${productIdsValue}&promotionType=${promotionTypeValue}&provinceCode=${provinceCode}&cityCode=${cityCode}&areaType=${areaType}&dataVersion=${dataVersionValue}`
        }).pipe(
            map(res => actions.cashStatic.SUCCESS(res.response)),
            catchError(error => of(actions.cashStatic.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    }
    )
);

//奖品统计列表
export const prizeListEpic = actions$ => actions$.pipe(
    ofType(actions.prizeList.REQUEST.toString()),
    mergeMap(action => {
        actionApi();
        const { page = 0, size = 20, sort = 'tagdate,desc', startTime = '', endTime = '', franchiserIds = {}, promotionId = '', productIds = {}, promotionType = {}, type = 0, prizeId = '', prizeType = {}, provinceCode = '', cityCode = '',areaType='', dataVersion = { value: 'NORMAL' },planRange='' } = action.payload;

        let franchiserIdsValue = [franchiserIds.value] || [];
        let promotionIdValue = (promotionId == 'all') ? '' : promotionId;
        let promotionTypeValue = [promotionType.value] || [];
        let productIdsValue = [productIds.value] || [];
        let prizeTypeValue = [prizeType.value] || [];
        let prizeIdValue = (prizeId == 'all') ? '' : prizeId;
        let dataVersionValue = [dataVersion.value] || ['NORMAL'];

        const { deptId = {}, officePost = {}, cityManagerPost = {}, planId = '',poolId='' } = action.payload;
        let deptIdValue = [deptId.value] || [];
        let officePostValue = [officePost.value] || [];
        let cityManagerPostValue = [cityManagerPost.value] || [];
        let planIdValue = (planId == 'all') ? '' : planId;
        let poolIdValue = (poolId == 'all') ? '' : poolId;
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.linkCirculate.consumer}/prize?planRange=${planRange}&poolId=${poolIdValue}&selectDeptId=${deptIdValue}&officePost=${officePostValue}&cityManagerPost=${cityManagerPostValue}&planId=${planIdValue}&page=${page}&size=${size}&sort=${sort}&startTime=${startTime}&endTime=${endTime}&franchiserId=${franchiserIdsValue}&promotionId=${promotionIdValue}&productIds=${productIdsValue}&promotionType=${promotionTypeValue}&type=${type}&prizeId=${prizeIdValue}&prizeType=${prizeTypeValue}&provinceCode=${provinceCode}&cityCode=${cityCode}&areaType=${areaType}&dataVersion=${dataVersionValue}`
        }).pipe(
            map(res => actions.prizeList.SUCCESS(res.response)),
            catchError(error => of(actions.prizeList.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    }
    )
);

//奖品统计 -基础统计
export const prizeStaticEpic = actions$ => actions$.pipe(
    ofType(actions.prizeStatic.REQUEST.toString()),
    mergeMap(action => {
        actionApi();
        const { startTime = '', endTime = '', franchiserIds = {}, productIds = {}, prizeType = {}, prizeId = '', promotionId = '', promotionType = {}, provinceCode = '', cityCode = '', areaType='',dataVersion = { value: 'NORMAL' },planRange='' } = action.payload;

        let franchiserIdsValue = [franchiserIds.value] || [];
        let promotionIdValue = (promotionId == 'all') ? '' : promotionId;
        let promotionTypeValue = [promotionType.value] || [];
        let productIdsValue = [productIds.value] || [];
        let prizeTypeValue = [prizeType.value] || [];
        let prizeIdValue = (prizeId == 'all') ? '' : prizeId;
        let dataVersionValue = [dataVersion.value] || ['NORMAL'];

        const { deptId = {}, officePost = {}, cityManagerPost = {}, planId = '',poolId='' } = action.payload;
        let deptIdValue = [deptId.value] || [];
        let officePostValue = [officePost.value] || [];
        let cityManagerPostValue = [cityManagerPost.value] || [];
        let planIdValue = (planId == 'all') ? '' : planId;
        let poolIdValue = (poolId == 'all') ? '' : poolId;
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.linkCirculate.prizeStatistics}?planRange=${planRange}&poolId=${poolIdValue}&selectDeptId=${deptIdValue}&officePost=${officePostValue}&cityManagerPost=${cityManagerPostValue}&planId=${planIdValue}&startTime=${startTime}&endTime=${endTime}&franchiserId=${franchiserIdsValue}&promotionId=${promotionIdValue}&productIds=${productIdsValue}&promotionType=${promotionTypeValue}&prizeId=${prizeIdValue}&prizeType=${prizeTypeValue}&provinceCode=${provinceCode}&cityCode=${cityCode}&areaType=${areaType}&dataVersion=${dataVersionValue}`
        }).pipe(
            map(res => actions.prizeStatic.SUCCESS(res.response)),
            catchError(error => of(actions.prizeStatic.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    }
    )
);


//活动类型下拉
export const promotionTypeEpic = actions$ => actions$.pipe(
    ofType(actions.promotionType.REQUEST.toString()),
    mergeMap(action => {
        actionApi();

        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.linkCirculate.enum}?enumClass=promotionType`
        }).pipe(
            map(res => actions.promotionType.SUCCESS(res.response)),
            catchError(error => of(actions.promotionType.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    }
    )
);
//奖品类型下拉
export const prizeTypeEpic = actions$ => actions$.pipe(
    ofType(actions.prizeType.REQUEST.toString()),
    mergeMap(action => {
        actionApi();

        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.linkCirculate.enum}?enumClass=prizeType`
        }).pipe(
            map(res => actions.prizeType.SUCCESS(res.response)),
            catchError(error => of(actions.prizeType.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    }
    )
);
//活动下拉
export const activityEpic = actions$ => actions$.pipe(
    ofType(actions.activity.REQUEST.toString()),
    mergeMap(action => {
        actionApi();

        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.linkCirculate.activity}?dataVersion=NORMAL`
        }).pipe(
            map(res => actions.activity.SUCCESS(res.response)),
            catchError(error => of(actions.activity.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    }
    )
);

//下级部门下拉
export const childDeptSelectionEpic = actions$ => actions$.pipe(
    ofType(actions.childDeptSelection.REQUEST.toString()),
    mergeMap(action => {
        actionApi();

        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.linkCirculate.childDept}`
        }).pipe(
            map(res => actions.childDeptSelection.SUCCESS(res.response)),
            catchError(error => of(actions.childDeptSelection.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    }
    )
);

//城市经理下拉
export const cityManagerPostSelectionEpic = actions$ => actions$.pipe(
    ofType(actions.cityManagerPostSelection.REQUEST.toString()),
    mergeMap(action => {
        actionApi();

        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.linkCirculate.citymanagerPost}`
        }).pipe(
            map(res => actions.cityManagerPostSelection.SUCCESS(res.response)),
            catchError(error => of(actions.cityManagerPostSelection.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    }
    )
);

//办事处下拉
export const officePostSelectionEpic = actions$ => actions$.pipe(
    ofType(actions.officePostSelection.REQUEST.toString()),
    mergeMap(action => {
        actionApi();

        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.linkCirculate.officePost}`
        }).pipe(
            map(res => actions.officePostSelection.SUCCESS(res.response)),
            catchError(error => of(actions.officePostSelection.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    }
    )
);

//方案下拉
export const planSelectionEpic = actions$ => actions$.pipe(
    ofType(actions.planSelection.REQUEST.toString()),
    mergeMap(action => {
        actionApi();
        const { activityId = '', deptId = '' } = action.payload;

        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.linkCirculate.plan}?activityId=${activityId}&deptId=${deptId}`
        }).pipe(
            map(res => actions.planSelection.SUCCESS(res.response)),
            catchError(error => of(actions.planSelection.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    }
    )
);


