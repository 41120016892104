import { createAction, createActions, handleActions, combineActions } from 'redux-actions';
import { fasterActions } from '../../utils';
import urls from '@/api/urls';

const moduleName = 'coupon';

/**
 * TODO: 优惠券管理
 */
// 优惠券品牌
export const loadCouponConfigsAll = fasterActions(moduleName, 'loadCouponConfigsAll');
// 优惠券种类
export const loadCouponConfigDetailsAll = fasterActions(moduleName, 'loadCouponConfigDetailsAll');
// 优惠券导出
export const couponCodePacketExport = fasterActions(moduleName, 'couponCodePacketExport');

const initialState = {
  couponLoaded: false,
  couponConfigsAllList: [],
  couponConfigDetailsAllList: [],
}

const reducer = handleActions(
  {
    // 优惠券品牌
    [loadCouponConfigsAll.REQUEST]: (state, { payload }) => ({ ...state}),
    [loadCouponConfigsAll.SUCCESS]: (state, { payload }) => {
      const selector = [];
      if (payload) {
        for (let elem of payload.values()) {
          selector.push({ value: elem.id, text: elem.name })
        }
     }
      return ({ ...state, couponConfigsAllList: selector })
    },
    [loadCouponConfigsAll.FAIL]: (state, { payload }) => ({ ...state, error: payload }),
    
    // 优惠券种类
    [loadCouponConfigDetailsAll.REQUEST]: (state, { payload }) => ({ ...state}),
    [loadCouponConfigDetailsAll.SUCCESS]: (state, { payload }) => {
      const selector = [];
      if (payload) {
        for (let elem of payload.values()) {
          selector.push({ value: elem.id, text: elem.name })
        }
      }
      return ({ ...state, couponConfigDetailsAllList: selector })
    },
    [loadCouponConfigDetailsAll.FAIL]: (state, { payload }) => ({ ...state, error: payload }),
    // 优惠券导出
    [couponCodePacketExport.REQUEST]: (state, { payload }) => ({ ...state }),
    [couponCodePacketExport.SUCCESS]: (state, { payload }) => ({ ...state }),
    [couponCodePacketExport.FAIL]: (state, { payload }) => ({ ...state, error: payload }),
    
  },
  initialState
);

export default reducer;
