import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { ajax } from '../../ajax';
import urls from '../../../api/urls';
import * as uiActions from '../ui';
import queryString from 'query-string';
import { ajaxBaseConfig, actionApi,apiBaseConfig } from '../../utils';
import * as actions from './enterpriseinfo';

// Epics

// 获取企业码量信息
export const enterpriseinfoEpic = actions$ => actions$.pipe(
  ofType(actions.enterpriseinfo.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    return ajax({
      ...ajaxBaseConfig,
      method: 'get',
      url: `${urls.code.enterpriseinfo.enterpriseinfo}/${action.payload}`,
    }).pipe(
      map(res => actions.enterpriseinfo.SUCCESS(res.response)),
      catchError(error => of(actions.enterpriseinfo.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  })
);

//list
export const loadenterpriseinfolistEpic = actions$ => actions$.pipe(
  ofType(actions.loadenterpriseinfolist.REQUEST.toString()),

  mergeMap(action => {
    actionApi()
    const { name = '', page = 0, size = 20, sort = 'id,desc', eseId } = action.payload;
    return (

      ajax({
        ...ajaxBaseConfig,
        url: `${urls.code.enterpriseinfo.enterpriseinfo}/tagcount-operation-log/${eseId}?name=${name}&page=${page}&size=${size}&sort=${sort}`,
      }).pipe(
        map(res => actions.loadenterpriseinfolist.SUCCESS(res.response || [])),
        catchError(error => of(actions.loadenterpriseinfolist.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);

//edit
export const editenterpriseinfoEpic = actions$ => actions$.pipe(
  ofType(actions.editenterpriseinfo.REQUEST.toString()),

  mergeMap(action => {
    actionApi()
    const { data, callback } = action.payload;
    return (

      ajax({
        ...ajaxBaseConfig,
        method: 'PUT',
        url: `${urls.code.enterpriseinfo.enterpriseinfo}`,
        body: action.payload.data,
      }).pipe(
        map(res => {
          callback && callback()
          return actions.editenterpriseinfo.SUCCESS(res)
        }),
        catchError(error => of(actions.editenterpriseinfo.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);

export const editenterpriseinfoWarningEpic = actions$ => actions$.pipe(
  ofType(actions.editenterpriseinfoWarning.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { data, callback } = action.payload;
    return (

      ajax({
        ...apiBaseConfig,
        method: 'PUT',
        url: `${urls.code.enterpriseinfo.enterpriseinfo}/warning`,
        body: data,
      }).pipe(
        map(res => {
          callback && callback()
          return actions.editenterpriseinfoWarning.SUCCESS(res)
        }),
        catchError(error => of(actions.editenterpriseinfoWarning.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);

