import { createAction, createActions, handleActions, combineActions } from 'redux-actions';
import { fasterActions } from '../../utils';
import urls from '@/api/urls';

const moduleName = 'miniProgramParameter';

/**
 * TODO: 企业管理
 */
export const loadParameterList = fasterActions(moduleName, 'loadParameterList');
export const loadParameterId = fasterActions(moduleName, 'loadParameterId');
export const createParameter = fasterActions(moduleName, 'createParameter');
export const editParameter = fasterActions(moduleName, 'editParameter');
export const ableParameter = fasterActions(moduleName, 'ableParameter');

export const mediaListParameter = fasterActions(moduleName, 'mediaListParameter');
export const mediaListParameterImage = fasterActions(moduleName, 'mediaListParameterImage');
export const mediaListsync = fasterActions(moduleName, 'mediaListsync');
export const thumbMediaId = fasterActions(moduleName, 'thumbMediaId');


const initialState = {

  parameterList: [],
  loadParameterListLoading: false,
  loadParameterListLoaded: false,

}

const reducer = handleActions(
  {

    [loadParameterList.REQUEST]: (state, { payload }) => ({ ...state, loadParameterListLoading: true }),
    [loadParameterList.SUCCESS]: (state, { payload }) => ({ ...state, loadParameterListLoading: false, loadParameterListLoaded: true, parameterList: payload }),
    [loadParameterList.FAIL]: (state, { payload }) => ({ ...state, loadParameterListLoading: false, loadParameterListLoaded: false, error: payload }),

    [loadParameterId.REQUEST]: (state, { payload }) => ({ ...state }),
    [loadParameterId.SUCCESS]: (state, { payload }) => ({ ...state }),
    [loadParameterId.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

    [createParameter.REQUEST]: (state, { payload }) => ({ ...state }),
    [createParameter.SUCCESS]: (state, { payload }) => ({ ...state }),
    [createParameter.FAIL]: (state, { payload }) => ({ ...state }),
    [editParameter.REQUEST]: (state, { payload }) => ({ ...state }),
    [editParameter.SUCCESS]: (state, { payload }) => ({ ...state }),
    [editParameter.FAIL]: (state, { payload }) => ({ ...state }),
    [ableParameter.REQUEST]: (state, { payload }) => ({ ...state }),
    [ableParameter.SUCCESS]: (state, { payload }) => ({ ...state }),
    [ableParameter.FAIL]: (state, { payload }) => ({ ...state }),


    
    [mediaListParameter.REQUEST]: (state, { payload }) => ({ ...state }),
    [mediaListParameter.SUCCESS]: (state, { payload }) => ({ ...state }),
    [mediaListParameter.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

    [mediaListParameterImage.REQUEST]: (state, { payload }) => ({ ...state }),
    [mediaListParameterImage.SUCCESS]: (state, { payload }) => ({ ...state }),
    [mediaListParameterImage.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

    [mediaListsync.REQUEST]: (state, { payload }) => ({ ...state }),
    [mediaListsync.SUCCESS]: (state, { payload }) => ({ ...state }),
    [mediaListsync.FAIL]: (state, { payload }) => ({ ...state, error: payload }),
    
    
    [thumbMediaId.REQUEST]: (state, { payload }) => ({ ...state }),
    [thumbMediaId.SUCCESS]: (state, { payload }) => ({ ...state }),
    [thumbMediaId.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

    
  },
  initialState
);

export default reducer;
