import { createAction, createActions, handleActions, combineActions } from 'redux-actions';
import { fasterActions } from '../../utils';

const moduleName = 'memberManage';

// upstate
/* 会员管理 */
export const getMemberInfoList = fasterActions(`${moduleName}/getMemberInfoList`);
export const exportMemberInfo = fasterActions(`${moduleName}/exportMemberInfo`);
export const enableOrDisabledInfo = fasterActions(`${moduleName}/enableOrDisabledInfo`);



export const getMemberShipList = fasterActions(`${moduleName}/getMemberShipList`);
export const deleteMemberShip = fasterActions(`${moduleName}/deleteMemberShip`);
export const exportMemberShip = fasterActions(`${moduleName}/exportMemberShip`);
export const getTagSelection = fasterActions(`${moduleName}/getTagSelection`);
export const addMemberShip = fasterActions(`${moduleName}/addMemberShip`);
export const editMemberShip = fasterActions(`${moduleName}/editMemberShip`);
export const getMemberShipContent = fasterActions(`${moduleName}/getMemberShipContent`);


export const getBaseInfo = fasterActions(`${moduleName}/getBaseInfo`);
export const setBaseInfo = createAction(`${moduleName}/setBaseInfo`);
export const editBaseInfo = fasterActions(`${moduleName}/editBaseInfo`);
export const getBasepointDetails = fasterActions(`${moduleName}/getBasepointDetails`);
export const editBasepoint = fasterActions(`${moduleName}/editBasepoint`);

export const getBaseMemberDetails = fasterActions(`${moduleName}/getBaseMemberDetails`);
export const editBaseEmpiricalValue = fasterActions(`${moduleName}/editBaseEmpiricalValue`);
export const editBaseEmpiricalLevel = fasterActions(`${moduleName}/editBaseEmpiricalLevel`);


export const getBasegrowthRecordsList = fasterActions(`${moduleName}/getBasegrowthRecordsList`)
export const getBasecompleteTaskRecordsList = fasterActions(`${moduleName}/getBasecompleteTaskRecordsList`)
export const getBasepointRecordsList = fasterActions(`${moduleName}/getBasepointRecordsList`)
export const exportBaseMemberInfo = fasterActions(`${moduleName}/exportBaseMemberInfo`);


export const getPortrait = fasterActions(`${moduleName}/getPortrait`);


export const getUpgradeRecords = fasterActions(`${moduleName}/getUpgradeRecords`);
export const getQueryArea = fasterActions(`${moduleName}/getQueryArea`);
export const getWinPrize = fasterActions(`${moduleName}/getWinPrize`);




export const loadLabelNumber = fasterActions(moduleName, 'loadLabelNumber');
export const createLabelNumber = fasterActions(moduleName, 'createLabelNumber');
export const editLabelNumber = fasterActions(moduleName, 'editLabelNumber');



export const exportAvailable = fasterActions(moduleName, 'exportAvailable');


export const unmount = fasterActions(moduleName, 'unmount');

const initialState = {
    // 会员体系

    //
    memberInfoList: [],
    memberInfoListLoading: false,

    memberShipList: [],
    memberShipListLoading: false,

    portrait: {},
    memberShipContent: '',

    upgradeRecords: {},
    queryArea: {},
    winPrize: {},

    //会员详情页
    baseInfo: {},
    pointDetails: [],
    memberDetails: [],
    basepointRecordsList: {},
    growthRecordsList: {},
    completeTaskRecordsList:{},




    tagSelection: [],

    // memberSystemSearch: { page: 0, size: 20 },
    // memberSystemTable: [],
    // systemHistorySearch: { page: 0 },
    // memberSystemHistoryTable: [],
    // // 会员等级
    // memberLevelList: [],
    // // 会员权益
    // memberRightsTable: [],
    // memberRightsSearch: { page: 0, size: 20 }


    labelNumberContent: {},

    availableCount: 0,

}

// Reducer
const reducer = handleActions(
    {
        /* 会员体系 */
        [getMemberInfoList.REQUEST]: (state, { payload }) => ({ ...state, memberInfoListLoading: true }),
        [getMemberInfoList.SUCCESS]: (state, { payload }) => {
            return { ...state, memberInfoListLoading: false, memberInfoList: payload }
        },
        [getMemberInfoList.FAIL]: (state, { payload }) => ({ ...state, memberInfoListLoading: false, error: payload }),

        [getMemberShipList.REQUEST]: (state, { payload }) => ({ ...state, memberShipListLoading: true }),
        [getMemberShipList.SUCCESS]: (state, { payload }) => {
            return { ...state, memberShipListLoading: false, memberShipList: payload }
        },
        [getMemberShipList.FAIL]: (state, { payload }) => ({ ...state, memberShipListLoading: false, error: payload }),


        [getPortrait.REQUEST]: (state, { payload }) => ({ ...state, getPortraitLoading: true }),
        [getPortrait.SUCCESS]: (state, { payload }) => {
            return { ...state, getPortraitLoading: false, portrait: payload }
        },
        [getPortrait.FAIL]: (state, { payload }) => ({ ...state, getPortraitLoading: false, error: payload }),


        [getUpgradeRecords.REQUEST]: (state, { payload }) => ({ ...state, getUpgradeRecordsLoading: true }),
        [getUpgradeRecords.SUCCESS]: (state, { payload }) => {
            return { ...state, getUpgradeRecordsLoading: false, upgradeRecords: payload }
        },
        [getUpgradeRecords.FAIL]: (state, { payload }) => ({ ...state, getUpgradeRecordsLoading: false, error: payload }),

        [getQueryArea.REQUEST]: (state, { payload }) => ({ ...state, getQueryAreaLoading: true }),
        [getQueryArea.SUCCESS]: (state, { payload }) => {
            return { ...state, getQueryAreaLoading: false, queryArea: payload }
        },
        [getQueryArea.FAIL]: (state, { payload }) => ({ ...state, getQueryAreaLoading: false, error: payload }),

        [getWinPrize.REQUEST]: (state, { payload }) => ({ ...state, getWinPrizeLoading: true }),
        [getWinPrize.SUCCESS]: (state, { payload }) => {
            return { ...state, getWinPrizeLoading: false, winPrize: payload }
        },
        [getWinPrize.FAIL]: (state, { payload }) => ({ ...state, getWinPrizeLoading: false, error: payload }),


        [deleteMemberShip.REQUEST]: (state, { payload }) => ({ ...state }),
        [deleteMemberShip.SUCCESS]: (state, { payload }) => ({ ...state }),
        [deleteMemberShip.FAIL]: (state, { payload }) => ({ ...state, error: payload }),


        [exportMemberShip.REQUEST]: (state, { payload }) => ({ ...state }),
        [exportMemberShip.SUCCESS]: (state, { payload }) => ({ ...state }),
        [exportMemberShip.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

        [exportMemberInfo.REQUEST]: (state, { payload }) => ({ ...state }),
        [exportMemberInfo.SUCCESS]: (state, { payload }) => ({ ...state }),
        [exportMemberInfo.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

        [exportBaseMemberInfo.REQUEST]: (state, { payload }) => ({ ...state }),
        [exportBaseMemberInfo.SUCCESS]: (state, { payload }) => ({ ...state }),
        [exportBaseMemberInfo.FAIL]: (state, { payload }) => ({ ...state, error: payload }),


        [enableOrDisabledInfo.REQUEST]: (state, { payload }) => ({ ...state }),
        [enableOrDisabledInfo.SUCCESS]: (state, { payload }) => ({ ...state }),
        [enableOrDisabledInfo.FAIL]: (state, { payload }) => ({ ...state, error: payload }),


        [getBaseInfo.REQUEST]: (state, { payload }) => ({ ...state }),
        [getBaseInfo.SUCCESS]: (state, { payload }) => ({ ...state, baseInfo: payload ? payload : {} }),
        [getBaseInfo.FAIL]: (state, { payload }) => ({ ...state, error: payload }),


        [setBaseInfo]: (state, { payload }) => ({ ...state, baseInfo: payload }),

        [getBasepointDetails.REQUEST]: (state, { payload }) => ({ ...state }),
        [getBasepointDetails.SUCCESS]: (state, { payload }) => {
            const { content = [] } = payload || {};
            let pointDetailsUpdateWay = {}
            let pointExpire = {};   // 过期积分
            const pointDetails = content.map(val => {
                pointDetailsUpdateWay[val.pointType] = val.pointName;
                if (val.expire) {
                    pointExpire = val.expire
                }
                return { ...val, value: val.pointType, text: val.pointName || '' }
            });

            return ({ ...state, pointDetails, pointDetailsUpdateWay, pointExpire });
        },
        [getBasepointDetails.FAIL]: (state, { payload }) => ({ ...state, error: payload }),


        [editBasepoint.REQUEST]: (state, { payload }) => ({ ...state }),
        [editBasepoint.SUCCESS]: (state, { payload }) => ({ ...state }),
        [editBasepoint.FAIL]: (state, { payload }) => ({ ...state, error: payload }),


        [getBaseMemberDetails.REQUEST]: (state, { payload }) => ({ ...state }),
        [getBaseMemberDetails.SUCCESS]: (state, { payload }) => ({ ...state, memberDetails: payload }),
        [getBaseMemberDetails.FAIL]: (state, { payload }) => ({ ...state, error: payload }),


        [editBaseEmpiricalValue.REQUEST]: (state, { payload }) => ({ ...state }),
        [editBaseEmpiricalValue.SUCCESS]: (state, { payload }) => ({ ...state }),
        [editBaseEmpiricalValue.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

        [editBaseEmpiricalLevel.REQUEST]: (state, { payload }) => ({ ...state }),
        [editBaseEmpiricalLevel.SUCCESS]: (state, { payload }) => ({ ...state }),
        [editBaseEmpiricalLevel.FAIL]: (state, { payload }) => ({ ...state, error: payload }),


        [editBaseInfo.REQUEST]: (state, { payload }) => ({ ...state }),
        [editBaseInfo.SUCCESS]: (state, { payload }) => ({ ...state }),
        [editBaseInfo.FAIL]: (state, { payload }) => ({ ...state, error: payload }),



        [addMemberShip.REQUEST]: (state, { payload }) => ({ ...state }),
        [addMemberShip.SUCCESS]: (state, { payload }) => ({ ...state }),
        [addMemberShip.FAIL]: (state, { payload }) => ({ ...state, error: payload }),


        [editMemberShip.REQUEST]: (state, { payload }) => ({ ...state }),
        [editMemberShip.SUCCESS]: (state, { payload }) => ({ ...state }),
        [editMemberShip.FAIL]: (state, { payload }) => ({ ...state, error: payload }),




        [getBasegrowthRecordsList.REQUEST]: (state, { payload }) => ({ ...state, growthRecordsListLoading: true }),
        [getBasegrowthRecordsList.SUCCESS]: (state, { payload }) => ({ ...state, growthRecordsListLoading: false, growthRecordsList: payload }),
        [getBasegrowthRecordsList.FAIL]: (state, { payload }) => ({ ...state, growthRecordsListLoading: false, error: payload }),

        [getBasecompleteTaskRecordsList.REQUEST]: (state, { payload }) => ({ ...state, completeTaskRecordsListLoading: true }),
        [getBasecompleteTaskRecordsList.SUCCESS]: (state, { payload }) => ({ ...state, completeTaskRecordsListLoading: false, completeTaskRecordsList: payload }),
        [getBasecompleteTaskRecordsList.FAIL]: (state, { payload }) => ({ ...state, completeTaskRecordsListLoading: false, error: payload }),

        
        [getBasepointRecordsList.REQUEST]: (state, { payload }) => ({ ...state, pointRecordsListLoading: true }),
        [getBasepointRecordsList.SUCCESS]: (state, { payload }) => ({ ...state, pointRecordsListLoading: false, basepointRecordsList: payload }),
        [getBasepointRecordsList.FAIL]: (state, { payload }) => ({ ...state, pointRecordsListLoading: false, error: payload }),


        [getTagSelection.REQUEST]: (state, { payload }) => ({ ...state }),
        [getTagSelection.SUCCESS]: (state, { payload }) => {
            const tagSelection = [];
            for (let elem of payload.content.values()) {
                tagSelection.push({ value: elem.id, text: elem.name, type: elem.groupSpecies, tags: elem.tags, groupType: elem.groupType, })
            }
            return { ...state, tagSelection }
        },
        [getTagSelection.FAIL]: (state, { payload }) => ({ ...state, error: payload }),



        [getMemberShipContent.REQUEST]: (state, { payload }) => ({ ...state }),
        [getMemberShipContent.SUCCESS]: (state, { payload }) => ({ ...state, memberShipContent: payload }),
        [getMemberShipContent.FAIL]: (state, { payload }) => ({ ...state, error: payload }),



        [loadLabelNumber.REQUEST]: (state, { payload }) => ({ ...state }),
        [loadLabelNumber.SUCCESS]: (state, { payload }) => ({ ...state, labelNumberContent: payload ? payload : {} }),
        [loadLabelNumber.FAIL]: (state, { payload }) => ({ ...state }),
        [createLabelNumber.REQUEST]: (state, { payload }) => ({ ...state }),
        [createLabelNumber.SUCCESS]: (state, { payload }) => ({ ...state }),
        [createLabelNumber.FAIL]: (state, { payload }) => ({ ...state }),
        [editLabelNumber.REQUEST]: (state, { payload }) => ({ ...state }),
        [editLabelNumber.SUCCESS]: (state, { payload }) => ({ ...state }),
        [editLabelNumber.FAIL]: (state, { payload }) => ({ ...state }),


        [exportAvailable.REQUEST]: (state, { payload }) => ({ ...state }),
        [exportAvailable.SUCCESS]: (state, { payload }) => ({ ...state, availableCount: payload ? payload : 0 }),
        [exportAvailable.FAIL]: (state, { payload }) => ({ ...state }),




        [unmount.REQUEST]: (state, { payload }) => ({
            ...state,
            baseInfo: {},
            pointDetails: [],
            memberDetails: [],
            basepointRecordsList: {},
            growthRecordsList: {},
            completeTaskRecordsList:{},

            portrait: {},
        }),



    },
    initialState
)
export default reducer;