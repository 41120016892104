import { of } from "rxjs"
import { ofType } from "redux-observable"
import { mergeMap, map, catchError,concatMap } from "rxjs/operators"
import { ajax } from "rxjs/ajax"
import urls from "../../../api/urls"
import * as uiActions from '../ui';
import queryString from 'query-string';
import { trims } from 'ucode-utils'
import * as actions from "./newsManage"
import { ajaxBaseConfig, apiBaseConfig, actionApi } from "../../utils";

// 获取消息管理列表
export const newsManageEpic = actions$ => actions$.pipe(
  ofType(actions.getNewsManageList.REQUEST.toString()),
  mergeMap(action => {
    actionApi();
    const { page = 0, size = 20,sendType = '',startTime = '',endTime = '',sceneName = '',sendStatus = ''} = action.payload;
    return ajax({
      ...apiBaseConfig,
      method: 'GET',
      url: `${urls.subscribeMsg.sendMsg}?page=${page}&size=${size}&sceneName=${sceneName}&sendType=${sendType}&startTime=${startTime}&endTime=${endTime}&sendStatus=${sendStatus}`,
    }).pipe(
      map(res => actions.getNewsManageList.SUCCESS(res.response)),
      catchError(error => of(actions.getNewsManageList.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);

// 获取触达率列表
export const arrivalRateEpic = actions$ => actions$.pipe(
  ofType(actions.getArrivalRate.REQUEST.toString()),
  mergeMap(action => {
    actionApi();
    const { page = 0, size = 20,id} = action.payload;
    return ajax({
      ...apiBaseConfig,
      method: 'GET',
      url: `${urls.subscribeMsg.arrivalRateById}/${id}?page=${page}&size=${size}`,
    }).pipe(
      map(res => actions.getArrivalRate.SUCCESS(res.response)),
      catchError(error => of(actions.getArrivalRate.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);
// 新增或编辑
export const msgModifyEpic = actions$ => actions$.pipe(
  ofType(actions.msgModify.REQUEST.toString()),
  mergeMap(action => {
    actionApi();
    const { id, callback } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.subscribeMsg.sendMsg}`,
      body: action.payload,
      method: id ? 'PUT' : 'POST'
    }).pipe(
      map(res => {
        callback && callback()
        return actions.msgModify.SUCCESS(res.response)
      }),
      catchError(error => of(actions.msgModify.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);
// 查看消息详情
export const getMsgInfoByIdEpic = actions$ => actions$.pipe(
  ofType(actions.getMsgInfoById.REQUEST.toString()),
  mergeMap(action => {
    actionApi();
    const { id ,callback} = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.subscribeMsg.sendMsg}/${id}`,
    }).pipe(
      map(res => {
        callback && callback(res.response)
        return actions.getMsgInfoById.SUCCESS(res.response)
      }),
      catchError(error => of(actions.getMsgInfoById.FAIL(error.xhr), uiActions.notification(error.xhr)))
    )
  }
  )
);
// 终止
export const terminationEpic = actions$ => actions$.pipe(
  ofType(actions.termination.REQUEST.toString()),
  mergeMap(action => {
    actionApi();
    const { id,page = 0, size = 20,sendType = '',startTime = '',endTime = '',sceneName = '',sendStatus = '' } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      method: 'PUT',
      url: `${urls.subscribeMsg.stopSendMsg}/${id}`,
    }).pipe(
      mergeMap(res =>{
        return [actions.termination.SUCCESS(res.response),actions.getNewsManageList.REQUEST({page,size,sendType,startTime,endTime,sceneName,sendStatus})]
      }),
      catchError(error => of(actions.termination.FAIL(error.xhr.response), uiActions.notification(error.xhr.response),actions.getNewsManageList.REQUEST({page,size,sendType,startTime,endTime,sceneName,sendStatus})))
    )
  }
  )
);
// 取消
export const cancelEpic = actions$ => actions$.pipe(
  ofType(actions.cancel.REQUEST.toString()),
  mergeMap(action => {
    actionApi();
    const { id, page = 0, size = 20,sendType = '',startTime = '',endTime = '',sceneName = '',sendStatus = '' } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      method: 'PUT',
      url: `${urls.subscribeMsg.cancelSendMsg}/${id}`,
    }).pipe(
      mergeMap(res =>{
        return [actions.cancel.SUCCESS(res.response),actions.getNewsManageList.REQUEST({page,size,sendType,startTime,endTime,sceneName,sendStatus})]
      }),
      catchError(error => of(actions.cancel.FAIL(error.xhr.response), uiActions.notification(error.xhr.response),actions.getNewsManageList.REQUEST({page,size,sendType,startTime,endTime,sceneName,sendStatus})))
    )
  }
  )
);
// 取消周期发送
export const cancelSendEpic = actions$ => actions$.pipe(
  ofType(actions.cancelSend.REQUEST.toString()),
  mergeMap(action => {
    actionApi();
    const { id, page = 0, size = 20,sendType = '',startTime = '',endTime = '',sceneName = '',sendStatus = '' } = action.payload;
    console.log('---', action.payload)
    return ajax({
      ...ajaxBaseConfig,
      method: 'PUT',
      url: `${urls.subscribeMsg.cancelSend}/${id}`,
    }).pipe(
      mergeMap(res =>{
        return [actions.cancelSend.SUCCESS(res.response),actions.getNewsManageList.REQUEST({page,size,sendType,startTime,endTime,sceneName,sendStatus})]
      }),
      catchError(error => of(actions.cancelSend.FAIL(error.xhr.response), uiActions.notification(error.xhr.response),actions.getNewsManageList.REQUEST({page,size,sendType,startTime,endTime,sceneName,sendStatus})))
    )
  }
  )
);
// 删除消息
export const newsDeleteEpic = actions$ => actions$.pipe(
  ofType(actions.newsDelete.REQUEST.toString()),
  mergeMap(action => {
    actionApi();
    const { id,page = 0,size = 20,sendType = '',startTime = '',endTime = '',sceneName = '',sendStatus = '' } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      method: 'DELETE',
      url: `${urls.subscribeMsg.sendMsg}/${id}`,
    }).pipe(
      mergeMap(res => {
        return [actions.newsDelete.SUCCESS(res.response),actions.getNewsManageList.REQUEST({page,size,sendType,startTime,endTime,sceneName,sendStatus})]
      }),
      catchError(error => of(actions.newsDelete.FAIL(error.xhr.response), uiActions.notification(error.xhr.response),actions.getNewsManageList.REQUEST({page,size,sendType,startTime,endTime,sceneName,sendStatus})))
    )
  }
  )
);
// 查看失败消息详情
export const errorNewsEpic = actions$ => actions$.pipe(
  ofType(actions.errorNews.REQUEST.toString()),
  mergeMap(action => {
    actionApi();
    const { id} = action.payload;
    return ajax({
      ...apiBaseConfig,
      method: 'GET',
      url: `${urls.subscribeMsg.error}/${id}`,
    }).pipe(
      concatMap(res => {
        callback && callback(res.response);
        return [actions.errorNews.SUCCESS(res.response)];
      }),
      catchError(error => of(actions.errorNews.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);