import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap, map, catchError, concatMap } from 'rxjs/operators';
import { ajax } from '../../ajax';
import urls from '@/api/urls';
import * as uiActions from '../ui';
import queryString from 'query-string';
import * as actions from './coupon';
import { ajaxBaseConfig, actionApi } from '../../utils';

/**
 * TODO: 优惠券管理
 */
// 优惠券品牌下拉列表
export const loadCouponConfigsAllEpic = actions$ => actions$.pipe(
  ofType(actions.loadCouponConfigsAll.REQUEST.toString()),
  mergeMap(action => { actionApi()
    return (
      ajax({
        ...ajaxBaseConfig,
        url: `${ urls.coupon.couponConfigs }`,
      }).pipe(
        map(res => actions.loadCouponConfigsAll.SUCCESS(res.response.content)),
        catchError(error => of (actions.loadCouponConfigsAll.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);
// 优惠券种类下拉列表
export const loadCouponConfigDetailsAllEpic = actions$ => actions$.pipe(
  ofType(actions.loadCouponConfigDetailsAll.REQUEST.toString()),
  mergeMap(action => { actionApi()
    return (
      ajax({
        ...ajaxBaseConfig,
        url: `${urls.coupon.couponConfigDetails}/${action.payload}`,
      }).pipe(
        map(res => actions.loadCouponConfigDetailsAll.SUCCESS(res.response.content)),
        catchError(error => of (actions.loadCouponConfigDetailsAll.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);
// 优惠券种类下拉列表
export const couponCodePacketExportEpic = actions$ => actions$.pipe(
  ofType(actions.couponCodePacketExport.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { couponId = '', num = 0, remark='', callback } = action.payload;
    return (
      ajax({
        ...ajaxBaseConfig,
        url: `${urls.coupon.couponCodePacketExport(couponId, num, remark)}`,
      }).pipe(
        map(res => {
          callback && callback();
          return actions.couponCodePacketExport.SUCCESS()
        }),
        catchError(error => of (actions.couponCodePacketExport.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);
