import { createAction, handleActions } from 'redux-actions';
import { fasterActions } from '../../utils';

const moduleName = 'codeQueryTool';

export const changeDictPage = createAction(`${moduleName}/changeDictPage`);
export const changeTabList = createAction(`${moduleName}/changeTabList`);
//清空数据
export const removeinfo = createAction(`${moduleName}/removeinfo`);

export const getCodeInfo = fasterActions(moduleName, 'getCodeInfo');
export const getScanRecord = fasterActions(moduleName, 'getScanRecord');
export const addPinCode = fasterActions(moduleName, 'addPinCode');
export const labelCycle = fasterActions(moduleName, 'labelCycle');
export const getIp = fasterActions(moduleName, 'getIp');
export const getGps = fasterActions(moduleName, 'getGps');
export const getThirdPlainDomain = fasterActions(moduleName, 'getThirdPlainDomain');
export const singleCodeQueryToolTypeList = fasterActions(moduleName, 'singleCodeQueryToolTypeList');

const initialState = {
  pageData: {},
  pageParams: {},
  tabVal: '',
  codeInfo: {},
  scanInfoData: [],
  scanLoading: false,
  pinCode: '',
  calculateData: [],
  codeLoading: false,
  ipLocation: '',
  thirdPlainDomainData: [],
  singleCodeQueryToolTypeData: {},
}

const reducer = handleActions(
  {
    [changeDictPage]: (state, { payload }) => {
      return { ...state, pageParams: { ...payload } }
    },

    [changeTabList]: (state, { payload }) => {
      return { ...state, tabVal: payload }
    },

    [getCodeInfo.REQUEST]: (state, { payload }) => ({ ...state, payload, codeInfo: {}, codeLoading: true }),
    [getCodeInfo.SUCCESS]: (state, { payload }) => ({ ...state, codeInfo: payload, codeLoading: false }),
    [getCodeInfo.FAIL]: (state, { payload }) => ({ ...state, codeLoading: false }),

    [getScanRecord.REQUEST]: (state, { payload }) => ({ ...state, payload, scanLoading: true }),
    [getScanRecord.SUCCESS]: (state, { payload }) => ({ ...state, scanInfoData: payload, scanLoading: false }),
    [getScanRecord.FAIL]: (state, { payload }) => ({ ...state, scanLoading: false }),

    [addPinCode.REQUEST]: (state, { payload }) => ({ ...state, payload }),
    [addPinCode.SUCCESS]: (state, { payload }) => ({ ...state, pinCode: payload }),
    [addPinCode.FAIL]: (state, { payload }) => ({ ...state }),

    [labelCycle.REQUEST]: (state, { payload }) => ({ ...state, payload }),
    [labelCycle.SUCCESS]: (state, { payload }) => ({ ...state, calculateData: payload }),
    [labelCycle.FAIL]: (state, { payload }) => ({ ...state }),

    [getIp.REQUEST]: (state, { payload }) => ({ ...state, payload }),
    [getIp.SUCCESS]: (state, { payload }) => ({ ...state, ipLocation: payload }),
    [getIp.FAIL]: (state, { payload }) => ({ ...state }),

    [getGps.REQUEST]: (state, { payload }) => ({ ...state, payload }),
    [getGps.SUCCESS]: (state, { payload }) => ({ ...state, }),
    [getGps.FAIL]: (state, { payload }) => ({ ...state }),

    [getThirdPlainDomain.REQUEST]: (state, { payload }) => ({ ...state, payload }),
    [getThirdPlainDomain.SUCCESS]: (state, { payload }) => ({ ...state, thirdPlainDomainData: payload }),
    [getThirdPlainDomain.FAIL]: (state, { payload }) => ({ ...state }),

    [singleCodeQueryToolTypeList.REQUEST]: (state, { payload }) => ({ ...state, payload, loading: true, singleCodeQueryToolTypeData: {} }),
    [singleCodeQueryToolTypeList.SUCCESS]: (state, { payload }) => ({ ...state, singleCodeQueryToolTypeData: payload, loading: false }),
    [singleCodeQueryToolTypeList.FAIL]: (state, { payload }) => ({ ...state, loading: false, singleCodeQueryToolTypeData: {} }),

    [removeinfo]: (state, { payload }) => ({ ...state, codeInfo: {} }),
  },
  initialState
)

export default reducer;