import { createAction, createActions, handleActions, combineActions } from 'redux-actions';
import { fasterActions } from '../../utils';

const moduleName = 'consumptions';

export const loadList = fasterActions(moduleName, 'loadList');
export const updateConsumption = fasterActions(moduleName, 'updateConsumption');
export const addConsumption = fasterActions(moduleName, 'addConsumption');
export const delConsumption = fasterActions(moduleName, 'delConsumption');
export const updateSelected = createAction(`${moduleName}/updateSelected`);
export const addSelectedDetailsItem = createAction(`${moduleName}/addSelectedDetailsItem`);
export const delSelectedDetailsItem = createAction(`${moduleName}/delSelectedDetailsItem`);
export const updateConsumptionCheckedItems = createAction(`${moduleName}/updateConsumptionCheckedItems`);

// 达标活动
export const loadList1 = fasterActions(moduleName, 'loadList1');
export const updateConsumption1 = fasterActions(moduleName, 'updateConsumption1');
export const addConsumption1 = fasterActions(moduleName, 'addConsumption1');
export const delConsumption1 = fasterActions(moduleName, 'delConsumption1');

const initialState = {
  listLoaded: false,
  selected: {},
  checkedItems: [],
}

const reducer = handleActions(
  {
    [loadList.SUCCESS]: (state, { payload }) => {
      let selector = [];
      for (let elem of payload.content.values()) {
        selector.push({value: elem.id, text: elem.name, consumptionType: elem.consumptionType })
      }
      return {
        ...state,
        selector,
        list: payload.content,
        listLoaded: true,
        // selected: payload.content && payload.content[0]
      }
    },
    [updateSelected]: (state, { payload }) => {
      return { ...state, selected: payload }
    },
    [addSelectedDetailsItem]: (state, { payload }) => {
      
      let details = [];
      if (payload && payload.details) {
        details = [...payload.details]
      }
      details.push({});
      return { ...state, selected: { ...payload, details } };
    },
    [delSelectedDetailsItem]: (state, { payload }) => {
      let details = [...state.selected.details];
      details.splice(payload, 1);
      return { ...state, selected: { ...state.selected, details } }
    },
    [updateConsumptionCheckedItems]: (state, { payload }) => {
      const { checked, value } = payload;
      let checkedItems = [...state.checkedItems];
      if (checked) {
        checkedItems.push(value);
      } else {
        const index = checkedItems.indexOf(value);
        checkedItems.splice(index, 1);
      }
      return { ...state, checkedItems }
    },
    [updateConsumption.REQUEST]: (state, { payload }) => {
      return { ...state, updateConsumptionLoading: true }
    },
    [updateConsumption.SUCCESS]: (state, { payload }) => {
      return { ...state, updateConsumptionLoading: false }
    },
    [updateConsumption.FAIL]: (state, { payload }) => {
      return { ...state, updateConsumptionLoading: false }
    },
    [addConsumption.REQUEST]: (state, { payload }) => {
      return { ...state, addConsumptionLoading: true }
    },
    [addConsumption.SUCCESS]: (state, { payload }) => {
      return { ...state, addConsumptionLoading: false, selected: {} }
    },
    [addConsumption.FAIL]: (state, { payload }) => {
      return { ...state, addConsumptionLoading: false }
    },

    [loadList1.SUCCESS]: (state, { payload }) => {
      let selector = [];
      for (let elem of payload.content.values()) {
        selector.push({value: elem.id, text: elem.name, consumptionType: elem.consumptionType })
      }
      return {
        ...state,
        selector,
        list: payload.content,
        listLoaded: true,
        // selected: payload.content && payload.content[0]
      }
    },
    [updateConsumption1.REQUEST]: (state, { payload }) => {
      return { ...state, updateConsumptionLoading: true }
    },
    [updateConsumption1.SUCCESS]: (state, { payload }) => {
      return { ...state, updateConsumptionLoading: false }
    },
    [updateConsumption1.FAIL]: (state, { payload }) => {
      return { ...state, updateConsumptionLoading: false }
    },
    [addConsumption1.REQUEST]: (state, { payload }) => {
      return { ...state, addConsumptionLoading: true }
    },
    [addConsumption1.SUCCESS]: (state, { payload }) => {
      return { ...state, addConsumptionLoading: false, selected: {} }
    },
    [addConsumption1.FAIL]: (state, { payload }) => {
      return { ...state, addConsumptionLoading: false }
    },
  },
  initialState
);

export default reducer;