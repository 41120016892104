import { createAction, createActions, handleActions, combineActions } from 'redux-actions';
import { fasterActions } from '../../utils';

const moduleName = 'plan';

export const getPlan = fasterActions(moduleName, 'getPlan');
export const getPlanHistory = fasterActions(moduleName, 'getPlanHistory');

export const getPlanNo = fasterActions(moduleName, 'getPlanNo');
// export const getPlanNo2 = fasterActions(moduleName, 'getPlanNo2');
export const choosePlan = fasterActions(moduleName, 'choosePlan');

export const createPlan = fasterActions(moduleName, 'createPlan');
export const createPlanMore = fasterActions(moduleName, 'createPlanMore');
export const sortPlan = fasterActions(moduleName, 'sortPlan');
export const copyPlan = fasterActions(moduleName, 'copyPlan');
export const exportPlan = fasterActions(moduleName, 'exportPlan');
export const planTypeValue = fasterActions(moduleName, 'planTypeValue');
export const getPlanDetail = fasterActions(moduleName, 'getPlanDetail');
export const getPlanDetailHistory = fasterActions(moduleName, 'getPlanDetailHistory');
export const getPlanSelections = fasterActions(moduleName, 'getPlanSelections');

export const editPlan = fasterActions(moduleName, 'editPlan');
export const editPlanIsEdit = fasterActions(moduleName, 'editPlanIsEdit');
export const editPlanDetail = fasterActions(moduleName, 'editPlanDetail');
export const editAccurateRadio = fasterActions(moduleName, 'editAccurateRadio');
export const editAccurateRadioSelf = fasterActions(moduleName, 'editAccurateRadioSelf');

export const editAccurateType = fasterActions(moduleName, 'editAccurateType');


export const editTemplateList = fasterActions(moduleName, 'editTemplateList');



export const getGpsPosition = fasterActions(moduleName, 'getGpsPosition');
export const createGpsPosition = fasterActions(moduleName, 'createGpsPosition');
export const editGpsPosition = fasterActions(moduleName, 'editGpsPosition');
export const deleteGpsPosition = fasterActions(moduleName, 'deleteGpsPosition');

export const getPositionScene = fasterActions(moduleName, 'getPositionScene');
//获取活动和方案
export const getActiveList = fasterActions(moduleName, 'getActiveList');
export const getPlanList = fasterActions(moduleName, 'getPlanList');

//查询工具获取活动和方案
export const getActiveListForQool = fasterActions(moduleName, 'getActiveListForQool');
export const getPlanListForQool = fasterActions(moduleName, 'getPlanListForQool');
export const getAwardInfo = fasterActions(moduleName, 'getAwardInfo');
export const getPlanInfo = fasterActions(moduleName, 'getPlanInfo');
export const getdealer = fasterActions(moduleName, 'getdealer');
export const unmountQool = fasterActions(moduleName, 'unmountQool');



export const getCounter = fasterActions(moduleName, 'getCounter');


export const unmount = fasterActions(moduleName, 'unmount');

export const editAccessConditions = fasterActions(moduleName, 'editAccessConditions');
export const editAccessRadio = fasterActions(moduleName, 'editAccessRadio');
export const editAccessRadioSelf = fasterActions(moduleName, 'editAccessRadioSelf');
export const getAccess = fasterActions(moduleName, 'getAccess');
export const createAccess = fasterActions(moduleName, 'createAccess');
export const editAccess = fasterActions(moduleName, 'editAccess');
export const deleteAccess = fasterActions(moduleName, 'deleteAccess');
export const deleteAccessAfter = fasterActions(moduleName, 'deleteAccessAfter');


/* 2019-12-25 签到活动 */
export const createCheckInPlan = fasterActions(moduleName, 'createCheckInPlan');

const initialState = {
  loading: false,
  listLoaded: false,

  planList: [],
  planAllList: [],
  planChoose: {},
  planfilterValue: '',
  planDetail: {},
  planEdit: null,


  accurateType: null,
  accurateRadio: [],
  accurateRadioSelf: [],

  templateList: [],

  gpsPositionList: [],
  counterList: [],

  positionSceneList: [],
  positionSceneListTwoTree: [],



  activeList: [],
  planList: [],




  accessList: [],
  accessAllList: [],

}

// Reducer

const reducer = handleActions(
  {
    //step2
    [getPlan.REQUEST]: (state, { payload }) => ({ ...state, loading: true }),
    [getPlan.SUCCESS]: (state, { payload }) => ({ ...state, loading: false, listLoaded: true, planList: payload, planAllList: payload }),
    [getPlan.FAIL]: (state, { payload }) => ({ ...state, loading: false, listLoaded: false, error: payload }),

    [getPlanHistory.REQUEST]: (state, { payload }) => ({ ...state, loading: true }),
    [getPlanHistory.SUCCESS]: (state, { payload }) => ({ ...state, loading: false, listLoaded: true, planList: payload, planAllList: payload }),
    [getPlanHistory.FAIL]: (state, { payload }) => ({ ...state, loading: false, listLoaded: false, error: payload }),

    [getPlanNo.REQUEST]: (state, { payload }) => ({ ...state }),
    [choosePlan.REQUEST]: (state, { payload }) => {
      let newdata = { ...payload.data }
      if (payload.data.planType == 'SPONSOR' || payload.data.planType == 'PARTICIPATION') {
        newdata.name = newdata.name.substring(0, 4)
      }

      return { ...state, planChoose: newdata }

    },

    [getPlanDetail.REQUEST]: (state, { payload }) => ({ ...state, loading: true }),
    [getPlanDetail.SUCCESS]: (state, { payload }) => ({ ...state, loading: false, listLoaded: true, planDetail: payload }),
    [getPlanDetail.FAIL]: (state, { payload }) => ({ ...state, loading: false, listLoaded: false, error: payload }),

    [getPlanDetailHistory.REQUEST]: (state, { payload }) => ({ ...state, loading: true }),
    [getPlanDetailHistory.SUCCESS]: (state, { payload }) => ({ ...state, loading: false, listLoaded: true, planDetail: payload }),
    [getPlanDetailHistory.FAIL]: (state, { payload }) => ({ ...state, loading: false, listLoaded: false, error: payload }),

    [getPlanSelections.REQUEST]: (state, { payload }) => ({ ...state, planSelectionLoading: true }),
    [getPlanSelections.SUCCESS]: (state, { payload }) => {
      let selector = [];
      if (payload) {
        for (let elem of payload.values()) {
          selector.push({ ...elem, value: elem.id, text: elem.name });
        }
      }
      return ({ ...state, planSelectionLoading: false, planSelection: selector })
    },
    [getPlanSelections.FAIL]: (state, { payload }) => ({ ...state, planSelectionLoading: false, listLoaded: false, error: payload }),

    [createPlan.REQUEST]: (state, { payload }) => ({ ...state, loading: true }),
    [createPlan.SUCCESS]: (state, { payload }) => ({ ...state, loading: false, listLoaded: true, planList: payload, planAllList: payload }),
    [createPlan.FAIL]: (state, { payload }) => ({ ...state, loading: false, listLoaded: false, error: payload }),

    [createPlanMore.REQUEST]: (state, { payload }) => ({ ...state, loading: true }),
    [createPlanMore.SUCCESS]: (state, { payload }) => ({ ...state, loading: false, listLoaded: true, planList: payload, planAllList: payload }),
    [createPlanMore.FAIL]: (state, { payload }) => ({ ...state, loading: false, listLoaded: false, error: payload }),


    [sortPlan.REQUEST]: (state, { payload }) => ({ ...state, loading: true }),
    [sortPlan.SUCCESS]: (state, { payload }) => ({ ...state, loading: false, listLoaded: true }),
    [sortPlan.FAIL]: (state, { payload }) => ({ ...state, loading: false, listLoaded: false, error: payload }),
    [copyPlan.REQUEST]: (state, { payload }) => ({ ...state, loading: true }),
    [copyPlan.SUCCESS]: (state, { payload }) => ({ ...state, loading: false, listLoaded: true, planList: payload, planAllList: payload }),
    [copyPlan.FAIL]: (state, { payload }) => ({ ...state, loading: false, listLoaded: false, error: payload }),
    [exportPlan.REQUEST]: (state, { payload }) => ({ ...state, loading: true }),
    [exportPlan.SUCCESS]: (state, { payload }) => ({ ...state, loading: false, listLoaded: true }),
    [exportPlan.FAIL]: (state, { payload }) => ({ ...state, loading: false, listLoaded: false, error: payload }),


    [planTypeValue.REQUEST]: (state, { payload }) => {
      let planTypeValueList = state.planAllList.filter(key => { if (key.planType != 'ACCURATE') { return key } else { return (key.name).indexOf(payload) >= 0 } });
      return { ...state, planfilterValue: payload, planList: planTypeValueList }
      // return { ...state, PlanfilterValue: payload }
    },
    [editPlan.REQUEST]: (state, { payload }) => ({ ...state, loading: true }),
    [editPlan.SUCCESS]: (state, { payload }) => ({ ...state, loading: false, listLoaded: true }),
    [editPlan.FAIL]: (state, { payload }) => ({ ...state, loading: false, listLoaded: false, error: payload }),

    [editPlanIsEdit.REQUEST]: (state, { payload }) => ({ ...state, planEdit: payload, planDetail: { ...state.planDetail, time: new Date().getTime() } }),
    //
    [editPlanDetail.REQUEST]: (state, { payload }) => ({ ...state, planDetail: { ...payload, time: new Date().getTime() } }),


    [editAccurateRadio.REQUEST]: (state, { payload }) => ({ ...state, accurateRadio: payload }),
    [editAccurateRadioSelf.REQUEST]: (state, { payload }) => ({ ...state, accurateRadioSelf: payload }),

    [editAccurateType.REQUEST]: (state, { payload }) => ({ ...state, accurateType: payload }),

    [editTemplateList.REQUEST]: (state, { payload }) => ({ ...state, templateList: payload }),


    [getCounter.REQUEST]: (state, { payload }) => ({ ...state, loading: true }),
    [getCounter.SUCCESS]: (state, { payload }) => ({ ...state, loading: false, listLoaded: true, counterList: payload }),
    [getCounter.FAIL]: (state, { payload }) => ({ ...state, loading: false, listLoaded: false, error: payload }),


    [getGpsPosition.REQUEST]: (state, { payload }) => ({ ...state, loading: true }),
    [getGpsPosition.SUCCESS]: (state, { payload }) => ({ ...state, loading: false, listLoaded: true, gpsPositionList: payload }),
    [getGpsPosition.FAIL]: (state, { payload }) => ({ ...state, loading: false, listLoaded: false, error: payload }),

    [createGpsPosition.REQUEST]: (state, { payload }) => ({ ...state, loading: true }),
    [createGpsPosition.SUCCESS]: (state, { payload }) => ({ ...state, loading: false, listLoaded: true }), //, planList: payload, planAllList: payload
    [createGpsPosition.FAIL]: (state, { payload }) => ({ ...state, loading: false, listLoaded: false, error: payload }),


    [editGpsPosition.REQUEST]: (state, { payload }) => ({ ...state, loading: true }),
    [editGpsPosition.SUCCESS]: (state, { payload }) => ({ ...state, loading: false, listLoaded: true }), //, planList: payload, planAllList: payload
    [editGpsPosition.FAIL]: (state, { payload }) => ({ ...state, loading: false, listLoaded: false, error: payload }),

    [deleteGpsPosition.REQUEST]: (state, { payload }) => ({ ...state, loading: true }),
    [deleteGpsPosition.SUCCESS]: (state, { payload }) => ({ ...state, loading: false, listLoaded: true }),
    [deleteGpsPosition.FAIL]: (state, { payload }) => ({ ...state, loading: false, listLoaded: false, error: payload }),

    [getPositionScene.REQUEST]: (state, { payload }) => ({ ...state, loading: true }),
    [getPositionScene.SUCCESS]: (state, { payload }) => ({ ...state, loading: false, listLoaded: true, positionSceneList: payload.list, positionSceneListTwoTree: payload.list2 }),
    [getPositionScene.FAIL]: (state, { payload }) => ({ ...state, loading: false, listLoaded: false, error: payload }),


    [getActiveList.REQUEST]: (state, { payload }) => ({ ...state, loading: true }),
    [getActiveList.SUCCESS]: (state, { payload }) => ({ ...state, loading: false, listLoaded: true, activeList: payload }),
    [getActiveList.FAIL]: (state, { payload }) => ({ ...state, loading: false, listLoaded: false, error: payload }),

    [getPlanList.REQUEST]: (state, { payload }) => ({ ...state, loading: true }),
    [getPlanList.SUCCESS]: (state, { payload }) => ({ ...state, loading: false, listLoaded: true, planListList: payload }),
    [getPlanList.FAIL]: (state, { payload }) => ({ ...state, loading: false, listLoaded: false, error: payload }),

    [getActiveListForQool.REQUEST]: (state, { payload }) => ({ ...state, loading: true }),
    [getActiveListForQool.SUCCESS]: (state, { payload }) => ({ ...state, loading: false, listLoaded: true, activeList: payload }),
    [getActiveListForQool.FAIL]: (state, { payload }) => ({ ...state, loading: false, listLoaded: false, error: payload }),

    [getPlanListForQool.REQUEST]: (state, { payload }) => ({ ...state, loading: true }),
    [getPlanListForQool.SUCCESS]: (state, { payload }) => ({ ...state, loading: false, listLoaded: true, planListList: payload }),
    [getPlanListForQool.FAIL]: (state, { payload }) => ({ ...state, loading: false, listLoaded: false, error: payload }),

    [getAwardInfo.REQUEST]: (state, { payload }) => ({ ...state, loading: true, awardInfo: {} }),
    [getAwardInfo.SUCCESS]: (state, { payload }) => ({ ...state, loading: false, listLoaded: true, awardInfo: payload }),
    [getAwardInfo.FAIL]: (state, { payload }) => ({ ...state, loading: false, listLoaded: false, error: payload }),

    [getPlanInfo.REQUEST]: (state, { payload }) => ({ ...state, loading: true }),
    [getPlanInfo.SUCCESS]: (state, { payload }) => ({ ...state, loading: false, listLoaded: true, planInfo: payload }),
    [getPlanInfo.FAIL]: (state, { payload }) => ({ ...state, loading: false, listLoaded: false, error: payload }),

    [getdealer.REQUEST]: (state, { payload }) => ({ ...state, loading: true, dealer: [] }),
    [getdealer.SUCCESS]: (state, { payload }) => ({ ...state, loading: false, listLoaded: true, dealer: payload }),
    [getdealer.FAIL]: (state, { payload }) => ({ ...state, loading: false, listLoaded: false, error: payload }),

    [unmountQool.REQUEST]: (state, { payload }) => ({
      ...state,
      awardInfo: {},
      planInfo: {},
      dealer: [],
    }),



    [editAccessConditions.REQUEST]: (state, { payload }) => ({ ...state, accessConditions: payload }),

    [editAccessRadio.REQUEST]: (state, { payload }) => ({ ...state, accessRadio: payload }),
    [editAccessRadioSelf.REQUEST]: (state, { payload }) => ({ ...state, accessRadioSelf: payload }),

    [getAccess.REQUEST]: (state, { payload }) => ({ ...state, loading: true }),
    [getAccess.SUCCESS]: (state, { payload }) => ({ ...state, loading: false, listLoaded: true, accessList: payload, accessAllList: payload }),
    [getAccess.FAIL]: (state, { payload }) => ({ ...state, loading: false, listLoaded: false, error: payload }),

    [createAccess.REQUEST]: (state, { payload }) => ({ ...state, loading: true }),
    [createAccess.SUCCESS]: (state, { payload }) => ({ ...state, loading: false, listLoaded: true, accessList: payload, accessAllList: payload }),
    [createAccess.FAIL]: (state, { payload }) => ({ ...state, loading: false, listLoaded: false, error: payload }),
    [editAccess.REQUEST]: (state, { payload }) => ({ ...state, loading: true }),
    [editAccess.SUCCESS]: (state, { payload }) => ({ ...state, loading: false, listLoaded: true }),
    [editAccess.FAIL]: (state, { payload }) => ({ ...state, loading: false, listLoaded: false, error: payload }),
    [deleteAccess.REQUEST]: (state, { payload }) => ({ ...state, loading: true }),
    [deleteAccess.SUCCESS]: (state, { payload }) => ({ ...state, loading: false, listLoaded: true }),
    [deleteAccess.FAIL]: (state, { payload }) => ({ ...state, loading: false, listLoaded: false, error: payload }),

    [deleteAccessAfter.REQUEST]: (state, { payload }) => {
      let selector = [];
      for (let i = 0; i < state.accessAllList.length; i++) {
        if (payload != state.accessAllList[i].id) {
          selector.push(state.accessAllList[i])
        }
      }
      return ({ ...state, accessList: selector, accessAllList: selector })
    },

    [unmount.REQUEST]: (state, { payload }) => ({
      loading: false,
      listLoaded: false,

      planList: [],
      planAllList: [],
      planChoose: {},
      planfilterValue: '',
      planDetail: {},
      planEdit: null,

      accurateType: null,
      accurateRadio: [],
      accurateRadioSelf: [],

      templateList: [],

      gpsPositionList: [],

      counterList: [],
      positionSceneList: [],
      positionSceneListTwoTree: [],

      activeList: [],
      planListList: [],

      accessList: [],
      accessAllList: [],
      accessRadio: [],
      accessRadioSelf: [],
    }),


    [createCheckInPlan.REQUEST]: (state, { payload }) => ({ ...state, loading: true }),
    [createCheckInPlan.SUCCESS]: (state, { payload }) => ({ ...state, loading: false }),
    [createCheckInPlan.FAIL]: (state, { payload }) => ({ ...state, loading: false, error: payload }),
  },
  initialState
)
export default reducer;

