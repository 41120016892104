import { createAction, createActions, handleActions, combineActions } from 'redux-actions';
import { fasterActions, setScrollTop } from '../../utils';

const moduleName = 'physicalDelivery';

export const getLogisticsTable = fasterActions(moduleName, 'getLogisticsTable');
export const changeKewWords = createAction(`${moduleName}/changeKewWords`);
/* 
** 登记信息
*/
export const remarkRegister = fasterActions(moduleName, 'remarkRegister');
/* 
** 导出
*/
export const exportPhysical = fasterActions(moduleName, 'exportPhysical');
export const exportRedPacket = fasterActions(moduleName, 'exportRedPacket');

/* 导入下单 */
export const importOrder = fasterActions(moduleName, 'importOrder');
export const importList = fasterActions(moduleName, 'importList');

const initialPrizeInfoState = {
    physicalSearch: {
        pageNum: 0,
        pageSize: 20, // 每页大小
        // isAsc: '',
        expressState: [],
        exportState: [],
        // registrationTimeBg: +new Date() - 1000 * 30 * 24 * 3600,
        // registrationTimeEd: +new Date()

        registrationTimeBg: new Date(new Date(+new Date() - 1000 * 30 * 24 * 3600).toLocaleDateString() + ' ' + '00:00:00').getTime(),
        registrationTimeEd: new Date(new Date(+new Date()).toLocaleDateString()  + ' ' + '23:59:59').getTime()
    },
    physicalTable: [],
    totalElements: '', /* 总数据条数 */
    perPageCountList: [20, 50, 100], // 每页大小候选


    // 导入列表
    importSearch: {
        pageNum: 0,
        pageSize: 20
    },
    importTable: [],
}


const reducer = handleActions(
    {
        /* 获取奖品登记列表  搜索 */
        [getLogisticsTable.REQUEST]: (state, { payload }) => {
            setScrollTop();
            return { ...state, loading: true, physicalSearch: payload };
        },
        [getLogisticsTable.SUCCESS]: (state, { payload }) => {
            const { rows = [], total } = payload || {};
            return { ...state, loading: false, physicalTable: rows, totalElements:total }
        },
        [getLogisticsTable.FAIL]: (state, { payload }) => ({ ...state, loading: false, error: payload }),
        [changeKewWords]: (state, { payload }) => {
            return { ...state, physicalSearch: { ...state.physicalSearch, ...payload, pageNum: 0 } }
        },
        /* 登记信息 */
        [remarkRegister.REQUEST]: (state, { payload }) => ({ ...state, disabled: true, payload }),
        [remarkRegister.SUCCESS]: (state, { payload }) => ({ ...state, disabled: false }),
        [remarkRegister.FAIL]: (state, { payload }) => ({ ...state, disabled: false, error: payload }),
        /* 导出 */
        [exportPhysical.REQUEST]: (state, { payload }) => ({ ...state, payload }),
        [exportPhysical.SUCCESS]: (state, { payload }) => ({ ...state, payload }),
        [exportPhysical.FAIL]: (state, { payload }) => ({ ...state, error: payload }),
        /* 红包导出 */
        [exportRedPacket.REQUEST]: (state, { payload }) => ({ ...state, payload, redLoading: true }),
        [exportRedPacket.SUCCESS]: (state, { payload }) => ({ ...state, payload, redLoading: false }),
        [exportRedPacket.FAIL]: (state, { payload }) => ({ ...state, error: payload, redLoading: false }),
        /* 导入下单 */
        [importOrder.REQUEST]: (state, { payload }) => ({ ...state, payload }),
        [importOrder.SUCCESS]: (state, { payload }) => ({ ...state, payload }),
        [importOrder.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

        /* 导入列表 */
        [importList.REQUEST]: (state, { payload }) => {
            setScrollTop();
            return { ...state, loading: true, importSearch: payload };
        },
        [importList.SUCCESS]: (state, { payload }) => {
            const { rows = [], total } = payload || {};
            console.log(rows,'rows-----')
            return { ...state, loading: false, importTable: rows, totalElements:total }
        },
        [importList.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

    },
    initialPrizeInfoState
)

export default reducer;