import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { ajax } from '../../ajax';
import urls from '../../../api/urls';
import * as uiActions from '../ui';
import * as actions from './singleCodeQueryToolType';
import { ajaxBaseConfig, apiBaseConfig, actionApi } from '../../utils';

// 获取数据
export const singleCodeQueryToolTypeListEpic = actions$ => actions$.pipe(
  ofType(actions.singleCodeQueryToolTypeList.REQUEST.toString()),
  mergeMap((action) => {
    actionApi();
    const { page = 0, size = 20, sort = 'createdTime,desc', work = '', eseId = '', requestLatestData } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.data.singleCodeQueryToolType.dict}?page=${page}&size=${size}&sort=${sort}&work=${work}&eseId=${eseId}`,
      // url: `${urls.data.singleCodeQueryToolType.dict}?sort=${sort}`,
      method: 'GET',

    }).pipe(
      map(res => {
        let sortedResponse = requestLatestData && requestLatestData(res.response);
        return actions.singleCodeQueryToolTypeList.SUCCESS(sortedResponse)
      }),
      catchError(error => {
        return of(actions.singleCodeQueryToolTypeList.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
      })
    )
  }
  )
);

// 修改状态 禁用/启用
export const singleCodeQueryToolTypeStatusEpic = actions$ => actions$.pipe(
  ofType(actions.singleCodeQueryToolTypeStatus.REQUEST.toString()),
  mergeMap(action => {
    actionApi();
    const { id = '', status = '', eseId = '', requestLatestData } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      // url: `${urls.data.singleCodeQueryToolType.dict}/${id}/status?eseId=${eseId}`,
      url: `${urls.data.singleCodeQueryToolType.dict}/${id}/status?eseId=${eseId}&status=${status}`,
      method: 'PATCH',
      // body: { status }
    }).pipe(
      map(res => { requestLatestData && requestLatestData(); return actions.singleCodeQueryToolTypeStatus.SUCCESS(res.response) }),
      catchError(error => { return of(actions.singleCodeQueryToolTypeStatus.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)) })
    )
  }
  )
);

// 新增 修改编辑
export const singleCodeQueryToolTypeUpdateEpic = actions$ => actions$.pipe(
  ofType(actions.singleCodeQueryToolTypeUpdate.REQUEST.toString()),
  mergeMap(action => {
    actionApi();
    const { filedName = "", filedCode = "", remark = '', id, version, method = 'POST', type = '', eseId = '', requestLatestData } = action.payload;
    let params = {};
    if (type == 'add') {
      params = { filedName, filedCode, remark };
    }
    if (type == 'edit') {
      params = { id, version, filedName, filedCode, remark };
    }
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.data.singleCodeQueryToolType.dict}?eseId=${eseId}`,
      method: method,
      body: params
    }).pipe(
      map(res => { requestLatestData && requestLatestData(); return actions.singleCodeQueryToolTypeUpdate.SUCCESS(res) }),
      catchError(error => { return of(actions.singleCodeQueryToolTypeUpdate.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)) })
    )
  }
  )
);

// 删除
export const singleCodeQueryToolTypeDeleteEpic = actions$ => actions$.pipe(
  ofType(actions.singleCodeQueryToolTypeDelete.REQUEST.toString()),
  mergeMap(action => {
    actionApi();
    const { id = '', eseId = '', requestLatestData } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.data.singleCodeQueryToolType.dict}/${id}?eseId=${eseId}`,
      // url: `${urls.data.singleCodeQueryToolType.dict}?eseId=${eseId}`,
      method: 'DELETE'
    }).pipe(
      map(res => { requestLatestData && requestLatestData(); return actions.singleCodeQueryToolTypeDelete.SUCCESS(res) }),
      catchError(error => { return of(actions.singleCodeQueryToolTypeDelete.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)) })
    )
  }
  )
);
