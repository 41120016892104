import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap, map, catchError, concatMap } from 'rxjs/operators';
import { ajax } from '../../ajax';
import urls from '../../../api/urls';
import * as uiActions from '../ui';
import queryString from 'query-string';
import * as actions from './miniProgramMaterials';
import { ajaxBaseConfig, actionApi } from '../../utils';

/**
 * TODO: 微信管理
 */

// Epics
// 获取小程序素材列表
export const miniProgramMaterialsEpic = actions$ => actions$.pipe(
  ofType(actions.miniProgramMaterials.REQUEST.toString()),
  mergeMap(action => { actionApi()
    const { keyword = '', page, size } = action.payload;
    let url = `${urls.queryTools.miniProgramMaterials.miniProgramMaterials}?page=${page}&size=${size}`;
    if (keyword) {
      url += `&keyword=${keyword}`;
    }

    return ajax({
      ...ajaxBaseConfig,
      url
    }).pipe(
      map(res => actions.miniProgramMaterials.SUCCESS(res.response)),
      catchError(error => of(actions.miniProgramMaterials.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);

// 根据id获取小程序素材列表
export const miniProgramMaterialsByIdEpic = actions$ => actions$.pipe(
  ofType(actions.miniProgramMaterialsById.REQUEST.toString()),
  mergeMap(action => { actionApi()

    const { id, callback } = action.payload;
    let url = `${urls.queryTools.miniProgramMaterials.miniProgramMaterials + id}`;
    return ajax({
      ...ajaxBaseConfig,
      url
    }).pipe(
      map(res => {
        callback && callback(res.response);
        return actions.miniProgramMaterialsById.SUCCESS(res.response)
    }),
      catchError(error => of(actions.miniProgramMaterialsById.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);

// 创建/修改 /上架下架 /删除小程序 获取小程序素材
export const modifyMiniProgramMaterialsEpic = actions$ => actions$.pipe(
  ofType(actions.modifyMiniProgramMaterials.REQUEST.toString()),
  mergeMap(action => { actionApi()
    let url = `${urls.queryTools.miniProgramMaterials.miniProgramMaterials}`;
    const { method, id } = action.payload;
    if (method === 'DELETE') {
      url += id;
    }
    return ajax({
      ...ajaxBaseConfig,
      url,
      method,
      body: action.payload,
    }).pipe(
      map(res => actions.modifyMiniProgramMaterials.SUCCESS({ status: res.status, method })),
      catchError(error => of(actions.modifyMiniProgramMaterials.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);