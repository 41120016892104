export const integal = {
  permission: "integer.*",
  parentId: "*",
  appName: "integer",
  name: '{"en_US":"Integer Mall","zh_CN":"积分商城"}',
  permissionType: "MENU",
  iconCls: "IntegerMall",
  url: "",
  orderNum: 779,
  visible: true,
  view: false,
  resourceType: null,
  children: [
    {
      permission: "integer.mallmgmt.*",
      parentId: "integer.*",
      appName: "integer",
      name: '{"en_US":"MallMgmt","zh_CN":"商城管理"}',
      permissionType: "MENU",
      iconCls: "",
      url: "mallManagement",
      orderNum: 79,
      visible: true,
      view: false,
      resourceType: null,
      children: [
        {
          permission: "integer.mallmgmt.release.*",
          parentId: "integer.mallmgmt.*",
          appName: "integer",
          name: '{"en_US":"Release","zh_CN":"正式版"}',
          permissionType: "MENU",
          iconCls: "",
          url: "releasemall",
          orderNum: 79,
          visible: true,
          view: false,
          resourceType: null,
          children: [
            {
              permission: "integer.mallmgmt.release.add.*",
              parentId: "integer.mallmgmt.release.*",
              appName: "integer",
              name: '{"en_US":"add","zh_CN":"添加"}',
              permissionType: "BUTTON",
              iconCls: "",
              url: "add",
              orderNum: 1,
              visible: false,
              view: false,
              resourceType: "ENTERPRISE",
              children: null
            }
          ]
        },
        {
          permission: "integer.mallmgmt.beta.*",
          parentId: "integer.mallmgmt.*",
          appName: "integer",
          name: '{"en_US":"Beta","zh_CN":"测试版"}',
          permissionType: "MENU",
          iconCls: "",
          url: "betamall",
          orderNum: 89,
          visible: true,
          view: false,
          resourceType: null,
          children: [
            {
              permission: "integer.mallmgmt.beta.add.*",
              parentId: "integer.mallmgmt.beta.*",
              appName: "integer",
              name: '{"en_US":"add","zh_CN":"添加"}',
              permissionType: "BUTTON",
              iconCls: "",
              url: "add",
              orderNum: 1,
              visible: false,
              view: false,
              resourceType: "ENTERPRISE",
              children: null
            }
          ]
        },
        {
          permission: "integer.mallmgmt.unrelease.*",
          parentId: "integer.mallmgmt.*",
          appName: "integer",
          name: '{"en_US":"Unreleased","zh_CN":"未发布商城（1）"}',
          permissionType: "MENU",
          iconCls: "",
          url: "unreleasedmall",
          orderNum: 69,
          visible: false,
          view: true,
          resourceType: null,
          children: [
            {
              permission: "integer.mallmgmt.unrelease.add.*",
              parentId: "integer.mallmgmt.unrelease.*",
              appName: "integer",
              name: '{"en_US":"add","zh_CN":"添加"}',
              permissionType: "BUTTON",
              iconCls: "",
              url: "add",
              orderNum: 1,
              visible: false,
              view: false,
              resourceType: "ENTERPRISE",
              children: null
            }
          ]
        }
        /* {
          "permission": "code.tagConfig.allots",
          "parentId": "code.tagConfig.*",
          "appName": "code",
          "name": "{\"en_US\":\"Distribution\",\"zh_CN\":\"分配\"}",
          "permissionType": "BUTTON",
          "iconCls": "",
          "url": "allots",
          "orderNum": 1,
          "visible": false,
          "view": false,
          "resourceType": "ENTERPRISE",
          "children": null
        } */
      ]
    },
    {
      permission: "integer.GiftMgmt.*",
      parentId: "integer.*",
      appName: "integer",
      name: '{"en_US":"GiftMgmt","zh_CN":"礼品管理"}',
      permissionType: "MENU",
      iconCls: "",
      url: "giftmanagement",
      orderNum: 60,
      visible: true,
      view: false,
      resourceType: null,
      children: [
        {
          permission: "integer.giftMgmt.apply.*",
          parentId: "integer.batchApply.*",
          appName: "integer",
          name: '{"en_US":"Apply","zh_CN":"码包申请"}',
          permissionType: "MENU",
          iconCls: "",
          url: "codeapply",
          orderNum: 60,
          visible: true,
          view: false,
          resourceType: null,
          children: [
            {
              permission: "code.batchApply.apply.add",
              parentId: "code.batchApply.apply.*",
              appName: "code",
              name: '{"en_US":"ApplyCode","zh_CN":"申请码"}',
              permissionType: "BUTTON",
              iconCls: "",
              url: "add",
              orderNum: 1,
              visible: false,
              view: false,
              resourceType: "ENTERPRISE",
              children: null
            },
            {
              permission: "code.batchApply.apply.edit",
              parentId: "code.batchApply.apply.*",
              appName: "code",
              name: '{"en_US":"EditApply","zh_CN":"编辑"}',
              permissionType: "BUTTON",
              iconCls: "",
              url: "edit",
              orderNum: 1,
              visible: false,
              view: false,
              resourceType: "ENTERPRISE",
              children: null
            },
            {
              permission: "code.batchApply.apply.view",
              parentId: "code.batchApply.apply.*",
              appName: "code",
              name: '{"en_US":"View","zh_CN":"查看"}',
              permissionType: "BUTTON",
              iconCls: "",
              url: "view",
              orderNum: 1,
              visible: false,
              view: true,
              resourceType: "ENTERPRISE",
              children: null
            },
            {
              permission: "code.batchApply.apply.sendBatchs",
              parentId: "code.batchApply.apply.*",
              appName: "code",
              name: '{"en_US":"SendBatchs","zh_CN":"发送"}',
              permissionType: "BUTTON",
              iconCls: "",
              url: "sendBatchs",
              orderNum: 1,
              visible: false,
              view: false,
              resourceType: "ENTERPRISE",
              children: null
            }
          ]
        },
        {
          permission: "integer.batchApply.auditor.*",
          parentId: "integer.batchApply.*",
          appName: "integer",
          name: '{"en_US":"Auditor","zh_CN":"码包审核"}',
          permissionType: "MENU",
          iconCls: "",
          url: "codeauditor",
          orderNum: 50,
          visible: true,
          view: false,
          resourceType: null,
          children: [
            {
              permission: "code.batchApply.auditor.view",
              parentId: "code.batchApply.auditor.*",
              appName: "code",
              name: '{"en_US":"View","zh_CN":"查看"}',
              permissionType: "BUTTON",
              iconCls: "",
              url: "view",
              orderNum: 1,
              visible: false,
              view: true,
              resourceType: "ENTERPRISE",
              children: null
            },
            {
              permission: "code.batchApply.auditor.audit",
              parentId: "code.batchApply.auditor.*",
              appName: "code",
              name: '{"en_US":"Audit","zh_CN":"审核"}',
              permissionType: "BUTTON",
              iconCls: "",
              url: "audit",
              orderNum: 1,
              visible: false,
              view: false,
              resourceType: "ENTERPRISE",
              children: null
            }
          ]
        }
      ]
    },
    {
      permission: "integer.registration.*",
      parentId: "integer.*",
      appName: "integer",
      name: '{"en_US":"OrderMgmt","zh_CN":"订单管理"}',
      permissionType: "MENU",
      iconCls: "",
      url: "ordermanagement",
      orderNum: 60,
      visible: true,
      view: false,
      resourceType: null,
      children: [
        {
          permission: "integer.registration.batch.*",
          parentId: "integer.registration.*",
          appName: "code",
          name: '{"en_US":"Registration Batch","zh_CN":"批次信息录入"}',
          permissionType: "MENU",
          iconCls: "",
          url: "codebatch",
          orderNum: 60,
          visible: true,
          view: false,
          resourceType: null,
          children: [
            {
              permission: "code.registration.batch.view",
              parentId: "code.registration.batch.*",
              appName: "code",
              name: '{"en_US":"View","zh_CN":"查看"}',
              permissionType: "BUTTON",
              iconCls: "",
              url: "view",
              orderNum: 1,
              visible: false,
              view: true,
              resourceType: "ENTERPRISE",
              children: null
            },
            {
              permission: "code.registration.batch.input",
              parentId: "code.registration.batch.*",
              appName: "code",
              name: '{"en_US":"Input","zh_CN":"录入"}',
              permissionType: "BUTTON",
              iconCls: "",
              url: "input",
              orderNum: 1,
              visible: false,
              view: false,
              resourceType: "ENTERPRISE",
              children: null
            },
            {
              permission: "code.registration.batch.activation",
              parentId: "code.registration.batch.*",
              appName: "code",
              name: '{"en_US":"Activation","zh_CN":"激活"}',
              permissionType: "BUTTON",
              iconCls: "",
              url: "activation",
              orderNum: 1,
              visible: false,
              view: false,
              resourceType: "ENTERPRISE",
              children: null
            },
            {
              permission: "code.registration.batch.revocation",
              parentId: "code.registration.batch.*",
              appName: "code",
              name: '{"en_US":"Revocation","zh_CN":"撤销"}',
              permissionType: "BUTTON",
              iconCls: "",
              url: "revocation",
              orderNum: 1,
              visible: false,
              view: false,
              resourceType: "ENTERPRISE",
              children: null
            },
            {
              permission: "code.registration.batch.appletsettings",
              parentId: "code.registration.batch.*",
              appName: "code",
              name: '{"en_US":"Applet Settings","zh_CN":"小程序设置"}',
              permissionType: "BUTTON",
              iconCls: "",
              url: "appletsettings",
              orderNum: 1,
              visible: false,
              view: false,
              resourceType: "ENTERPRISE",
              children: null
            }
          ]
        },
        {
          permission: "code.registration.seqnum.*",
          parentId: "code.registration.*",
          appName: "code",
          name: '{"en_US":"Registration Seqnum","zh_CN":"号段补充录入"}',
          permissionType: "MENU",
          iconCls: "",
          url: "codeseqnum",
          orderNum: 50,
          visible: true,
          view: false,
          resourceType: null,
          children: [
            {
              permission: "code.registration.seqnum.view",
              parentId: "code.registration.seqnum.*",
              appName: "code",
              name: '{"en_US":"View","zh_CN":"查看"}',
              permissionType: "BUTTON",
              iconCls: "",
              url: "view",
              orderNum: 1,
              visible: false,
              view: true,
              resourceType: "ENTERPRISE",
              children: null
            },
            {
              permission: "code.registration.seqnum.input",
              parentId: "code.registration.seqnum.*",
              appName: "code",
              name: '{"en_US":"Input","zh_CN":"录入"}',
              permissionType: "BUTTON",
              iconCls: "",
              url: "input",
              orderNum: 1,
              visible: false,
              view: false,
              resourceType: "ENTERPRISE",
              children: null
            },
            {
              permission: "code.registration.seqnum.delete",
              parentId: "code.registration.seqnum.*",
              appName: "code",
              name: '{"en_US":"Delete","zh_CN":"刪除"}',
              permissionType: "BUTTON",
              iconCls: "",
              url: "delete",
              orderNum: 1,
              visible: false,
              view: false,
              resourceType: "ENTERPRISE",
              children: null
            },
            {
              permission: "code.registration.seqnum.activation",
              parentId: "code.registration.seqnum.*",
              appName: "code",
              name: '{"en_US":"Activation","zh_CN":"激活"}',
              permissionType: "BUTTON",
              iconCls: "",
              url: "activation",
              orderNum: 1,
              visible: false,
              view: false,
              resourceType: "ENTERPRISE",
              children: null
            },
            {
              permission: "code.registration.seqnum.revocation",
              parentId: "code.registration.seqnum.*",
              appName: "code",
              name: '{"en_US":"Revocation","zh_CN":"撤销"}',
              permissionType: "BUTTON",
              iconCls: "",
              url: "revocation",
              orderNum: 1,
              visible: false,
              view: false,
              resourceType: "ENTERPRISE",
              children: null
            }
          ]
        },
        {
          permission: "code.registration.scrap.*",
          parentId: "code.registration.*",
          appName: "code",
          name: '{"en_US":"Tag Scrap","zh_CN":"二维码报废"}',
          permissionType: "MENU",
          iconCls: "",
          url: "codescrap",
          orderNum: 40,
          visible: true,
          view: false,
          resourceType: null,
          children: [
            {
              permission: "code.registration.scrap.view",
              parentId: "code.registration.scrap.*",
              appName: "code",
              name: '{"en_US":"View","zh_CN":"查看"}',
              permissionType: "BUTTON",
              iconCls: "",
              url: "view",
              orderNum: 1,
              visible: false,
              view: true,
              resourceType: "ENTERPRISE",
              children: null
            },
            {
              permission: "code.registration.scrap.scrap",
              parentId: "code.registration.scrap.*",
              appName: "code",
              name: '{"en_US":"Scrap","zh_CN":"报废"}',
              permissionType: "BUTTON",
              iconCls: "",
              url: "scrap",
              orderNum: 1,
              visible: false,
              view: false,
              resourceType: "ENTERPRISE",
              children: null
            }
          ]
        },
        {
          permission: "code.registration.accessories.*",
          parentId: "code.registration.*",
          appName: "dm",
          name: '{"en_US":"accessories","zh_CN":"辅料箱管理"}',
          permissionType: "MENU",
          iconCls: "",
          url: "dmaccessories",
          orderNum: 20,
          visible: true,
          view: false,
          resourceType: null,
          children: [
            {
              permission: "code.registration.accessories.view",
              parentId: "code.registration.accessories.*",
              appName: "dm",
              name: '{"en_US":"view","zh_CN":"查看"}',
              permissionType: "BUTTON",
              iconCls: "",
              url: "view",
              orderNum: 1,
              visible: false,
              view: true,
              resourceType: "ENTERPRISE",
              children: null
            },
            {
              permission: "code.registration.accessories.delete",
              parentId: "code.registration.accessories.*",
              appName: "dm",
              name: '{"en_US":"delete","zh_CN":"删除"}',
              permissionType: "BUTTON",
              iconCls: "",
              url: "delete",
              orderNum: 1,
              visible: false,
              view: false,
              resourceType: "ENTERPRISE",
              children: null
            },
            {
              permission: "code.registration.accessories.activation",
              parentId: "code.registration.accessories.*",
              appName: "dm",
              name: '{"en_US":"activation","zh_CN":"激活"}',
              permissionType: "BUTTON",
              iconCls: "",
              url: "activation",
              orderNum: 1,
              visible: false,
              view: false,
              resourceType: "ENTERPRISE",
              children: null
            },
            {
              permission: "code.registration.accessories.revocation",
              parentId: "code.registration.accessories.*",
              appName: "dm",
              name: '{"en_US":"revocation","zh_CN":"撤销激活"}',
              permissionType: "BUTTON",
              iconCls: "",
              url: "revocation",
              orderNum: 1,
              visible: false,
              view: false,
              resourceType: "ENTERPRISE",
              children: null
            },
            {
              permission: "code.registration.accessories.registration",
              parentId: "code.registration.accessories.*",
              appName: "dm",
              name: '{"en_US":"registration","zh_CN":"登记"}',
              permissionType: "BUTTON",
              iconCls: "",
              url: "registration",
              orderNum: 1,
              visible: false,
              view: false,
              resourceType: "ENTERPRISE",
              children: null
            }
          ]
        }
      ]
    }
  ]
}


