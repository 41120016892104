import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap, map, catchError, concatMap, concatMapTo } from 'rxjs/operators';
//不要encode
import { ajax as noecAjax } from 'rxjs/ajax';
import { ajax } from '../../ajax';
import urls from '@/api/urls';
import * as uiActions from '../ui';
import queryString from 'query-string';
import * as actions from './memberManage';
import { ajaxBaseConfig, actionApi } from '../../utils';

// Epics
// 会员管理
export const getMemberInfoListEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.getMemberInfoList.REQUEST),
    mergeMap(action => {
        actionApi()
        const { page = 0, size = 20, name = '', openid = '', applyStart = '', applyEnd = '', phone = '', userSourceType = '', sort = 'um.ct,desc', memberId = '', phoneExists = 'false' } = action.payload || {};
        return noecAjax({
            ...ajaxBaseConfig,
            url: `${urls.memberShip.memberManage.info}?page=${page}&size=${size}&name=${name}&keywords=${openid}&phone=${phone ? encodeURIComponent(phone) : ''}&phoneExists=${phoneExists}&startTime=${applyStart}&endTime=${applyEnd}&sort=${sort}&userSourceType=${userSourceType}`,

        }).pipe(
            map(res => {
                return actions.getMemberInfoList.SUCCESS(res.response)
            }),
            catchError(error => of(actions.getMemberInfoList.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)


export const getMemberShipListEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.getMemberShipList.REQUEST),
    mergeMap(action => {
        actionApi()
        const { page = 0, size = 20, name = '', sort = 'createdTime,desc' } = action.payload || {};
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.memberShip.memberManage.ship}?page=${page}&size=${size}&sort=${sort}&name=${name}`,
        }).pipe(
            map(res => {
                return actions.getMemberShipList.SUCCESS(res.response)
            }),
            catchError(error => of(actions.getMemberShipList.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)

export const getPortraitEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.getPortrait.REQUEST),
    mergeMap(action => {
        actionApi()
        const { appId = '', openId = '', phone = '', memberId = '' } = action.payload || {};
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.memberShip.memberManage.portrait}?appId=${appId}&openId=${openId}&memberId=${memberId}&phone=${phone}`,
        }).pipe(
            map(res => {
                return actions.getPortrait.SUCCESS(res.response)
            }),
            catchError(error => of(actions.getPortrait.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)


export const getUpgradeRecordsEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.getUpgradeRecords.REQUEST),
    mergeMap(action => {
        actionApi()
        const { appId = '', openId = '', memberId = '' } = action.payload || {};
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.memberShip.memberManage.trajectory}/upgradeRecords?appId=${appId}&openId=${openId}&memberId=${memberId}`,
        }).pipe(
            map(res => {
                return actions.getUpgradeRecords.SUCCESS(res.response)
            }),
            catchError(error => of(actions.getUpgradeRecords.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)
export const getQueryAreaEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.getQueryArea.REQUEST),
    mergeMap(action => {
        actionApi()
        const { appId = '', openId = '', memberId = '' } = action.payload || {};
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.memberShip.memberManage.trajectory}/query-area?appId=${appId}&openId=${openId}&memberId=${memberId}`,
        }).pipe(
            map(res => {
                return actions.getQueryArea.SUCCESS(res.response)
            }),
            catchError(error => of(actions.getQueryArea.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)

export const getWinPrizeEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.getWinPrize.REQUEST),
    mergeMap(action => {
        actionApi()
        const { appId = '', openId = '', memberId = '', } = action.payload || {};
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.memberShip.memberManage.trajectory}/win-prize?appId=${appId}&openId=${openId}&memberId=${memberId}`,
        }).pipe(
            map(res => {
                return actions.getWinPrize.SUCCESS(res.response)
            }),
            catchError(error => of(actions.getWinPrize.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)

export const deleteMemberShipEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.deleteMemberShip.REQUEST),
    mergeMap(action => {
        actionApi()
        const { data, callback } = action.payload;
        return ajax({
            ...ajaxBaseConfig,
            method: 'DELETE',
            url: `${urls.memberShip.memberManage.ship}/${data.id}`,
        }).pipe(
            map(res => {
                callback && callback()
                return actions.deleteMemberShip.SUCCESS(res.response)
            }),
            catchError(error => of(actions.deleteMemberShip.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)
export const exportMemberShipEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.exportMemberShip.REQUEST),
    mergeMap(action => {
        actionApi()
        const { data, callback } = action.payload;
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.memberShip.memberManage.ship}/export?tagCode=${data.id}&email=${data.email}&tagName=${data.name}`,
        }).pipe(
            map(res => {
                callback && callback()
                return actions.exportMemberShip.SUCCESS(res.response)
            }),
            catchError(error => of(actions.exportMemberShip.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)
export const exportMemberInfoEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.exportMemberInfo.REQUEST),
    mergeMap(action => {
        actionApi()
        const { data, callback } = action.payload;
        const { openid = '', email = "", systemId = '', phoneExists = '', applyStart = '', applyEnd = '', phone = '', exportRemark = '' } = data || {};

        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.memberShip.memberManage.info}/export?email=${email}&systemId=${systemId}&phoneExists=${phoneExists}&keywords=${openid}&phone=${phone}&startTime=${applyStart}&endTime=${applyEnd}&exportRemark=${exportRemark}`,
        }).pipe(
            map(res => {
                callback && callback()
                return actions.exportMemberInfo.SUCCESS(res.response)
            }),
            catchError(error => of(actions.exportMemberInfo.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)

export const exportBaseMemberInfoEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.exportBaseMemberInfo.REQUEST),
    mergeMap(action => {
        actionApi()
        const { data, callback } = action.payload;
        const { appId = '', openId = '', applyStart = '', applyEnd = '', pointUpdateWay = '', sort = 'prs.ct,desc', exportRemark = '', exportType = '' } = data || {};

        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.memberShip.memberManage.info}/export/pointRecords?email=${data.email}&appId=${appId}&openId=${openId}&startTime=${applyStart}&endTime=${applyEnd}&pointUpdateWay=${pointUpdateWay}&sort=${sort}&exportRemark=${exportRemark}&exportType=${exportType}`,
            // 
        }).pipe(
            map(res => {
                callback && callback()
                return actions.exportBaseMemberInfo.SUCCESS(res.response)
            }),
            catchError(error => of(actions.exportBaseMemberInfo.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)




export const enableOrDisabledInfoEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.enableOrDisabledInfo.REQUEST),
    mergeMap(action => {
        actionApi()
        const { data, callback } = action.payload;
        return ajax({
            ...ajaxBaseConfig,
            method: 'PUT',
            url: `${urls.memberShip.memberManage.info}/${data.status}`,
            body: data
        }).pipe(
            map(res => {
                callback && callback()
                return actions.enableOrDisabledInfo.SUCCESS(res.response)
            }),
            catchError(error => of(actions.enableOrDisabledInfo.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)

export const getBaseInfoEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.getBaseInfo.REQUEST),
    mergeMap(action => {
        actionApi()
        const { data, callback } = action.payload;
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.memberShip.memberManage.info}/info?appId=${data.appId}&openId=${data.openId}&memberId=${data.memberId}`,
        }).pipe(
            map(res => {
                callback && callback()
                return actions.getBaseInfo.SUCCESS(res.response)
            }),
            catchError(error => of(actions.getBaseInfo.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)
export const getBasepointDetailsEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.getBasepointDetails.REQUEST),
    mergeMap(action => {
        actionApi()
        const { data, callback } = action.payload;
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.memberShip.memberManage.info}/pointDetails?appId=${data.appId}&openId=${data.openId}&memberId=${data.memberId}`,
        }).pipe(
            map(res => {
                callback && callback()
                return actions.getBasepointDetails.SUCCESS(res.response)
            }),
            catchError(error => of(actions.getBasepointDetails.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)
export const editBasepointEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.editBasepoint.REQUEST),
    mergeMap(action => {
        actionApi()
        const { data, callback } = action.payload;
        return ajax({
            ...ajaxBaseConfig,
            method: 'PUT',
            url: `${urls.memberShip.memberManage.info}/point`,
            body: data,
        }).pipe(
            map(res => {
                callback && callback()
                return actions.editBasepoint.SUCCESS(res.response)
            }),
            catchError(error => of(actions.editBasepoint.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)


// 获取会员详情
export const getBaseMemberDetailsEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.getBaseMemberDetails.REQUEST),
    mergeMap(action => {
        actionApi()
        const { data, callback } = action.payload;
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.memberShip.memberManage.info}/memberDetails?appId=${data.appId}&openId=${data.openId}&memberId=${data.memberId}&authType=${data.authType}`,
            body: data,
        }).pipe(
            map(res => {
                callback && callback()
                return actions.getBaseMemberDetails.SUCCESS(res.response)
            }),
            catchError(error => of(actions.getBaseMemberDetails.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)



// 修改经验值
export const editBaseEmpiricalValueEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.editBaseEmpiricalValue.REQUEST),
    mergeMap(action => {
        actionApi()
        const { data, callback } = action.payload;
        return ajax({
            ...ajaxBaseConfig,
            method: 'PUT',
            url: `${urls.memberShip.memberManage.info}/empiricalValue`,
            body: data,
        }).pipe(
            map(res => {
                callback && callback()
                return actions.editBaseEmpiricalValue.SUCCESS(res.response)
            }),
            catchError(error => of(actions.editBaseEmpiricalValue.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)

export const editBaseEmpiricalLevelEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.editBaseEmpiricalLevel.REQUEST),
    mergeMap(action => {
        actionApi()
        const { data, callback } = action.payload;
        return ajax({
            ...ajaxBaseConfig,
            method: 'PUT',
            url: `${urls.memberShip.memberManage.info}/updateMemberLelve`,
            body: data,
        }).pipe(
            map(res => {
                callback && callback()
                return actions.editBaseEmpiricalLevel.SUCCESS(res.response)
            }),
            catchError(error => of(actions.editBaseEmpiricalLevel.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)



export const getBasegrowthRecordsListEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.getBasegrowthRecordsList.REQUEST),
    mergeMap(action => {
        actionApi()
        const { page = 0, size = 20, appId = '', openId = '', memberId = '', applyStart = '', applyEnd = '', systemId = '', growthValueSource = '', sort = '' } = action.payload || {};
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.memberShip.memberManage.info}/growthRecords?page=${page}&size=${size}&appId=${appId}&openId=${openId}&memberId=${memberId}&startTime=${applyStart}&endTime=${applyEnd}&sort=${sort}&systemId=${systemId}&growthValueSource=${growthValueSource}`,
        }).pipe(
            map(res => {
                return actions.getBasegrowthRecordsList.SUCCESS(res.response)
            }),
            catchError(error => of(actions.getBasegrowthRecordsList.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)


export const getBasecompleteTaskRecordsListEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.getBasecompleteTaskRecordsList.REQUEST),
    mergeMap(action => {
        actionApi()
        const { page = 0, size = 20, appId = '', openId = '', memberId = '', applyStart = '', applyEnd = '', taskId = '', taskName = '', sort = '' } = action.payload || {};
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.memberShip.memberManage.info}/completeTaskRecords?page=${page}&size=${size}&appId=${appId}&openId=${openId}&memberId=${memberId}&startTime=${applyStart}&endTime=${applyEnd}&sort=${sort}&taskId=${taskId}&taskName=${taskName}`,
        }).pipe(
            map(res => {
                return actions.getBasecompleteTaskRecordsList.SUCCESS(res.response)
            }),
            catchError(error => of(actions.getBasecompleteTaskRecordsList.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)



export const getBasepointRecordsListEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.getBasepointRecordsList.REQUEST),
    mergeMap(action => {
        actionApi()
        const { page = 0, size = 20, appId = '', openId = '', memberId = '', applyStart = '', applyEnd = '', pointUpdateWay = '', pointType = '', sort = 'prs.ct,desc' } = action.payload || {};
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.memberShip.memberManage.info}/pointRecords?page=${page}&size=${size}&appId=${appId}&openId=${openId}&memberId=${memberId}&startTime=${applyStart}&endTime=${applyEnd}&sort=${sort}&pointUpdateWay=${pointUpdateWay}&pointType=${pointType}`,
        }).pipe(
            map(res => {
                return actions.getBasepointRecordsList.SUCCESS(res.response)
            }),
            catchError(error => of(actions.getBasepointRecordsList.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)

export const editBaseInfoEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.editBaseInfo.REQUEST),
    mergeMap(action => {
        actionApi()
        const { data, callback } = action.payload;
        return ajax({
            ...ajaxBaseConfig,
            method: 'PUT',
            url: `${urls.memberShip.memberManage.info}/info`,
            body: data,
        }).pipe(
            map(res => {
                callback && callback()
                return actions.editBaseInfo.SUCCESS(res.response)
            }),
            catchError(error => of(actions.editBaseInfo.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)

export const getTagSelectionEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.getTagSelection.REQUEST),
    mergeMap(action => {
        actionApi()
        const { data, callback } = action.payload;
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.memberShip.memberManage.selection}?size=100`,
        }).pipe(
            map(res => {
                callback && callback()
                return actions.getTagSelection.SUCCESS(res.response)
            }),
            catchError(error => of(actions.getTagSelection.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)


export const addMemberShipEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.addMemberShip.REQUEST),
    mergeMap(action => {
        actionApi()
        const { data, callback } = action.payload;
        return ajax({
            ...ajaxBaseConfig,
            method: 'POST',
            url: `${urls.memberShip.memberManage.ship}`,
            body: data,
        }).pipe(
            map(res => {
                callback && callback()
                return actions.addMemberShip.SUCCESS(res.response)
            }),
            catchError(error => of(actions.addMemberShip.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)

export const editMemberShipEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.editMemberShip.REQUEST),
    mergeMap(action => {
        actionApi()
        const { data, callback } = action.payload;
        return ajax({
            ...ajaxBaseConfig,
            method: 'PATCH',
            url: `${urls.memberShip.memberManage.ship}`,
            body: data,
        }).pipe(
            map(res => {
                callback && callback()
                return actions.editMemberShip.SUCCESS(res.response)
            }),
            catchError(error => of(actions.editMemberShip.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)


export const getMemberShipContentEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.getMemberShipContent.REQUEST),
    mergeMap(action => {
        actionApi()
        const { data, callback } = action.payload;
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.memberShip.memberManage.ship}/${data.id}`,
        }).pipe(
            map(res => {
                callback && callback()
                return actions.getMemberShipContent.SUCCESS(res.response)
            }),
            catchError(error => of(actions.getMemberShipContent.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)





export const loadLabelNumberEpic = actions$ => actions$.pipe(
    ofType(actions.loadLabelNumber.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { data, callback } = action.payload;
        return (
            ajax({
                ...ajaxBaseConfig,
                url: `${urls.memberShip.memberManage.tag}?eseId=${data.id}`,
            }).pipe(
                map(res => {
                    callback && callback()
                    return actions.loadLabelNumber.SUCCESS(res.response)
                }),
                catchError(error => of(actions.loadLabelNumber.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
            )
        )
    })
);

//create
export const createLabelNumberEpic = actions$ => actions$.pipe(
    ofType(actions.createLabelNumber.REQUEST.toString()),

    mergeMap(action => {
        actionApi()
        const { data, callback } = action.payload;
        return (

            ajax({
                ...ajaxBaseConfig,
                method: 'POST',
                url: `${urls.memberShip.memberManage.tag}`,
                body: data,
            }).pipe(
                map(res => {
                    callback && callback()
                    return actions.createLabelNumber.SUCCESS(res)
                }),
                catchError(error => of(actions.createLabelNumber.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
            )
        )
    })
);
//edit
export const editLabelNumberEpic = actions$ => actions$.pipe(
    ofType(actions.editLabelNumber.REQUEST.toString()),

    mergeMap(action => {
        actionApi()
        const { data, callback } = action.payload;
        return (
            ajax({
                ...ajaxBaseConfig,
                method: 'PATCH',
                url: `${urls.memberShip.memberManage.tag}`,
                body: data,
            }).pipe(
                map(res => {
                    callback && callback()
                    return actions.editLabelNumber.SUCCESS(res)
                }),
                catchError(error => of(actions.editLabelNumber.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
            )
        )
    })
);

export const exportAvailableEpic = actions$ => actions$.pipe(
    ofType(actions.exportAvailable.REQUEST.toString()),

    mergeMap(action => {
        actionApi()
        const { callback } = action.payload;
        return (
            ajax({
                ...ajaxBaseConfig,
                url: `${urls.memberShip.memberExport.exportAvailable}`,
            }).pipe(
                map(res => {
                    callback && callback()
                    return actions.exportAvailable.SUCCESS(res.response)
                }),
                catchError(error => of(actions.exportAvailable.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
            )
        )
    })
);


