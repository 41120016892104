import { createAction, createActions, handleActions, combineActions } from 'redux-actions';
import { fasterActions } from '../../utils';
import urls from '@/api/urls';

const moduleName = 'warehouse';

//字段分配
export const warehouseList = fasterActions(moduleName, 'warehouseList');
export const changeDictPaging = createAction(`${moduleName}/changeDictPaging`);
export const warehouseStatus = fasterActions(moduleName, 'warehouseStatus');
export const warehouseUpdate = fasterActions(moduleName, 'warehouseUpdate');
export const warehouseSelection = fasterActions(moduleName, 'warehouseSelection');
export const loadwarehousesQuery = fasterActions(moduleName, 'loadwarehousesQuery');

const initialState = {
    dictPaging: {
        page: 0,
        size: 20,
        // name:''
    },
    warehouseData:[],
    warehouseSelectionData:[]
}

const reducer = handleActions(
    {
        [changeDictPaging]: (state, { payload }) => {
            return { ...state, dictPaging: { ...payload} }
        },

        [warehouseList.REQUEST]: (state, { payload }) => ({ ...state, payload, loading: true }),
        [warehouseList.SUCCESS]: (state, { payload }) => ({ ...state, warehouseData: payload, loading: false }),
        [warehouseList.FAIL]: (state, { payload }) => ({ ...state, loading: false }),

        [warehouseStatus.REQUEST]: (state, { payload }) => ({ ...state, payload, loading: true }),
        [warehouseStatus.SUCCESS]: (state, { payload }) => ({ ...state, warehouseData: payload, loading: false }),
        [warehouseStatus.FAIL]: (state, { payload }) => ({ ...state, loading: false }),

        [warehouseUpdate.REQUEST]: (state, { payload }) => ({ ...state, payload, loading: true }), 
        [warehouseUpdate.SUCCESS]: (state, { payload }) => ({ ...state, warehouseData: payload, loading: false }),
        [warehouseUpdate.FAIL]: (state, { payload }) => ({ ...state, loading: false }),

        [warehouseSelection.REQUEST]: (state, { payload }) => ({ ...state, payload, loading: true }),
        [warehouseSelection.SUCCESS]: (state, { payload }) => ({ ...state, warehouseSelectionData: payload, loading: false }),
        [warehouseSelection.FAIL]: (state, { payload }) => ({ ...state, loading: false }),
        
        // 下拉数据
        [loadwarehousesQuery.REQUEST]: (state, { payload }) => ({ ...state, payload, loading: true }),
        [loadwarehousesQuery.SUCCESS]: (state, { payload }) => ({ ...state, warehousesQueryData: payload.content||[], loading: false }),
        [loadwarehousesQuery.FAIL]: (state, { payload }) => ({ ...state, loading: false }),
    },
    initialState
);

export default reducer;
