import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'
import { getNowTabs } from 'ucode-utils';
import urls from '@/api/urls';
import BackArrow from '@/icons/BackArrow';
import queryString from 'query-string';
import FileCenter from './FileCenter';
import FileCenterIcon from '@/icons/FileCenter';
import LayoutIcon from '@/icons/Layout';
import BackArrow2 from '@/icons/BackArrow2.tsx';
import ResetpwdDialog from '../../SystemLogin/ResetpwdDialog';
import { toggleChangePWDDialog } from '@/redux/modules/users/users'
import { logout, logoutAndDisabledCookie } from '@/redux/modules/ui'

import { layout } from '@/redux/modules/users/users';

// 微应用列表
const { apps: mockAppsConfig = [] } = require('../../../mock/apps'); // 本地调试用
const mockAppsPath = mockAppsConfig.map(v => v.activePath && v.activePath.split('/')[1]);

@withRouter
@connect(
  (state) => {
    return {
      sidebarStatus: state.ui.sidebarStatus,
      lvThreeMenuOfLvTwo: state.ui.lvThreeMenuOfLvTwo,
      lvTwoMenuUrls: state.ui.lvTwoMenuUrls,
      lvOneMenuUrls: state.ui.lvOneMenuUrls,
      nowRouter: state.ui.nowRouter,
      navbar: state.ui.navbar,
      openIds: state.users.openIds,
      showChangePWDDialog: state.users.showChangePWDDialog,

    }
  }, {
  layout: layout.REQUEST,
  toggleChangePWDDialog,
  logoutAndDisabledCookie,
  logout

}
)
class Navbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showFileCenter: false,
    }
  }

  componentDidMount() {
  }

  componentDidUpdate(prevProps) {

  }

  layout = () => {

    this.props.logoutAndDisabledCookie({
      callback: () => {
        // 将菜单置空
        this.props.history.replace('/SystemLogin');
      }
    });
  //   this.props.layout({ data: {}, callback: () => { } })
  //   if (urls.baseUrl == 'https://cust.uma.plsbd.com/api-gateway') {
  //     window.location.href = 'https://uma.plsbd.com/'
  //   }
  //   else {
  //     window.location.href = 'https://sso-qa.uma.plsbd.com/'
  //   }
  }
  layout2 = () => {
    if (urls.baseUrl == 'https://cust.uma.plsbd.com/api-gateway') {
      window.location.href = 'https://uma.plsbd.com/'
    }
    else {
      window.location.href = 'https://sso-qa.uma.plsbd.com/'
    }
  }

  handleRollback = () => {
    const { history } = this.props;
    // history.goBack();

    if (window.location.href.indexOf('promotionactive/activity/edit/conditions') >= 0) {

      if (window.location.href.indexOf('promotionId=') >= 0) {
        history.replace('/promotionactive/activity/edit?action=edit&promotionId=' + window.location.href.split('promotionId=')[1].split('&')[0] + '&step=0&again=true')
      }
      else {
        history.replace('/promotionactive/activity/edit?action=create&promotionCategory=' + window.location.href.split('promotionCategory=')[1].split('&')[0] + '&step=0&again=true')

      }


      console.log(history)
    }
    //只要路径里有/wswe/merchants，则去商户号列表页
    else if (window.location.href.indexOf('/wswe/merchants') >= 0) {
      // history.replace('/wswe'); // 原来的
      history.replace('/data/wswe');
    }

    else {
      history.goBack();
    }

    console.log(window)
    // window.parent.history.back();
  }


  render() {
    const { nowRouter, location, lvThreeMenuOfLvTwo, navbar, lvOneMenuUrls = [], lvTwoMenuUrls = [], openIds, showChangePWDDialog, toggleChangePWDDialog } = this.props;
    const isMockApp = mockAppsPath.includes(location.pathname.split("/")[1]);
    const location_pathname = isMockApp ? location.pathname.substr(location.pathname.indexOf('/', 1)) : location.pathname;
    const tabList = getNowTabs(nowRouter, lvThreeMenuOfLvTwo, location.pathname);
    const navbarBottomStatus = !(tabList.length > 0);
    const parsed = queryString.parse(location.search);
    const isNoBottomLine =
      (nowRouter === 'promotionactive' && parsed['action'] === 'view') ||
      (nowRouter === 'promotionantiFake' && parsed['action'] === 'view') ||
      (nowRouter === 'membercentersystem' && parsed['action'] === 'view') ||
      (nowRouter === 'membercentertag' && parsed['action'] === 'view') ||
      (nowRouter === 'pointsmallmall' && parsed['action'] === 'view') ||
      (nowRouter === 'codetagConfig' && parsed['action'] === 'edit') ||
      (nowRouter === 'datadict') ||
      (nowRouter === 'datafieldAssign') ||
      (nowRouter === 'reportlink') ||
      (location_pathname === '/dataenterprise/allot') ||
      (location_pathname === '/membercentermanage/allot') ||
      (location_pathname === '/dmlinkUpload') ||
      (location_pathname === '/reportbill') ||
      (location_pathname === '/reportoverview') ||
      (location_pathname === '/reportstatistics') ||
      (location_pathname === '/reportrealtimeoverview') ||
      (location_pathname === '/pointsmallorders') ||
      (location_pathname === '/reportsjfBill');
      // 原来的
      // (location.pathname === '/dataenterprise/allot') ||
      // (location.pathname === '/membercentermanage/allot') ||
      // (location.pathname === '/dmlinkUpload') ||
      // (location.pathname === '/reportbill') ||
      // (location.pathname === '/reportoverview') ||
      // (location.pathname === '/reportstatistics') ||
      // (location.pathname === '/reportrealtimeoverview') ||
      // (location.pathname === '/pointsmallorders') ||
      // (location.pathname === '/reportsjfBill');
      
    return (
      <div className={`navbar ${navbarBottomStatus && !isNoBottomLine ? 'border-bottom' : ''}`}>
        {
          !navbarBottomStatus || !navbar || lvTwoMenuUrls.includes(location_pathname.replace('/', '')) || lvOneMenuUrls.includes(location_pathname.replace('/', '')) ? '' :
          // !navbarBottomStatus || !navbar || lvTwoMenuUrls.includes(location.pathname.replace('/', '')) || lvOneMenuUrls.includes(location.pathname.replace('/', '')) ? '' :
            <BackArrow onClick={this.handleRollback} width="20" height="20" style={{ display: 'inline-block', position: 'relative', top: '5px', marginRight: '30px', cursor: 'pointer' }} />
        }
        <h3 style={{ display: 'inline-block' }}>
          {navbar}
        </h3>
        {/* {(openIds.userType == 'SYSTEM' || openIds.type == 'TY') && */}


        {/* } */}

        {!queryString.parse(window.location.search)?.data && <React.Fragment>
          <div style={{ float: 'right', marginRight: 20, cursor: 'pointer' }} onClick={() => { this.layout(); }}>退出登录</div>
          <LayoutIcon onClick={() => { this.layout(); }} width="20" height="20" style={{ float: 'right', display: 'inline-block', position: 'relative', top: '18px', cursor: 'pointer' }} />
        </React.Fragment>}

        {/* <React.Fragment>
          <div style={{ float: 'right', marginRight: 20, cursor: 'pointer',display:"none" }} onClick={() => { this.layout2(); }}>返回主页</div>
          <BackArrow2 onClick={() => { this.layout2(); }} width="20" height="20" style={{ float: 'right', display: 'inline-block', position: 'relative', top: '18px', cursor: 'pointer'}} />
        </React.Fragment> */}
        {/* 迎驾贡注释 */}
        {/* <React.Fragment>
          <div style={{ float: 'right', marginRight: 20, cursor: 'pointer' }} onClick={() => { this.setState({ showFileCenter: true }) }}>文件中心</div>
          <FileCenterIcon onClick={() => { this.setState({ showFileCenter: true }) }} width="20" height="20" style={{ float: 'right', display: 'inline-block', position: 'relative', top: '19px', cursor: 'pointer' }} />
        </React.Fragment> */}

        {this.state.showFileCenter && <FileCenter
          visible={this.state.showFileCenter}
          handleCancel={() => {
            this.setState({ showFileCenter: false })
          }}>
        </FileCenter>}
      </div>

    );
  }
}

export default Navbar;

