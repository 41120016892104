

import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap, map, catchError, concatMap } from 'rxjs/operators';
import { ajax } from '../../ajax';
import urls from '@/api/urls';
import * as uiActions from '../ui';
import queryString from 'query-string';
import * as actions from './liveManageMent';
import { ajaxBaseConfig, actionApi } from '../../utils';

// Epics
export const loadListEpic = actions$ => actions$.pipe(
  ofType(actions.loadList.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.liveMange.lives.lives}?${queryString.stringify(action.payload)}`,
    }).pipe(
      map(res => actions.loadList.SUCCESS(res.response)),
      catchError(error => of(actions.loadList.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
      )
    )
  }
  )
);

// 页面列表
export const loadLiveManageListEpic = (actions$) => actions$.pipe(
  ofType(actions.loadLiveManageList.REQUEST.toString()),

  mergeMap(action => {
    actionApi()
    const { page, size, keyWords = '', pageTemplateType = '', status = '' } = action.payload;

    return (
      ajax({
        ...ajaxBaseConfig,
        url: `${urls.liveMange.lives.livesPub}?page=${page}&size=${size}&keywords=${keyWords}&pageTemplateTypes=${pageTemplateType}&status=${status}`
      }).pipe(
        map(res => actions.loadLiveManageList.SUCCESS(res.response)),
        catchError(error => of(actions.loadLiveManageList.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);

export const modifyLiveManageListEpic = (actions$) => actions$.pipe(
  ofType(actions.modifyLiveManageList.REQUEST.toString()),
  mergeMap(action => {

    actionApi()
    return (
      ajax({
        ...ajaxBaseConfig,
        url: `${urls.liveMange.lives.lives}/enable`,
        body: action.payload,
        method: 'PUT'
      }).pipe(
        map(res => actions.modifyLiveManageList.SUCCESS(res.response)),
        catchError(error => of(actions.modifyLiveManageList.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);




// 查询样式
export const loadLiveTemListEpic = (actions$) => actions$.pipe(
  ofType(actions.loadLiveTemList.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { pageManageId, page, size, keyWords = '' } = action.payload;

    return (
      ajax({
        ...ajaxBaseConfig,
        url: `${urls.liveMange.lives.lives}/styles?page=${page}&size=${size}&pageManageId=${pageManageId}&keyWords=${keyWords}`
      }).pipe(
        map(res => actions.loadLiveTemList.SUCCESS(res.response)),
        catchError(error => of(actions.loadLiveTemList.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);
// 未发布列表
export const LoadUnpublishedListEpic = (actions$, state$) => actions$.pipe(
  ofType(actions.LoadUnpublishedList.REQUEST.toString()),
  mergeMap(action => {
    actionApi()

    // const { certificateListParam = {}, factorySearchKey= '' } = state$.value.certificate;
    return (
      ajax({
        ...ajaxBaseConfig,
        url: `${urls.liveMange.lives.lives}?page=${action.payload.page}&size=${action.payload.size}&keywords=${action.payload.keyWords || ""}&pageTemplateTypes=${action.payload.pageTemplateType ? action.payload.pageTemplateType || "" : ""}`,
      }).pipe(
        map(res => actions.LoadUnpublishedList.SUCCESS(Object.assign(res.response))),
        catchError(error => of(actions.LoadUnpublishedList.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
        )
      )
    )
  }
  )
);
// 历史

export const loadHistoriesListEpic = (actions$, state$) => actions$.pipe(
  ofType(actions.loadHistoriesList.REQUEST.toString()),
  mergeMap(action => {
    actionApi()

    // const { certificateListParam = {}, factorySearchKey= '' } = state$.value.certificate;
    return (
      ajax({
        ...ajaxBaseConfig,
        url: `${urls.liveMange.lives.histories}?page=${action.payload.page}&size=${action.payload.size}&pageManageId=${action.payload.pageManageId}&keywords=${action.payload.keywords || ""}`,
      }).pipe(
        map(res => actions.loadHistoriesList.SUCCESS(Object.assign(res.response))),
        catchError(error => of(actions.loadHistoriesList.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
        )
      )
    )
  }
  )
);
// 历史详情列表
export const loadParticularsListEpic = (actions$) => actions$.pipe(
  ofType(actions.loadParticularsList.REQUEST.toString()),

  mergeMap(action => {
    console.log(action.payload)
    actionApi()
    return (
      ajax({
        ...ajaxBaseConfig,
        url: `${urls.liveMange.lives.histories}/${action.payload.historyId}?page=${action.payload.page}&size=${action.payload.size}`
      }).pipe(
        map(res => actions.loadParticularsList.SUCCESS(res.response)),
        catchError(error => of(actions.loadParticularsList.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);
// 设置为最新
export const loadSetLastEpic = (actions$, state$) => actions$.pipe(
  ofType(actions.loadSetLast.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    console.log(action.payload)

    // const { certificateListParam = {}, factorySearchKey= '' } = state$.value.certificate;
    const { historyId } = action.payload
    return (
      ajax({
        ...ajaxBaseConfig,
        method: 'PATCH',
        url: `${urls.liveMange.lives.lives}/${historyId}/setLast`,
      }).pipe(
        map(res => actions.loadSetLast.SUCCESS(res.response)),
        catchError(error => of(actions.loadSetLast.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
        )
      )
    )
  }
  )
)


export const loadPageStyleEpic = (actions$) => actions$.pipe(
  ofType(actions.loadPageStyle.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { callback } = action.payload;
    return (
      ajax({
        ...ajaxBaseConfig,
        url: `${urls.liveMange.lives.pageStyle}?pageManageId=${action.payload.pageManageId || ""}&elementId=${action.payload.elementId || ""}`,
        method: 'DELETE',
      }).pipe(
        concatMap(res => {
          callback && callback();
          return [actions.loadPageStyle.SUCCESS(res.response)];
        }),
        catchError(error => of(actions.loadPageStyle.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))


      )
    )
  })
);

export const loadUsedListEpic = actions$ => actions$.pipe(
  ofType(actions.loadUsedList.REQUEST.toString()),

  mergeMap(action => {
    actionApi();
    console.log(action.payload)
    const { planKeywords = '', promotionKeywords = '', pageManageId = '', page, size = 20 } = action.payload;
    console.log(planKeywords, promotionKeywords)

    let url = `${urls.liveMange.lives.lives}/used?page=${page}&size=${size}&pageManageId=${pageManageId}&promotionKeywords=${promotionKeywords}&planKeywords=${planKeywords}`;
    return ajax({
      ...ajaxBaseConfig,
      url,
    }).pipe(
      map(res => actions.loadUsedList.SUCCESS(res.response)),
      catchError(error => of(actions.loadUsedList.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  })
);


// 模板下拉
export const loadtemplatesSelectEpic = (actions$) => actions$.pipe(
  ofType(actions.loadtemplatesSelect.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { callback } = action.payload;
    console.log(action.payload)
    return (
      ajax({
        ...ajaxBaseConfig,
        url: `${urls.liveMange.lives.templates}?authType=${action.payload.authType || ""}&appId=${action.payload.appId || ""}&pageTemplateType=${action.payload.pageTemplateType ? action.payload.pageTemplateType || "" : ""}`
      }).pipe(
        concatMap(res => {
          callback && callback(res);
          return [actions.loadtemplatesSelect.SUCCESS(res.response)];
        }),
        // catchError(error => of(actions.loadtemplatesSelect.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))


      )
    )
  })
);


// 奖品复制
export const liveCopyEpic = (actions$, state$) => actions$.pipe(
  ofType(actions.liveCopy.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { temId, nextParam = {}, callback } = action.payload;


    return (
      ajax({
        url: `${urls.liveMange.lives.lives}/${temId}/copy`,
        method: 'PATCH',
        ...ajaxBaseConfig,
        body: JSON.stringify(action.payload)
      }).pipe(
        concatMap(res => {
          callback && callback();
          return [actions.liveCopy.SUCCESS(res.response)];
        }),
        catchError(error => of(actions.liveCopy.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);
// 发布
export const loadLiveTemFabuEpic = (actions$, state$) => actions$.pipe(

  ofType(actions.loadLiveTemFabu.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { pageManageId, nextParam = {}, callback } = action.payload;
    return (
      ajax({
        url: `${urls.liveMange.lives.lives}/${pageManageId}/publish`,
        method: 'PATCH',
        ...ajaxBaseConfig,
        body: JSON.stringify(action.payload)
      }).pipe(
        concatMap(res => {
          callback && callback(res);

          return [actions.loadLiveTemFabu.SUCCESS(res.response)]
        }),
        catchError(error => of(actions.loadLiveTemFabu.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);
// 替换
export const loadLiveTemTihuanEpic = (actions$, state$) => actions$.pipe(
  ofType(actions.loadLiveTemTihuan.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { pageManageId, nextParam = {}, callback } = action.payload;
    return (
      ajax({
        url: `${urls.liveMange.lives.lives}/pageStyle`,
        method: 'POST',
        ...ajaxBaseConfig,
        body: JSON.stringify(action.payload)
      }).pipe(
        concatMap(res => {
          callback && callback();
          console.log(res)
          return [actions.loadLiveTemTihuan.SUCCESS(res.response)]
        }),
        catchError(error => of(actions.loadLiveTemTihuan.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);

// 奖品删除
export const liveDeleteEpic = (actions$, state$) => actions$.pipe(
  ofType(actions.liveDelete.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { id, nextParam = {}, callback } = action.payload;
    return (
      ajax({
        ...ajaxBaseConfig,
        method: 'DELETE',
        url: `${urls.liveMange.lives.lives}/${id}`
      }).pipe(
        concatMap(res => {
          callback && callback();
          return [actions.liveDelete.SUCCESS(res.response)];
        }),
        catchError(error => of(actions.liveDelete.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);// 查询
export const loadLiveTemListIdEpic = (actions$, state$) => actions$.pipe(
  ofType(actions.loadLiveTemListId.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { id, callback } = action.payload;
    return (
      ajax({
        ...ajaxBaseConfig,
        url: `${urls.liveMange.lives.lives}/${id}`
      }).pipe(
        concatMap(res => {
          callback && callback(res);
          return [actions.loadLiveTemListId.SUCCESS(res.response)];
        }),
        catchError(error => of(actions.loadLiveTemListId.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);
// 
export const loadTemAppEpic = (actions$, state$) => actions$.pipe(
  ofType(actions.loadTemApp.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { id, nextParam = {}, callback } = action.payload;
    return (
      ajax({
        ...ajaxBaseConfig,
        url: `${urls.liveMange.lives.templatesApp}/${id}`
      }).pipe(
        concatMap(res => {
          callback && callback(res);
          return [actions.loadTemApp.SUCCESS(res.response)];
        }),
        catchError(error => of(actions.loadTemApp.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);

// 模板id查询
export const loadtemplatesIdEpic = (actions$, state$) => actions$.pipe(
  ofType(actions.loadtemplatesId.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { id, nextParam = {}, callback } = action.payload;
    return (
      ajax({
        ...ajaxBaseConfig,
        url: `${urls.liveMange.lives.lives}/${id}`
      }).pipe(
        concatMap(res => {
          callback && callback(res);
          return [actions.loadtemplatesId.SUCCESS(res.response)];
        }),
        // catchError(error => of(actions.loadLiveTemListId.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);



// 奖品详情
// export const prizeDetailByIdEpic = actions$ => actions$.pipe(
//   ofType(actions.prizeDetail.REQUEST.toString()),
//   mergeMap(action => {
//     actionApi()
//     const { id, callback } = action.payload;
//     return (
//       ajax({
//         ...ajaxBaseConfig,
//         url: `${urls.liveMange.lives.lives}/${id}`
//       }).pipe(
//         map(res => {
//           callback && callback(res.response);
//           return actions.prizeDetail.SUCCESS(res.response);
//         }),
//         catchError(error => of (actions.prizeDetail.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
//       )
//     )
//   })
// );

// 创建
export const liveCreateEpic = (actions$, state$) => actions$.pipe(
  ofType(actions.liveCreate.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { data, callback } = action.payload;
    console.log()
    return (
      ajax({
        ...ajaxBaseConfig,
        url: `${urls.liveMange.lives.lives}`,
        method: 'POST',
        body: JSON.stringify(data)
      }).pipe(
        concatMap(res => {
          callback && callback(res);
          return [actions.liveCreate.SUCCESS(res.response)];
        }),
        catchError(error => of(actions.liveCreate.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);
// 

// 奖品修改
export const liveEditEpic = (actions$, state$) => actions$.pipe(
  ofType(actions.liveEdit.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { data, callback } = action.payload;
    console.log()
    return (
      ajax({
        ...ajaxBaseConfig,
        url: `${urls.liveMange.lives.lives}`,
        method: 'PUT',
        body: JSON.stringify(data)
      }).pipe(
        concatMap(res => {
          callback && callback(res);
          return [actions.liveEdit.SUCCESS(res.response)];
        }),
        catchError(error => of(actions.liveEdit.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);


// 活动页面下拉
export const loadLiveSelectEpic = (actions$) => actions$.pipe(
  ofType(actions.loadLiveSelect.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { templateId, appId, authType, callback } = action.payload;

    return (
      ajax({
        ...ajaxBaseConfig,
        url: `${urls.liveMange.lives.lives}/selections/${templateId}?appId=${appId}&authType=${authType}`
      }).pipe(
        concatMap(res => {
          callback && callback(res);
          return [actions.loadLiveSelect.SUCCESS(res.response)];
        }),
        catchError(error => of(actions.loadLiveSelect.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))


      )
    )
  })
);
// 活动页面下拉
export const loadLiveSelectAgainEpic = (actions$) => actions$.pipe(
  ofType(actions.loadLiveSelectAgain.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { templateId, appId, authType, callback } = action.payload;

    return (
      ajax({
        ...ajaxBaseConfig,
        url: `${urls.liveMange.lives.lives}/selections/${templateId}?appId=${appId}&authType=${authType}`
      }).pipe(
        concatMap(res => {
          callback && callback(res);
          return [actions.loadLiveSelectAgain.SUCCESS(res.response)];
        }),
        catchError(error => of(actions.loadLiveSelectAgain.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))


      )
    )
  })
);


export const loadTemFindEpic = (actions$) => actions$.pipe(
  ofType(actions.loadTemFind.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { templateId, appId, authType, callback } = action.payload;

    return (
      ajax({
        ...ajaxBaseConfig,
        url: `${urls.liveMange.lives.templatesFind}/${templateId}`
      }).pipe(
        concatMap(res => {
          callback && callback(res);
          return [actions.loadTemFind.SUCCESS(res.response)];
        }),
        catchError(error => of(actions.loadTemFind.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))


      )
    )
  })
);


