import { createAction, handleActions } from 'redux-actions';
import { fasterActions } from '../../utils';

const moduleName = 'addressBooks';

/**
 * TODO: 通讯录管理
 */
export const loadAddressBooksPageList = fasterActions(moduleName, 'loadAddressBooksPageList');
export const loadAddressBookById = fasterActions(moduleName, 'loadAddressBookById');
export const loadAddressBookSelections = fasterActions(moduleName, 'loadAddressBookSelections');
export const enableOrDisableAddressBooks = fasterActions(moduleName, 'enableOrDisableAddressBooks');
export const createAddressbook = fasterActions(moduleName, 'createAddressbook');
export const editAddressbook = fasterActions(moduleName, 'editAddressbook');

export const updateAddressBookSearchKey = createAction(`${moduleName}/updateAddressBookSearchKey`);

const initialState = {
    addressBookPageList: [],
    perPageCountList: [20, 50, 100],
    addressBookSearchKey: '',
    addressBookDetail: {},
    addressBookPageParam: {
        size: 20,
        page: 0,
        sort: 'id,desc',
        totalElements: 0,
        deptIds: ''
    }
}

const reducer = handleActions({
    // 分页列表
    [loadAddressBooksPageList.REQUEST]: (state, { payload }) => ({ ...state, addressBookPageParam: payload, addressBookPageListLoading: true }),
    [loadAddressBooksPageList.SUCCESS]: (state, { payload }) => ({ ...state, addressBookPageList: payload.content || [], addressBookPageParam: { ...state.addressBookPageParam, totalElements: payload.totalElements }, addressBookPageListLoading: false }),
    [loadAddressBooksPageList.FAIL]: (state, { payload }) => ({ ...state, error: payload, addressBookPageListLoading: false }),

    // id 详情
    [loadAddressBookById.REQUEST]: (state, { payload }) => ({ ...state }),
    [loadAddressBookById.SUCCESS]: (state, { payload }) => ({ ...state, addressBookDetail: payload }),
    [loadAddressBookById.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

    // 下拉选项
    [loadAddressBookSelections.REQUEST]: (state, { payload }) => ({ ...state }),
    [loadAddressBookSelections.SUCCESS]: (state, { payload }) => {
        let addressBookSelections = [];
        if (payload.content) {
            payload.content.forEach(v => { addressBookSelections.push({ text: v.name + (v.mobile ? v.mobile : '') + (v.deptName ? v.deptName : ''), value: v.id, id: v.id }) });
        }
        return { ...state, addressBookSelections }
    },
    [loadAddressBookSelections.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

    // 禁用/启用
    [enableOrDisableAddressBooks.REQUEST]: (state, { payload }) => ({ ...state }),
    [enableOrDisableAddressBooks.SUCCESS]: (state, { payload }) => ({ ...state }),
    [enableOrDisableAddressBooks.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

    // 创建
    [createAddressbook.REQUEST]: (state, { payload }) => ({ ...state }),
    [createAddressbook.SUCCESS]: (state, { payload }) => ({ ...state }),
    [createAddressbook.FAIL]: (state, { payload }) => ({ ...state, error: payload }),
    // 编辑
    [editAddressbook.REQUEST]: (state, { payload }) => ({ ...state }),
    [editAddressbook.SUCCESS]: (state, { payload }) => ({ ...state }),
    [editAddressbook.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

    [updateAddressBookSearchKey]: (state, { payload }) => ({ ...state, ...payload })
},
    initialState
);

export default reducer;