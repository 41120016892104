import { createAction, handleActions } from 'redux-actions';
import { fasterActions } from '../../utils';

const moduleName = 'channelBill';

// 渠道单据
export const changeCodeSearch = createAction(`${moduleName}/changeCodeSearch`);
export const getChannelBillPage = fasterActions(moduleName, 'getChannelBillPage');
export const getChannelBillDetail = fasterActions(moduleName, 'getChannelBillDetail');
export const getBillInfo = fasterActions(moduleName, 'getBillInfo');
export const addChannelBill = fasterActions(moduleName, 'addChannelBill');
export const editChannelBill = fasterActions(moduleName, 'editChannelBill');
export const getCodesDetail = fasterActions(moduleName, 'getCodesDetail');
export const getProductLevel = fasterActions(moduleName, 'getProductLevel');
export const closeChannelBill = fasterActions(moduleName, 'closeChannelBill');
export const exportChannelBill = fasterActions(moduleName, 'exportChannelBill');
export const importChannelBill = fasterActions(moduleName, 'importChannelBill');
export const importChannelBilltransfer = fasterActions(moduleName, 'importChannelBilltransfer');

// 仓库、经销商、门店、产品下拉查询
export const getWarehouseSelect = fasterActions(moduleName, 'getWarehouseSelect');
export const getDealerSelect = fasterActions(moduleName, 'getDealerSelect');
export const getShopSelect = fasterActions(moduleName, 'getShopSelect');
export const getProductSelect = fasterActions(moduleName, 'getProductSelect');
// 查询产品
export const getProductInfo = fasterActions(moduleName, `getProductInfo`);




const initialState = {
  billTableData: {},
  channelBillDetail: {},
  codeSearch: {
    page: 0,
    size: 5,
  },
  codeTableData: {},

  warehouseSelection: [],
  dealerSelection: [],
  shopSelection: [],
  productSelection: [],
  productInfo: {},
}

const reducer = handleActions(
  {
    [changeCodeSearch]: (state, { payload }) => {
      return { ...state, codeSearch: { ...state.codeSearch, ...payload } }
    },

    [getChannelBillPage.REQUEST]: (state, { payload }) => ({ ...state, billLoading: true ,billTableData:[]}),
    [getChannelBillPage.SUCCESS]: (state, { payload }) => ({ ...state, loading: false, billTableData: payload }),
    [getChannelBillPage.FAIL]: (state, { payload }) => ({ ...state, billLoading: false, error: payload }),

    [getChannelBillDetail.REQUEST]: (state, { payload }) => ({ ...state, loadingInfo: true }),
    [getChannelBillDetail.SUCCESS]: (state, { payload }) => ({ ...state, loadingInfo: false, channelBillDetail: payload }),
    [getChannelBillDetail.FAIL]: (state, { payload }) => ({ ...state, loadingInfo: false, error: payload }),

    [getBillInfo.REQUEST]: (state, { payload }) => ({ ...state, loading: true }),
    [getBillInfo.SUCCESS]: (state, { payload }) => ({ ...state, loading: false }),
    [getBillInfo.FAIL]: (state, { payload }) => ({ ...state, loading: false, error: payload }),

    [addChannelBill.REQUEST]: (state, { payload }) => ({ ...state, disabled: true }),
    [addChannelBill.SUCCESS]: (state, { payload }) => ({ ...state, disabled: false }),
    [addChannelBill.FAIL]: (state, { payload }) => ({ ...state, disabled: false, error: payload }),

    [editChannelBill.REQUEST]: (state, { payload }) => ({ ...state, disabled: true }),
    [editChannelBill.SUCCESS]: (state, { payload }) => ({ ...state, disabled: false }),
    [editChannelBill.FAIL]: (state, { payload }) => ({ ...state, disabled: false, error: payload }),

    [getProductLevel.REQUEST]: (state, { payload }) => {
      return { ...state }
    },
    [getProductLevel.SUCCESS]: (state, { payload }) => ({ ...state, }),
    [getProductLevel.FAIL]: (state, { payload }) => ({ ...state, error: payload, }),

    [getCodesDetail.REQUEST]: (state, { payload }) => ({ ...state, loadingCode: true, codeSearch: payload, codeTableData: {} }),
    [getCodesDetail.SUCCESS]: (state, { payload }) => ({ ...state, loadingCode: false, codeTableData: payload }),
    [getCodesDetail.FAIL]: (state, { payload }) => ({ ...state, loadingCode: false, error: payload, codeTableData: {} }),

    [closeChannelBill.REQUEST]: (state, { payload }) => ({ ...state, }),
    [closeChannelBill.SUCCESS]: (state, { payload }) => ({ ...state, }),
    [closeChannelBill.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

    [exportChannelBill.REQUEST]: (state, { payload }) => ({ ...state, }),
    [exportChannelBill.SUCCESS]: (state, { payload }) => ({ ...state, }),
    [exportChannelBill.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

    [importChannelBill.REQUEST]: (state, { payload }) => ({ ...state, }),
    [importChannelBill.SUCCESS]: (state, { payload }) => ({ ...state, }),
    [importChannelBill.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

    [importChannelBilltransfer.REQUEST]: (state, { payload }) => ({ ...state, }),
    [importChannelBilltransfer.SUCCESS]: (state, { payload }) => ({ ...state, }),
    [importChannelBilltransfer.FAIL]: (state, { payload }) => ({ ...state, error: payload }),
    
    // 仓库
    [getWarehouseSelect.REQUEST]: (state, { payload }) => ({ ...state }),
    [getWarehouseSelect.SUCCESS]: (state, { payload }) => ({ ...state, warehouseSelection: payload }),
    [getWarehouseSelect.FAIL]: (state, { payload }) => ({ ...state, error: payload }),
    // 经销商
    [getDealerSelect.REQUEST]: (state, { payload }) => ({ ...state }),
    [getDealerSelect.SUCCESS]: (state, { payload }) => ({ ...state, dealerSelection: payload }),
    [getDealerSelect.FAIL]: (state, { payload }) => ({ ...state, error: payload }),
    // 门店
    [getShopSelect.REQUEST]: (state, { payload }) => ({ ...state }),
    [getShopSelect.SUCCESS]: (state, { payload }) => ({ ...state, shopSelection: payload }),
    [getShopSelect.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

    // 产品
    [getProductSelect.REQUEST]: (state, { payload }) => ({ ...state }),
    [getProductSelect.SUCCESS]: (state, { payload }) => ({ ...state, productSelection: payload }),
    [getProductSelect.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

    // 产品详情
    [getProductInfo.REQUEST]: (state, { payload }) => ({ ...state }),
    [getProductInfo.SUCCESS]: (state, { payload }) => ({ ...state, productInfo: payload }),
    [getProductInfo.FAIL]: (state, { payload }) => ({ ...state, error: payload }),
  },
  initialState
)

export default reducer;