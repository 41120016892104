import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap, map, catchError, concatMap } from 'rxjs/operators';
import { ajax } from '../../ajax';
import urls from '../../../api/urls';
import * as uiActions from '../ui';
import * as actions from './pinCodeRecord';
import { ajaxBaseConfig, apiBaseConfig, actionApi } from '../../utils';

// 
export const getPinCodeRecordEpic = (actions$, state$) => actions$.pipe(
  ofType(actions.getPinCodeRecord.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { page=0,size=20,code='',callback } = action.payload;

    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.pinCodeRecord.record}?page=${page}&size=${size}&code=${code}`,

    }).pipe(
      map(res => {
        // callback && callback(res.response.content);
        return actions.getPinCodeRecord.SUCCESS(res.response);
      }),
      catchError(error => {
          console.log(error)
          return of(actions.getPinCodeRecord.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))})
    )
  })
)