import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { ajax } from '../../ajax';
import urls from '@/api/urls';
import * as uiActions from '../ui';
import * as actions from './codeSource';
import { ajaxBaseConfig, actionApi } from '../../utils';


export const loadSelectListEpic = actions$ => actions$.pipe(
  ofType(actions.loadSelectList.REQUEST.toString()),
  mergeMap(action => { actionApi()
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.code.codeSource.selectList}?codeSourceType=${action.payload}`,
    }).pipe(
      map(res => actions.loadSelectList.SUCCESS(res.response)),
      catchError(error => of(actions.loadSelectList.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
      )
    )}
  )
);

export const loadCodeSourceListEpic = actions$ => actions$.pipe(
  ofType(actions.loadCodeSourceList.REQUEST.toString()),
  mergeMap(action => { actionApi();
    const { name = '', page = 0, size = 20, sort = 'id,desc' } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.code.codeSource.codeSource}?name=${name}&page=${page}&size=${size}&sort=${sort}`
    }).pipe(
      map(res => actions.loadCodeSourceList.SUCCESS(res.response)),
      catchError(error => of(actions.loadCodeSourceList.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
      )
    )}
  )
);

export const createCodeSourceEpic = actions$ => actions$.pipe(
  ofType(actions.createCodeSource.REQUEST.toString()),
  mergeMap(action => { actionApi()
    return ajax({
      ...ajaxBaseConfig,
      url: urls.code.codeSource.codeSource,
      method: 'POST',
      body: action.payload.data
    }).pipe(
      map(res => {
        action.payload.history.goBack();
        return actions.createCodeSource.SUCCESS(res.response);
      }),
      catchError(error => of(actions.createCodeSource.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
      )
    )}
  )
);

export const loadCodeSourceByIdEpic = actions$ => actions$.pipe(
  ofType(actions.loadCodeSourceById.REQUEST.toString()),
  mergeMap(action => { actionApi()
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.code.codeSource.codeSource}/${action.payload}/detail`,

    }).pipe(
      map(res => {
        // action.payload.history.goBack();
        return actions.loadCodeSourceById.SUCCESS(res.response);
      }),
      catchError(error => of(actions.loadCodeSourceById.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
      )
    )}
  )
);

export const editCodeSourceEpic = actions$ => actions$.pipe(
  ofType(actions.editCodeSource.REQUEST.toString()),
  mergeMap(action => { actionApi()
    return ajax({
      ...ajaxBaseConfig,
      url: urls.code.codeSource.codeSource,
      method: 'PUT',
      body: action.payload.data
    }).pipe(
      map(res => {
        action.payload.history.goBack();
        return actions.editCodeSource.SUCCESS(res.response);
      }),
      catchError(error => of(actions.editCodeSource.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
      )
    )}
  )
);

export const editDistributionEpic = actions$ => actions$.pipe(
  ofType(actions.editDistribution.REQUEST.toString()),
  mergeMap(action => { actionApi()
    const { data, callback } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      url: urls.code.codeSource.distribution,
      method: 'POST',
      body:data
    }).pipe(
      map(res => {
        callback && callback()
        return actions.editDistribution.SUCCESS(res.response);
      }),
      catchError(error => of(actions.editDistribution.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
      )
    )}
  )
);
export const getDistributionEpic = actions$ => actions$.pipe(
  ofType(actions.getDistribution.REQUEST.toString()),
  mergeMap(action => { actionApi()
    const { data, callback } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      url: urls.code.codeSource.getDistribution(data.id),
      // method: 'POST',
      // body:data
    }).pipe(
      map(res => {
        callback && callback(res.response)
        return actions.getDistribution.SUCCESS(res.response);
      }),
      catchError(error => of(actions.getDistribution.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
      )
    )}
  )
);



