import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap, map, catchError, concatMap } from 'rxjs/operators';
import { ajax } from '../../ajax';
import urls from '../../../api/urls';
import * as uiActions from '../ui';
import queryString from 'query-string';
import * as actions from './dataAllots';
import { ajaxBaseConfig, apiBaseConfig, actionApi } from '../../utils';

const getUrl = function (url, params) {
  const { deptId = '', isPage, word = '', page = '', size = '', status = '' } = params;
  url = `${url}?page=${page}&size=${size}&sort=id,desc`;
  if (word) {
    url += `&word=${word}`;
  }

  if (deptId) {
    url += `&deptId=${deptId}`;
  }

  if (isPage !== undefined) {
    url += `&isPage=${isPage}`;
  }

  if (status !== undefined) {
    url += `&status=${status}`;
  }
  return url;
}


// 码印刷规格分配查询(分页查询)
export const tagbatchAllotsEpic = actions$ => actions$.pipe(
  ofType(actions.tagbatchAllots.REQUEST.toString()),
  mergeMap(action => {
    actionApi();
    return ajax({
      ...apiBaseConfig,
      url: getUrl(`${urls.code.tagbatchConfigs.tagbatchConfigs}/allots`, action.payload),
    }).pipe(
      map(res => actions.tagbatchAllots.SUCCESS(Object.assign(res.response, { ...action.payload }))),
      catchError(error => of(actions.tagbatchAllots.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);

// 码印刷规格分配
export const tagbatchAllotsModifyEpic = actions$ => actions$.pipe(
  ofType(actions.tagbatchAllotsModify.REQUEST.toString()),
  mergeMap(action => {
    actionApi();
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.code.tagbatchConfigs.tagbatchConfigs}/allots`,
      body: action.payload,
      method: 'POST'
    }).pipe(
      mergeMap(res => [actions.tagbatchAllotsModify.SUCCESS(res.status), uiActions.notification('分配成功', 'success')]),
      catchError(error => of(actions.tagbatchAllotsModify.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  })
);
/****************************************************** */

// 码域名分配查询(分页查询)
export const domainAllotsEpic = actions$ => actions$.pipe(
  ofType(actions.domainAllots.REQUEST.toString()),
  mergeMap(action => {
    actionApi();
    return ajax({
      ...apiBaseConfig,
      url: getUrl(`${urls.code.domainConfigs.domainConfigsUrl}/allots`, action.payload),
    }).pipe(
      map(res => actions.domainAllots.SUCCESS(Object.assign(res.response, { ...action.payload }))),
      catchError(error => of(actions.domainAllots.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);

// 码域名分配
export const domainAllotsModifyEpic = actions$ => actions$.pipe(
  ofType(actions.domainAllotsModify.REQUEST.toString()),
  mergeMap(action => {
    actionApi();
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.code.domainConfigs.domainConfigsUrl}/allots`,
      body: action.payload,
      method: 'POST'
    }).pipe(
      mergeMap(res => [actions.domainAllotsModify.SUCCESS(res.status), uiActions.notification('分配成功', 'success')]),
      catchError(error => of(actions.domainAllotsModify.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  })
);
/****************************************************** */

// 产品分配查询(分页查询)
export const productAllotsEpic = actions$ => actions$.pipe(
  ofType(actions.productAllots.REQUEST.toString()),
  mergeMap(action => {
    actionApi();
    return ajax({
      ...apiBaseConfig,
      url: getUrl(`${urls.data.products.products}/allots`, action.payload),
    }).pipe(
      map(res => actions.productAllots.SUCCESS(Object.assign(res.response, { ...action.payload }))),
      catchError(error => of(actions.productAllots.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);

// 产品分配
export const productAllotsModifyEpic = actions$ => actions$.pipe(
  ofType(actions.productAllotsModify.REQUEST.toString()),
  mergeMap(action => {
    actionApi();
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.data.products.products}/allots`,
      body: action.payload,
      method: 'POST'
    }).pipe(
      mergeMap(res => [actions.productAllotsModify.SUCCESS(res.status), uiActions.notification('分配成功', 'success')]),
      catchError(error => of(actions.productAllotsModify.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  })
);
/****************************************************** */
// 工厂分配查询(分页查询)
export const factoryAllotsEpic = actions$ => actions$.pipe(
  ofType(actions.factoryAllots.REQUEST.toString()),
  mergeMap(action => {
    actionApi();
    return ajax({
      ...apiBaseConfig,
      url: getUrl(`${urls.data.factory.factories}/allots`, action.payload),
    }).pipe(
      map(res => actions.factoryAllots.SUCCESS(Object.assign(res.response, { ...action.payload }))),
      catchError(error => of(actions.factoryAllots.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);

// 工厂分配
export const factoryAllotsModifyEpic = actions$ => actions$.pipe(
  ofType(actions.factoryAllotsModify.REQUEST.toString()),
  mergeMap(action => {
    actionApi();
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.data.factory.factories}/allots`,
      body: action.payload,
      method: 'POST'
    }).pipe(
      mergeMap(res => [actions.factoryAllotsModify.SUCCESS(res.status), uiActions.notification('分配成功', 'success')]),
      catchError(error => of(actions.factoryAllotsModify.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  })
);

/****************************************************** */
// 数据字典分配查询(分页查询)
export const fieldsAllotsEpic = actions$ => actions$.pipe(
  ofType(actions.fieldsAllots.REQUEST.toString()),
  mergeMap(action => {
    actionApi();
    return ajax({
      ...apiBaseConfig,
      url: getUrl(`${urls.data.factory.factories}/allots`, action.payload),
    }).pipe(
      map(res => actions.fieldsAllots.SUCCESS(Object.assign(res.response, { ...action.payload }))),
      catchError(error => of(actions.fieldsAllots.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);

// 数据字典分配
export const fieldsAllotsModifyEpic = actions$ => actions$.pipe(
  ofType(actions.fieldsAllotsModify.REQUEST.toString()),
  mergeMap(action => {
    actionApi();
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.data.factory.factories}/allots`,
      body: action.payload,
      method: 'POST'
    }).pipe(
      mergeMap(res => [actions.fieldsAllotsModify.SUCCESS(res.status), uiActions.notification('分配成功', 'success')]),
      catchError(error => of(actions.fieldsAllotsModify.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  })
);

/****************************************************** */
// 经销商信息分配查询(分页查询)
export const dealerAllotsEpic = actions$ => actions$.pipe(
  ofType(actions.dealerAllots.REQUEST.toString()),
  mergeMap(action => {
    actionApi();
    return ajax({
      ...apiBaseConfig,
      url: getUrl(`${urls.data.dealers.dealers}/allots`, action.payload),
    }).pipe(
      map(res => actions.dealerAllots.SUCCESS(Object.assign(res.response, { ...action.payload }))),
      catchError(error => of(actions.dealerAllots.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);

// 经销商信息分配
export const dealerAllotsModifyEpic = actions$ => actions$.pipe(
  ofType(actions.dealerAllotsModify.REQUEST.toString()),
  mergeMap(action => {
    actionApi();
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.data.dealers.dealers}/allots`,
      body: action.payload,
      method: 'POST'
    }).pipe(
      mergeMap(res => [actions.dealerAllotsModify.SUCCESS(res.status), uiActions.notification('分配成功', 'success')]),
      catchError(error => of(actions.dealerAllotsModify.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  })
);
/****************************************************** */
// 门店信息分配查询(分页查询)
export const shopAllotsEpic = actions$ => actions$.pipe(
  ofType(actions.shopAllots.REQUEST.toString()),
  mergeMap(action => {
    actionApi();
    return ajax({
      ...apiBaseConfig,
      url: getUrl(`${urls.data.terminal.terminal}/v1/allots`, action.payload),
    }).pipe(
      map(res => actions.shopAllots.SUCCESS(Object.assign(res.response, { ...action.payload }))),
      catchError(error => of(actions.shopAllots.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);

// 门店信息分配
export const shopAllotsModifyEpic = actions$ => actions$.pipe(
  ofType(actions.shopAllotsModify.REQUEST.toString()),
  mergeMap(action => {
    actionApi();
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.data.terminal.terminal}/v1/allots`,
      body: action.payload,
      method: 'POST'
    }).pipe(
      mergeMap(res => [actions.shopAllotsModify.SUCCESS(res.status), uiActions.notification('分配成功', 'success')]),
      catchError(error => of(actions.shopAllotsModify.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  })
);
/****************************************************** */
// 环节字段分配查询(分页查询)
export const linksAllotsEpic = actions$ => actions$.pipe(
  ofType(actions.linksAllots.REQUEST.toString()),
  mergeMap(action => {
    actionApi();
    return ajax({
      ...apiBaseConfig,
      url: getUrl(`${urls.dataManage.links.links}/allots`, action.payload),
    }).pipe(
      map(res => actions.linksAllots.SUCCESS(Object.assign(res.response, { ...action.payload }))),
      catchError(error => of(actions.linksAllots.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);

// 环节字段分配
export const linksAllotsModifyEpic = actions$ => actions$.pipe(
  ofType(actions.linksAllotsModify.REQUEST.toString()),
  mergeMap(action => {
    actionApi();
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.dataManage.links.links}/allots`,
      body: action.payload,
      method: 'POST'
    }).pipe(
      mergeMap(res => [actions.linksAllotsModify.SUCCESS(res.status), uiActions.notification('分配成功', 'success')]),
      catchError(error => of(actions.linksAllotsModify.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  })
);
/****************************************************** */
// 微信商户号分配查询(分页查询)
export const wechatAllotsEpic = actions$ => actions$.pipe(
  ofType(actions.wechatAllots.REQUEST.toString()),
  mergeMap(action => {
    actionApi();
    return ajax({
      ...apiBaseConfig,
      url: getUrl(`${urls.wechat.merchant.merchant}/allots`, action.payload),
    }).pipe(
      map(res => actions.wechatAllots.SUCCESS(Object.assign(res.response, { ...action.payload }))),
      catchError(error => of(actions.wechatAllots.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);

//微信商户号分配
export const wechatAllotsModifyEpic = actions$ => actions$.pipe(
  ofType(actions.wechatAllotsModify.REQUEST.toString()),
  mergeMap(action => {
    actionApi();
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.wechat.merchant.merchant}/allots`,
      body: action.payload,
      method: 'POST'
    }).pipe(
      mergeMap(res => [actions.wechatAllotsModify.SUCCESS(res.status), uiActions.notification('分配成功', 'success')]),
      catchError(error => of(actions.wechatAllotsModify.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  })
);

/****************************************************** */
// 奖品配查询(分页查询)
export const prizeAllotsEpic = actions$ => actions$.pipe(
  ofType(actions.prizeAllots.REQUEST.toString()),
  mergeMap(action => {
    actionApi();
    return ajax({
      ...apiBaseConfig,
      url: getUrl(`${urls.promotion.prizes.prizes}/allots`, action.payload),
    }).pipe(
      map(res => actions.prizeAllots.SUCCESS(Object.assign(res.response, { ...action.payload }))),
      catchError(error => of(actions.prizeAllots.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);

// 奖品分配
export const prizeAllotsModifyEpic = actions$ => actions$.pipe(
  ofType(actions.prizeAllotsModify.REQUEST.toString()),
  mergeMap(action => {
    actionApi();
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.promotion.prizes.prizes}/allots`,
      body: action.payload,
      method: 'POST'
    }).pipe(
      mergeMap(res => [actions.prizeAllotsModify.SUCCESS(res.status), uiActions.notification('分配成功', 'success')]),
      catchError(error => of(actions.prizeAllotsModify.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  })
);

/****************************************************** */
// 奖品配查询(分页查询)2
export const prizePointAllotsEpic = actions$ => actions$.pipe(
  ofType(actions.prizePointAllots.REQUEST.toString()),
  mergeMap(action => {
    actionApi();
    return ajax({
      ...apiBaseConfig,
      url: getUrl(`${urls.promotion.prizePointTypes.prizePointTypes}/allots`, action.payload),
    }).pipe(
      map(res => actions.prizePointAllots.SUCCESS(Object.assign(res.response, { ...action.payload }))),
      catchError(error => of(actions.prizePointAllots.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);

// 奖品分配2
export const prizePointAllotsModifyEpic = actions$ => actions$.pipe(
  ofType(actions.prizePointAllotsModify.REQUEST.toString()),
  mergeMap(action => {
    actionApi();
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.promotion.prizePointTypes.prizePointTypes}/allots`,
      body: action.payload,
      method: 'POST'
    }).pipe(
      mergeMap(res => [actions.prizePointAllotsModify.SUCCESS(res.status), uiActions.notification('分配成功', 'success')]),
      catchError(error => of(actions.prizePointAllotsModify.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  })
);

/****************************************************** */
// 奖品配查询(分页查询)3
export const prizeCardAllotsEpic = actions$ => actions$.pipe(
  ofType(actions.prizeCardAllots.REQUEST.toString()),
  mergeMap(action => {
    actionApi();
    return ajax({
      ...apiBaseConfig,
      url: getUrl(`${urls.promotion.prizeCardTypes.prizeCardTypes}/allots`, action.payload),
    }).pipe(
      map(res => actions.prizeCardAllots.SUCCESS(Object.assign(res.response, { ...action.payload }))),
      catchError(error => of(actions.prizeCardAllots.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);

// 奖品分配3
export const prizeCardAllotsModifyEpic = actions$ => actions$.pipe(
  ofType(actions.prizeCardAllotsModify.REQUEST.toString()),
  mergeMap(action => {
    actionApi();
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.promotion.prizeCardTypes.prizeCardTypes}/allots`,
      body: action.payload,
      method: 'POST'
    }).pipe(
      mergeMap(res => [actions.prizeCardAllotsModify.SUCCESS(res.status), uiActions.notification('分配成功', 'success')]),
      catchError(error => of(actions.prizeCardAllotsModify.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  })
);



/****************************************************** */
// 奖池配查询(分页查询)
export const prizePoolAllotsEpic = actions$ => actions$.pipe(
  ofType(actions.prizePoolAllots.REQUEST.toString()),
  mergeMap(action => {
    actionApi();
    return ajax({
      ...apiBaseConfig,
      url: getUrl(`${urls.promotion.pools.pools}/allots`, action.payload),
    }).pipe(
      map(res => actions.prizePoolAllots.SUCCESS(Object.assign(res.response, { ...action.payload }))),
      catchError(error => of(actions.prizePoolAllots.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);

// 奖品分配
export const prizePoolAllotsModifyEpic = actions$ => actions$.pipe(
  ofType(actions.prizePoolAllotsModify.REQUEST.toString()),
  mergeMap(action => {
    actionApi();
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.promotion.pools.pools}/allots`,
      body: action.payload,
      method: 'POST'
    }).pipe(
      mergeMap(res => [actions.prizePoolAllotsModify.SUCCESS(res.status), uiActions.notification('分配成功', 'success')]),
      catchError(error => of(actions.prizePoolAllotsModify.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  })
);

/****************************************************** */
// 主数据分配查询(分页查询)
export const datasAllotsEpic = actions$ => actions$.pipe(
  ofType(actions.datasAllots.REQUEST.toString()),
  mergeMap(action => {
    actionApi();
    return ajax({
      ...apiBaseConfig,
      url: getUrl(urls.masterData.masterDataAllots, action.payload),
    }).pipe(
      map(res => actions.datasAllots.SUCCESS(Object.assign(res.response, { ...action.payload }))),
      catchError(error => of(actions.datasAllots.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);

// 主数据分配
export const datasAllotsModifyEpic = actions$ => actions$.pipe(
  ofType(actions.datasAllotsModify.REQUEST.toString()),
  mergeMap(action => {
    actionApi();
    return ajax({
      ...ajaxBaseConfig,
      url: urls.masterData.masterDataAllots,
      body: action.payload,
      method: 'POST'
    }).pipe(
      mergeMap(res => [actions.datasAllotsModify.SUCCESS(res.status), uiActions.notification('分配成功', 'success')]),
      catchError(error => of(actions.datasAllotsModify.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  })
);