import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap, map, catchError, concatMap } from 'rxjs/operators';
import { ajax } from '../../ajax';
import urls from '../../../api/urls';
import * as uiActions from '../ui';
import queryString from 'query-string';
import * as actions from './workOrder';
import { ajaxBaseConfig, apiBaseConfig, actionApi } from '../../utils';

// 数据源下拉
export const dataSourceListEpic = actions$ => actions$.pipe(
    ofType(actions.dataSourceList.REQUEST.toString()),
    mergeMap(action => {
        actionApi();
        let url = `${urls.data.workOrder.businessType}`;

        return ajax({
            ...apiBaseConfig,
            url,
        }).pipe(
            map(res => actions.dataSourceList.SUCCESS(res.response)),
            catchError(error => of(actions.dataSourceList.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    }
    )
);

export const businessFieldEpic = actions$ => actions$.pipe(
    ofType(actions.businessField.REQUEST.toString()),
    mergeMap(action => {
        actionApi();
        const { businessCode = '', callback } = action.payload;

        let url = `${urls.data.workOrder.businessField}?businessCode=${businessCode}`;

        return ajax({
            ...apiBaseConfig,
            url,
        }).pipe(
            map(res => {
                callback && callback(res.response);
                return actions.businessField.SUCCESS(res.response)
            }),
            catchError(error => {
                console.log(error)
                return of(actions.businessField.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
            })
        )
    }
    )
);

export const businessDimensionEpic = actions$ => actions$.pipe(
    ofType(actions.businessDimension.REQUEST.toString()),
    mergeMap(action => {
        actionApi();
        const { businessCode = '' } = action.payload;
        let url = `${urls.data.workOrder.businessDimension}?businessCode=${businessCode}`;

        return ajax({
            ...apiBaseConfig,
            url,
        }).pipe(
            map(res => actions.businessDimension.SUCCESS(res.response)),
            catchError(error => of(actions.businessDimension.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    }
    )
);

//提交工单
export const workEpic = actions$ => actions$.pipe(
    ofType(actions.work.REQUEST.toString()),
    mergeMap(action => {
        actionApi();
        // const { actionType = '' } = action.payload;
        const {callback} = action.payload;
        let url = `${urls.data.workOrder.work}`;
        let obj = {};
        for(var key in action.payload){
            if(key != 'callback'){
                obj[key] = action.payload[key]
            }
        }
        console.log(obj,action.payload,'22222')
        return ajax({
            ...ajaxBaseConfig,
            url,
            method: 'post',
            body: {
                ...obj
            }
        }).pipe(
            map(res => {
                // action.payload && action.payload.callback();
                callback && callback(res.response || []);
                console.log(res.response)
                return actions.work.SUCCESS(res.response)}),
            catchError(error =>{
                console.log(error.xhr.response.message)
                return of(actions.work.FAIL(error.xhr.response.message), uiActions.notification(error.xhr.response.message))
            } 
                
                )
        )
    }
    )
);

//工单管理数据统计
export const indexStatisticsEpic = actions$ => actions$.pipe(
    ofType(actions.indexStatistics.REQUEST.toString()),
    mergeMap(action => {
        actionApi();
        // const {status='', commitStartTime='',commitEndTime='',taskStartTime='',taskEndTime='',workName='',page=1,pageSize=10} = action.payload;
        let url = `${urls.data.workOrder.indexStatistics}`;
      
        return ajax({
            ...apiBaseConfig,
            url,
            method: 'get',
            body: {
                ...action.payload
            }
        }).pipe(
            map(res => actions.indexStatistics.SUCCESS(res.response.data)),
            catchError(error => of(actions.indexStatistics.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    }
    )
);

//工单管理列表
export const listEpic = actions$ => actions$.pipe(
    ofType(actions.list.REQUEST.toString()),
    mergeMap(action => {
        actionApi();
      
        const {status='', commitStartTime='',commitEndTime='',taskStartTime='',taskEndTime='',workName='',page=1,pageSize=20,sort='temp.createTime DESC'} = action.payload;
        let url = `${urls.data.workOrder.list}?status=${status}&commitStartTime=${commitStartTime}&commitEndTime=${commitEndTime}&taskStartTime=${taskStartTime}&taskEndTime=${taskEndTime}&workName=${workName}&page=${page}&pageSize=${pageSize}&sort=${sort}`;
      
        return ajax({
            ...apiBaseConfig,
            url,
            method: 'get',
            body: {
                ...action.payload
            }
        }).pipe(
            map(res => actions.list.SUCCESS(res.response.data)),
            catchError(error => of(actions.list.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    }
    )
);

//工单管理查看
export const detailEpic = actions$ => actions$.pipe(
    ofType(actions.detail.REQUEST.toString()),
    mergeMap(action => {
        actionApi();
      
        const {id=''} = action.payload;
        let url = `${urls.data.workOrder.detail}?id=${id}`;
      
        return ajax({
            ...apiBaseConfig,
            url,
            method: 'get',
            body: {
                ...action.payload
            }
        }).pipe(
            map(res => actions.detail.SUCCESS(res.response.data)),
            catchError(error => of(actions.detail.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    }
    )
);

//工单操作
export const actionEpic = actions$ => actions$.pipe(
    ofType(actions.action.REQUEST.toString()),
    mergeMap(action => {
        actionApi();
      
        const {data,callback} = action.payload;
        let emailVal=data.email?data.email:''
        
        let url = `${urls.data.workOrder.action}?action=${data.actionName}&id=${data.id}&emails=${emailVal}`;
      
        return ajax({
            ...apiBaseConfig,
            url,
            method: 'get',
            body: {
                ...action.payload
            }
        }).pipe(
            map(res =>{
                callback && callback(res.response || []);
                // console.log(res.response,'lllljjjj')
                return actions.action.SUCCESS(res.response)
            }),
            catchError(error => of(actions.action.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    }
    )
);

//工单管理结果
export const resultEpic = actions$ => actions$.pipe(
    ofType(actions.result.REQUEST.toString()),
    mergeMap(action => {
        actionApi();
      
        const {id='',statusId} = action.payload;
        let url = `${urls.data.workOrder.result}?statusId=${statusId}&id=${id}`;
      
        return ajax({
            ...apiBaseConfig,
            url,
            method: 'get',
            body: {
                ...action.payload
            }
        }).pipe(
            map(res => actions.result.SUCCESS(res.response.data)),
            catchError(error => of(actions.result.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    }
    )
);

//工单状态查看
export const orderStatusEpic = actions$ => actions$.pipe(
    ofType(actions.orderStatus.REQUEST.toString()),
    mergeMap(action => {
        actionApi();
      
        const {callback} = action.payload;
        
        let url = `${urls.data.workOrder.status}`;
      
        return ajax({
            ...apiBaseConfig,
            url,
            method: 'get',
            // body: {
            //     ...action.payload
            // }
        }).pipe(
            map(res =>{
                callback && callback(res.response || []);
                console.log(res.response,'lllljjjj')
                return actions.orderStatus.SUCCESS(res.response)
            }),
            catchError(error => of(actions.orderStatus.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    }
    )
);
// 草稿数据获取
export const draftEpic = actions$ => actions$.pipe(
    ofType(actions.draft.REQUEST.toString()),
    mergeMap(action => {
        actionApi();
        const {callback} = action.payload;
        // const {id='',statusId} = action.payload;
        let url = `${urls.data.workOrder.draft}`;
      
        return ajax({
            ...apiBaseConfig,
            url,
            method: 'get',
            // body: {
            //     ...action.payload
            // }
        }).pipe(
            map(res =>{
                callback && callback(res.response || []);
                console.log(res.response,'lllljjjj')
                return actions.draft.SUCCESS(res.response)
            }),
            catchError(error => of(actions.draft.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    }
    )
);