import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap, map, catchError, concatMap } from 'rxjs/operators';
import { ajax } from '../../ajax';
import urls from '@/api/urls';
import * as uiActions from '../ui';
import queryString from 'query-string';
import * as actions from './marketing';
import { ajaxBaseConfig, actionApi } from '../../utils';

export const loadListEpic = actions$ => actions$.pipe(
    ofType(actions.loadList.REQUEST.toString()),
    mergeMap(action => { actionApi()
        const {
            dataVersion = 'NORMAL',
            endDate = '',
            type: periodType,
            startDate,
            statisticsType = 'SCAN',
            dimension = '',
            ids,
            areaDataType = '',
            areaName = '',
            areaType = '',
            page = 0,
            size=20,
        } = action.payload;
        console.log(ids,'chanpin or active')
        let idsValue = ids.join(',');
        for(var i=0;i<ids.length;i++){//产品和活动含有全部选项的话，搜索全部数据；
              if(ids[i] === "") {
                idsValue = ''
              }  
          }
        const url = urls.statistics.marketing.list;
        return ajax({
            ...ajaxBaseConfig,
            url: `${url}?endDate=${endDate}&pageSize=${size}&page=${page+1}&dataVersion=${dataVersion}&periodType=${periodType}&startDate=${startDate}&statisticsType=${statisticsType}&dimension=${dimension}&ids=${idsValue}&areaDataType=${areaDataType}&areaName=${areaName}&areaType=${areaType}`
        }).pipe(
            map(res => actions.loadList.SUCCESS(res.response)),
            catchError(error => of(actions.loadList.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
);

// 营销统计异步下载
export const marketingDownEpic = actions$ => actions$.pipe(
    ofType(actions.marketingDown.REQUEST.toString()),
    mergeMap(action => {
      actionApi();
      const { callback } = action.payload;
      const { dataVersion = 'NORMAL',
        endDate = '',
        type: periodType,
        startDate,
        statisticsType = 'SCAN',
        dimension = '',
        ids,
        areaDataType = '',
        areaName = '',
        areaType = '',
        page = 0,
        size=20, } = action.payload.data;
  
        let idsValue = ids.join(',');
        for(var i=0;i<ids.length;i++){//产品和活动含有全部选项的话，搜索全部数据；
            if(ids[i] === "") {
            idsValue = ''
            }  
        }
  
      return ajax({
        ...ajaxBaseConfig,
        url: `${urls.statistics.marketing.download}??endDate=${endDate}&pageSize=${size}&page=${page+1}&dataVersion=${dataVersion}&periodType=${periodType}&startDate=${startDate}&statisticsType=${statisticsType}&dimension=${dimension}&ids=${idsValue}&areaDataType=${areaDataType}&areaName=${areaName}&areaType=${areaType}`
      }).pipe(
        map(res => {
          console.log(res)
            callback && callback()
            return actions.marketingDown.SUCCESS(res.response || []) 
        }),
        catchError(error => {
          console.log(error,'错误返回');
          of(actions.marketingDown.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
        })
      )
    }
    )
  );