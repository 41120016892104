import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap, map, catchError, concatMap } from 'rxjs/operators';
import { ajax } from '../../ajax';
import urls from '../../../api/urls';
import * as uiActions from '../ui';
import queryString from 'query-string';
import * as actions from './channel';
import { ajaxBaseConfig, apiBaseConfig, actionApi } from '../../utils';

/**
 * TODO: 字段分配
 */

// 列表
export const channelListEpic = actions$ => actions$.pipe(
  ofType(actions.channelList.REQUEST.toString()), 
  mergeMap(action => {
      actionApi();
      const { page, size,name="",code=""} = action.payload;
      return ajax({
          ...ajaxBaseConfig,
          url: `${urls.data.channel.dict}?page=${page}&size=${size}&name=${name}&code=${code}`,
      }).pipe(
          map(res => actions.channelList.SUCCESS(res.response)),
          catchError(error => of(actions.channelList.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
  }
  )
);

// 新增 修改
export const channelUpdateEpic = actions$ => actions$.pipe(
    ofType(actions.channelUpdate.REQUEST.toString()), 
    mergeMap(action => {
        actionApi();
        console.log('触发')
        const {name="",code="",id='',parentId,method='POST',version=''} = action.payload;
        console.log(method)
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.data.channel.dict}`,
            method:method,
            body:{
                name,
                code,
                id,
                parentId,
                version
            }
        }).pipe(
            map(res => actions.channelList.REQUEST({page:0,size:20})),
            catchError(error =>{console.log(error); return of(actions.channelUpdate.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))})
        )
    }
    )
  );

  // 修改状态
export const channelStatusEpic = actions$ => actions$.pipe(
    ofType(actions.channelStatus.REQUEST.toString()), 
    mergeMap(action => {
        actionApi();
        const {id='',cstatus} = action.payload;
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.data.channel.dict}/${id}/status?status=${cstatus}`,
            method:'PATCH'
        }).pipe(
            map(res => actions.channelList.REQUEST({page:0,size:20})),
            catchError(error =>{return of(actions.channelStatus.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))})
        )
    }
    )
  );
// 下拉
export const channelSelectionEpic = actions$ => actions$.pipe(
    ofType(actions.channelSelection.REQUEST.toString()), 
    mergeMap(action => {
        actionApi();
        const {status} = action.payload;
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.data.channel.dict}/selections?status=${status}`
        }).pipe(
            map(res => actions.channelSelection.SUCCESS(res.response)),
            catchError(error => of(actions.channelSelection.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    }
    )
  );
  