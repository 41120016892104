import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap, map, catchError, concatMap } from 'rxjs/operators';
import { ajax } from '../../ajax';
import urls from '../../../api/urls';
import * as uiActions from '../ui';
import * as actions from './products';
import { ajaxBaseConfig, actionApi } from '../../utils';
import qs from 'query-string';

// Epics
export const loadListEpic = actions$ => actions$.pipe(
  ofType(actions.loadList.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    let url = `${urls.data.products.products}/allots`;
    if (action.payload && action.payload.page) {
      url += `?isPage=${action.payload.page}`;
    }
    else {
      url += `?isPage=false`;
    }

    if (action.payload && action.payload.status) {
      url += `&status=${action.payload.status}`;
    }
    if (action.payload && action.payload.size) {
      url += `&size=${action.payload.size}`;
    }

    if (action.payload && action.payload.word) {
      url += `&word=${action.payload.word}`;
    }



    return ajax({
      ...ajaxBaseConfig,
      url
      //  url: action.payload && action.payload.status ? `${urls.data.products.selections}?status=${action.payload.status}` : `${urls.data.products.selections}`
    }).pipe(
      map(res => {
        action.payload?.callback && action.payload.callback(res.response);
        return actions.loadList.SUCCESS({ ...res.response, type: action.payload?.type || '' })
      }),
      catchError(error => of(actions.loadList.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
      )
    )
  }
  )
)

// 根据id 查产品列表
export const loadListByIdEpic = actions$ => actions$.pipe(
  ofType(actions.loadListById.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.data.products.products}/ids?ids=${action.payload}`,
    }).pipe(
      map(res => actions.loadListById.SUCCESS(res.response)),
      catchError(error => of(actions.loadListById.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
      )
    )
  }
  )
)


// select
// export const productsEpic = actions$ => actions$.pipe(
//   ofType(actions.products.REQUEST.toString()),
//   mergeMap(action =>
//     ajax({
//       ...ajaxBaseConfig,
//       url: `${urls.data.products.products}`
//     }).pipe(
//       map(res => actions.products.SUCCESS(res.response)),
//       catchError(error => of(actions.products.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
//       )
//     )
//   )
// )
// 分页查询
export const loadProductPageListEpic = (actions$, state$) => actions$.pipe(
  ofType(actions.loadProductPageList.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { productPageParam = {}, productSearchKey = '' } = state$.value.products;
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.data.products.products}?page=${productPageParam.page}&size=${productPageParam.size}&sort=status,createdTime,${productPageParam.newOrder || 'desc'}&brandIds=${productPageParam.brandId}&word=${productSearchKey}`
    }).pipe(
      map(res => actions.loadProductPageList.SUCCESS(res.response)),
      catchError(error => of(actions.loadProductPageList.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
      )
    )
  }
  )
)

// 启用，禁用产品
export const enabledOrDisabledProductsEpic = (actions$, state$) => actions$.pipe(
  ofType(actions.enabledOrDisabledProducts.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.data.products.products}/${action.payload.id}/status?status=${action.payload.status}`,
      method: 'PATCH',
      body: action.payload,
    }).pipe(
      concatMap(res => [actions.enabledOrDisabledProducts.SUCCESS(res.response), actions.loadProductPageList.REQUEST()]),
      catchError(error => of(actions.enabledOrDisabledProducts.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
      )
    )
  }
  )
);
// 创建产品
export const createProductsEpic = (actions$, state$) => actions$.pipe(
  ofType(actions.createProducts.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.data.products.products}`,
      method: 'POST',
      body: action.payload.data,
      // url: `${urls.data.products.products}/${action.payload.id}/${action.payload.status}`
    }).pipe(
      concatMap(res => {
        action.payload.callback && action.payload.callback(res);
        return [actions.createProducts.SUCCESS(res.response), actions.loadProductPageList.REQUEST({ productPageParam: { ...state$.value.products.productPageParam, page: 0 } })]
      }),
      catchError(error => of(actions.createProducts.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
      )
    )
  }
  )
);
// 编辑产品
export const editProductsEpic = (actions$, state$) => actions$.pipe(
  ofType(actions.editProducts.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.data.products.products}`,
      method: 'PUT',
      body: action.payload.data,
    }).pipe(
      concatMap(res => {
        action.payload.callback && action.payload.callback(res);
        return [actions.editProducts.SUCCESS(res.response), actions.loadProductPageList.REQUEST()]
      }),
      catchError(error => of(actions.editProducts.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
      )
    )
  }
  )
);
// 根据id查询产品
export const loadProductByIdEpic = (actions$, state$) => actions$.pipe(
  ofType(actions.loadProductById.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { id, callback } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.data.products.products}/${id}`,
    }).pipe(
      map(res => {
        callback && callback(res.response);
        return actions.loadProductById.SUCCESS(res.response);
      }),
      catchError(error => of(actions.loadProductById.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
      )
    )
  }
  )
);
// 导出产品
export const exportProductEpic = (actions$, state$) => actions$.pipe(
  ofType(actions.exportProduct.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { productPageParam = {}, productSearchKey = '' } = state$.value.products;
    return ajax({
      ...ajaxBaseConfig,
      method: 'POST',
      body: {
        brandIds: productPageParam.brandId?.length ? productPageParam.brandId.split(',') : [],
        word: productSearchKey,
      },
      url: `${urls.data.products.products}/exports`,
    }).pipe(
      concatMap(res => {

        action.payload.callback && action.payload.callback(res);
        return [actions.exportProduct.SUCCESS(res.response)]
      }),

      catchError(error => {return of(actions.exportProduct.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))})
    )
  }
  )
);


export const editProductPackEpic = (actions$, state$) => actions$.pipe(
  ofType(actions.editProductPack.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { data, callback } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.data.products.products}/${data.id}/pack`,
      method: 'POST',
       body: data.pack,
    }).pipe(
      concatMap(res => {
        callback && callback(res);
        return [actions.editProductPack.SUCCESS(res.response), actions.loadProductPageList.REQUEST()]
      }),
      catchError(error => of(actions.editProductPack.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
      )
    )
  }
  )
);

export const getCategoryListEpic = actions$ =>
  actions$.pipe(
    ofType(actions.categoryList.REQUEST.toString()),
    mergeMap(action => {
      actionApi()
      const { callback, onError, params = {} } = action.payload
      const queryStr = qs.stringify(params, { encode: false })  
      return ajax({
        ...ajaxBaseConfig,
        url: `${urls.data.dataGroup}?${queryStr}`,
      }).pipe(
        map(res => {
          callback?.(res.response)
          return actions.categoryList.SUCCESS(res.response)
        }),
        catchError(error => {
          onError?.(error.xhr.response)
          return of(
            actions.categoryList.FAIL(error.xhr.response),
            uiActions.notification(error.xhr.response)
          )
        })
      )
    })
  )
  
export const createCategoryEpic = actions$ =>
  actions$.pipe(
    ofType(actions.createCategory.REQUEST.toString()),
    mergeMap(action => {
      actionApi()
      const { callback, onError, body = {} } = action.payload
      return ajax({
        ...ajaxBaseConfig,
        url: `${urls.data.dataGroup}`,
        method: 'POST',
        body
      }).pipe(
        map(res => {
          callback?.(res.response)
          return actions.createCategory.SUCCESS(res.response)
        }),
        catchError(error => {
          onError?.(error.xhr.response)
          return of(
            actions.createCategory.FAIL(error.xhr.response),
            uiActions.notification(error.xhr.response)
          )
        })
      )
    })
  )

export const editCategoryEpic = actions$ =>
  actions$.pipe(
    ofType(actions.editCategory.REQUEST.toString()),
    mergeMap(action => {
      actionApi()
      const { callback, onError, body = {} } = action.payload
      return ajax({
        ...ajaxBaseConfig,
        url: `${urls.data.dataGroup}`,
        method: 'PUT',
        body
      }).pipe(
        map(res => {
          callback?.(res.response)
          return actions.editCategory.SUCCESS(res.response)
        }),
        catchError(error => {
          onError?.(error.xhr.response)
          return of(
            actions.editCategory.FAIL(error.xhr.response),
            uiActions.notification(error.xhr.response)
          )
        })
      )
    })
  )

export const categoryDetailEpic = actions$ =>
  actions$.pipe(
    ofType(actions.categoryDetail.REQUEST.toString()),
    mergeMap(action => {
      actionApi()
      const { callback, onError, id } = action.payload
      return ajax({
        ...ajaxBaseConfig,
        url: `${urls.data.dataGroup}/${id}/detail`
      }).pipe(
        map(res => {
          callback?.(res.response)
          return actions.categoryDetail.SUCCESS(res.response)
        }),
        catchError(error => {
          onError?.(error.xhr.response)
          return of(
            actions.categoryDetail.FAIL(error.xhr.response),
            uiActions.notification(error.xhr.response)
          )
        })
      )
    })
  )

export const categoryHistoryListEpic = actions$ =>
  actions$.pipe(
    ofType(actions.categoryHistoryList.REQUEST.toString()),
    mergeMap(action => {
      actionApi()
      const { callback, onError, params = {} } = action.payload
      const queryStr = qs.stringify(params, { encode: false })  
      return ajax({
        ...ajaxBaseConfig,
        url: `${urls.data.dataGroup}/history?${queryStr}`
      }).pipe(
        map(res => {
          callback?.(res.response)
          return actions.categoryHistoryList.SUCCESS(res.response)
        }),
        catchError(error => {
          onError?.(error.xhr.response)
          return of(
            actions.categoryHistoryList.FAIL(error.xhr.response),
            uiActions.notification(error.xhr.response)
          )
        })
      )
    })
  )

export const categoryHistoryDetailListEpic = actions$ =>
  actions$.pipe(
    ofType(actions.categoryHistoryDetail.REQUEST.toString()),
    mergeMap(action => {
      actionApi()
      const { callback, onError, params = {} } = action.payload
      const queryStr = qs.stringify(params, { encode: false })  
      return ajax({
        ...ajaxBaseConfig,
        url: `${urls.data.dataGroup}/history/detail?${queryStr}`
      }).pipe(
        map(res => {
          callback?.(res.response)
          return actions.categoryHistoryDetail.SUCCESS(res.response)
        }),
        catchError(error => {
          onError?.(error.xhr.response)
          return of(
            actions.categoryHistoryDetail.FAIL(error.xhr.response),
            uiActions.notification(error.xhr.response)
          )
        })
      )
    })
  )

