import { createAction, createActions, handleActions, combineActions } from 'redux-actions';
import { fasterActions } from '../../utils';
import urls from '@/api/urls';

const moduleName = 'scancodeStatistics';

/**
 * TODO: 
 */

export const statisticsList = fasterActions(moduleName, 'statisticsList');
export const changeDictPaging = createAction(`${moduleName}/changeDictPaging`);
export const fixedDownload = fasterActions(moduleName, 'fixedDownload');
export const selectLoad = fasterActions(moduleName, 'selectLoad');
export const statisticSum = fasterActions(moduleName, 'statisticSum');

const initialState = {
    pageData:[],
    pageParams:{
      page:0,
      size:20,
      startTime:'',
      endTime:''
    },
    sumData:{},
    downloadData:[],
    loading:false,
}

const reducer = handleActions(
  { 
    [changeDictPaging]: (state, { payload }) => {
        return { ...state, pageParams: { ...payload} }
    },

    [statisticsList.REQUEST]: (state, { payload }) => ({ ...state, pageData: {}, loading: true }),
    [statisticsList.SUCCESS]: (state, { payload }) => ({ ...state, pageData: payload, loading: false }),
    [statisticsList.FAIL]: (state, { payload }) => ({ ...state, loading: false }),

    [statisticSum.REQUEST]: (state, { payload }) => ({ ...state, sumData: {}, loading: true }),
    [statisticSum.SUCCESS]: (state, { payload }) => ({ ...state, sumData: payload, loading: false }),
    [statisticSum.FAIL]: (state, { payload }) => ({ ...state, loading: false }),

    [fixedDownload.REQUEST]: (state, { payload }) => ({ ...state, downloadData: [], }),
    [fixedDownload.SUCCESS]: (state, { payload }) => ({ ...state, downloadData: payload }),
    [fixedDownload.FAIL]: (state, { payload }) => ({ ...state }),

    [selectLoad.REQUEST]: (state, { payload }) => ({ ...state }),
    [selectLoad.SUCCESS]: (state, { payload }) => ({ ...state}),
    [selectLoad.FAIL]: (state, { payload }) => ({ ...state }),
  },
  initialState
);

export default reducer;
