import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { PageLoading } from 'tyb';
import UcodeLogin from './Ucode/UcodeLogin'


class NoPermission extends React.Component {

  componentDidMount() {
    // this.props.history.replace('/promotionnormal')
  }


  render() {
    return (
      <UcodeLogin hideButton={true} />
    )
  }
}

export default NoPermission;