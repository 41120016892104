import { createAction, handleActions } from 'redux-actions';
import { fasterActions } from '../../utils';

const moduleName = 'codeLabel';


// 自定义标签
export const getUserDefinedLabelData = fasterActions(moduleName, 'getUserDefinedLabelData');
export const enableUserDefinedLabel = fasterActions(moduleName, 'enableUserDefinedLabel');
export const deleteGroupUserDefinedLabel = fasterActions(moduleName, 'deleteGroupUserDefinedLabel');
export const getbindGroupUserDefinedLabel = fasterActions(moduleName, 'getbindGroupUserDefinedLabel');
export const bindGroupUserDefinedLabel = fasterActions(moduleName, 'bindGroupUserDefinedLabel');
export const pushGroupUserDefinedLabel = fasterActions(moduleName, 'pushGroupUserDefinedLabel');
export const pushrecordGroupUserDefinedLabel = fasterActions(moduleName, 'pushrecordGroupUserDefinedLabel');

//推送设置的部分数据
export const getPlatforms = fasterActions(moduleName, 'getPlatforms');

// upstate
export const updateUserDefinedStep = createAction(`${moduleName}/updateUserDefinedStep`);


export const postUserDefinedLabel = fasterActions(moduleName, 'postUserDefinedLabel');
export const getUserDefinedDetail = fasterActions(moduleName, 'getUserDefinedDetail');


export const patchUserDefinedLabel = fasterActions(moduleName, 'patchUserDefinedLabel');
export const patchUserDefinedLabelStep2 = fasterActions(moduleName, 'patchUserDefinedLabelStep2');
export const patchUserDefinedLabelStep3 = fasterActions(moduleName, 'patchUserDefinedLabelStep3');

//新码标签下拉框(树) 用于营销
export const loadCodeLabelSelect = fasterActions(moduleName, 'loadCodeLabelSelect');


const initialState = {
  userDefinedLabelData: [],
  userDefinedDetail: {},
  userDefinedStep: 0,
}



const reducer = handleActions(
  {
    // 获取列表
    [getUserDefinedLabelData.REQUEST]: (state, { payload }) => ({ ...state, ...payload }),
    [getUserDefinedLabelData.SUCCESS]: (state, { payload }) => ({ ...state, userDefinedLabelData: payload.content || [] }),
    [getUserDefinedLabelData.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

    // 禁用，启用
    [enableUserDefinedLabel.REQUEST]: (state, { payload }) => ({ ...state, ...payload }),
    [enableUserDefinedLabel.SUCCESS]: (state, { payload }) => ({ ...state }),
    [enableUserDefinedLabel.FAIL]: (state, { payload }) => ({ ...state, error: payload }),
    // 删除标签组
    [deleteGroupUserDefinedLabel.REQUEST]: (state, { payload }) => ({ ...state, ...payload }),
    [deleteGroupUserDefinedLabel.SUCCESS]: (state, { payload }) => ({ ...state }),
    [deleteGroupUserDefinedLabel.FAIL]: (state, { payload }) => ({ ...state, error: payload }),
    //推送设置
    [getbindGroupUserDefinedLabel.REQUEST]: (state, { payload }) => ({ ...state, ...payload }),
    [getbindGroupUserDefinedLabel.SUCCESS]: (state, { payload }) => ({ ...state, bindGroupUserDefinedLabelList: payload.content || [] }),
    [getbindGroupUserDefinedLabel.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

    [bindGroupUserDefinedLabel.REQUEST]: (state, { payload }) => ({ ...state, ...payload }),
    [bindGroupUserDefinedLabel.SUCCESS]: (state, { payload }) => ({ ...state }),
    [bindGroupUserDefinedLabel.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

    [getPlatforms.REQUEST]: (state, { payload }) => ({ ...state, ...payload }),
    [getPlatforms.SUCCESS]: (state, { payload }) => ({ ...state, platforms: payload.content || [] }),
    [getPlatforms.FAIL]: (state, { payload }) => ({ ...state, error: payload }),
    //推送标签
    [pushGroupUserDefinedLabel.REQUEST]: (state, { payload }) => ({ ...state, ...payload , loadpushGroupUserDefinedLabel: true}),
    [pushGroupUserDefinedLabel.SUCCESS]: (state, { payload }) => ({ ...state ,loadpushGroupUserDefinedLabel: false}),
    [pushGroupUserDefinedLabel.FAIL]: (state, { payload }) => ({ ...state, error: payload ,loadpushGroupUserDefinedLabel: false}),

    //推送历史
    [pushrecordGroupUserDefinedLabel.REQUEST]: (state, { payload }) => ({ ...state, ...payload, loadpushrecordGroupUserDefinedLabel: true }),
    [pushrecordGroupUserDefinedLabel.SUCCESS]: (state, { payload }) => ({ ...state, loadpushrecordGroupUserDefinedLabel: false, pushrecordGroupUserDefinedLabelList: payload  }),
    [pushrecordGroupUserDefinedLabel.FAIL]: (state, { payload }) => ({ ...state, error: payload, loadpushrecordGroupUserDefinedLabel: false,}),




    //翻页
    [updateUserDefinedStep]: (state, { payload }) => ({ ...state, ...payload }),

    // 创建
    [postUserDefinedLabel.REQUEST]: (state, { payload }) => ({ ...state, ...payload, changeUserDefinedLoading: true }),
    [postUserDefinedLabel.SUCCESS]: (state, { payload }) => ({ ...state, changeUserDefinedLoading: false }),
    [postUserDefinedLabel.FAIL]: (state, { payload }) => ({ ...state, changeUserDefinedLoading: true, error: payload }),

    // 获取详情
    [getUserDefinedDetail.REQUEST]: (state, { payload }) => ({ ...state, ...payload, userDefinedDetailLoading: true }),
    [getUserDefinedDetail.SUCCESS]: (state, { payload }) => ({ ...state, userDefinedDetail: payload || {}, userDefinedDetailLoading: false }),
    [getUserDefinedDetail.FAIL]: (state, { payload }) => ({ ...state, error: payload, userDefinedDetailLoading: false }),
    // 修改标签组
    [patchUserDefinedLabel.REQUEST]: (state, { payload }) => ({ ...state, changeUserDefinedLoading: true, ...payload }),
    [patchUserDefinedLabel.SUCCESS]: (state, { payload }) => ({ ...state, changeUserDefinedLoading: false }),
    [patchUserDefinedLabel.FAIL]: (state, { payload }) => ({ ...state, changeUserDefinedLoading: true, error: payload }),


    [patchUserDefinedLabelStep2.REQUEST]: (state, { payload }) => ({ ...state, changeUserDefinedLoading3: true, ...payload }),
    [patchUserDefinedLabelStep2.SUCCESS]: (state, { payload }) => ({ ...state, changeUserDefinedLoading3: false }),
    [patchUserDefinedLabelStep2.FAIL]: (state, { payload }) => ({ ...state, changeUserDefinedLoading3: true, error: payload }),


    [patchUserDefinedLabelStep3.REQUEST]: (state, { payload }) => ({ ...state, changeUserDefinedLoading3: true, ...payload }),
    [patchUserDefinedLabelStep3.SUCCESS]: (state, { payload }) => ({ ...state, changeUserDefinedLoading3: false }),
    [patchUserDefinedLabelStep3.FAIL]: (state, { payload }) => ({ ...state, changeUserDefinedLoading3: true, error: payload }),

 
  },
  initialState
)

export default reducer;