import { handleActions, createAction } from 'redux-actions';
import { fasterActions } from '../../utils';

const moduleName = 'codePrintingConfig';

export const loadCodePrintingConfigList = fasterActions(moduleName, 'loadCodePrintingConfigList');
export const loadCodeParamsDetail = fasterActions(moduleName, 'loadCodeParamsDetail');
export const loadTagMaterialsList = fasterActions(moduleName, 'loadTagMaterialsList');
export const loadTemplateConfigsList = fasterActions(moduleName, 'loadTemplateConfigsList');
export const addConfig = fasterActions(moduleName, 'addConfig');
export const editConfig = fasterActions(moduleName, 'editConfig');
export const loadCodePrintingConfigById = fasterActions(moduleName, 'loadCodePrintingConfigById');

// 核销码配置模板列表
export const cancelverifyCodeTemplateList = fasterActions(moduleName, 'cancelverifyCodeTemplateList');
// 创建编辑核销码规格
export const submitCancelverifyCodeTemplateList = fasterActions(moduleName, 'submitCancelverifyCodeTemplateList');
// 初始化创建编辑核销码规格状态
export const initSubmitCancelverifyCodeTemplateList = createAction(`${moduleName}/initSubmitCancelverifyCodeTemplateList`);

export const loadSelect = fasterActions(moduleName, 'loadSelect');

const SET_CURRENTSTEP = `${moduleName}/SET_CURRENTSTEP`;
const SET_CREATESTEP = `${moduleName}/SET_CREATESTEP`;

const SET_EDITSTEP = `${moduleName}/SET_EDITSTEP`;

export function setCurrentStep(step) {
  return { type: SET_CURRENTSTEP, payload: step };
}

export function setCreateStep(params) {
  return { type: SET_CREATESTEP, payload: params };
}

export function setEditStep(params) {
  return { type: SET_EDITSTEP, payload: params };
}



const initialState = {
  currentStep: 0,
  templateConfigsById: {},
  selector:[],
  createStep0: {
    combinationTagType: 'SAME_LEVEL_TAG', 
    conditionKeys: '2' ,
    batchs:'1',
    multiCodeAsc:'-1',
    // multiCodeAsc:false,
    tagInitStatus: "defalut"
  },
  createStep1: {
    passwordComplexity: '',
    printFileFormat: 'CSV',
    registrationMethod: 'ONLINE_INPUT',
    jumpPath: '/pages/scan/scan'
  },
  createStep2: {
    configWorkflowTemplateDTOs: [{
      workflowNodes: [
        {
          nodeCategory: 'USER',
          nodeType: 'PROCESS_START',
        },
        {
          nodeCategory: 'USER',
          nodeType: 'PROCESS_AUDIT',
        },
        {
          nodeCategory: 'USER',
          nodeType: 'PACKET_RECEIVER',
        },
        {
          nodeCategory: 'USER',
          nodeType: 'PASSWORD_RECEIVER',
        },
      ]
    }]
  },
  createStep3: {
    genNoactiveWntime: 720,
    regNoactiveWntime: 15,
    downloadNoactiveWntime: 72,
    ipcNoDownloadWntime:2,
  },
  codePrintingConfigById: {},
}

const reducer = handleActions(
  {
    [loadCodePrintingConfigList.REQUEST]: (state, { payload }) => ({ ...state, loadCodePrintingConfigListLoading: true }),
    [loadCodePrintingConfigList.SUCCESS]: (state, { payload }) => ({ ...state, loadCodePrintingConfigListLoading: false, loadCodePrintingConfigListLoaded: true, codePrintingConfigList: payload }),
    [loadCodePrintingConfigList.FAIL]: (state, { payload }) => ({ ...state, loadCodePrintingConfigListLoading: false, loadCodePrintingConfigListLoaded: false, error: payload }),

    [loadCodeParamsDetail.REQUEST]: (state, { payload }) => ({ ...state, loadCodeParamsDetailLoading: true }),
    [loadCodeParamsDetail.SUCCESS]: (state, { payload }) => ({ ...state, loadCodeParamsDetailLoading: false, loadCodeParamsDetailLoaded: true, codeParamsDetail: payload }),
    [loadCodeParamsDetail.FAIL]: (state, { payload }) => ({ ...state, loadCodeParamsDetailLoading: false, loadCodeParamsDetailLoaded: false, error: payload }),

    [loadTagMaterialsList.REQUEST]: (state, { payload }) => ({ ...state, loadTagMaterialsListLoading: true }),
    [loadTagMaterialsList.SUCCESS]: (state, { payload }) => ({ ...state, loadTagMaterialsListLoading: false, loadTagMaterialsListLoaded: true, tagMaterialsList: payload }),
    [loadTagMaterialsList.FAIL]: (state, { payload }) => ({ ...state, loadTagMaterialsListLoading: false, loadTagMaterialsListLoaded: false, error: payload }),

    [loadTemplateConfigsList.REQUEST]: (state, { payload }) => ({ ...state, loadTemplateConfigsListLoading: true }),
    [loadTemplateConfigsList.SUCCESS]: (state, { payload }) => {
      const content = payload.content;
      let templateConfigsById = {};
      let templateConfigsObById = {};
      const selectTemplate = content.map(v => {
        const templateConfigs = v.templateConfigList.map(v => {
          return {
            text: v.printType,
            value: v.id,
            version: v.version,
            encryption:v.encryption
          }
        })
        templateConfigsById[v.id] = templateConfigs;
        templateConfigsObById[v.id] = v.templateConfigList;
        return {
          text: v.name,
          value: v.id,
          encryption:v.encryption
        }
        
      });
     
      return { ...state, loadTemplateConfigsListLoading: false, loadTemplateConfigsListLoaded: true, templateConfigsList: content, selectTemplate, templateConfigsById, templateConfigsObById }
    },
    [loadTemplateConfigsList.FAIL]: (state, { payload }) => ({ ...state, loadTemplateConfigsListLoading: false, loadTemplateConfigsListLoaded: false, error: payload }),

    [SET_CREATESTEP]: (state, { payload }) => {
      const result = {
        ...state
      }
      result[`createStep${payload.step}`] = payload.values;
      return result;
    },

    [SET_CURRENTSTEP]: (state, { payload }) => ({
      ...state, currentStep: payload
    }),

    [SET_EDITSTEP]: (state, { payload }) => {
      const result = {
        ...state
      }
      result[`editStep${payload.step}`] = payload.values;
      return result;
    },


    [addConfig.REQUEST]: (state, { payload }) => ({ ...state, addConfigLoading: true }),
    [addConfig.SUCCESS]: (state, { payload }) => ({ ...state, addConfigLoading: false, addConfigLoaded: true, addConfig: payload }),
    [addConfig.FAIL]: (state, { payload }) => ({ ...state, addConfigLoading: false, addConfigLoaded: false, error: payload }),


    [editConfig.REQUEST]: (state, { payload }) => ({ ...state, editConfigLoading: true }),
    [editConfig.SUCCESS]: (state, { payload }) => ({ ...state, editConfigLoading: false, editConfigLoaded: true, editConfig: payload }),
    [editConfig.FAIL]: (state, { payload }) => ({ ...state, editConfigLoading: false, editConfigLoaded: false, error: payload }),

    [loadCodePrintingConfigById.REQUEST]: (state, { payload }) => ({ ...state, loadCodePrintingConfigByIdLoading: true }),
    [loadCodePrintingConfigById.SUCCESS]: (state, { payload }) => ({ ...state, loadCodePrintingConfigByIdLoading: false, codePrintingConfigById: payload }),
    [loadCodePrintingConfigById.FAIL]: (state, { payload }) => ({ ...state, loadCodePrintingConfigByIdLoading: false, error: payload }),

    [loadSelect.REQUEST]: (state, { payload }) => ({ ...state, }),
    [loadSelect.SUCCESS]: (state, { payload }) => ({ ...state,  selector: payload }),
    [loadSelect.FAIL]: (state, { payload }) => ({ ...state, error: payload }),
    
    // 核销码配置模板列表
    [cancelverifyCodeTemplateList.REQUEST]: (state, { payload }) => ({ ...state, payload }),
    [cancelverifyCodeTemplateList.SUCCESS]: (state, { payload }) => {
      let select = [];
      if (payload) {
        payload.content.forEach(val => {
          select.push({
            text: val.name, value: val.id
          })
        });
      } else {
        select = [];
      }
      return ({ ...state, getCancelverifyCodeTemplateList: payload, cancelverifyCodeTemplateListSelect: select });
    },
    [cancelverifyCodeTemplateList.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

    // 创建编辑核销码规格
    [submitCancelverifyCodeTemplateList.REQUEST]: (state, { payload }) => ({ ...state, payload, submitCancelverifyCodeStatus: 'LOADING' }),
    [submitCancelverifyCodeTemplateList.SUCCESS]: (state, { payload }) => ({ ...state, payload, submitCancelverifyCodeStatus: 'SUCCESS' }),
    [submitCancelverifyCodeTemplateList.FAIL]: (state, { payload }) => ({ ...state, error: payload, submitCancelverifyCodeStatus: 'FAIL' }),

    [initSubmitCancelverifyCodeTemplateList]: (state, {payload})=>({ ...state, submitCancelverifyCodeStatus: '' }),

  },
  initialState
)



export default reducer;