import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap, map, catchError, concatMap } from 'rxjs/operators';
import { ajax } from '../../ajax';
import urls from '@/api/urls';
import * as uiActions from '../ui';
import queryString from 'query-string';
import * as actions from './security';
import { ajaxBaseConfig, actionApi } from '../../utils';
import { trims } from 'ucode-utils';


// 防伪明细新加
// 防伪下拉
export const secActivitySelectorEpic = actions$ => actions$.pipe(
  ofType(actions.secActivitySelector.REQUEST.toString()),
  mergeMap(action => {
    actionApi();
    const { dataVersion = '', type = '' } = action.payload;
    // let factoryIdsValue = [factoryIds.value] || [];
    // let deptIdsValue = [deptId.value] || [];
    // let productIdsValue = productIds || [];

    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.promotion.security.secActivity}?dataVersion=${dataVersion}&type=${type}`
    }).pipe(
      map(res => actions.secActivitySelector.SUCCESS(res.response)),
      catchError(error => of(actions.secActivitySelector.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);

// 防伪列表
export const securityDetailtivitySelectorEpic = actions$ => actions$.pipe(
  ofType(actions.securityDetail.REQUEST.toString()),
  mergeMap(action => {
    actionApi();
    const { page = 0, size = 20, startTime = '', endTime = '', securityPromotionId = '', securityPlanIds = '', dealerIds = [], dealerName = '', shopIds = [], productIds = [], salesChannelIds = [], seqStatus = [], seqConfIds = [], eseSeqNum = [], provinceCode = [], cityCode = [], securityLevel = '' } = action.payload;

    let productIdsValue = productIds || [];
    let shopIdsValue = shopIds || [];
    let dealerIdsValue = dealerIds || [];
    let seqStatusValue = seqStatus || [];
    let seqConfIdsValue = seqConfIds || [];
    let securityLevelValue = securityLevel || [];

    let securityPromotionIdValue = (securityPromotionId == 'all') ? '' : securityPromotionId;
    let securityPlanIdsValue = (securityPlanIds == 'all') ? '' : securityPlanIds;
    let salesChannelIdsValue = salesChannelIds.value || '';

    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.promotion.security.securityDetail}?page=${page}&size=${size}&startTime=${startTime}&endTime=${endTime}&securityPromotionId=${securityPromotionIdValue}&securityPlanIds=${securityPlanIdsValue}&dealerIds=${dealerIdsValue}&shopIds=${shopIdsValue}&productIds=${productIdsValue}&salesChannelIds=${salesChannelIdsValue}&seqStatus=${seqStatusValue}&seqConfIds=${seqConfIdsValue}&eseSeqNum=${eseSeqNum}&provinceCode=${provinceCode}&cityCode=${cityCode}&dealerName=${dealerName}&securityLevel=${securityLevelValue}`
    }).pipe(
      map(res => actions.securityDetail.SUCCESS(res.response)),
      catchError(error => of(actions.securityDetail.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);

//单码统计类表
export const codeSingleListEpic = actions$ => actions$.pipe(
  ofType(actions.codeSingleList.REQUEST.toString()),
  mergeMap(action => {
    actionApi();
    const { page = 0, size = 20, securityPromotionId = '', securityPlanIds = '', productIds = [], eseSeqNum = [], securityLevel = '' } = action.payload;

    let productIdsValue = productIds || [];
    let securityLevelValue = securityLevel || [];

    let securityPromotionIdValue = (securityPromotionId == 'all') ? '' : securityPromotionId;
    let securityPlanIdsValue = (securityPlanIds == 'all') ? '' : securityPlanIds;

    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.promotion.security.codeSingle}?page=${page}&size=${size}&securityPromotionId=${securityPromotionIdValue}&securityPlanIds=${securityPlanIdsValue}&productIds=${productIdsValue}&eseSeqNum=${eseSeqNum}&securityLevel=${securityLevelValue}`
    }).pipe(
      map(res => actions.codeSingleList.SUCCESS(res.response)),
      catchError(error => of(actions.codeSingleList.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);

//单码统计类表异步下载
export const ccodeSingleDownEpic = actions$ => actions$.pipe(
  ofType(actions.codeSingleDown.REQUEST.toString()),
  mergeMap(action => {
    actionApi();
    const { callback } = action.payload;
    const { page = 0, size = 20, securityPromotionId = '', securityPlanIds = '', productIds = [], eseSeqNum = [], securityLevel = '' } = action.payload.data;

    let productIdsValue = productIds || [];
    let securityLevelValue = securityLevel || [];

    let securityPromotionIdValue = (securityPromotionId == 'all') ? '' : securityPromotionId;
    let securityPlanIdsValue = (securityPlanIds == 'all') ? '' : securityPlanIds;

    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.promotion.security.codeSingleDown}?page=${page}&size=${size}&securityPromotionId=${securityPromotionIdValue}&securityPlanIds=${securityPlanIdsValue}&productIds=${productIdsValue}&eseSeqNum=${eseSeqNum}&securityLevel=${securityLevelValue}`
    }).pipe(
      map(res => {
          callback && callback()
          return actions.codeSingleDown.SUCCESS(res.response || []) 
      }),
      catchError(error => of(actions.codeSingleDown.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);

// 防伪明细异步下载
export const SecurityDetailDownEpic = actions$ => actions$.pipe(
  ofType(actions.SecurityDetailDown.REQUEST.toString()),
  mergeMap(action => {
    actionApi();
    const { callback } = action.payload;
    const { page = 0, size = 20, startTime = '', endTime = '', securityPromotionId = '', securityPlanIds = '', dealerIds = [], dealerName = '', shopIds = [], productIds = [], salesChannelIds = [], seqStatus = [], seqConfIds = [], eseSeqNum = [], provinceCode = [], cityCode = [], securityLevel = '' } = action.payload.data;

    let productIdsValue = productIds || [];
    let shopIdsValue = shopIds || [];
    let dealerIdsValue = dealerIds || [];
    let seqStatusValue = seqStatus || [];
    let seqConfIdsValue = seqConfIds || [];
    let securityLevelValue = securityLevel || [];

    let securityPromotionIdValue = (securityPromotionId == 'all') ? '' : securityPromotionId;
    let securityPlanIdsValue = (securityPlanIds == 'all') ? '' : securityPlanIds;
    let salesChannelIdsValue = salesChannelIds.value || '';

    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.promotion.security.SecurityDetailDown}?page=${page}&size=${size}&startTime=${startTime}&endTime=${endTime}&securityPromotionId=${securityPromotionIdValue}&securityPlanIds=${securityPlanIdsValue}&dealerIds=${dealerIdsValue}&shopIds=${shopIdsValue}&productIds=${productIdsValue}&salesChannelIds=${salesChannelIdsValue}&seqStatus=${seqStatusValue}&seqConfIds=${seqConfIdsValue}&eseSeqNum=${eseSeqNum}&provinceCode=${provinceCode}&cityCode=${cityCode}&dealerName=${dealerName}&securityLevel=${securityLevelValue}`
    }).pipe(
      map(res => {
        console.log(res)
          callback && callback()
          return actions.SecurityDetailDown.SUCCESS(res.response || []) 
      }),
      catchError(error => {
        console.log(error,'错误返回');
        of(actions.SecurityDetailDown.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
      })
    )
  }
  )
);



// Epics
export const loadByIdEpic = actions$ => actions$.pipe(
  ofType(actions.loadById.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.promotion.security.security}/${action.payload.callback ? action.payload.id : action.payload}`
    }).pipe(
      map(res => {
        action.payload.callback && action.payload.callback(res.response)
        return actions.loadById.SUCCESS(res.response)
      }),

      catchError(error => of(actions.loadById.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
      )
    )
  }
  )
);


export const loadListEpic = actions$ => actions$.pipe(
  ofType(actions.loadList.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { isVaild = true, promotionCategory, keywords = '', productKeywords = '', promotionType = '', promotionPublishStatus = '', page = 0, size = 20, sort = '' } = action.payload;
    const url = isVaild ? urls.promotion.security.security + '/published' : urls.promotion.security.security
    return ajax({
      ...ajaxBaseConfig,
      url: `${url}?promotionCategory=${promotionCategory}&keywords=${keywords}&productKeywords=${productKeywords}&promotionType=${promotionType}&promotionPublishStatus=${promotionPublishStatus}&page=${page}&size=${size}&sort=${sort}`
    }).pipe(
      map(res => actions.loadList.SUCCESS(res.response)),
      catchError(error => of(actions.loadList.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  })
);


export const editSecurityEpic = actions$ => actions$.pipe(
  ofType(actions.editSecurity.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { sendData, callback } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.promotion.security.security}`,
      method: sendData.id ? 'PUT' : 'POST',
      body: trims(sendData)
    }).pipe(
      map(res => {
        callback && callback(res.response)
        return actions.editSecurity.SUCCESS(res.response)
      }),
      catchError(error => of(actions.editSecurity.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
      )
    )
  }
  )
);


export const deleteSecurity = (actions$, store) => actions$.pipe(
  ofType(actions.deleteSecurity.REQUEST.toString()),
  mergeMap(action => {
    const { id, callback } = action.payload;
    actionApi()
    return ajax({
      ...ajaxBaseConfig,
      method: 'DELETE',
      url: `${urls.promotion.security.security}/${id}`
    }).pipe(
      map(res => {
        callback && callback(res.response)
        return actions.deleteSecurity.SUCCESS(res.response)
      }),
      catchError(error => of(actions.deleteSecurity.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
      )
    )
  }
  )
);

export const securityPauseEpic = (actions$, store) => actions$.pipe(
  ofType(actions.securityPause.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { id, callback } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      method: 'PATCH',
      url: `${urls.promotion.security.security}/${id}/pause`
    }).pipe(
      map(res => {
        callback && callback(res.response)
        return actions.securityPause.SUCCESS(res.response)
      }),
      catchError(error => of(actions.securityPause.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
      )
    )
  })
);

export const securityResumeEpic = (actions$, store) => actions$.pipe(
  ofType(actions.securityResume.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { id, callback } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      method: 'PATCH',
      url: `${urls.promotion.security.security}/${id}/resume`
    }).pipe(
      map(res => {
        callback && callback(res.response)
        return actions.securityResume.SUCCESS(res.response)
      }),
      catchError(error => of(actions.securityResume.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
      )
    )
  })
);

export const publishSecurityEpic = (actions$, store) => actions$.pipe(
  ofType(actions.publishSecurity.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { data, callback } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      method: 'PATCH',
      url: `${urls.promotion.security.security}/${data.id}/publish`,
      // url: `${urls.promotion.promotions.publish(action.payload.id)}?&authType=${action.payload.authType}`,
      body: data
    }).pipe(
      map(res => {
        callback && callback(res.response)
        return actions.publishSecurity.SUCCESS(res.response)
      }),
      catchError(error => of(actions.publishSecurity.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  })
);


export const loadPromotionHistoriesByIdEpic = (actions$, store) => actions$.pipe(
  ofType(actions.loadPromotionHistoriesById.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { keywords, promotionId, page = 0, size = 10, sort } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.promotion.promotionHistories.promotionHistories}?keywords=${keywords}&promotionId=${promotionId}&page=${page}&size=${size}&sort=${sort}`
    }).pipe(
      map(res => actions.loadPromotionHistoriesById.SUCCESS(res.response)),
      catchError(error => of(actions.loadPromotionHistoriesById.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  })
);





//保存环节方案
export const addLinkPlanEpic = actions$ => actions$.pipe(
  ofType(actions.addLinkPlan.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { data, callback } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      method: data.id ? 'PUT' : 'POST',
      body: data,
      url: `${urls.promotion.plans.plans}`
    }).pipe(
      map(res => {

        callback && callback()
        return actions.addLinkPlan.SUCCESS(res.response);

      }),
      catchError(error => of(actions.addLinkPlan.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
      )
    )
  }
  )
);


//环节方案不分页
export const linkPlanSelectEpic = actions$ => actions$.pipe(
  ofType(actions.linkPlanSelect.REQUEST.toString()),
  mergeMap(action => {
    actionApi();
    const { promotionId, callback } = action.payload;
    let url = `${urls.promotion.plans.plans}?promotionId=${promotionId}`

    return ajax({
      ...ajaxBaseConfig,
      url,
    }).pipe(
      map(res => {
        // res.response.reverse()
        callback && callback(res.response.content);

        return actions.linkPlanSelect.SUCCESS(res.response.content);
      }),
      catchError(error => of(actions.linkPlanSelect.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  })
);
//获取step2方案详情
export const getPlanDetailEpic = (actions$, state$) => actions$.pipe(
  ofType(actions.getPlanDetail.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { planId, callback } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      method: 'GET',
      url: `${urls.promotion.plans.plans}?planId=${planId}`
    }).pipe(
      map(res => {
        callback && callback(res.response);
        return actions.getPlanDetail.SUCCESS(res.response);
      }),
      catchError(error => of(actions.getPlanDetail.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))

    )
  }
  )
);





//排序方案
export const sortLinkPlanEpic = actions$ => actions$.pipe(
  ofType(actions.sortLinkPlan.REQUEST.toString()),
  mergeMap(action => {
    actionApi();
    const { callback, data } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.promotion.plans.sort}`,
      body: data,
      method: 'PUT'
    }).pipe(
      map(res => {
        callback && callback();

        return actions.sortLinkPlan.SUCCESS(res.response);
      }),
      catchError(error => of(actions.sortLinkPlan.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  })
);

//copystep2方案
export const copyPlanEpic = (actions$, state$) => actions$.pipe(
  ofType(actions.copyPlan.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { data, callback } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      method: 'PUT',
      body: data,
      url: `${urls.promotion.plans.plans}/copy`
    }).pipe(
      map(res => {
        callback && callback();

        return actions.copyPlan.SUCCESS(res.response);
      }),
      catchError(error => of(actions.copyPlan.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
      )
    )
  }
  )
);





//保存环节方案
export const linkPlanCheckRuleEpic = actions$ => actions$.pipe(
  ofType(actions.linkPlanCheckRule.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { data, callback } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      method: 'POST',
      body: data,
      url: `${urls.dataManage.links.linkRule}`
    }).pipe(
      map(res => {

        callback && callback()
        return actions.linkPlanCheckRule.SUCCESS(res.response);

      }),
      catchError(error => of(actions.linkPlanCheckRule.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
      )
    )
  }
  )
);





export const loadPrioritiesEpic = actions$ => actions$.pipe(
  ofType(actions.loadPriorities.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { promotionCategory, keywords, statues, page = 0, size = 20, sort = '', promotionType = '' } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.promotion.security.security}/priorities/${promotionCategory}?keywords=${keywords}&statues=${statues}&page=${page}&size=${size}&sort=${sort}&promotionType=${promotionType}`,
    }).pipe(
      map(res => actions.loadPriorities.SUCCESS(res.response)),
      catchError(error => of(actions.loadPriorities.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  })
);

export const updatePrioritiesEpic = (actions$, store) => actions$.pipe(
  ofType(actions.updatePriorities.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { callback, publishId, promotionPriority } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      method: 'PATCH',
      url: `${urls.promotion.security.security}/${publishId}/priority/${promotionPriority}`,
    }).pipe(
      map(res => {

        callback && callback()
        return actions.updatePriorities.SUCCESS(res.response);

      }),
      catchError(error => of(actions.updatePriorities.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  })
);
