import { createAction, createActions, handleActions, combineActions } from 'redux-actions';
import { fasterActions } from '../../utils';
import urls from '@/api/urls';


const moduleName = 'departments';

/**
 * 小程序开发-基础配置: 
 */
export const departments = fasterActions(moduleName, 'departments');
export const departmentsTree = fasterActions(moduleName, 'departmentsTree');
export const modifyDepartments = fasterActions(moduleName, 'modifyDepartments');


export const departmentSelections = fasterActions(moduleName, 'departmentSelections');
export const loadDepartmentTree = fasterActions(moduleName, 'departmentTree');

export const enableDepartment = fasterActions(moduleName, 'enableDepartment');


const initialState = {
    wechatLoad: false,
    departmentSelectionsList: [],
    departmentTreeData: {},
    departmentTree: []
}

const reducer = handleActions(
    {
        [departments.REQUEST]: (state, { payload }) => ({ ...state, payload, loading: true }),
        [departments.SUCCESS]: (state, { payload }) => ({ ...state, departmentsList: payload, loading: false }),
        [departments.FAIL]: (state, { payload }) => ({ ...state, error: payload, loading: false }),


        [departmentsTree.REQUEST]: (state, { payload }) => ({ ...state, payload }),
        [departmentsTree.SUCCESS]: (state, { payload }) => {
            return ({ ...state, departmentsTreeList: [payload] });
        },
        [departmentsTree.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

        [modifyDepartments.REQUEST]: (state, { payload }) => ({ ...state, payload, modifyDepartmentsLoaded: '' }),
        [modifyDepartments.SUCCESS]: (state, { payload }) => ({ ...state, modifyDepartmentsLoaded: payload }),
        [modifyDepartments.FAIL]: (state, { payload }) => ({ ...state, error: payload }),
        // 部门选框
        [departmentSelections.REQUEST]: (state, { payload }) => ({ ...state, }),
        [departmentSelections.SUCCESS]: (state, { payload }) => {
            const { content = [] } = payload;
            content.map(item => {
                item.text = item.name;
                item.value = item.id;
                return item;
            })
            return { ...state, departmentSelectionsList: content }
        },
        [departmentSelections.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

        // 部门树
        [loadDepartmentTree.REQUEST]: (state, { payload }) => ({ ...state, }),
        [loadDepartmentTree.SUCCESS]: (state, { payload }) => {
            const departmentTree = [];
            departmentTree.push(payload);
            const checkTree = (list = []) => {
                list.map(item => {
                    if (item.children.length < 1) delete item.children;
                    if (item.children) {
                        checkTree(item.children);
                    }
                })
            }
            checkTree(departmentTree);
            return ({ ...state, departmentTree })
        },
        [loadDepartmentTree.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

        [enableDepartment.REQUEST]: (state, { payload }) => ({ ...state, payload, modifyDepartmentsLoaded: '' }),
        [enableDepartment.SUCCESS]: (state, { payload }) => ({ ...state, modifyDepartmentsLoaded: payload }),
        [enableDepartment.FAIL]: (state, { payload }) => ({ ...state, error: payload }),
    },

    initialState
);

export default reducer;
