
import { createAction, createActions, handleActions, combineActions } from 'redux-actions';
import { fasterActions } from '../../utils';
import { factoriesEpic } from '../factory/factoryEpic';

const moduleName = 'users';
export const toggleChangePWDDialog = createAction(`${moduleName}/toggleChangePWDDialog`);
export const changePassword = fasterActions(moduleName, 'changePassword'); // 修改密码
export const resetPassword = fasterActions(moduleName, 'resetPassword'); // 重置密码
export const systemLogin = fasterActions(moduleName, 'systemLogin'); // 系统登录
export const systemSignOut = fasterActions(moduleName, 'systemSignOut'); // 系统登出
export const checkOpenId = fasterActions(moduleName, 'checkOpenId');
export const invitationCodes = fasterActions(moduleName, 'invitationCodes');
export const registration = fasterActions(moduleName, 'registration');
export const loadUsersSelections = fasterActions(moduleName, 'loadUsersSelections');

// 子账号管理
export const changeUserWord = createAction(`${moduleName}/changeUserWord`);
export const getUsersList = fasterActions(moduleName, 'getUsersList');
export const enableOrDisabledUser = fasterActions(moduleName, 'enableOrDisabledUser');
export const getUserData = fasterActions(moduleName, 'getUserData');
export const createUser = fasterActions(moduleName, 'createUser');
export const editUser = fasterActions(moduleName, 'editUser');
export const userInvitationCodes = fasterActions(moduleName, 'userInvitationCodes');
export const sendOutInviteCode = fasterActions(moduleName, 'sendOutInviteCode');
export const selfInfo = fasterActions(moduleName, 'selfInfo');
export const inviteCodeChance = fasterActions(moduleName, 'inviteCodeChance');
export const authorization = fasterActions(moduleName, 'authorization');
export const refresh = fasterActions(moduleName, 'refresh');
export const sendInvitationCode = fasterActions(moduleName, 'sendInvitationCode');

//五粮液单点登录
export const binding = fasterActions(moduleName, 'binding');
export const layout = fasterActions(moduleName, 'layout');

//解锁账号
export const unlock = fasterActions(moduleName, 'unlock');

//文件中心
export const getFileList = fasterActions(moduleName, 'getFileList');
export const delFile = fasterActions(moduleName, 'delFile');
export const downFile = fasterActions(moduleName, 'downFile');

const initialState = {
  showChangePWDDialog: false,
  // 子账号管理
  userSearch: {
    page: 0,
    size: 20
  },
  userTableData: [],
  perPageCountList: [20, 50, 100], // 每页大小候选,
  permissionIds: [],

  //文件中心
  fileList: {},
}

// Reducer

const reducer = handleActions(
  {
    [checkOpenId.REQUEST]: (state, { payload }) => ({ ...state, }),
    [checkOpenId.SUCCESS]: (state, { payload }) => ({ ...state, checkOpenIdLoaded: true, openIds: payload }),
    [checkOpenId.FAIL]: (state, { payload }) => {
      const { ecode = '', emsg } = payload || {};
      // 60102  数据不存在  60701  用户已禁用  60401  企业已禁用
      return ({ ...state, checkOpenIdLoaded: true, checkOpenIdError: '60102'.includes(ecode) ? '' : emsg });
    },
    [toggleChangePWDDialog]: (state, { payload }) => ({ ...state, showChangePWDDialog: payload }),
    // 修改密码
    [changePassword.REQUEST]: (state, { payload }) => ({ ...state, }),
    [changePassword.SUCCESS]: (state, { payload }) => ({ ...state, changePasswordInfo: payload }),
    [changePassword.FAIL]: (state, { payload }) => ({ ...state, codesError: payload }),

    // 重置密码
    [resetPassword.REQUEST]: (state, { payload }) => ({ ...state, }),
    [resetPassword.SUCCESS]: (state, { payload }) => ({ ...state, resetPasswordInfo: payload }),
    [resetPassword.FAIL]: (state, { payload }) => ({ ...state, codesError: payload }),

    [systemLogin.REQUEST]: (state, { payload }) => ({ ...state, openIds: {} }),
    // [systemLogin.SUCCESS]: (state, { payload }) => ({ ...state, systemLoginInfo: payload, userType: payload.userType, openIds: payload }),
    [systemLogin.SUCCESS]: (state, { payload }) => ({ ...state, systemLoginInfo: payload }),
    [systemLogin.FAIL]: (state, { payload }) => ({ ...state, codesError: payload }),

    [systemSignOut.REQUEST]: (state, { payload }) => ({ ...state, }),
    [systemSignOut.SUCCESS]: (state, { payload }) => ({ ...state, systemSignOutInfo: payload }),
    [systemSignOut.FAIL]: (state, { payload }) => ({ ...state, codesError: payload }),

    
    [invitationCodes.REQUEST]: (state, { payload }) => ({ ...state, }),
    [invitationCodes.SUCCESS]: (state, { payload }) => ({ ...state, invitationCodes: payload }),
    [invitationCodes.FAIL]: (state, { payload }) => ({ ...state, codesError: payload }),

    [registration.REQUEST]: (state, { payload }) => ({ ...state, }),
    [registration.SUCCESS]: (state, { payload }) => ({ ...state, registration: payload }),
    [registration.FAIL]: (state, { payload }) => ({ ...state, codesError: payload }),

    [loadUsersSelections.REQUEST]: (state, { payload }) => ({ ...state, }),
    [loadUsersSelections.SUCCESS]: (state, { payload }) => {
      let usersSelections = [], usersSelectionsOne = [];
      let usersSelectionsTwo = [{ text: '无（免审）', value: -1 }];
      if (payload.content) {
        payload.content.forEach(v => {
          usersSelectionsOne.push({
            text: v.addressBookName,
            value: v.addressBookId,
          });
          usersSelections.push({
            text: v.addressBookName,
            value: v.addressBookId,
          });
          usersSelectionsTwo.push({
            text: v.addressBookName,
            value: v.addressBookId,
          })
        });
      }
      usersSelectionsOne.unshift({ text: "ISV账号", value: -1 })
      return { ...state, usersSelections, usersSelectionsOne, usersSelectionsTwo }
    },
    [loadUsersSelections.FAIL]: (state, { payload }) => ({ ...state, codesError: payload }),


    // 子账号管理
    [changeUserWord]: (state, { payload }) => {
      return { ...state, userSearch: { ...state.userSearch, ...payload } }
    },
    // 列表
    [getUsersList.REQUEST]: (state, { payload }) => {
      return { ...state, userLoading: true, userSearch: payload }
    },
    [getUsersList.SUCCESS]: (state, { payload }) => {
      const { content = [], totalElements = 0 } = payload;
      return { ...state, userLoading: false, userTableData: content, totalElements }
    },
    [getUsersList.FAIL]: (state, { payload }) => ({ ...state, userLoading: false, codesError: payload }),
    // 禁用、启用
    [enableOrDisabledUser.REQUEST]: (state, { payload }) => {
      return { ...state }
    },
    [enableOrDisabledUser.SUCCESS]: (state, { payload }) => ({ ...state, }),
    [enableOrDisabledUser.FAIL]: (state, { payload }) => ({ ...state, error: payload, }),
    // 根据id 获取用户详情  
    [getUserData.REQUEST]: (state, { payload }) => {
      return { ...state }
    },
    [getUserData.SUCCESS]: (state, { payload }) => ({ ...state, }),
    [getUserData.FAIL]: (state, { payload }) => ({ ...state, error: payload, }),
    // 新建用户
    [createUser.REQUEST]: (state, { payload }) => {
      return { ...state }
    },
    [createUser.SUCCESS]: (state, { payload }) => ({ ...state, }),
    [createUser.FAIL]: (state, { payload }) => ({ ...state, error: payload, }),
    // 编辑用户
    [editUser.REQUEST]: (state, { payload }) => {
      return { ...state }
    },
    [editUser.SUCCESS]: (state, { payload }) => ({ ...state, }),
    [editUser.FAIL]: (state, { payload }) => ({ ...state, error: payload, }),
    // 生成邀请码 
    [userInvitationCodes.REQUEST]: (state, { payload }) => {
      return { ...state }
    },
    [userInvitationCodes.SUCCESS]: (state, { payload }) => ({ ...state, }),
    [userInvitationCodes.FAIL]: (state, { payload }) => ({ ...state, error: payload, }),
    // 发送邀请码
    [sendOutInviteCode.REQUEST]: (state, { payload }) => {
      return { ...state }
    },
    [sendOutInviteCode.SUCCESS]: (state, { payload }) => ({ ...state, }),
    [sendOutInviteCode.FAIL]: (state, { payload }) => ({ ...state, error: payload, }),
    // 当前用户信息查询
    [selfInfo.REQUEST]: (state, { payload }) => {
      return { ...state, openIds: {} }
    },
    [selfInfo.SUCCESS]: (state, { payload }) => ({ ...state, permissionIds: payload.permissionIds, userType: payload.userType, openIds: payload }),
    [selfInfo.FAIL]: (state, { payload }) => ({ ...state, error: payload, }),
    //查询当前已发送邀请码次数 
    [inviteCodeChance.REQUEST]: (state, { payload }) => {
      return { ...state }
    },
    [inviteCodeChance.SUCCESS]: (state, { payload }) => ({ ...state }),
    [inviteCodeChance.FAIL]: (state, { payload }) => ({ ...state, error: payload, }),




    //用户授权
    [authorization.REQUEST]: (state, { payload }) => {
      return { ...state }
    },
    [authorization.SUCCESS]: (state, { payload }) => ({ ...state, }),
    [authorization.FAIL]: (state, { payload }) => ({ ...state, error: payload, }),

    //用户邀请码刷新
    [refresh.REQUEST]: (state, { payload }) => {
      return { ...state }
    },
    [refresh.SUCCESS]: (state, { payload }) => ({ ...state, }),
    [refresh.FAIL]: (state, { payload }) => ({ ...state, error: payload, }),

    //发送邀请码
    [sendInvitationCode.REQUEST]: (state, { payload }) => {
      return { ...state }
    },
    [sendInvitationCode.SUCCESS]: (state, { payload }) => ({ ...state, }),
    [sendInvitationCode.FAIL]: (state, { payload }) => ({ ...state, error: payload, }),


    //五粮液单点登录

    [binding.REQUEST]: (state, { payload }) => { return { ...state } },
    [binding.SUCCESS]: (state, { payload }) => ({ ...state, }),
    [binding.FAIL]: (state, { payload }) => ({ ...state, error: payload, }),

    [layout.REQUEST]: (state, { payload }) => { return { ...state } },
    [layout.SUCCESS]: (state, { payload }) => ({ ...state, }),
    [layout.FAIL]: (state, { payload }) => ({ ...state, error: payload, }),


    //解锁账号
    [unlock.REQUEST]: (state, { payload }) => {
      return { ...state }
    },
    [unlock.SUCCESS]: (state, { payload }) => ({ ...state, }),
    [unlock.FAIL]: (state, { payload }) => ({ ...state, error: payload, }),


    //文件中心
    [getFileList.REQUEST]: (state, { payload }) => ({ ...state, loadingFile: true }),
    [getFileList.SUCCESS]: (state, { payload }) => ({ ...state, fileList: payload, loadingFile: false }),
    [getFileList.FAIL]: (state, { payload }) => ({ ...state, error: payload, loadingFile: false }),

    [delFile.REQUEST]: (state, { payload }) => { return { ...state } },
    [delFile.SUCCESS]: (state, { payload }) => ({ ...state, }),
    [delFile.FAIL]: (state, { payload }) => ({ ...state, error: payload, }),


    [downFile.REQUEST]: (state, { payload }) => { return { ...state } },
    [downFile.SUCCESS]: (state, { payload }) => ({ ...state, }),
    [downFile.FAIL]: (state, { payload }) => ({ ...state, error: payload, }),



  },
  initialState
)
export default reducer;

