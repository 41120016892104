import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap, map, catchError, concatMap } from 'rxjs/operators';
import { ajax } from '../../ajax';
import urls from '@/api/urls';
import * as uiActions from '../ui';
import queryString from 'query-string';
import * as actions from './codeLabel';
import { ajaxBaseConfig, actionApi } from '../../utils';


// 获取列表数据
export const getUserDefinedLabelDataEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.getUserDefinedLabelData.REQUEST),
    mergeMap(action => {
        actionApi()
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.code.codeLabel.userDefinedTag}?sort=id,desc&size=100`
        }).pipe(
            map(res => actions.getUserDefinedLabelData.SUCCESS(res.response)),
            catchError(error => of(actions.getUserDefinedLabelData.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)
// 禁用
export const enableUserDefinedLabelEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.enableUserDefinedLabel.REQUEST),

    mergeMap(action => {
        const { id, status } = action.payload;
        actionApi()
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.code.codeLabel.userDefinedTag}/${id}/status?status=${status}`,
            method: 'PATCH',
            // body: action.payload
        }).pipe(
            concatMap(res => [actions.enableUserDefinedLabel.SUCCESS(res.response), actions.getUserDefinedLabelData.REQUEST()]),
            catchError(error => of(actions.enableUserDefinedLabel.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)

// 删除
export const deleteGroupUserDefinedLabelEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.deleteGroupUserDefinedLabel.REQUEST),
    mergeMap(action => {
        const { groupId, status } = action.payload;
        actionApi()
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.code.codeLabel.userDefinedTag}/group/${groupId}`,
            method: 'DELETE',
        }).pipe(
            concatMap(res => [actions.deleteGroupUserDefinedLabel.SUCCESS(res.response), actions.getUserDefinedLabelData.REQUEST()]),
            catchError(error => of(actions.deleteGroupUserDefinedLabel.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)

// 推送设置部分数据
export const getPlatformsEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.getPlatforms.REQUEST),
    mergeMap(action => {
        actionApi()
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.code.codeLabel.userDefinedTag}/platforms`,
        }).pipe(
            concatMap(res => [actions.getPlatforms.SUCCESS(res.response)]),
            catchError(error => of(actions.getPlatforms.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)


//推送设置
export const bindGroupUserDefinedLabelEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.bindGroupUserDefinedLabel.REQUEST),
    mergeMap(action => {
        const { callback, data } = action.payload;
        actionApi()
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.code.codeLabel.userDefinedTag}/bind`,
            method: 'POST',
            body: data
        }).pipe(
            concatMap(res => {
                callback && callback(res.response.id || '');
                return [actions.bindGroupUserDefinedLabel.SUCCESS(res.response)]
            }),
            catchError(error => of(actions.bindGroupUserDefinedLabel.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)
//查看推送设置
export const getbindGroupUserDefinedLabelEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.getbindGroupUserDefinedLabel.REQUEST),
    mergeMap(action => {
        const { callback, id } = action.payload;
        actionApi()
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.code.codeLabel.userDefinedTag}/bind/${id}`,
        }).pipe(
            concatMap(res => {
                callback && callback(res.response.content || []);
                return [actions.getbindGroupUserDefinedLabel.SUCCESS(res.response)]
            }),
            catchError(error => of(actions.getbindGroupUserDefinedLabel.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)





// 推送标签
export const pushGroupUserDefinedLabelEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.pushGroupUserDefinedLabel.REQUEST),
    mergeMap(action => {
        const { callback, data } = action.payload;
        actionApi()
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.code.codeLabel.userDefinedTag}/push`,
            method: 'POST',
            body: data
        }).pipe(
            concatMap(res => {
                callback && callback(res.response.id || '');
                return [actions.pushGroupUserDefinedLabel.SUCCESS(res.response)]
            }),
            catchError(error => of(actions.pushGroupUserDefinedLabel.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)
//查看推送历史
export const pushrecordGroupUserDefinedLabelEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.pushrecordGroupUserDefinedLabel.REQUEST),
    mergeMap(action => {
        const { groupId = '', page = 0, size = 20, sort = '' } = action.payload;
        actionApi()
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.code.codeLabel.userDefinedTag}/push?groupId=${groupId}&page=${page}&size=${size}&=${sort}`,
        }).pipe(
            concatMap(res => {
                // callback && callback(res.response.content || []);
                return [actions.pushrecordGroupUserDefinedLabel.SUCCESS(res.response)]
            }),
            catchError(error => of(actions.pushrecordGroupUserDefinedLabel.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)




// (详情页面接口)
// 获取数据详情
export const getUserDefinedDetailEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.getUserDefinedDetail.REQUEST),
    mergeMap(action => {
        actionApi()
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.code.codeLabel.userDefinedTag}/${action.payload.id}`
        }).pipe(

            map(res => {
                return actions.getUserDefinedDetail.SUCCESS(res.response)
            }),
            catchError(error => of(actions.getUserDefinedDetail.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)
// 创建自定义标签
export const postUserDefinedLabelEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.postUserDefinedLabel.REQUEST),
    mergeMap(action => {
        const { callback, data } = action.payload;
        actionApi()
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.code.codeLabel.userDefinedTag}`,
            method: 'POST',
            body: data
        }).pipe(
            concatMap(res => {
                callback && callback(res.response.id || '');
                return [actions.postUserDefinedLabel.SUCCESS(res.response), actions.getUserDefinedDetail.REQUEST({ id: res.response.id || '' })]
            }),
            catchError(error => of(actions.postUserDefinedLabel.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)

// 修改自定义标签
export const patchUserDefinedLabelEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.patchUserDefinedLabel.REQUEST),
    mergeMap(action => {
        const { callback, data } = action.payload;
        actionApi()
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.code.codeLabel.userDefinedTag}`,
            method: 'POST',
            body: data
        }).pipe(
            concatMap(res => {
                callback && callback(res.response.id || '');
                // return [actions.patchUserDefinedLabel.SUCCESS(res.response)]
                return [actions.patchUserDefinedLabel.SUCCESS(res.response)]
            }),
            catchError(error => of(actions.patchUserDefinedLabel.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)


export const patchUserDefinedLabelStep2Epic = (actions$, state$) => actions$.pipe(
    ofType(actions.patchUserDefinedLabelStep2.REQUEST),
    mergeMap(action => {
        const { callback, data } = action.payload;
        actionApi()
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.code.codeLabel.userDefinedTag}/upsert-rules`,
            method: 'PUT',
            body: data
        }).pipe(
            concatMap(res => {
                callback && callback(res.response.id || '');
                // return [actions.patchUserDefinedLabelStep2.SUCCESS(res.response)]
                return [actions.patchUserDefinedLabelStep2.SUCCESS(res.response)]
            }),
            catchError(error => of(actions.patchUserDefinedLabelStep2.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)

export const patchUserDefinedLabelStep3Epic = (actions$, state$) => actions$.pipe(
    ofType(actions.patchUserDefinedLabelStep3.REQUEST),
    mergeMap(action => {
        const { callback, data } = action.payload;
        actionApi()
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.code.codeLabel.userDefinedTag}/update-group`,
            method: 'PUT',
            body: data
        }).pipe(
            concatMap(res => {
                callback && callback(res.response.id || '');
                // return [actions.patchUserDefinedLabel.SUCCESS(res.response)]
                return [actions.patchUserDefinedLabelStep3.SUCCESS(res.response)]
            }),
            catchError(error => of(actions.patchUserDefinedLabelStep3.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)
