import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap, map, catchError, concatMap } from 'rxjs/operators';
import { ajax } from '../../ajax';
import urls from '../../../api/urls';
import * as uiActions from '../ui';
import * as actions from './pointMallOrder';
import { ajaxBaseConfig, apiBaseConfig, actionApi } from '../../utils';

/**
 * TODO: 积分商城订单
 */

// Epics
// 分页查询订单记录
export const pointMallOrderEpic = actions$ => actions$.pipe(
  ofType(actions.pointMallOrder.REQUEST.toString()),
  mergeMap(action => {
    actionApi();
    const { orderStatus = '', orderCode = '', openIdOrPhone = '', pointMallId = '', dataRange: { from = '', to = '' } = {}, page, size } = action.payload;
    let url = `${urls.pointMall.order.order}?page=${page}&size=${size}&orderStatus=${orderStatus}&orderCode=${orderCode}&openIdOrPhone=${openIdOrPhone}&pointMallId=${pointMallId}&startTime=${from}&endTime=${to}&sort=id,desc`;
    return ajax({
      ...apiBaseConfig,
      url,
    }).pipe(
      map(res => actions.pointMallOrder.SUCCESS(res.response)),
      catchError(error => of(actions.pointMallOrder.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);


// 订单状态统计
export const pointMallOrderCountEpic = actions$ => actions$.pipe(
  ofType(actions.pointMallOrderCount.REQUEST.toString()),
  mergeMap(action => {
    actionApi();
    return ajax({
      ...apiBaseConfig,
      url: `${urls.pointMall.order.order}/status-count`,
    }).pipe(
      map(res => actions.pointMallOrderCount.SUCCESS(res.response)),
      catchError(error => of(actions.pointMallOrderCount.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);


/**
 * 导出订单列表
 * @param {*} actions$ 
 */
export const pointMallOrderExportEpic = actions$ => actions$.pipe(
  ofType(actions.pointMallOrderExport.REQUEST.toString()),
  mergeMap(action => {
    actionApi();
    const { email, startTime, endTime, orderStatus, exportStatus,exportRemark='',pointMallId='' } = action.payload;

    return ajax({
      ...apiBaseConfig,
      url: `${urls.pointMall.order.order}/export?email=${email}&pointMallId=${pointMallId}&startTime=${startTime}&endTime=${endTime}&orderStatus=${orderStatus}&exportStatus=${exportStatus}&exportRemark=${exportRemark}`,
      // body: action.payload,
    }).pipe(
      mergeMap(res =>{
        action.payload.callback && action.payload.callback()
        return [actions.pointMallOrderExport.SUCCESS(res.status)]
      } ),
      catchError(error => of(actions.pointMallOrderExport.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  })
);




/**
 * 订单备注
 * @param {*} actions$ 
 */
export const pointMallOrderRemarkEpic = actions$ => actions$.pipe(
  ofType(actions.pointMallOrderRemark.REQUEST.toString()),
  mergeMap(action => {
    actionApi();
    const { orderId, description } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.pointMall.order.order}/${orderId}/description?orderId=${orderId}&description=${description}`,
      // body: action.payload,
      method: 'PUT'
    }).pipe(
      mergeMap(res => [actions.pointMallOrderRemark.SUCCESS(res.status), uiActions.notification('修改成功', 'success')]),
      catchError(error => of(actions.pointMallOrderRemark.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  })
);

/**
 * 查询订单详情
 * @param {*} actions$ 
 */
export const pointMallOrderByIdEpic = actions$ => actions$.pipe(
  ofType(actions.pointMallOrderById.REQUEST.toString()),
  mergeMap(action => {
    actionApi();
    const { orderId } = action.payload;
    return ajax({
      ...apiBaseConfig,
      url: `${urls.pointMall.order.order}/${orderId}`,
    }).pipe(
      map(res => actions.pointMallOrderById.SUCCESS(res.response)),
      catchError(error => of(actions.pointMallOrderById.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);

/**
 * 查询订单详情
 * @param {*} actions$ 
 */
export const pointMallOrderDetailEpic = actions$ => actions$.pipe(
  ofType(actions.pointMallOrderDetail.REQUEST.toString()),
  mergeMap(action => {
    actionApi();
    const { orderId } = action.payload;
    return ajax({
      ...apiBaseConfig,
      url: `${urls.pointMall.order.order}/${orderId}/detail`,
    }).pipe(
      map(res => actions.pointMallOrderDetail.SUCCESS(res.response)),
      catchError(error => of(actions.pointMallOrderDetail.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);


/**
 * 查询第三方订单详情
 * @param {*} actions$ 
 */
export const pointMallOrderThirdDetailEpic = actions$ => actions$.pipe(
  ofType(actions.pointMallOrderThirdDetail.REQUEST.toString()),
  mergeMap(action => {
    actionApi();
    const { orderDetailId } = action.payload;
    return ajax({
      ...apiBaseConfig,
      url: `${urls.pointMall.order.order}/third-detail/${orderDetailId}`,
    }).pipe(
      map(res => actions.pointMallOrderThirdDetail.SUCCESS(res.response)),
      catchError(error => of(actions.pointMallOrderThirdDetail.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  })
);

/**
 * 登记物流
 * @param {*} actions$ 
 */
export const pointMallOrderRegLogisticsEpic = actions$ => actions$.pipe(
  ofType(actions.pointMallOrderRegLogistics.REQUEST.toString()),
  mergeMap(action => {
    actionApi();
    const { orderId, orderDetailIds, expressName, trackingNumber } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.pointMall.order.order}/${orderId}/regLogistics?orderId=${orderId}&orderDetailIds=${orderDetailIds}&expressName=${expressName}&trackingNumber=${trackingNumber}`,
      body: action.payload,
      method: 'POST'
    }).pipe(
      mergeMap(res => [actions.pointMallOrderRegLogistics.SUCCESS(res.status), uiActions.notification('登记成功', 'success')]),
      catchError(error => of(actions.pointMallOrderRegLogistics.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  })
);