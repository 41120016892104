import { createAction, createActions, handleActions, combineActions } from 'redux-actions';
import { fasterActions } from '../../utils';

const moduleName = 'promotions';

export const loadList = fasterActions(moduleName, 'loadList');
export const loadById = fasterActions(moduleName, 'loadById');
export const loadTimeAll = fasterActions(moduleName, 'loadTimeAll');
export const deletePromotionById = fasterActions(moduleName, 'deletePromotionById');
export const createTagPromotion = fasterActions(moduleName, 'createTagPromotion');
export const editTagPromotion = fasterActions(moduleName, 'editTagPromotion');
export const createOtherPromotion = fasterActions(moduleName, 'createOtherPromotion');
export const editOtherPromotion = fasterActions(moduleName, 'editOtherPromotion');
export const promotionPause = fasterActions(moduleName, 'promotionPause');
export const promotionResume = fasterActions(moduleName, 'promotionResume');

export const promotionRegulate = fasterActions(moduleName, 'promotionRegulate');

export const loadPromotionHistoriesById = fasterActions(moduleName, 'loadPromotionHistoriesById');
export const publishPromotion = fasterActions(moduleName, 'publishPromotion');
export const publishPromotionTime = fasterActions(moduleName, 'publishPromotionTime');
export const publishPromotionInStep3 = fasterActions(moduleName, 'publishPromotionInStep3');
export const publishPromotionTimeInStep3 = fasterActions(moduleName, 'publishPromotionTimeInStep3');
export const deletePublishTime = fasterActions(moduleName, 'deletePublishTime');
export const loadPriorities = fasterActions(moduleName, 'loadPriorities');
export const updatePriorities = fasterActions(moduleName, 'updatePriorities');
export const loadTagConfigsById = fasterActions(moduleName, 'loadTagConfigsById'); // 查询指定活动扫码抽奖配置
export const updateTagConfigsById = fasterActions(moduleName, 'updateTagConfigsById'); // 修改指定活动扫码抽奖配置
export const addTagConfigs = fasterActions(moduleName, 'addTagConfigs'); // 新增扫码抽奖配置
export const loadUserConfigsById = fasterActions(moduleName, 'loadUserConfigsById'); // 查询指定活动用户限制配置
export const updateUserConfigsById = fasterActions(moduleName, 'updateUserConfigsById'); // 修改指定活动用户限制配置
export const addUserConfigs = fasterActions(moduleName, 'addUserConfigs'); // 新增用户限制配置
export const checkMax = fasterActions(moduleName, 'checkMax'); // 查询活动中方案概率 和 金额都是最大的奖品概率
export const checkLimit = fasterActions(moduleName, 'checkLimit'); // 查询活动中未设置限制的大奖
export const checkTencentBsp = fasterActions(moduleName, 'checkTencentBsp'); // 查询使用天御策略，未配置天御账号的方案
export const checkTencentUserWeight = fasterActions(moduleName, 'checkTencentUserWeight'); // 查询UserWeight
export const checkTencentAppid = fasterActions(moduleName, 'checkTencentAppid'); // 检查 活动中红包奖品所用微信号与活动关联的微信号是否一致
export const checkTencentIntelligent = fasterActions(moduleName, 'checkTencentIntelligent'); // 查询Intelligent
export const checkPayment = fasterActions(moduleName, 'checkPayment'); // 检查方案中使用了红包，微信支付配置是否正常
export const productValid = fasterActions(moduleName, 'productValid');
export const draftCount = fasterActions(moduleName, 'draftCount'); // 统计草稿数量
export const draftCountTime = fasterActions(moduleName, 'draftCountTime'); // 统计待发布数量
export const bindCode = fasterActions(moduleName, 'bindCode'); // 绑定活动code
export const getPromotionSelection = fasterActions(moduleName, 'getPromotionSelection'); // 活动下拉列表
export const getTagCodeUsedPromotions = fasterActions(moduleName, 'getTagCodeUsedPromotions'); // 活动下拉列表
export const getPromotionSelectionByeseId = fasterActions(moduleName, 'getPromotionSelectionByeseId'); // 活动下拉列表。根据企业id

export const getHistory = fasterActions(moduleName, 'getHistory');

export const changeWarn = fasterActions(moduleName, 'changeWarn');


//多活动策略
export const loadMoreActiveList = fasterActions(moduleName, 'loadMoreActiveList');
export const loadMoreActiveId = fasterActions(moduleName, 'loadMoreActiveId');
export const createMoreActive = fasterActions(moduleName, 'createMoreActive');
export const editMoreActive = fasterActions(moduleName, 'editMoreActive');
export const ableMoreActive = fasterActions(moduleName, 'ableMoreActive');
export const deleteMoreActive = fasterActions(moduleName, 'deleteMoreActive');
export const getPromotionSelectionForMore = fasterActions(moduleName, 'getPromotionSelectionForMore');




const INIT_BYID = `${moduleName}/INIT_BYID`;

export function initByid() {
  return { type: INIT_BYID };
}


const initialState = {
  loading: false,
  listLoaded: false,
  loaded: false,
  byIdLoaded: false,
  byId: {},
  promotionSelection: [],
  promotionSelectionForMore: [],
  history: {},
  timeList: [],



  moreActiveList: [],
  loadMoreActiveListLoading: false,
  loadMoreActiveListLoaded: false,
}

// Reducer

const reducer = handleActions(
  {
    [INIT_BYID]: (state, { payload }) => ({ ...state, byId: {}, history: {}, historyStep0: '', historyStep1: '', historyStep2: '', historyStep3: '' }),
    [loadList.REQUEST]: (state, { payload }) => ({ ...state, loading: true }),
    [loadList.SUCCESS]: (state, { payload }) => ({ ...state, loading: false, listLoaded: true, list: payload }),
    [loadList.FAIL]: (state, { payload }) => ({ ...state, loading: false, listLoaded: false, error: payload }),
    [loadById.REQUEST]: (state, { payload }) => ({ ...state, byIdLoaded: true }),
    [loadById.SUCCESS]: (state, { payload }) => {
      let data = {
        PRODUCT: [],
        PARTICIPATING_DEALERS: [],
        PARTICIPATING_SHOP: [],
        PARTICIPATING_SALES_AREA: [],
        MARKETING_CHANNEL: [],
        CODE_STATUS: [],
        CODE_LABEL: [],

        VERIFY_SHOP: [],

        likeProduct: '-1',
        likeDealers: '-1',
        likeShop: '-1',
        likeChannel: '-1',

        likeShopagain: '-1',

        terminalRadio: -1,

      }
      let conditions = {};
      conditions = payload.conditions;
      if (conditions && conditions.PRODUCT) {
        for (let elem of conditions.PRODUCT.values()) {
          data.PRODUCT.push(elem.value)
          if (elem.notContain) {
            data.likeProduct = 'true'
          }
        }
      }
      if (conditions && conditions.PARTICIPATING_DEALERS) {
        for (let elem of conditions.PARTICIPATING_DEALERS.values()) {
          data.PARTICIPATING_DEALERS.push(elem.value)
          if (elem.notContain) {
            data.likeDealers = 'true'
          }
        }
      }
      if (conditions && conditions.PARTICIPATING_SHOP) {
        for (let elem of conditions.PARTICIPATING_SHOP.values()) {
          data.PARTICIPATING_SHOP.push(elem.value)
          if (elem.notContain) {
            data.likeShop = 'true'
          }
        }
      }
      if (conditions && conditions.VERIFY_SHOP) {
        data.terminalRadio = 'VERIFY_SHOP'
        for (let elem of conditions.VERIFY_SHOP.values()) {
          data.VERIFY_SHOP.push(elem.value)
          if (elem.notContain) {
            data.likeShopagain = 'true'
          }
        }
      }

      if (conditions && conditions.PARTICIPATING_SALES_AREA) {
        for (let elem of conditions.PARTICIPATING_SALES_AREA.values()) {
          data.PARTICIPATING_SALES_AREA.push(elem.value)
        }
      }
      if (conditions && conditions.MARKETING_CHANNEL) {
        for (let elem of conditions.MARKETING_CHANNEL.values()) {
          data.MARKETING_CHANNEL.push(elem.value)
          if (elem.notContain) {
            data.likeChannel = 'true'
          }
        }
      }
      if (conditions && conditions.CODE_STATUS) {
        for (let elem of conditions.CODE_STATUS.values()) {
          data.CODE_STATUS.push(elem.value)
        }
      }
      if (conditions && conditions.CODE_LABEL) {
        for (let elem of conditions.CODE_LABEL.values()) {
          data.CODE_LABEL.push(elem.value)
        }
      }


      return {
        ...state, byId: payload, byIdLoaded: true,
        selectProductValues: data.PRODUCT || [],
        likeProduct: data.likeProduct,
        selectDealersValues: data.PARTICIPATING_DEALERS || [],
        likeDealers: data.likeDealers,
        selectShopValues: data.PARTICIPATING_SHOP || [],
        likeShop: data.likeShop,

        selectShopValuesagain: data.VERIFY_SHOP || [],
        likeShopagain: data.likeShopagain,

        selectSalesAreaValues: data.PARTICIPATING_SALES_AREA || [],
        selectChannelValues: data.MARKETING_CHANNEL || [],
        likeChannel: data.likeChannel,
        selectCodeStatusValues: data.CODE_STATUS || [],
        selectCodeLabelValues: data.CODE_LABEL || [],

        terminalRadio: data.terminalRadio,
      };
    },
    [loadById.FAIL]: (state, { payload }) => ({ ...state, byIdLoaded: false, error: payload }),

    [loadTimeAll.REQUEST]: (state, { payload }) => ({ ...state, }),
    [loadTimeAll.SUCCESS]: (state, { payload }) => ({ ...state, timeList: payload }),
    [loadTimeAll.FAIL]: (state, { payload }) => ({ ...state, error: payload }),


    [promotionPause.REQUEST]: (state, { payload }) => ({ ...state, promotionPauseLoading: true, loadPromotionsParams: payload.params }),
    [promotionPause.SUCCESS]: (state, { payload }) => ({ ...state, promotionPauseLoading: false, promotionPauseLoaded: true }),
    [promotionPause.FAIL]: (state, { payload }) => ({ ...state, promotionPauseLoading: false, promotionPauseLoaded: false, error: payload }),


    [promotionRegulate.REQUEST]: (state, { payload }) => ({ ...state, loadPromotionsParams: payload.params }),
    [promotionRegulate.SUCCESS]: (state, { payload }) => ({ ...state, }),
    [promotionRegulate.FAIL]: (state, { payload }) => ({ ...state, error: payload }),


    [deletePromotionById.REQUEST]: (state, { payload }) => ({ ...state, deletePromotionByIdLoading: true, deletePromotionByIdLoaded: false, loadPromotionsParams: payload.params }),
    [deletePromotionById.SUCCESS]: (state, { payload }) => ({ ...state, deletePromotionByIdLoading: false, deletePromotionByIdLoaded: true }),
    [deletePromotionById.FAIL]: (state, { payload }) => ({ ...state, deletePromotionByIdLoading: false, deletePromotionByIdLoaded: false, error: payload }),
    [promotionResume.REQUEST]: (state, { payload }) => ({ ...state, promotionResumeLoading: true, loadPromotionsParams: payload.params }),
    [promotionResume.SUCCESS]: (state, { payload }) => ({ ...state, promotionResumeLoading: false, promotionResumeLoaded: true }),
    [promotionResume.FAIL]: (state, { payload }) => ({ ...state, promotionResumeLoading: false, promotionResumeLoaded: false, error: payload }),
    [loadPromotionHistoriesById.REQUEST]: (state, { payload }) => ({ ...state, loadPromotionHistoriesByIdLoading: true }),
    [loadPromotionHistoriesById.SUCCESS]: (state, { payload }) => ({ ...state, loadPromotionHistoriesByIdLoading: false, loadPromotionHistoriesByIdLoaded: true, promotionHistories: payload }),
    [loadPromotionHistoriesById.FAIL]: (state, { payload }) => ({ ...state, loadPromotionHistoriesByIdLoading: false, loadPromotionHistoriesByIdLoaded: false, error: payload }),
    [publishPromotion.REQUEST]: (state, { payload }) => ({ ...state, publishPromotionLoading: true, loadPromotionsParams: payload.params }),
    [publishPromotion.SUCCESS]: (state, { payload }) => ({ ...state, publishPromotionLoading: false, publishPromotionLoaded: true }),
    [publishPromotion.FAIL]: (state, { payload }) => ({ ...state, publishPromotionLoading: false, publishPromotionLoaded: false, error: payload }),
    [publishPromotionTime.REQUEST]: (state, { payload }) => ({ ...state, publishPromotionLoading: true, loadPromotionsParams: payload.params }),
    [publishPromotionTime.SUCCESS]: (state, { payload }) => ({ ...state, publishPromotionLoading: false, publishPromotionLoaded: true }),
    [publishPromotionTime.FAIL]: (state, { payload }) => ({ ...state, publishPromotionLoading: false, publishPromotionLoaded: false, error: payload }),


    [publishPromotionInStep3.REQUEST]: (state, { payload }) => ({ ...state, publishPromotionInStep3Loading: true, publishPromotionInStep3Loaded: false }),
    [publishPromotionInStep3.SUCCESS]: (state, { payload }) => ({ ...state, publishPromotionInStep3Loading: false, publishPromotionInStep3Loaded: true }),
    [publishPromotionInStep3.FAIL]: (state, { payload }) => ({ ...state, publishPromotionInStep3Loading: false, publishPromotionInStep3Loaded: false, error: payload }),

    [publishPromotionTimeInStep3.REQUEST]: (state, { payload }) => ({ ...state, publishPromotionInStep3Loading: true, publishPromotionInStep3Loaded: false }),
    [publishPromotionTimeInStep3.SUCCESS]: (state, { payload }) => ({ ...state, publishPromotionInStep3Loading: false, publishPromotionInStep3Loaded: true }),
    [publishPromotionTimeInStep3.FAIL]: (state, { payload }) => ({ ...state, publishPromotionInStep3Loading: false, publishPromotionInStep3Loaded: false, error: payload }),


    [deletePublishTime.REQUEST]: (state, { payload }) => ({ ...state, loadPromotionsParams: payload.params }),
    [deletePublishTime.SUCCESS]: (state, { payload }) => ({ ...state, }),
    [deletePublishTime.FAIL]: (state, { payload }) => ({ ...state, error: payload }),


    [loadPriorities.REQUEST]: (state, { payload }) => ({ ...state, loadPrioritiesLoading: true }),
    [loadPriorities.SUCCESS]: (state, { payload }) => ({ ...state, loadPrioritiesLoading: false, loadPrioritiesLoaded: true, priorities: payload }),
    [loadPriorities.FAIL]: (state, { payload }) => ({ ...state, loadPrioritiesLoading: false, loadPrioritiesLoaded: false, error: payload }),
    [updatePriorities.REQUEST]: (state, { payload }) => ({ ...state, updatePrioritiesLoading: true, loadPrioritiesParams: payload.params }),
    [updatePriorities.SUCCESS]: (state, { payload }) => ({ ...state, updatePrioritiesLoading: false, updatePrioritiesLoaded: true }),
    [updatePriorities.FAIL]: (state, { payload }) => ({ ...state, updatePrioritiesLoading: false, updatePrioritiesLoaded: false, error: payload }),
    [loadTagConfigsById.REQUEST]: (state, { payload }) => ({ ...state, loadTagConfigsByIdLoading: true, loadTagConfigsByIdLoaded: false, configs: null, historyConfigs: null }),
    [loadTagConfigsById.SUCCESS]: (state, { payload }) => {
      let configs = { ...payload };
      configs.userConfig ? configs.userConfig.policyTypes ? configs.userConfig.policyTypes = configs.userConfig.policyTypes.split(',') : configs.userConfig.policyTypes = [] : ''

      return { ...state, loadTagConfigsByIdLoading: false, loadTagConfigsByIdLoaded: true, configs: configs, historyConfigs: configs }
    },
    [loadTagConfigsById.FAIL]: (state, { payload }) => ({ ...state, loadTagConfigsByIdLoading: false, loadTagConfigsByIdLoaded: false, error: payload }),
    [updateTagConfigsById.REQUEST]: (state, { payload }) => ({ ...state, updateTagConfigsByIdLoading: true, updateTagConfigsByIdLoaded: false }),
    [updateTagConfigsById.SUCCESS]: (state, { payload }) => ({ ...state, updateTagConfigsByIdLoading: false, updateTagConfigsByIdLoaded: true }),
    [updateTagConfigsById.FAIL]: (state, { payload }) => ({ ...state, updateTagConfigsByIdLoading: false, updateTagConfigsByIdLoaded: false, error: payload }),
    [addTagConfigs.REQUEST]: (state, { payload }) => ({ ...state, addTagConfigsLoading: true, addTagConfigsLoaded: false }),
    [addTagConfigs.SUCCESS]: (state, { payload }) => ({ ...state, addTagConfigsLoading: false, addTagConfigsLoaded: true }),
    [addTagConfigs.FAIL]: (state, { payload }) => ({ ...state, addTagConfigsLoading: false, addTagConfigsLoaded: false, error: payload }),
    [loadUserConfigsById.REQUEST]: (state, { payload }) => ({ ...state, loadUserConfigsByIdLoading: true, configs: null, historyConfigs: null }),
    [loadUserConfigsById.SUCCESS]: (state, { payload }) => {

      let configs2 = { ...payload };
      configs2.policyTypes ? configs2.policyTypes = configs2.policyTypes.split(',') : configs2.policyTypes = []

      return { ...state, loadUserConfigsByIdLoading: false, loadUserConfigsByIdLoaded: true, configs: configs2, historyConfigs: configs2 }

    },

    [loadUserConfigsById.FAIL]: (state, { payload }) => ({ ...state, loadUserConfigsByIdLoading: false, loadUserConfigsByIdLoaded: false, error: payload }),
    [updateUserConfigsById.REQUEST]: (state, { payload }) => ({ ...state, updateUserConfigsByIdLoading: true, updateUserConfigsByIdLoaded: false }),
    [updateUserConfigsById.SUCCESS]: (state, { payload }) => ({ ...state, updateUserConfigsByIdLoading: false, updateUserConfigsByIdLoaded: true }),
    [updateUserConfigsById.FAIL]: (state, { payload }) => ({ ...state, updateUserConfigsByIdLoading: false, updateUserConfigsByIdLoaded: false, error: payload }),
    [addUserConfigs.REQUEST]: (state, { payload }) => ({ ...state, addUserConfigsLoading: true, addUserConfigsLoaded: false }),
    [addUserConfigs.SUCCESS]: (state, { payload }) => ({ ...state, addUserConfigsLoading: false, addUserConfigsLoaded: true }),
    [addUserConfigs.FAIL]: (state, { payload }) => ({ ...state, addUserConfigsLoading: false, addUserConfigsLoaded: false, error: payload }),
    [checkMax.REQUEST]: (state, { payload }) => ({ ...state, checkMaxLoading: true }),
    [checkMax.SUCCESS]: (state, { payload }) => ({ ...state, checkMaxLoading: false, checkMaxLoaded: true, checkMax: payload }),
    [checkMax.FAIL]: (state, { payload }) => ({ ...state, checkMaxLoading: false, checkMaxLoaded: false, error: payload }),
    [checkLimit.REQUEST]: (state, { payload }) => ({ ...state, checkLimitLoading: true }),
    [checkLimit.SUCCESS]: (state, { payload }) => ({ ...state, checkLimitLoading: false, checkLimitLoaded: true, checkLimit: payload }),
    [checkLimit.FAIL]: (state, { payload }) => ({ ...state, checkLimitLoading: false, checkLimitLoaded: false, error: payload }),
    [checkTencentBsp.REQUEST]: (state, { payload }) => ({ ...state, checkTencentBspLoading: true }),
    [checkTencentBsp.SUCCESS]: (state, { payload }) => ({ ...state, checkTencentBspLoading: false, checkTencentBspLoaded: true, checkTencentBsp: payload }),
    [checkTencentBsp.FAIL]: (state, { payload }) => ({ ...state, checkTencentBspLoading: false, checkTencentBspLoaded: false, error: payload }),

    [checkTencentUserWeight.REQUEST]: (state, { payload }) => ({ ...state, }),
    [checkTencentUserWeight.SUCCESS]: (state, { payload }) => ({ ...state, checkTencentUserWeight: payload }),
    [checkTencentUserWeight.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

    [checkTencentAppid.REQUEST]: (state, { payload }) => ({ ...state, }),
    [checkTencentAppid.SUCCESS]: (state, { payload }) => ({ ...state, checkTencentAppid: payload }),
    [checkTencentAppid.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

    [checkTencentIntelligent.REQUEST]: (state, { payload }) => ({ ...state, }),
    [checkTencentIntelligent.SUCCESS]: (state, { payload }) => ({ ...state, checkTencentIntelligent: payload }),
    [checkTencentIntelligent.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

    [checkPayment.REQUEST]: (state, { payload }) => ({ ...state, checkPaymentLoading: true }),
    [checkPayment.SUCCESS]: (state, { payload }) => ({ ...state, checkPaymentLoading: false, checkPaymentLoaded: true, checkPayment: payload }),
    [checkPayment.FAIL]: (state, { payload }) => ({ ...state, checkPaymentLoading: false, checkPaymentLoaded: false, error: payload }),
    [draftCount.REQUEST]: (state, { payload }) => ({ ...state, draftCountLoading: true }),
    [draftCount.SUCCESS]: (state, { payload }) => ({ ...state, draftCountLoading: false, draftCountLoaded: true, draftCount: payload }),
    [draftCount.FAIL]: (state, { payload }) => ({ ...state, draftCountLoading: false, draftCountLoaded: false, error: payload }),

    [draftCountTime.REQUEST]: (state, { payload }) => ({ ...state, draftCountLoading: true }),
    [draftCountTime.SUCCESS]: (state, { payload }) => ({ ...state, draftCountLoading: false, draftCountLoaded: true, draftCountTime: payload }),
    [draftCountTime.FAIL]: (state, { payload }) => ({ ...state, draftCountLoading: false, draftCountLoaded: false, error: payload }),



    [getHistory.REQUEST]: (state, { payload }) => ({ ...state }),
    [getHistory.SUCCESS]: (state, { payload }) => {
      const newState = { ...state };
      newState[`historyStep${payload.step - 1}`] = payload.res;
      return newState;
    },
    [getHistory.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

    [bindCode.REQUEST]: (state, { payload }) => ({ ...state, payload, bindCodeLoaded: false }),
    [bindCode.SUCCESS]: (state, { payload }) => ({ ...state, bindCodeLoaded: payload }),
    [bindCode.FAIL]: (state, { payload }) => ({ ...state, error: payload }),
    // 活动下拉列表
    [getPromotionSelection.REQUEST]: (state, { payload }) => ({ ...state, payload, promotionSelectionLoading: true }),
    [getPromotionSelection.SUCCESS]: (state, { payload }) => {
      let selector = []
      if (payload) {
        for (let elem of payload.values()) {
          selector.push({ ...elem, id: elem.promotionId, value: elem.promotionId, text: elem.name })
        }
      }
      return ({ ...state, promotionSelection: selector, promotionSelectionLoading: false })
    },
    [getPromotionSelection.FAIL]: (state, { payload }) => ({ ...state, error: payload, promotionSelectionLoading: false }),

    // 会员标签被活动使用列表
    [getTagCodeUsedPromotions.REQUEST]: (state, { payload }) => ({ ...state, payload, tagCodeUsedPromotionsloading: true }),
    [getTagCodeUsedPromotions.SUCCESS]: (state, { payload }) => ({ ...state, tagCodeUsedPromotions: payload.content || [], tagCodeUsedPromotionsloading: false }),
    [getTagCodeUsedPromotions.FAIL]: (state, { payload }) => ({ ...state, error: payload, tagCodeUsedPromotionsloading: false }),

    // 活动下拉列表ESEID
    [getPromotionSelectionByeseId.REQUEST]: (state, { payload }) => ({ ...state, payload, }),
    [getPromotionSelectionByeseId.SUCCESS]: (state, { payload }) => {
      let selector = []
      if (payload) {
        for (let elem of payload.values()) {
          selector.push({ ...elem, id: elem.id, value: elem.id, text: elem.name })
        }
      }
      return ({ ...state, promotionSelectionByeseId: selector })
    },
    // [getPromotionSelectionByeseId.SUCCESS]: (state, { payload }) => ({ ...state, promotionSelectionByeseId: payload.content || []}),
    [getPromotionSelectionByeseId.FAIL]: (state, { payload }) => ({ ...state, error: payload, }),


    [changeWarn.REQUEST]: (state, { payload }) => ({ ...state, changewarnLoaded: false, }),
    [changeWarn.SUCCESS]: (state, { payload }) => ({ ...state, changewarnLoaded: true, }),
    [changeWarn.FAIL]: (state, { payload }) => ({ ...state, changewarnLoaded: false, error: payload }),



    [loadMoreActiveList.REQUEST]: (state, { payload }) => ({ ...state, loadMoreActiveListLoading: true }),
    [loadMoreActiveList.SUCCESS]: (state, { payload }) => ({ ...state, loadMoreActiveListLoading: false, loadMoreActiveListLoaded: true, moreActiveList: payload }),
    [loadMoreActiveList.FAIL]: (state, { payload }) => ({ ...state, loadMoreActiveListLoading: false, loadMoreActiveListLoaded: false, error: payload }),

    [loadMoreActiveId.REQUEST]: (state, { payload }) => ({ ...state }),
    [loadMoreActiveId.SUCCESS]: (state, { payload }) => ({ ...state }),
    [loadMoreActiveId.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

    [createMoreActive.REQUEST]: (state, { payload }) => ({ ...state }),
    [createMoreActive.SUCCESS]: (state, { payload }) => ({ ...state }),
    [createMoreActive.FAIL]: (state, { payload }) => ({ ...state }),
    [editMoreActive.REQUEST]: (state, { payload }) => ({ ...state }),
    [editMoreActive.SUCCESS]: (state, { payload }) => ({ ...state }),
    [editMoreActive.FAIL]: (state, { payload }) => ({ ...state }),
    [ableMoreActive.REQUEST]: (state, { payload }) => ({ ...state }),
    [ableMoreActive.SUCCESS]: (state, { payload }) => ({ ...state }),
    [ableMoreActive.FAIL]: (state, { payload }) => ({ ...state }),

    [deleteMoreActive.REQUEST]: (state, { payload }) => ({ ...state }),
    [deleteMoreActive.SUCCESS]: (state, { payload }) => ({ ...state }),
    [deleteMoreActive.FAIL]: (state, { payload }) => ({ ...state }),
    [getPromotionSelectionForMore.REQUEST]: (state, { payload }) => ({ ...state }),
    [getPromotionSelectionForMore.SUCCESS]: (state, { payload }) => {
      let selector = []
      if (payload) {
        for (let elem of payload.values()) {
          selector.push({ ...elem, id: elem.id, value: elem.id, text: elem.name })
        }
      }
      return ({ ...state, promotionSelectionForMore: selector })
    },
    [getPromotionSelectionForMore.FAIL]: (state, { payload }) => ({ ...state }),
  },
  initialState
)
export default reducer;

