import { handleActions } from 'redux-actions';
import { fasterActions } from '../../utils';

const moduleName = 'codedb';

export const loadCodedb = fasterActions(moduleName, 'loadCodedb');
export const loadCodedbSelect = fasterActions(moduleName, 'loadCodedbSelect');
export const createCodedb = fasterActions(moduleName, 'createCodedb');
export const editCodedb = fasterActions(moduleName, 'editCodedb');


export const loadUserdb = fasterActions(moduleName, 'loadUserdb');
export const loadUserdbSelect = fasterActions(moduleName, 'loadUserdbSelect');
export const loadUserdbId = fasterActions(moduleName, 'loadUserdbId');
export const createUserdb = fasterActions(moduleName, 'createUserdb');
export const editUserdb = fasterActions(moduleName, 'editUserdb');
export const getmongoUser = fasterActions(moduleName, 'getmongoUser');
export const createMongoUser = fasterActions(moduleName, 'createMongoUser');
export const editMongoUser = fasterActions(moduleName, 'editMongoUser');
// 码状态--stateCode
// 表格
export const stateTableCode = fasterActions(moduleName, 'stateTableCode'); 
// 修改
export const stateEdit = fasterActions(moduleName, 'stateEdit');
// 新增
export const stateIncrease = fasterActions(moduleName, 'stateIncrease');
// 搜索
// export const stateQuery = fasterActions(moduleName, 'stateQuery');


const initialState = {
  codedbList: {},
  codedbListSelect: {},
  codedbIdList: {},
  loadCodedbLoading: false,
  loadCodedbLoaded: false,
  // 码列表
  stateCodeList: {},
  // 列表分页
  factoryPageListParam: {
    page: 0,
    size: 20,
    sort: 'id,desc',
    factoryType: ''
  },


  userdbList: {},
  userdbListSelect: '',
  userdbIdList: {},
  loadUserdbLoading: false,
  loadUserdbLoaded: false,

  mongoUser: {},
}


const reducer = handleActions(
  {
    [loadCodedb.REQUEST]: (state, { payload }) => ({ ...state, loadCodedbLoading: true }),
    [loadCodedb.SUCCESS]: (state, { payload }) => ({ ...state, loadCodedbLoading: false, loadCodedbLoaded: true, codedbList: payload }),
    [loadCodedb.FAIL]: (state, { payload }) => ({ ...state, loadCodedbLoading: false, loadCodedbLoaded: false, error: payload }),


    [loadCodedbSelect.REQUEST]: (state, { payload }) => ({ ...state, loadCodedbLoading: true }),
    [loadCodedbSelect.SUCCESS]: (state, { payload }) => ({ ...state, loadCodedbLoading: false, loadCodedbLoaded: true, codedbListSelect: payload }),
    [loadCodedbSelect.FAIL]: (state, { payload }) => ({ ...state, loadCodedbLoading: false, loadCodedbLoaded: false, error: payload }),



    [createCodedb.REQUEST]: (state, { payload }) => ({ ...state, }),
    [createCodedb.SUCCESS]: (state, { payload }) => ({ ...state, }),
    [createCodedb.FAIL]: (state, { payload }) => ({ ...state, error: payload }),
    
    [editCodedb.REQUEST]: (state, { payload }) => ({ ...state, }),
    [editCodedb.SUCCESS]: (state, { payload }) => ({ ...state, }),
    [editCodedb.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

    // 码状态--stateCode
    [stateTableCode.REQUEST]: (state, { payload }) => ({ ...state, loadCodedbLoading: true , payload }),
    [stateTableCode.SUCCESS]: (state, { payload }) => ({ ...state, loadCodedbLoading: false, loadCodedbLoaded: true, stateCodeList: payload }),
    [stateTableCode.FAIL]: (state, { payload }) => ({ ...state, loadCodedbLoading: false, loadCodedbLoaded: false, error: payload }),


    // [stateQuery.REQUEST]: (state, { payload }) => ({ ...state, loadCodedbLoading: true }),
    // [stateQuery.SUCCESS]: (state, { payload }) => ({ ...state, loadCodedbLoading: false, loadCodedbLoaded: true, stateQuery: payload }),
    // [stateQuery.FAIL]: (state, { payload }) => ({ ...state, loadCodedbLoading: false, loadCodedbLoaded: false, error: payload }),


    [stateIncrease.REQUEST]: (state, { payload }) => ({ ...state, }),
    [stateIncrease.SUCCESS]: (state, { payload }) => ({ ...state, }),
    [stateIncrease.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

    [stateEdit.REQUEST]: (state, { payload }) => ({ ...state, }),
    [stateEdit.SUCCESS]: (state, { payload }) => ({ ...state, }),
    [stateEdit.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

    // [loadSeqnumPasses.REQUEST]: (state, { payload }) => ({ ...state,  loadSeqnumPassesLoading: true }),
    // [loadSeqnumPasses.SUCCESS]: (state, { payload }) => ({ ...state, loadSeqnumPassesLoading: false, loadSeqnumPassesLoaded: true, applyPasses: payload }),
    // [loadSeqnumPasses.FAIL]: (state, { payload }) => ({ ...state, loadSeqnumPassesLoading: false, loadSeqnumPassesLoaded: false, error: payload }),


    [loadUserdb.REQUEST]: (state, { payload }) => ({ ...state, loadUserdbLoading: true }),
    [loadUserdb.SUCCESS]: (state, { payload }) => ({ ...state, loadUserdbLoading: false, loadUserdbLoaded: true, userdbList: payload }),
    [loadUserdb.FAIL]: (state, { payload }) => ({ ...state, loadUserdbLoading: false, loadUserdbLoaded: false, error: payload }),


    [loadUserdbSelect.REQUEST]: (state, { payload }) => ({ ...state, loadUserdbLoading: true }),
    [loadUserdbSelect.SUCCESS]: (state, { payload }) => ({ ...state, loadUserdbLoading: false, loadUserdbLoaded: true, userdbListSelect: payload }),
    [loadUserdbSelect.FAIL]: (state, { payload }) => ({ ...state, loadUserdbLoading: false, loadUserdbLoaded: false, error: payload }),


    [loadUserdbId.REQUEST]: (state, { payload }) => ({ ...state }),
    [loadUserdbId.SUCCESS]: (state, { payload }) => ({ ...state, userdbIdList: payload }),
    [loadUserdbId.FAIL]: (state, { payload }) => ({ ...state, error: payload }),




    [createUserdb.REQUEST]: (state, { payload }) => ({ ...state, }),
    [createUserdb.SUCCESS]: (state, { payload }) => ({ ...state, }),
    [createUserdb.FAIL]: (state, { payload }) => ({ ...state, error: payload }),
    [editUserdb.REQUEST]: (state, { payload }) => ({ ...state, }),
    [editUserdb.SUCCESS]: (state, { payload }) => ({ ...state, }),
    [editUserdb.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

    [getmongoUser.REQUEST]: (state, { payload }) => ({ ...state, }),
    [getmongoUser.SUCCESS]: (state, { payload }) => ({ ...state, mongoUser: payload ? payload : {}}), 
    [getmongoUser.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

    [createMongoUser.REQUEST]: (state, { payload }) => ({ ...state, }),
    [createMongoUser.SUCCESS]: (state, { payload }) => ({ ...state, }),
    [createMongoUser.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

    [editMongoUser.REQUEST]: (state, { payload }) => ({ ...state, }),
    [editMongoUser.SUCCESS]: (state, { payload }) => ({ ...state, }),
    [editMongoUser.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

  },
  initialState
)

export default reducer;