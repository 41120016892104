import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap, map, catchError, concatMap } from 'rxjs/operators';
import { ajax } from '../../ajax';
import urls from '@/api/urls';
import * as uiActions from '../ui';
import queryString from 'query-string';
import * as actions from './identification';
import { ajaxBaseConfig, actionApi } from '../../utils';

/**
 * TODO: 通讯录管理
 */

// 分页列表
export const loadIdentificationListEpic = (actions$, state$) => actions$.pipe(
  ofType(actions.loadIdentificationList.REQUEST.toString()),
  mergeMap(action => {
 
    actionApi();
    const { addressBookPageParam = {}, addressBookSearchKey } = state$.value.identification;
    
    return (
      ajax({
        ...ajaxBaseConfig,
        url: `${urls.data.dismgtsvc.dismgtsvc}?page=${addressBookPageParam.page}&size=${addressBookPageParam.size}&word=${addressBookSearchKey}&status=${addressBookPageParam.status||""}&type=${addressBookPageParam.type||""}&account=${addressBookPageParam.account||""}`,
      }).pipe(
        map(res => actions.loadIdentificationList.SUCCESS(res.response)),
        catchError(error => of(actions.loadIdentificationList.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);
//导出
export const loadIdentificationExportEpic = (actions$, state$) => actions$.pipe(
  ofType(actions.loadIdentificationExport.REQUEST.toString()),
  mergeMap((action) => {
    actionApi();
    const { addressBookPageParam = {}, addressBookSearchKey } = state$.value.identification;

    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.data.dismgtsvc.dismgtsvcExport}?word=${addressBookSearchKey}&status=${addressBookPageParam.status||""}&type=${addressBookPageParam.type||""}&account=${addressBookPageParam.account||""}`,
    }).pipe(
      concatMap(res => [actions.loadIdentificationExport.SUCCESS(res.response), uiActions.notification('文件导出成功，请注意查收', 'success')]),
      catchError(error => of(actions.loadIdentificationExport.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
      )
    )
  })
);
export const loadIdentificationList1Epic = (actions$, state$) => actions$.pipe(
  ofType(actions.loadIdentificationList1.REQUEST.toString()),
  mergeMap(action => {
 
    actionApi();
    const { addressBookPageParam = {}, addressBookSearchKey } = state$.value.identification;
    
    return (
      ajax({
        ...ajaxBaseConfig,
        url: `${urls.data.dismgtsvc.dismgtsvc}?page=${addressBookPageParam.page}&size=${addressBookPageParam.size}&word=${addressBookSearchKey}&status=${addressBookPageParam.status||""}&type=${addressBookPageParam.type||""}&account=${addressBookPageParam.account||""}`,
      }).pipe(
        map(res => actions.loadIdentificationList1.SUCCESS(res.response)),
        catchError(error => of(actions.loadIdentificationList1.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);
// 仓库
export const loadwarehouseSelectionsEpic = (actions$, state$) => actions$.pipe(
  ofType(actions.loadwarehouseSelections.REQUEST.toString()),
  mergeMap(action => {
    actionApi();
    const {callback} =action.payload;
    console.log(action.payload)
    // 企业  调用部门接口
    if(action.payload.sourceType=="ENTERPRISE" ||action.payload=="ENTERPRISE"){
      return (
        ajax({
          ...ajaxBaseConfig,
          url: `${urls.data.departments.selections}?status=ENABLE&name=`,
        }).pipe(
          map(res => {
            callback && callback(res.response || {});
            return actions.loadwarehouseSelections.SUCCESS(res.response || {});
          }),
          catchError(error => of(actions.loadwarehouseSelections.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
      )
    }
    // 仓库
    if(action.payload.sourceType=="WAREHOUSE" ||action.payload=="WAREHOUSE"){
      return (
        ajax({
          ...ajaxBaseConfig,
          url: `${urls.data.tmfeign.tmfeign}?name=`,
        }).pipe(
          map(res => {
            callback && callback(res.response || {});
            return actions.loadwarehouseSelections.SUCCESS(res.response || {});
          }),
          // map(res => actions.loadrolesSelections.SUCCESS(res.response)),)
          catchError(error => of(actions.loadwarehouseSelections.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
      )

    }else if(action.payload.sourceType=="DEALER"||action.payload=="DEALER"){
      // 经销商
      return (
        ajax({
          ...ajaxBaseConfig,
          url: `${urls.data.tmfeign.dealerlist}?name=`,
        }).pipe(
          map(res => {
            callback && callback(res.response || {});
            return actions.loadwarehouseSelections.SUCCESS(res.response || {});
          }),
          // map(res => actions.loadrolesSelections.SUCCESS(res.response)),)
          catchError(error => of(actions.loadwarehouseSelections.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
      )
    }else if(action.payload.sourceType=="TERMINAL_SHOP"||action.payload=="TERMINAL_SHOP"){
      // 门店
      return (
        ajax({
          ...ajaxBaseConfig,
          url: `${urls.data.tmfeign.shoplist}?name=`,
        }).pipe(
          map(res => {
            callback && callback(res.response || {});
            return actions.loadwarehouseSelections.SUCCESS(res.response || {});
          }),
          // map(res => actions.loadrolesSelections.SUCCESS(res.response)),)
          catchError(error => of(actions.loadwarehouseSelections.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
      )

    } else if(action.payload.sourceType=="FACTORYS"||action.payload=="FACTORYS"){
      return (
        ajax({
          ...ajaxBaseConfig,
          url: `${urls.data.tmfeign.factory}?name=`,
        }).pipe(
          map(res => {
            callback && callback(res.response || {});
            return actions.loadwarehouseSelections.SUCCESS(res.response || {});
          }),
          catchError(error => of(actions.loadwarehouseSelections.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
      )

    }

  
  })
);
// 角色下拉
export const loadrolesSelectionsEpic = (actions$, state$) => actions$.pipe(
ofType(actions.loadrolesSelections.REQUEST.toString()),
  mergeMap(action => {
    actionApi();
    const {wordName,sourceType} = action.payload;
    
      return (
        ajax({
          ...ajaxBaseConfig,
          url: `${urls.data.getlist.getlist}?word=${wordName}&sourceType=${sourceType}`,
         
        }).pipe(
          map(res => actions.loadrolesSelections.SUCCESS(res.response)),
          catchError(error => of(actions.loadrolesSelections.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
      )
    

   
  })
); 
// id
export const loadIdentificationIdEpic = actions$ => actions$.pipe(
  ofType(actions.loadIdentificationId.REQUEST.toString()),
  mergeMap(action => { 
    actionApi()
    const { id, callback } = action.payload;
    return (
      ajax({
        ...ajaxBaseConfig,
        url: `${urls.data.dismgtsvc.dismgtsvcId}/${id}`,
      }).pipe(
        map(res => {
          callback && callback(res.response || {});
          return actions.loadIdentificationId.SUCCESS(res.response || {});
        }),
        catchError(error => of(actions.loadIdentificationId.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);
// 修改密码
export const loadpasswordEpic = (actions$, state$) => actions$.pipe(
  ofType(actions.loadpassword.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
//  console.log(action.payload)
    return (
      ajax({
        ...ajaxBaseConfig,
        url: `${urls.data.dismgtsvc.dismgtsvcpassword}?id=${action.payload.id}&password=${action.payload.password}`,
      }).pipe(
        concatMap(res => {
          action.payload.callback && action.payload.callback();
          return [actions.loadIdentificationList.SUCCESS(res.response), actions.loadIdentificationList.REQUEST({ ...state$.value.addressBooks.addressBookPageParam})];
        }),
        catchError(error => of(actions.editAddressbook.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  }
  )
);
// 编辑
export const editAddressbookEpic = (actions$,state$) => actions$.pipe(
  ofType(actions.editAddressbook.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    return (
      ajax({
        ...ajaxBaseConfig,
        url: `${urls.data.dismgtsvc.dismgtsvcupdate}`,
        method: 'post',
        body: action.payload.data
      }).pipe(
        concatMap(res => {
          action.payload.callback && action.payload.callback();
          return [actions.editAddressbook.SUCCESS(res.response), actions.loadIdentificationList.REQUEST({ ...state$.value.addressBooks.addressBookPageParam})];
        }),
        catchError(error => of(actions.editAddressbook.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);
//禁用，启用
export const enableOrDisableAddressBooksEpic = (actions$,state$) => actions$.pipe(
  ofType(actions.enableOrDisableAddressBooks.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { id = '', status = '' } = action.payload;
    return (
      ajax({
        ...ajaxBaseConfig,
        url: `${urls.data.dismgtsvc.dismgtsvcStatus}?id=${id}&status=${status}`,
      }).pipe(
        concatMap(res => [actions.enableOrDisableAddressBooks.SUCCESS(res.response), actions.loadIdentificationList.REQUEST({ ...state$.value.addressBooks.addressBookPageParam, page: 0})]),
        catchError(error => of (actions.enableOrDisableAddressBooks.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);
//创建
export const createAddressbookEpic = (actions$,state$) => actions$.pipe(
  ofType(actions.createAddressbook.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    return (
      ajax({
        ...ajaxBaseConfig,
        url: `${urls.data.dismgtsvc.dismgtsvcEdit}`,
        method: 'POST',
        body: action.payload.data
      }).pipe(
        concatMap(res => {
          action.payload.callback && action.payload.callback();
          return[actions.createAddressbook.SUCCESS(res.response), actions.loadIdentificationList.REQUEST( { ...state$.value.addressBooks.addressBookPageParam, page: 0})]
        }),
        catchError(error => of(actions.createAddressbook.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);
//编辑

